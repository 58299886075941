import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Provider, useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import 'regenerator-runtime/runtime'
import Cookies from 'js-cookie'
import { store } from './store'
import Home from './containers/home'
import Questionnaire from './containers/questionnaire'
import Result from './containers/result'
import PdfDisplayer from './containers/pdfDisplayer'
import PageNotFound from './containers/pageNotFound'
import GetAdvice from './containers/getAdvice'
import { APP_URL } from './utils/constants'
import { getCookieMeta } from './utils/helpers'
import { setAppText } from './store/actions'
import { CookiesProvider } from './utils/cookiesProvider'

function ProtectedRoute(props) {
  if (!Cookies.get('existingUser')) {
    return <Redirect to="/home" />
  }
  return <Route {...props} />
}

function Routes() {
  const { isLoading, ...commonState } = useSelector((state) => state.common)
  const userId = Number(Cookies.get('userId'))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAppText())
  }, [dispatch])

  useEffect(() => {
    if (!userId) {
      axios
        .post(`${APP_URL}/session`)
        .then(
          ({ data: { id } }) => Cookies.set('userId', id, getCookieMeta()),
          Cookies.set('existingUser', true, getCookieMeta()),
        )
    }
  }, [userId])

  return (
    <>
      {isLoading ? (
        <div className="spinner a-spinner">
          <span className="a-spinner__circle" />
          <div className="a-spinner__text">&hellip;loading</div>
        </div>
      ) : (
        <Router>
          <Switch>
            <Route exact path="/">
              <Home
                homeLabelData={commonState.home}
                footerLabelData={commonState.footer}
              />
            </Route>
            <Route exact path="/home">
              <Home
                homeLabelData={commonState.home}
                footerLabelData={commonState.footer}
              />
            </Route>
            <Route path="/vraag">
              <Questionnaire
                questionnaireLableData={commonState.questionnaire}
                modalLabelData={commonState.modal_meer_info}
              />
            </Route>
            <ProtectedRoute path="/adviesappartementen">
              <GetAdvice
                adviceLabelData={commonState.advice}
                footerLabelData={commonState.footer}
              />
            </ProtectedRoute>
            <ProtectedRoute exact path="/advies">
              <Result
                resultLabelData={commonState.result}
                footerLabelData={commonState.footer}
                pdfLabelData={commonState.pdf}
              />
            </ProtectedRoute>
            <ProtectedRoute exact path="/pdf/:id">
              <PdfDisplayer />
            </ProtectedRoute>
            <Route path="*">
              <PageNotFound
                notFoundLabelData={commonState.not_found}
                footerLabelData={commonState.footer}
              />
            </Route>
          </Switch>
        </Router>
      )}
    </>
  )
}

function App() {
  useEffect(() => {
    const path = window.location.pathname

    if (path.includes('/vraag') && !Cookies.get('userId')) {
      Cookies.remove('localState', getCookieMeta())
      Cookies.remove('localImprovements', getCookieMeta())
      localStorage.clear()
      location.replace('/')
    } else if (path.includes('/advies') && !Cookies.get('userId')) {
      location.replace('/')
    }
  }, [])

  return (
    <CookiesProvider>
      <Provider store={store}>
        <Routes />
      </Provider>
    </CookiesProvider>
  )
}

export default App
