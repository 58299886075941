import React, { useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'

import Cookies from 'js-cookie'

import { sessionPutCall } from '../../utils/apiActions'

import './styles.scss'
import { APP_URL } from '../../utils/constants'

function getDataUri(url) {
  return new Promise((resolve) => {
    const image = new Image()
    image.setAttribute('crossOrigin', 'anonymous') // getting images from external domain

    image.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = this.naturalWidth
      canvas.height = this.naturalHeight

      // next three lines for white background in case png has a transparent background
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = '#fff' /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      canvas.getContext('2d').drawImage(this, 0, 0)

      resolve(canvas.toDataURL('image/jpeg'))
    }

    image.src = url
  })
}

const OntvangEmailCard = ({ emailCard = {} }) => {
  const [selected, setSelected] = useState(false)
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')
  const [clicked, setClicked] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const userId = Number(Cookies.get('userId'))
  const localImprovements = Cookies.get('localImprovements')
  const { result } = useSelector((state) => state)

  const {
    title = '',
    checkBoxText = '',
    privacyText = '',
    emailLabel = '',
    buttonText = '',
    success_text = '',
    error_text = '',
  } = emailCard

  const sendEmail = async () => {
    setIsLoading(true)
    const date = new Date()
    const pdfDom = document.getElementById('pdf-dom')
    pdfDom.style.display = 'block !important'
    const email = document.getElementById('email').value
    const subscribed =
      document.getElementById('ontvangUpdate').checked === true ? 'on' : 'off'
    if (email == '') {
      return false
    }

    sessionPutCall(userId, {
      email,
      news_letter_subscription: subscribed === 'on',
    })

    const payload = new FormData()
    payload.append('email', email)
    payload.append('subscribed', subscribed)
    payload.append('pdfDom', pdfDom.innerHTML)
    payload.append('userId', Cookies.get('userId'))
    fetch(`${APP_URL}/email/send`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'multipart/form-data',
      },
      body: payload, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.status === 200) {
          setSelected(true)
          localStorage.removeItem('optionNo')
        } else {
          setError(true)
          // alert('Error sending email')
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        pdfDom.style.display = 'none'
        setIsLoading(false)
      })
    // doc.addImage(
    //   img,
    //   'PNG',
    //   0,
    //   0,
    //   pdfDom.scrollWidth,
    //   pdfDom.scrollHeight,
    //   '',
    //   'SLOW',
    // )
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleInput = (event) => {
    const { value: val } = event.target
    const validEmail = validateEmail(val)
    if (!validEmail) {
      setError(true)
    } else {
      setValue(val)
      setError(false)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setClicked(!clicked)
      document.getElementById('ontvangUpdate').checked = clicked
    }
  }

  const handleKeyPressEmail = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendEmail()
    }
  }

  useEffect(() => {
    setSelected(false)
  }, [localImprovements])

  useEffect(() => {
    if (isLoading) {
      document.getElementById('root')
        ? document.getElementById('root').setAttribute('aria-hidden', false)
        : null
      document.getElementById('root')
        ? (document.getElementsByTagName('body')[0].style.overflow = 'hidden')
        : null
    } else {
      document.getElementById('root')
        ? document.getElementById('root').setAttribute('aria-hidden', false)
        : null
      document.getElementById('root')
        ? (document.getElementsByTagName('body')[0].style.overflow = 'initial')
        : null
    }
  }, [isLoading])

  return (
    <>
      {isLoading && (
        <div className="spinner a-spinner">
          <span className="a-spinner__circle" />
          <div className="a-spinner__text">&hellip;loading</div>
        </div>
      )}
      <section
        id="email-card-overview"
        className="highlight cta-block mail highlight--left m-alert m-alert--inline m-alert--success"
      >
        <div className="highlight__inner">
          <form action="#" method="#">
            <h2
              className="h4 u-margin-bottom-xs"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {!selected ? (
              <>
                <div className="form-item a-input u-margin-bottom-xs">
                  <label
                    htmlFor="email"
                    dangerouslySetInnerHTML={{ __html: emailLabel }}
                    className="a-input__label"
                  />
                  <div className="form-columns">
                    <div className="form-item-column a-input__wrapper">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="email"
                        aria-describedby="email--error-message"
                        onChange={handleInput}
                        onKeyPress={handleKeyPressEmail}
                      />
                    </div>

                    {error && (
                      <div className="form-item-column">
                        <div
                          className="a-input__description u-margin-bottom-xs u-text-danger field-message error"
                          role="alert"
                          id="email--error-message"
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: error_text }}
                          />
                          <div className="accolade " />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-item a-input u-margin-bottom-xs">
                  <div className="form-columns">
                    <div className="checkbox a-input__checkbox">
                      <input
                        type="checkbox"
                        id="ontvangUpdate"
                        name="ontvangUpdate"
                        className="checkbox"
                        onKeyPress={handleKeyPress}
                      />
                      <label
                        htmlFor="ontvangUpdate"
                        dangerouslySetInnerHTML={{ __html: checkBoxText }}
                      />
                    </div>
                  </div>
                </div>
                <p
                  className="u-margin-bottom"
                  dangerouslySetInnerHTML={{ __html: privacyText }}
                />
                <button
                  onClick={sendEmail}
                  id="email-btn"
                  type="button"
                  className="a-button a-button--s a-button--success button-email-card"
                  disabled={error || value.length === 0}
                  dangerouslySetInnerHTML={{ __html: buttonText }}
                />
              </>
            ) : (
              <div className="form-item-column ">
                <div
                  className="m-alert m-alert--success"
                  role="alert"
                >
                  <span class="m-alert__icon"><span class="ai" aria-hidden="true"><svg><use href="/public/icons.svg#ai-check-1" /></svg></span></span>
                  {success_text}
                </div>
              </div>
            )}
          </form>
        </div>
      </section>
    </>
  )
}

export default OntvangEmailCard
