import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { cityOptions } from '../../utils/constants'
import { homePageIcon } from '../../images/svg'
import { setStateFromCookies } from '../../store/actions'
import { appendRoute, getCookieMeta } from '../../utils/helpers'
import { sessionPutCall } from '../../utils/apiActions'

const HomeAddressForm = ({ homeLabelData }) => {
  const userId = Number(Cookies.get('userId'))
  const { handleSubmit, register, formState, errors } = useForm({
    mode: 'onChange',
  })
  const history = useHistory()
  const handleNextStep = () => {
    history.push({ pathname: appendRoute(1), state: { fromPage: 0 } })
  }

  const [suggestions, setSuggestions] = useState([])
  const [straatText, setStraatText] = useState('')
  const [straatValid, setStraatValid] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = (values) => {
    if (!straatValid && straatText.length) {
      sessionPutCall(userId, {
        address: `${values.straat} ${values.nummer}, ${values.city}${
          values.bus ? `, ${values.bus}` : ''
        }`,
      })

      handleNextStep()
      Cookies.set('homePageData', values, getCookieMeta())
    } else {
      setStraatValid(true)
    }
  }

  useEffect(() => {
    if (Cookies.get('homePageData')) {
      dispatch(setStateFromCookies(Cookies.get('homePageData')))
    }
  }, [dispatch, Cookies])

  const handleChange = (e) => {
    const { value } = e.target
    setStraatText(value)
    const selectedCity = document.getElementById('city-input').value
    if (value.length) {
      axios
        .get(
          `https://geo.api.vlaanderen.be/geolocation/v1/Suggestion?q=${value}, ${selectedCity} &c=25`,
        )
        .then((response) => {
          let newData = []
          const tempData = response.data.SuggestionResult
          newData = tempData.map((item) => item.split(',')[0])
          const found = newData.find((v) =>
            v.toLowerCase().includes(value.toLowerCase()),
          )
          if (found) {
            setSuggestions(newData)
            setStraatValid(false)
          } else {
            setStraatValid(true)
            setSuggestions([])
          }
        })
        .catch(() => {
          // console.log(error)
        })
    }
  }

  const suggestionSelected = (item) => {
    setSuggestions([])
    setStraatText(item)
    setStraatValid(false)
  }

  const handleBlur = ({ force = false } = {}) => {
    if (force || !straatText) {
      setStraatText('')
      setSuggestions([])
    }
  }

  const hideSuggestions = () => {
    setSuggestions([])
    setStraatText('')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-container">
        <fieldset>
          <legend dangerouslySetInnerHTML={{ __html: homeLabelData.adres }} />
          <div className="form-item-home">
            <div className="form-row">
              <div className="form-item stacked a-input has-icon-right u-margin-bottom">
                <label
                  htmlFor="city-input"
                  dangerouslySetInnerHTML={{ __html: homeLabelData.gemeente }}
                  className="a-input__label"
                />
                <div className="form-columns">
                  <div className={`form-item-column a-input__wrapper ${errors.city ? 'has-error' : ''}`}>
                      <select
                        onChange={() => handleBlur({ force: true })}
                        name="city"
                        id="city-input"
                        ref={register({
                          required:
                            'Het adres is niet geldig. Vul een geldig adres in Antwerpen in.',
                        })}
                        className={errors.city ? 'text error' : 'text'}
                      >
                        {cityOptions.map((option) => (
                          <option key={`${option}-id`} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <span className="ai"><svg width="100px" height="100px"><use href="/public/icons.svg#ai-arrow-down-1" /></svg></span>
                      {errors.city && (
                        <div
                          className="field-message error"
                          role="alert"
                          id="straat-message"
                        >
                          {errors.city.message}
                          <div className="accolade" />
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="form-item stacked u-margin-bottom a-input">
                <label
                  htmlFor="street-id"
                  dangerouslySetInnerHTML={{ __html: homeLabelData.straat }}
                  className="a-input__label"
                />
                <div className="form-columns a-input__wrapper">
                  <div className="form-item-column form-box">
                    <input
                      value={straatText}
                      type="text"
                      name="straat"
                      id="street-id"
                      className={straatValid ? 'text error' : 'text'}
                      onChange={(e) => handleChange(e)}
                      onBlur={() => handleBlur()}
                      autoComplete="off"
                      ref={register({})}
                    />
                    {suggestions.length > 0 && (
                      <div className="suggestions">
                        {suggestions.map((item) => (
                          <button
                            type="button"
                            key={item}
                            onMouseDown={() => suggestionSelected(item)}
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                    )}
                    {straatValid && (
                      <div
                        className="field-message error"
                        role="alert"
                        id="straat-message"
                      >
                        De straat naam is niet geldig. Vul een geldige straat
                        naam in van jouw gemeente.
                        <div className="accolade " />
                      </div>
                    )}
                  </div>
                  {suggestions.length > 0 && (
                    <button
                      type="button"
                      className="a-button"
                      onMouseDown={() => hideSuggestions()}
                    >
                      &nbsp;
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-item error stacked a-input u-margin-bottom">
                <label
                  className="a-input__label"
                  htmlFor="number-id-home"
                  dangerouslySetInnerHTML={{ __html: homeLabelData.nummer }}
                />
                <div className="form-columns">
                  <div className="form-item-column">
                    <input
                      type="text"
                      name="nummer"
                      id="number-id-home"
                      className={errors.nummer ? 'text error' : 'text'}
                      ref={register({
                        validate: (value) =>
                          parseInt(value, 10) >= 0 &&
                          parseInt(value, 10) <= 9999,
                        required:
                          'Uw antwoord moet bestaan uit cijfers en moet een geheel getal tussen 0 en 10.000',
                        pattern: {
                          value: /^[0-9a-zA-Z]+$/,
                          message:
                            'Uw antwoord moet bestaan uit cijfers en moet een geheel getal tussen 0 en 10.000',
                        },
                      })}
                    />
                  </div>
                  {errors.nummer && (
                    <div className="form-item-column">
                      <div
                        className="field-message error"
                        role="alert"
                        id="nummer-message"
                      >
                        {errors.nummer.type == 'validate'
                          ? 'Uw antwoord moet bestaan uit cijfers en moet een geheel getal tussen 0 en 10.000'
                          : errors.nummer.message}
                        <div className="accolade " />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-item stacked a-input">
                <label
                  className="a-input__label"
                  htmlFor="mailbox-id"
                  dangerouslySetInnerHTML={{
                    __html: `${homeLabelData.bus} <span className="label-optional">${homeLabelData.optioneel}</span>`,
                  }}
                />
                <div className="form-columns">
                  <div className="form-item-column">
                    <input
                      type="text"
                      name="bus"
                      id="mailbox-id"
                      className="text"
                      ref={register({})}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <figure className="center-image">{homePageIcon}</figure>
      </div>
      <p className="u-margin-top-xs">
        <strong
          dangerouslySetInnerHTML={{
            __html: `${homeLabelData.privacyText} <a href="${homeLabelData.privacyTarget}" target="_blank" alt="Link to privacy policy" class="no-icon">${homeLabelData.privacyLink}</a>.`,
          }}
        />
      </p>

      <div className="form-actions">
        <button
          type="submit"
          className="a-button a-button--s"
          disabled={!formState.isValid || straatValid}
        >
          <span
            dangerouslySetInnerHTML={{ __html: homeLabelData.buttonText }}
          />
        </button>
      </div>
    </form>
  )
}

export default HomeAddressForm
