import React from 'react'

/* hoofddak card */
export const step12Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
        <g clip-path="url(#clip0_68_4800)">
      <path
        d="M933.03 429.91L765.6 527.22L427.76 332.16L595.19 234.86L933.03 429.91Z"
        fill="#E8E6E0"
      />
      <path
        d="M933.03 429.91L932.28 695.2L764.84 792.5L765.6 527.22L933.03 429.91Z"
        fill="#D7D3CC"
      />
      <path
        d="M765.6 527.22L764.84 792.5L427.01 597.45L427.76 332.16L765.6 527.22Z"
        fill="#E8E6E0"
      />
      <path
        d="M445.39 535.43L444.55 978.39L767.22 791.28L764.74 382.09L445.39 535.43Z"
        fill="#D7D3CC"
      />
      <path
        d="M149.46 808.02L444.55 978.39L445.39 535.43L146.97 365.05L149.46 808.02Z"
        fill="#E8E6E0"
      />
      <path
        d="M764.74 382.09L441.22 195.5L318.43 168.5L146.97 365.05L445.39 535.43L617.38 330.98"
        fill="#B3B4B3"
      />
      <path
        d="M146.97 306.05L445.39 476.43L617.38 271.98L322.3 101.61L146.97 306.05Z"
        fill="#00A3DE"
      />
      <path
        d="M617.38 330.98L764.74 382.09L445.39 535.43L617.38 330.98Z"
        fill="#D7D3CC"
      />
      <path
        d="M322.3 101.61L617.38 271.98L764.74 323.09L441.22 138.5L322.3 101.61Z"
        fill="#00545E"
      />
      <path
        d="M532.86 927.36L618.54 876.96L618.92 637.77L533.08 688.77L532.86 927.36Z"
        fill="#E8E6E0"
      />
      <mask
        id="mask0_68_4800"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="660"
        y="582"
        width="61"
        height="163"
      >
        <path
          d="M661.04 744.23L660.68 616.18L719.67 582.12L720.04 710.17L661.04 744.23Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_4800)">
        <path
          d="M719.67 582.12L660.68 616.18L661.04 744.23L720.04 710.17L719.67 582.12Z"
          fill="#E8E6E0"
        />
      </g>
      <path
        d="M379.47 803.03L379.84 674.98L320.84 640.92L320.48 768.97L379.47 803.03Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask1_68_4800"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="320"
        y="640"
        width="60"
        height="164"
      >
        <path
          d="M379.47 803.03L379.84 674.98L320.84 640.92L320.48 768.97L379.47 803.03Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_68_4800)">
        <path
          d="M320.84 640.92L379.84 674.98L379.47 803.03L320.48 768.97L320.84 640.92Z"
          fill="#B3B4B3"
        />
      </g>
      <path
        d="M262.71 734.99L263.08 606.94L204.08 572.88L203.72 700.93L262.71 734.99Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask2_68_4800"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="203"
        y="572"
        width="61"
        height="163"
      >
        <path
          d="M262.71 734.99L263.08 606.94L204.08 572.88L203.72 700.93L262.71 734.99Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_68_4800)">
        <path
          d="M204.08 572.88L263.08 606.94L262.71 734.99L203.72 700.93L204.08 572.88Z"
          fill="#B3B4B3"
        />
      </g>
      <path
        d="M444.55 978.39L445.39 534.5"
        stroke="#B3B4B3"
        stroke-miterlimit="10"
      />
      <path
        d="M908.57 490.5L802.22 551.9L802.88 674.56L909.22 613.16L908.57 490.5Z"
        fill="#E8E6E0"
      />
      <path
        d="M146.97 306.05V314.1L445.22 484.5L618.3 278.66L764.74 329.38V323.09L617.38 271.98L445.22 476.5L146.97 306.05Z"
        fill="#00545E"
      />
      <path
        opacity="0.3"
        d="M672.96 1469.46L533.6 1388.99L498.46 1368.71L463.33 1348.43L428.2 1328.14L393.08 1307.87L357.95 1287.58L322.82 1267.3L287.69 1247.02L252.55 1226.73L217.43 1206.46L182.3 1186.17L147.17 1165.89L112.04 1145.61L76.9102 1125.32L41.7701 1105.04L6.65015 1084.76L-28.4799 1064.48L-63.6099 1044.2L-98.7399 1023.91L-133.87 1003.63L-169.01 983.35L-204.13 963.07L-239.26 942.79L-274.39 922.5L-309.52 902.22L-344.65 881.94L-379.79 861.65L-414.91 841.38L-450.04 821.09L-485.17 800.81L-520.3 780.53L-555.43 760.24L-590.56 739.96L-625.68 719.68L-660.82 699.4L-695.95 679.12L-731.08 658.83L-766.21 638.55L-801.33 618.27L-836.46 597.99L-871.6 577.71L-906.73 557.42L-1046.09 476.96L-906.73 557.42L-871.6 577.71L-836.46 597.99L-801.33 618.27L-766.21 638.55L-731.08 658.83L-695.95 679.12L-660.82 699.4L-625.68 719.68L-590.56 739.96L-555.43 760.24L-520.3 780.53L-485.17 800.81L-450.04 821.09L-414.91 841.38L-379.79 861.65L-344.65 881.94L-309.52 902.22L-274.39 922.5L-239.26 942.79L-204.13 963.07L-169.01 983.35L-133.87 1003.63L-98.7399 1023.91L-63.6099 1044.2L-28.4799 1064.48L6.65015 1084.76L41.7701 1105.04L76.9102 1125.32L112.04 1145.61L147.17 1165.89L182.3 1186.17L217.43 1206.46L252.55 1226.73L287.69 1247.02L322.82 1267.3L357.95 1287.58L393.08 1307.87L428.2 1328.14L463.33 1348.43L498.46 1368.71L533.6 1388.99L672.96 1469.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M707.86 1449.17L568.5 1368.71L533.37 1348.43L498.24 1328.14L463.1 1307.86L427.98 1287.58L392.85 1267.3L357.72 1247.02L322.59 1226.73L287.46 1206.45L252.34 1186.17L217.2 1165.89L182.07 1145.61L146.94 1125.32L111.81 1105.04L76.6801 1084.76L41.5601 1064.48L6.43005 1044.2L-28.71 1023.91L-63.84 1003.63L-98.97 983.35L-134.1 963.06L-169.22 942.79L-204.35 922.5L-239.49 902.22L-274.62 881.94L-309.75 861.65L-344.88 841.37L-380 821.09L-415.13 800.81L-450.27 780.53L-485.4 760.24L-520.53 739.96L-555.66 719.68L-590.78 699.4L-625.91 679.12L-661.05 658.83L-696.18 638.55L-731.31 618.27L-766.43 597.99L-801.56 577.71L-836.69 557.42L-871.82 537.14L-1011.19 456.68L-871.82 537.14L-836.69 557.42L-801.56 577.71L-766.43 597.99L-731.31 618.27L-696.18 638.55L-661.05 658.83L-625.91 679.12L-590.78 699.4L-555.66 719.68L-520.53 739.96L-485.4 760.24L-450.27 780.53L-415.13 800.81L-380 821.09L-344.88 841.37L-309.75 861.65L-274.62 881.94L-239.49 902.22L-204.35 922.5L-169.22 942.79L-134.1 963.06L-98.97 983.35L-63.84 1003.63L-28.71 1023.91L6.43005 1044.2L41.5601 1064.48L76.6801 1084.76L111.81 1105.04L146.94 1125.32L182.07 1145.61L217.2 1165.89L252.34 1186.17L287.46 1206.45L322.59 1226.73L357.72 1247.02L392.85 1267.3L427.98 1287.58L463.1 1307.86L498.24 1328.14L533.37 1348.43L568.5 1368.71L707.86 1449.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M742.77 1428.89L603.4 1348.43L568.27 1328.14L533.14 1307.86L498.01 1287.58L462.89 1267.3L427.76 1247.02L392.62 1226.73L357.49 1206.45L322.36 1186.17L287.24 1165.89L252.11 1145.61L216.98 1125.32L181.84 1105.04L146.71 1084.76L111.58 1064.47L76.4597 1044.2L41.3298 1023.91L6.19977 1003.63L-28.9302 983.35L-64.0703 963.06L-99.2003 942.78L-134.32 922.5L-169.45 902.22L-204.58 881.94L-239.71 861.65L-274.85 841.37L-309.98 821.09L-345.1 800.81L-380.23 780.53L-415.36 760.24L-450.49 739.96L-485.63 719.68L-520.76 699.39L-555.88 679.12L-591.01 658.83L-626.14 638.55L-661.27 618.27L-696.41 597.98L-731.53 577.71L-766.66 557.42L-801.79 537.14L-836.92 516.85L-976.28 436.39L-836.92 516.85L-801.79 537.14L-766.66 557.42L-731.53 577.71L-696.41 597.98L-661.27 618.27L-626.14 638.55L-591.01 658.83L-555.88 679.12L-520.76 699.39L-485.63 719.68L-450.49 739.96L-415.36 760.24L-380.23 780.53L-345.1 800.81L-309.98 821.09L-274.85 841.37L-239.71 861.65L-204.58 881.94L-169.45 902.22L-134.32 922.5L-99.2003 942.78L-64.0703 963.06L-28.9302 983.35L6.19977 1003.63L41.3298 1023.91L76.4597 1044.2L111.58 1064.47L146.71 1084.76L181.84 1105.04L216.98 1125.32L252.11 1145.61L287.24 1165.89L322.36 1186.17L357.49 1206.45L392.62 1226.73L427.76 1247.02L462.89 1267.3L498.01 1287.58L533.14 1307.86L568.27 1328.14L603.4 1348.43L742.77 1428.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M777.66 1408.61L638.3 1328.15L603.16 1307.87L568.03 1287.58L532.9 1267.3L497.78 1247.02L462.65 1226.74L427.52 1206.46L392.38 1186.17L357.25 1165.89L322.13 1145.61L287 1125.33L251.87 1105.05L216.74 1084.76L181.6 1064.48L146.47 1044.2L111.35 1023.92L76.2198 1003.64L41.0899 983.35L5.9599 963.07L-29.1801 942.79L-64.3101 922.5L-99.4301 902.23L-134.56 881.94L-169.69 861.66L-204.82 841.38L-239.95 821.09L-275.09 800.81L-310.21 780.53L-345.34 760.25L-380.47 739.97L-415.6 719.68L-450.73 699.4L-485.87 679.12L-520.99 658.84L-556.12 638.56L-591.25 618.27L-626.38 597.99L-661.51 577.7L-696.63 557.43L-731.77 537.14L-766.9 516.86L-802.03 496.58L-941.39 416.12L-802.03 496.58L-766.9 516.86L-731.77 537.14L-696.63 557.43L-661.51 577.7L-626.38 597.99L-591.25 618.27L-556.12 638.56L-520.99 658.84L-485.87 679.12L-450.73 699.4L-415.6 719.68L-380.47 739.97L-345.34 760.25L-310.21 780.53L-275.09 800.81L-239.95 821.09L-204.82 841.38L-169.69 861.66L-134.56 881.94L-99.4301 902.23L-64.3101 922.5L-29.1801 942.79L5.9599 963.07L41.0899 983.35L76.2198 1003.64L111.35 1023.92L146.47 1044.2L181.6 1064.48L216.74 1084.76L251.87 1105.05L287 1125.33L322.13 1145.61L357.25 1165.89L392.38 1186.17L427.52 1206.46L462.65 1226.74L497.78 1247.02L532.9 1267.3L568.03 1287.58L603.16 1307.87L638.3 1328.15L777.66 1408.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M812.56 1388.33L673.2 1307.87L638.07 1287.58L602.94 1267.3L567.8 1247.02L532.68 1226.74L497.55 1206.46L462.42 1186.17L427.29 1165.89L392.16 1145.61L357.04 1125.33L321.9 1105.05L286.77 1084.76L251.64 1064.48L216.51 1044.2L181.38 1023.91L146.26 1003.64L111.12 983.35L75.9897 963.07L40.8597 942.79L5.72974 922.5L-29.4003 902.22L-64.5203 881.94L-99.6603 861.66L-134.79 841.38L-169.92 821.09L-205.05 800.81L-240.18 780.52L-275.3 760.25L-310.43 739.97L-345.57 719.68L-380.7 699.4L-415.83 679.11L-450.96 658.83L-486.08 638.55L-521.21 618.27L-556.35 597.99L-591.48 577.7L-626.61 557.42L-661.73 537.14L-696.86 516.86L-731.99 496.58L-767.13 476.29L-906.49 395.83L-767.13 476.29L-731.99 496.58L-696.86 516.86L-661.73 537.14L-626.61 557.42L-591.48 577.7L-556.35 597.99L-521.21 618.27L-486.08 638.55L-450.96 658.83L-415.83 679.11L-380.7 699.4L-345.57 719.68L-310.43 739.97L-275.3 760.25L-240.18 780.52L-205.05 800.81L-169.92 821.09L-134.79 841.38L-99.6603 861.66L-64.5203 881.94L-29.4003 902.22L5.72974 922.5L40.8597 942.79L75.9897 963.07L111.12 983.35L146.26 1003.64L181.38 1023.91L216.51 1044.2L251.64 1064.48L286.77 1084.76L321.9 1105.05L357.04 1125.33L392.16 1145.61L427.29 1165.89L462.42 1186.17L497.55 1206.46L532.68 1226.74L567.8 1247.02L602.94 1267.3L638.07 1287.58L673.2 1307.87L812.56 1388.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M847.47 1368.04L708.1 1287.58L672.97 1267.3L637.84 1247.02L602.71 1226.73L567.59 1206.46L532.46 1186.17L497.32 1165.89L462.19 1145.61L427.06 1125.32L391.94 1105.05L356.81 1084.76L321.68 1064.48L286.54 1044.2L251.41 1023.91L216.28 1003.63L181.16 983.35L146.03 963.07L110.9 942.79L75.7597 922.5L40.6297 902.22L5.49969 881.93L-29.6203 861.66L-64.7503 841.37L-99.8803 821.09L-135.02 800.81L-170.15 780.52L-205.28 760.24L-240.4 739.96L-275.53 719.68L-310.66 699.4L-345.79 679.11L-380.93 658.83L-416.06 638.55L-451.18 618.27L-486.31 597.99L-521.44 577.7L-556.57 557.42L-591.71 537.14L-626.83 516.86L-661.96 496.58L-697.09 476.29L-732.22 456.01L-871.59 375.55L-732.22 456.01L-697.09 476.29L-661.96 496.58L-626.83 516.86L-591.71 537.14L-556.57 557.42L-521.44 577.7L-486.31 597.99L-451.18 618.27L-416.06 638.55L-380.93 658.83L-345.79 679.11L-310.66 699.4L-275.53 719.68L-240.4 739.96L-205.28 760.24L-170.15 780.52L-135.02 800.81L-99.8803 821.09L-64.7503 841.37L-29.6203 861.66L5.49969 881.93L40.6297 902.22L75.7597 922.5L110.9 942.79L146.03 963.07L181.16 983.35L216.28 1003.63L251.41 1023.91L286.54 1044.2L321.68 1064.48L356.81 1084.76L391.94 1105.05L427.06 1125.32L462.19 1145.61L497.32 1165.89L532.46 1186.17L567.59 1206.46L602.71 1226.73L637.84 1247.02L672.97 1267.3L708.1 1287.58L847.47 1368.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M882.37 1347.76L743.01 1267.3L707.87 1247.02L672.74 1226.73L637.61 1206.45L602.49 1186.17L567.36 1165.89L532.23 1145.61L497.09 1125.32L461.96 1105.04L426.84 1084.76L391.71 1064.48L356.58 1044.2L321.45 1023.91L286.32 1003.63L251.18 983.34L216.06 963.07L180.93 942.78L145.8 922.5L110.67 902.22L75.5398 881.93L40.3998 861.65L5.27985 841.37L-29.8502 821.09L-64.9802 800.81L-100.11 780.52L-135.24 760.24L-170.38 739.96L-205.5 719.68L-240.63 699.4L-275.76 679.11L-310.89 658.83L-346.02 638.55L-381.16 618.26L-416.28 597.99L-451.41 577.7L-486.54 557.42L-521.67 537.14L-556.8 516.85L-591.92 496.58L-627.05 476.29L-662.19 456.01L-697.32 435.73L-836.68 355.27L-697.32 435.73L-662.19 456.01L-627.05 476.29L-591.92 496.58L-556.8 516.85L-521.67 537.14L-486.54 557.42L-451.41 577.7L-416.28 597.99L-381.16 618.26L-346.02 638.55L-310.89 658.83L-275.76 679.11L-240.63 699.4L-205.5 719.68L-170.38 739.96L-135.24 760.24L-100.11 780.52L-64.9802 800.81L-29.8502 821.09L5.27985 841.37L40.3998 861.65L75.5398 881.93L110.67 902.22L145.8 922.5L180.93 942.78L216.06 963.07L251.18 983.34L286.32 1003.63L321.45 1023.91L356.58 1044.2L391.71 1064.48L426.84 1084.76L461.96 1105.04L497.09 1125.32L532.23 1145.61L567.36 1165.89L602.49 1186.17L637.61 1206.45L672.74 1226.73L707.87 1247.02L743.01 1267.3L882.37 1347.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M917.27 1327.48L777.91 1247.02L742.78 1226.73L707.65 1206.45L672.51 1186.16L637.39 1165.89L602.26 1145.6L567.13 1125.32L532 1105.04L496.87 1084.75L461.75 1064.48L426.61 1044.19L391.48 1023.91L356.35 1003.63L321.22 983.34L286.09 963.06L250.97 942.78L215.84 922.5L180.7 902.22L145.57 881.93L110.44 861.65L75.3098 841.37L40.1898 821.09L5.05975 800.81L-30.0803 780.52L-65.2103 760.24L-100.34 739.96L-135.47 719.67L-170.59 699.4L-205.72 679.11L-240.86 658.83L-275.99 638.55L-311.12 618.26L-346.25 597.98L-381.37 577.7L-416.5 557.42L-451.64 537.14L-486.77 516.85L-521.9 496.57L-557.02 476.29L-592.15 456.01L-627.28 435.73L-662.42 415.44L-801.78 334.98L-662.42 415.44L-627.28 435.73L-592.15 456.01L-557.02 476.29L-521.9 496.57L-486.77 516.85L-451.64 537.14L-416.5 557.42L-381.37 577.7L-346.25 597.98L-311.12 618.26L-275.99 638.55L-240.86 658.83L-205.72 679.11L-170.59 699.4L-135.47 719.67L-100.34 739.96L-65.2103 760.24L-30.0803 780.52L5.05975 800.81L40.1898 821.09L75.3098 841.37L110.44 861.65L145.57 881.93L180.7 902.22L215.84 922.5L250.97 942.78L286.09 963.06L321.22 983.34L356.35 1003.63L391.48 1023.91L426.61 1044.19L461.75 1064.48L496.87 1084.75L532 1105.04L567.13 1125.32L602.26 1145.6L637.39 1165.89L672.51 1186.16L707.65 1206.45L742.78 1226.73L777.91 1247.02L917.27 1327.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M952.18 1307.19L812.81 1226.73L777.68 1206.45L742.55 1186.16L707.42 1165.88L672.3 1145.6L637.17 1125.32L602.03 1105.04L566.9 1084.75L531.77 1064.47L496.65 1044.19L461.52 1023.91L426.39 1003.63L391.25 983.34L356.12 963.06L320.99 942.78L285.87 922.5L250.74 902.22L215.61 881.93L180.47 861.65L145.34 841.37L110.21 821.08L75.0898 800.81L39.9599 780.52L4.8299 760.24L-30.3001 739.96L-65.4401 719.67L-100.57 699.39L-135.69 679.11L-170.82 658.83L-205.95 638.55L-241.08 618.26L-276.22 597.98L-311.35 577.7L-346.47 557.42L-381.6 537.14L-416.73 516.85L-451.86 496.57L-487 476.29L-522.12 456.01L-557.25 435.73L-592.38 415.44L-627.51 395.16L-766.87 314.7L-627.51 395.16L-592.38 415.44L-557.25 435.73L-522.12 456.01L-487 476.29L-451.86 496.57L-416.73 516.85L-381.6 537.14L-346.47 557.42L-311.35 577.7L-276.22 597.98L-241.08 618.26L-205.95 638.55L-170.82 658.83L-135.69 679.11L-100.57 699.39L-65.4401 719.67L-30.3001 739.96L4.8299 760.24L39.9599 780.52L75.0898 800.81L110.21 821.08L145.34 841.37L180.47 861.65L215.61 881.93L250.74 902.22L285.87 922.5L320.99 942.78L356.12 963.06L391.25 983.34L426.39 1003.63L461.52 1023.91L496.65 1044.19L531.77 1064.47L566.9 1084.75L602.03 1105.04L637.17 1125.32L672.3 1145.6L707.42 1165.88L742.55 1186.16L777.68 1206.45L812.81 1226.73L952.18 1307.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M987.07 1286.92L847.71 1206.45L812.57 1186.17L777.44 1165.89L742.31 1145.6L707.19 1125.33L672.06 1105.04L636.93 1084.76L601.79 1064.48L566.66 1044.19L531.54 1023.92L496.41 1003.63L461.28 983.35L426.15 963.07L391.01 942.78L355.88 922.5L320.76 902.22L285.63 881.94L250.5 861.66L215.37 841.37L180.23 821.09L145.1 800.81L109.98 780.53L74.85 760.25L39.72 739.96L4.59003 719.68L-30.54 699.4L-65.68 679.11L-100.8 658.84L-135.93 638.55L-171.06 618.27L-206.19 597.99L-241.32 577.7L-276.46 557.42L-311.58 537.14L-346.71 516.86L-381.84 496.58L-416.97 476.29L-452.1 456.01L-487.22 435.73L-522.36 415.45L-557.49 395.17L-592.62 374.88L-731.98 294.42L-592.62 374.88L-557.49 395.17L-522.36 415.45L-487.22 435.73L-452.1 456.01L-416.97 476.29L-381.84 496.58L-346.71 516.86L-311.58 537.14L-276.46 557.42L-241.32 577.7L-206.19 597.99L-171.06 618.27L-135.93 638.55L-100.8 658.84L-65.68 679.11L-30.54 699.4L4.59003 719.68L39.72 739.96L74.85 760.25L109.98 780.53L145.1 800.81L180.23 821.09L215.37 841.37L250.5 861.66L285.63 881.94L320.76 902.22L355.88 922.5L391.01 942.78L426.15 963.07L461.28 983.35L496.41 1003.63L531.54 1023.92L566.66 1044.19L601.79 1064.48L636.93 1084.76L672.06 1105.04L707.19 1125.33L742.31 1145.6L777.44 1165.89L812.57 1186.17L847.71 1206.45L987.07 1286.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1021.97 1266.63L882.61 1186.17L847.48 1165.89L812.34 1145.6L777.21 1125.32L742.09 1105.04L706.96 1084.76L671.83 1064.48L636.7 1044.19L601.57 1023.91L566.45 1003.63L531.31 983.35L496.18 963.07L461.05 942.78L425.92 922.5L390.79 902.22L355.67 881.94L320.53 861.66L285.4 841.37L250.27 821.09L215.14 800.81L180.01 780.52L144.89 760.25L109.75 739.96L74.6199 719.68L39.4899 699.4L4.35992 679.11L-30.7701 658.83L-65.8901 638.55L-101.03 618.27L-136.16 597.99L-171.29 577.7L-206.42 557.42L-241.55 537.14L-276.67 516.86L-311.8 496.58L-346.94 476.29L-382.07 456.01L-417.2 435.73L-452.32 415.45L-487.45 395.17L-522.58 374.88L-557.72 354.6L-697.08 274.14L-557.72 354.6L-522.58 374.88L-487.45 395.17L-452.32 415.45L-417.2 435.73L-382.07 456.01L-346.94 476.29L-311.8 496.58L-276.67 516.86L-241.55 537.14L-206.42 557.42L-171.29 577.7L-136.16 597.99L-101.03 618.27L-65.8901 638.55L-30.7701 658.83L4.35992 679.11L39.4899 699.4L74.6199 719.68L109.75 739.96L144.89 760.25L180.01 780.52L215.14 800.81L250.27 821.09L285.4 841.37L320.53 861.66L355.67 881.94L390.79 902.22L425.92 922.5L461.05 942.78L496.18 963.07L531.31 983.35L566.45 1003.63L601.57 1023.91L636.7 1044.19L671.83 1064.48L706.96 1084.76L742.09 1105.04L777.21 1125.32L812.34 1145.6L847.48 1165.89L882.61 1186.17L1021.97 1266.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1056.88 1246.35L917.51 1165.89L882.38 1145.6L847.25 1125.32L812.12 1105.04L777 1084.76L741.86 1064.48L706.73 1044.19L671.6 1023.91L636.47 1003.63L601.35 983.35L566.22 963.07L531.09 942.78L495.95 922.5L460.82 902.22L425.69 881.93L390.57 861.66L355.44 841.37L320.31 821.09L285.17 800.81L250.04 780.52L214.91 760.24L179.79 739.96L144.66 719.68L109.53 699.4L74.3898 679.11L39.2598 658.83L4.12982 638.55L-30.9902 618.27L-66.1202 597.99L-101.25 577.7L-136.39 557.42L-171.52 537.14L-206.65 516.85L-241.77 496.58L-276.9 476.29L-312.03 456.01L-347.17 435.73L-382.3 415.44L-417.42 395.17L-452.55 374.88L-487.68 354.6L-522.81 334.32L-662.18 253.85L-522.81 334.32L-487.68 354.6L-452.55 374.88L-417.42 395.17L-382.3 415.44L-347.17 435.73L-312.03 456.01L-276.9 476.29L-241.77 496.58L-206.65 516.85L-171.52 537.14L-136.39 557.42L-101.25 577.7L-66.1202 597.99L-30.9902 618.27L4.12982 638.55L39.2598 658.83L74.3898 679.11L109.53 699.4L144.66 719.68L179.79 739.96L214.91 760.24L250.04 780.52L285.17 800.81L320.31 821.09L355.44 841.37L390.57 861.66L425.69 881.93L460.82 902.22L495.95 922.5L531.09 942.78L566.22 963.07L601.35 983.35L636.47 1003.63L671.6 1023.91L706.73 1044.19L741.86 1064.48L777 1084.76L812.12 1105.04L847.25 1125.32L882.38 1145.6L917.51 1165.89L1056.88 1246.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1091.78 1226.06L952.42 1145.6L917.28 1125.32L882.15 1105.04L847.02 1084.75L811.9 1064.48L776.77 1044.19L741.64 1023.91L706.5 1003.63L671.37 983.34L636.25 963.07L601.12 942.78L565.99 922.5L530.86 902.22L495.72 881.93L460.59 861.65L425.47 841.37L390.34 821.09L355.21 800.81L320.08 780.52L284.95 760.24L249.81 739.96L214.69 719.68L179.56 699.4L144.43 679.11L109.3 658.83L74.17 638.55L39.03 618.26L3.90997 597.99L-31.22 577.7L-66.35 557.42L-101.48 537.14L-136.61 516.85L-171.75 496.57L-206.87 476.29L-242 456.01L-277.13 435.72L-312.26 415.44L-347.39 395.16L-382.51 374.88L-417.65 354.6L-452.78 334.31L-487.91 314.03L-627.27 233.57L-487.91 314.03L-452.78 334.31L-417.65 354.6L-382.51 374.88L-347.39 395.16L-312.26 415.44L-277.13 435.72L-242 456.01L-206.87 476.29L-171.75 496.57L-136.61 516.85L-101.48 537.14L-66.35 557.42L-31.22 577.7L3.90997 597.99L39.03 618.26L74.17 638.55L109.3 658.83L144.43 679.11L179.56 699.4L214.69 719.68L249.81 739.96L284.95 760.24L320.08 780.52L355.21 800.81L390.34 821.09L425.47 841.37L460.59 861.65L495.72 881.93L530.86 902.22L565.99 922.5L601.12 942.78L636.25 963.07L671.37 983.34L706.5 1003.63L741.64 1023.91L776.77 1044.19L811.9 1064.48L847.02 1084.75L882.15 1105.04L917.28 1125.32L952.42 1145.6L1091.78 1226.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1126.68 1205.78L987.32 1125.32L952.19 1105.04L917.06 1084.75L881.92 1064.47L846.8 1044.19L811.67 1023.91L776.54 1003.63L741.41 983.34L706.28 963.06L671.16 942.78L636.02 922.5L600.89 902.22L565.76 881.93L530.63 861.65L495.5 841.37L460.38 821.09L425.24 800.81L390.11 780.52L354.98 760.24L319.85 739.96L284.72 719.67L249.6 699.4L214.47 679.11L179.33 658.83L144.2 638.54L109.07 618.26L73.9399 597.98L38.8199 577.7L3.68988 557.42L-31.4501 537.13L-66.5801 516.85L-101.71 496.57L-136.84 476.28L-171.96 456.01L-207.09 435.72L-242.23 415.44L-277.36 395.16L-312.49 374.87L-347.61 354.6L-382.74 334.31L-417.87 314.03L-453.01 293.75L-592.37 213.29L-453.01 293.75L-417.87 314.03L-382.74 334.31L-347.61 354.6L-312.49 374.87L-277.36 395.16L-242.23 415.44L-207.09 435.72L-171.96 456.01L-136.84 476.28L-101.71 496.57L-66.5801 516.85L-31.4501 537.13L3.68988 557.42L38.8199 577.7L73.9399 597.98L109.07 618.26L144.2 638.54L179.33 658.83L214.47 679.11L249.6 699.4L284.72 719.67L319.85 739.96L354.98 760.24L390.11 780.52L425.24 800.81L460.38 821.09L495.5 841.37L530.63 861.65L565.76 881.93L600.89 902.22L636.02 922.5L671.16 942.78L706.28 963.06L741.41 983.34L776.54 1003.63L811.67 1023.91L846.8 1044.19L881.92 1064.47L917.06 1084.75L952.19 1105.04L987.32 1125.32L1126.68 1205.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1161.59 1185.5L1022.22 1105.04L987.09 1084.75L951.96 1064.47L916.83 1044.19L881.71 1023.91L846.58 1003.63L811.44 983.34L776.31 963.06L741.18 942.78L706.06 922.5L670.93 902.22L635.8 881.93L600.66 861.65L565.53 841.37L530.4 821.08L495.28 800.81L460.15 780.52L425.02 760.24L389.88 739.95L354.75 719.67L319.62 699.39L284.5 679.11L249.37 658.83L214.24 638.54L179.1 618.26L143.97 597.98L108.84 577.69L73.7197 557.42L38.5898 537.13L3.45978 516.85L-31.6702 496.57L-66.8102 476.28L-101.94 456L-137.06 435.72L-172.19 415.44L-207.32 395.16L-242.45 374.87L-277.59 354.59L-312.71 334.31L-347.84 314.03L-382.97 293.75L-418.1 273.46L-557.47 193L-418.1 273.46L-382.97 293.75L-347.84 314.03L-312.71 334.31L-277.59 354.59L-242.45 374.87L-207.32 395.16L-172.19 415.44L-137.06 435.72L-101.94 456L-66.8102 476.28L-31.6702 496.57L3.45978 516.85L38.5898 537.13L73.7197 557.42L108.84 577.69L143.97 597.98L179.1 618.26L214.24 638.54L249.37 658.83L284.5 679.11L319.62 699.39L354.75 719.67L389.88 739.95L425.02 760.24L460.15 780.52L495.28 800.81L530.4 821.08L565.53 841.37L600.66 861.65L635.8 881.93L670.93 902.22L706.06 922.5L741.18 942.78L776.31 963.06L811.44 983.34L846.58 1003.63L881.71 1023.91L916.83 1044.19L951.96 1064.47L987.09 1084.75L1022.22 1105.04L1161.59 1185.5Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1196.48 1165.22L1057.11 1084.76L1021.98 1064.48L986.85 1044.19L951.72 1023.91L916.6 1003.63L881.47 983.35L846.34 963.07L811.2 942.78L776.07 922.5L740.95 902.22L705.82 881.94L670.69 861.66L635.56 841.37L600.42 821.09L565.29 800.8L530.17 780.53L495.04 760.24L459.91 739.96L424.78 719.68L389.64 699.39L354.51 679.11L319.39 658.83L284.26 638.55L249.13 618.27L214 597.98L178.86 577.7L143.73 557.42L108.61 537.14L73.4797 516.86L38.3497 496.57L3.21967 476.29L-31.9103 456.01L-67.0503 435.72L-102.17 415.45L-137.3 395.16L-172.43 374.88L-207.56 354.6L-242.69 334.31L-277.81 314.04L-312.95 293.75L-348.08 273.47L-383.21 253.19L-522.57 172.73L-383.21 253.19L-348.08 273.47L-312.95 293.75L-277.81 314.04L-242.69 334.31L-207.56 354.6L-172.43 374.88L-137.3 395.16L-102.17 415.45L-67.0503 435.72L-31.9103 456.01L3.21967 476.29L38.3497 496.57L73.4797 516.86L108.61 537.14L143.73 557.42L178.86 577.7L214 597.98L249.13 618.27L284.26 638.55L319.39 658.83L354.51 679.11L389.64 699.39L424.78 719.68L459.91 739.96L495.04 760.24L530.17 780.53L565.29 800.8L600.42 821.09L635.56 841.37L670.69 861.66L705.82 881.94L740.95 902.22L776.07 922.5L811.2 942.78L846.34 963.07L881.47 983.35L916.6 1003.63L951.72 1023.91L986.85 1044.19L1021.98 1064.48L1057.11 1084.76L1196.48 1165.22Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1231.38 1144.94L1092.02 1064.48L1056.89 1044.19L1021.75 1023.91L986.62 1003.62L951.5 983.35L916.37 963.07L881.24 942.78L846.11 922.5L810.97 902.21L775.85 881.94L740.72 861.65L705.59 841.37L670.46 821.09L635.33 800.8L600.2 780.52L565.08 760.24L529.94 739.96L494.81 719.68L459.68 699.39L424.55 679.11L389.42 658.83L354.3 638.55L319.16 618.27L284.03 597.98L248.9 577.7L213.77 557.42L178.64 537.13L143.52 516.86L108.38 496.57L73.2501 476.29L38.1201 456.01L2.99008 435.72L-32.1399 415.44L-67.2599 395.16L-102.4 374.88L-137.53 354.6L-172.66 334.31L-207.79 314.03L-242.91 293.75L-278.04 273.47L-313.17 253.19L-348.31 232.9L-487.67 152.44L-348.31 232.9L-313.17 253.19L-278.04 273.47L-242.91 293.75L-207.79 314.03L-172.66 334.31L-137.53 354.6L-102.4 374.88L-67.2599 395.16L-32.1399 415.44L2.99008 435.72L38.1201 456.01L73.2501 476.29L108.38 496.57L143.52 516.86L178.64 537.13L213.77 557.42L248.9 577.7L284.03 597.98L319.16 618.27L354.3 638.55L389.42 658.83L424.55 679.11L459.68 699.39L494.81 719.68L529.94 739.96L565.08 760.24L600.2 780.52L635.33 800.8L670.46 821.09L705.59 841.37L740.72 861.65L775.85 881.94L810.97 902.21L846.11 922.5L881.24 942.78L916.37 963.07L951.5 983.35L986.62 1003.62L1021.75 1023.91L1056.89 1044.19L1092.02 1064.48L1231.38 1144.94Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1266.29 1124.65L1126.92 1044.19L1091.79 1023.91L1056.66 1003.62L1021.53 983.34L986.41 963.06L951.27 942.78L916.14 922.5L881.01 902.21L845.88 881.93L810.76 861.65L775.63 841.37L740.49 821.09L705.36 800.8L670.23 780.52L635.1 760.24L599.98 739.96L564.85 719.68L529.72 699.39L494.58 679.11L459.45 658.83L424.32 638.54L389.2 618.27L354.07 597.98L318.94 577.7L283.8 557.42L248.67 537.13L213.54 516.85L178.42 496.57L143.29 476.29L108.16 456.01L73.02 435.72L37.89 415.44L2.75998 395.16L-32.36 374.88L-67.49 354.6L-102.62 334.31L-137.76 314.03L-172.89 293.75L-208.01 273.47L-243.14 253.19L-278.27 232.9L-313.4 212.62L-452.77 132.16L-313.4 212.62L-278.27 232.9L-243.14 253.19L-208.01 273.47L-172.89 293.75L-137.76 314.03L-102.62 334.31L-67.49 354.6L-32.36 374.88L2.75998 395.16L37.89 415.44L73.02 435.72L108.16 456.01L143.29 476.29L178.42 496.57L213.54 516.85L248.67 537.13L283.8 557.42L318.94 577.7L354.07 597.98L389.2 618.27L424.32 638.54L459.45 658.83L494.58 679.11L529.72 699.39L564.85 719.68L599.98 739.96L635.1 760.24L670.23 780.52L705.36 800.8L740.49 821.09L775.63 841.37L810.76 861.65L845.88 881.93L881.01 902.21L916.14 922.5L951.27 942.78L986.41 963.06L1021.53 983.34L1056.66 1003.62L1091.79 1023.91L1126.92 1044.19L1266.29 1124.65Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1301.19 1104.37L1161.83 1023.91L1126.69 1003.62L1091.56 983.34L1056.43 963.06L1021.31 942.78L986.18 922.5L951.05 902.21L915.91 881.93L880.78 861.65L845.66 841.37L810.53 821.09L775.4 800.8L740.27 780.52L705.13 760.24L670 739.95L634.88 719.68L599.75 699.39L564.62 679.11L529.49 658.83L494.36 638.54L459.22 618.26L424.1 597.98L388.97 577.7L353.84 557.42L318.71 537.13L283.58 516.85L248.44 496.57L213.32 476.29L178.19 456.01L143.06 435.72L107.93 415.44L72.7996 395.16L37.6596 374.87L2.53964 354.6L-32.5904 334.31L-67.7204 314.03L-102.85 293.75L-137.98 273.46L-173.1 253.19L-208.24 232.9L-243.37 212.62L-278.5 192.34L-417.86 111.87L-278.5 192.34L-243.37 212.62L-208.24 232.9L-173.1 253.19L-137.98 273.46L-102.85 293.75L-67.7204 314.03L-32.5904 334.31L2.53964 354.6L37.6596 374.87L72.7996 395.16L107.93 415.44L143.06 435.72L178.19 456.01L213.32 476.29L248.44 496.57L283.58 516.85L318.71 537.13L353.84 557.42L388.97 577.7L424.1 597.98L459.22 618.26L494.36 638.54L529.49 658.83L564.62 679.11L599.75 699.39L634.88 719.68L670 739.95L705.13 760.24L740.27 780.52L775.4 800.8L810.53 821.09L845.66 841.37L880.78 861.65L915.91 881.93L951.05 902.21L986.18 922.5L1021.31 942.78L1056.43 963.06L1091.56 983.34L1126.69 1003.62L1161.83 1023.91L1301.19 1104.37Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1336.09 1084.09L1196.73 1003.62L1161.6 983.34L1126.47 963.06L1091.33 942.77L1056.21 922.5L1021.08 902.21L985.95 881.93L950.82 861.65L915.69 841.36L880.57 821.09L845.43 800.8L810.3 780.52L775.17 760.24L740.04 739.95L704.91 719.67L669.79 699.39L634.65 679.11L599.52 658.83L564.39 638.54L529.26 618.26L494.13 597.98L459.01 577.7L423.87 557.42L388.74 537.13L353.61 516.85L318.48 496.57L283.35 476.28L248.23 456.01L213.1 435.72L177.96 415.44L142.83 395.16L107.7 374.87L72.57 354.59L37.45 334.31L2.32004 314.03L-32.82 293.75L-67.95 273.46L-103.08 253.18L-138.2 232.9L-173.33 212.62L-208.46 192.34L-243.6 172.05L-382.96 91.59L-243.6 172.05L-208.46 192.34L-173.33 212.62L-138.2 232.9L-103.08 253.18L-67.95 273.46L-32.82 293.75L2.32004 314.03L37.45 334.31L72.57 354.59L107.7 374.87L142.83 395.16L177.96 415.44L213.1 435.72L248.23 456.01L283.35 476.28L318.48 496.57L353.61 516.85L388.74 537.13L423.87 557.42L459.01 577.7L494.13 597.98L529.26 618.26L564.39 638.54L599.52 658.83L634.65 679.11L669.79 699.39L704.91 719.67L740.04 739.95L775.17 760.24L810.3 780.52L845.43 800.8L880.57 821.09L915.69 841.36L950.82 861.65L985.95 881.93L1021.08 902.21L1056.21 922.5L1091.33 942.77L1126.47 963.06L1161.6 983.34L1196.73 1003.62L1336.09 1084.09Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1371 1063.8L1231.63 983.34L1196.5 963.06L1161.37 942.77L1126.24 922.49L1091.12 902.21L1055.99 881.93L1020.85 861.65L985.72 841.36L950.59 821.08L915.47 800.8L880.34 780.52L845.21 760.24L810.07 739.95L774.94 719.67L739.81 699.39L704.69 679.11L669.56 658.83L634.43 638.54L599.29 618.26L564.16 597.98L529.03 577.69L493.91 557.42L458.78 537.13L423.65 516.85L388.51 496.57L353.38 476.28L318.25 456L283.13 435.72L248 415.44L212.87 395.16L177.73 374.87L142.6 354.59L107.47 334.31L72.3499 314.03L37.2199 293.75L2.08994 273.46L-33.0401 253.18L-68.1801 232.89L-103.3 212.62L-138.43 192.34L-173.56 172.05L-208.69 151.77L-348.06 71.31L-208.69 151.77L-173.56 172.05L-138.43 192.34L-103.3 212.62L-68.1801 232.89L-33.0401 253.18L2.08994 273.46L37.2199 293.75L72.3499 314.03L107.47 334.31L142.6 354.59L177.73 374.87L212.87 395.16L248 415.44L283.13 435.72L318.25 456L353.38 476.28L388.51 496.57L423.65 516.85L458.78 537.13L493.91 557.42L529.03 577.69L564.16 597.98L599.29 618.26L634.43 638.54L669.56 658.83L704.69 679.11L739.81 699.39L774.94 719.67L810.07 739.95L845.21 760.24L880.34 780.52L915.47 800.8L950.59 821.08L985.72 841.36L1020.85 861.65L1055.99 881.93L1091.12 902.21L1126.24 922.49L1161.37 942.77L1196.5 963.06L1231.63 983.34L1371 1063.8Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1405.89 1043.53L1266.52 963.06L1231.39 942.78L1196.26 922.5L1161.13 902.21L1126.01 881.94L1090.88 861.65L1055.74 841.37L1020.61 821.09L985.48 800.8L950.36 780.53L915.23 760.24L880.1 739.96L844.97 719.68L809.83 699.39L774.7 679.11L739.58 658.83L704.45 638.55L669.32 618.27L634.19 597.98L599.05 577.7L563.92 557.42L528.8 537.14L493.67 516.86L458.54 496.57L423.41 476.29L388.27 456.01L353.14 435.72L318.02 415.45L282.89 395.16L247.76 374.88L212.63 354.6L177.49 334.31L142.36 314.03L107.24 293.75L72.1099 273.47L36.9799 253.19L1.84985 232.9L-33.2802 212.62L-68.4001 192.34L-103.54 172.06L-138.67 151.77L-173.8 131.49L-313.16 51.03L-173.8 131.49L-138.67 151.77L-103.54 172.06L-68.4001 192.34L-33.2802 212.62L1.84985 232.9L36.9799 253.19L72.1099 273.47L107.24 293.75L142.36 314.03L177.49 334.31L212.63 354.6L247.76 374.88L282.89 395.16L318.02 415.45L353.14 435.72L388.27 456.01L423.41 476.29L458.54 496.57L493.67 516.86L528.8 537.14L563.92 557.42L599.05 577.7L634.19 597.98L669.32 618.27L704.45 638.55L739.58 658.83L774.7 679.11L809.83 699.39L844.97 719.68L880.1 739.96L915.23 760.24L950.36 780.53L985.48 800.8L1020.61 821.09L1055.74 841.37L1090.88 861.65L1126.01 881.94L1161.13 902.21L1196.26 922.5L1231.39 942.78L1266.52 963.06L1405.89 1043.53Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1440.79 1023.24L1301.43 942.78L1266.3 922.5L1231.16 902.21L1196.03 881.93L1160.91 861.65L1125.78 841.37L1090.65 821.09L1055.52 800.8L1020.38 780.52L985.26 760.24L950.13 739.96L915 719.68L879.87 699.39L844.74 679.11L809.61 658.83L774.49 638.55L739.35 618.27L704.22 597.98L669.09 577.7L633.96 557.42L598.83 537.13L563.71 516.86L528.57 496.57L493.44 476.29L458.31 456.01L423.18 435.72L388.05 415.44L352.93 395.16L317.79 374.88L282.66 354.59L247.53 334.31L212.4 314.03L177.27 293.74L142.15 273.47L107.01 253.18L71.8798 232.9L36.7498 212.62L1.61975 192.33L-33.5002 172.06L-68.6302 151.77L-103.77 131.49L-138.9 111.21L-278.26 30.75L-138.9 111.21L-103.77 131.49L-68.6302 151.77L-33.5002 172.06L1.61975 192.33L36.7498 212.62L71.8798 232.9L107.01 253.18L142.15 273.47L177.27 293.74L212.4 314.03L247.53 334.31L282.66 354.59L317.79 374.88L352.93 395.16L388.05 415.44L423.18 435.72L458.31 456.01L493.44 476.29L528.57 496.57L563.71 516.86L598.83 537.13L633.96 557.42L669.09 577.7L704.22 597.98L739.35 618.27L774.49 638.55L809.61 658.83L844.74 679.11L879.87 699.39L915 719.68L950.13 739.96L985.26 760.24L1020.38 780.52L1055.52 800.8L1090.65 821.09L1125.78 841.37L1160.91 861.65L1196.03 881.93L1231.16 902.21L1266.3 922.5L1301.43 942.78L1440.79 1023.24Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1475.7 1002.96L1336.33 922.5L1301.2 902.21L1266.07 881.93L1230.94 861.65L1195.82 841.37L1160.68 821.09L1125.55 800.8L1090.42 780.52L1055.29 760.24L1020.17 739.96L985.04 719.68L949.9 699.39L914.77 679.11L879.64 658.83L844.51 638.54L809.39 618.27L774.26 597.98L739.12 577.7L703.99 557.41L668.86 537.13L633.73 516.85L598.61 496.57L563.48 476.29L528.35 456L493.21 435.72L458.08 415.44L422.95 395.15L387.83 374.88L352.7 354.59L317.57 334.31L282.43 314.03L247.3 293.74L212.17 273.46L177.05 253.18L141.92 232.9L106.79 212.62L71.6497 192.33L36.5197 172.05L1.39964 151.77L-33.7303 131.49L-68.8604 111.21L-103.99 90.92L-243.36 10.46L-103.99 90.92L-68.8604 111.21L-33.7303 131.49L1.39964 151.77L36.5197 172.05L71.6497 192.33L106.79 212.62L141.92 232.9L177.05 253.18L212.17 273.46L247.3 293.74L282.43 314.03L317.57 334.31L352.7 354.59L387.83 374.88L422.95 395.15L458.08 415.44L493.21 435.72L528.35 456L563.48 476.29L598.61 496.57L633.73 516.85L668.86 537.13L703.99 557.41L739.12 577.7L774.26 597.98L809.39 618.27L844.51 638.54L879.64 658.83L914.77 679.11L949.9 699.39L985.04 719.68L1020.17 739.96L1055.29 760.24L1090.42 780.52L1125.55 800.8L1160.68 821.09L1195.82 841.37L1230.94 861.65L1266.07 881.93L1301.2 902.21L1336.33 922.5L1475.7 1002.96Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1510.6 982.67L1371.24 902.21L1336.1 881.93L1300.97 861.65L1265.84 841.36L1230.72 821.09L1195.59 800.8L1160.46 780.52L1125.32 760.24L1090.19 739.95L1055.07 719.68L1019.94 699.39L984.81 679.11L949.68 658.82L914.54 638.54L879.41 618.26L844.29 597.98L809.16 577.7L774.03 557.41L738.9 537.13L703.76 516.85L668.63 496.56L633.51 476.29L598.38 456L563.25 435.72L528.12 415.44L492.99 395.15L457.85 374.87L422.73 354.59L387.6 334.31L352.47 314.03L317.34 293.74L282.21 273.46L247.07 253.18L211.95 232.9L176.82 212.62L141.69 192.33L106.56 172.05L71.4298 151.77L36.3098 131.49L1.1698 111.21L-33.9602 90.92L-69.0902 70.64L-208.45 -9.82001L-69.0902 70.64L-33.9602 90.92L1.1698 111.21L36.3098 131.49L71.4298 151.77L106.56 172.05L141.69 192.33L176.82 212.62L211.95 232.9L247.07 253.18L282.21 273.46L317.34 293.74L352.47 314.03L387.6 334.31L422.73 354.59L457.85 374.87L492.99 395.15L528.12 415.44L563.25 435.72L598.38 456L633.51 476.29L668.63 496.56L703.76 516.85L738.9 537.13L774.03 557.41L809.16 577.7L844.29 597.98L879.41 618.26L914.54 638.54L949.68 658.82L984.81 679.11L1019.94 699.39L1055.07 719.68L1090.19 739.95L1125.32 760.24L1160.46 780.52L1195.59 800.8L1230.72 821.09L1265.84 841.36L1300.97 861.65L1336.1 881.93L1371.24 902.21L1510.6 982.67Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1545.5 962.39L1406.14 881.93L1371.01 861.64L1335.87 841.36L1300.74 821.08L1265.62 800.8L1230.49 780.52L1195.36 760.23L1160.23 739.95L1125.1 719.67L1089.98 699.39L1054.84 679.11L1019.71 658.82L984.58 638.54L949.45 618.26L914.32 597.97L879.2 577.7L844.06 557.41L808.93 537.13L773.8 516.85L738.67 496.56L703.54 476.28L668.42 456L633.28 435.72L598.15 415.44L563.02 395.15L527.89 374.87L492.76 354.59L457.64 334.31L422.5 314.03L387.37 293.74L352.24 273.46L317.11 253.18L281.98 232.89L246.86 212.62L211.73 192.33L176.59 172.05L141.46 151.77L106.33 131.48L71.2097 111.21L36.0797 90.92L0.949707 70.64L-34.1903 50.36L-173.55 -30.11L-34.1903 50.36L0.949707 70.64L36.0797 90.92L71.2097 111.21L106.33 131.48L141.46 151.77L176.59 172.05L211.73 192.33L246.86 212.62L281.98 232.89L317.11 253.18L352.24 273.46L387.37 293.74L422.5 314.03L457.64 334.31L492.76 354.59L527.89 374.87L563.02 395.15L598.15 415.44L633.28 435.72L668.42 456L703.54 476.28L738.67 496.56L773.8 516.85L808.93 537.13L844.06 557.41L879.2 577.7L914.32 597.97L949.45 618.26L984.58 638.54L1019.71 658.82L1054.84 679.11L1089.98 699.39L1125.1 719.67L1160.23 739.95L1195.36 760.23L1230.49 780.52L1265.62 800.8L1300.74 821.08L1335.87 841.36L1371.01 861.64L1406.14 881.93L1545.5 962.39Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1580.41 942.11L1441.04 861.64L1405.91 841.36L1370.78 821.08L1335.65 800.79L1300.53 780.52L1265.39 760.23L1230.26 739.95L1195.13 719.67L1160 699.38L1124.88 679.11L1089.75 658.82L1054.62 638.54L1019.48 618.26L984.35 597.97L949.22 577.69L914.1 557.41L878.97 537.13L843.84 516.85L808.7 496.56L773.57 476.28L738.44 456L703.32 435.72L668.19 415.44L633.06 395.15L597.92 374.87L562.79 354.59L527.66 334.3L492.54 314.03L457.41 293.74L422.28 273.46L387.14 253.18L352.01 232.89L316.88 212.61L281.76 192.33L246.63 172.05L211.5 151.77L176.37 131.48L141.23 111.2L106.11 90.92L70.9796 70.64L35.8496 50.36L0.719604 30.07L-138.65 -50.39L0.719604 30.07L35.8496 50.36L70.9796 70.64L106.11 90.92L141.23 111.2L176.37 131.48L211.5 151.77L246.63 172.05L281.76 192.33L316.88 212.61L352.01 232.89L387.14 253.18L422.28 273.46L457.41 293.74L492.54 314.03L527.66 334.3L562.79 354.59L597.92 374.87L633.06 395.15L668.19 415.44L703.32 435.72L738.44 456L773.57 476.28L808.7 496.56L843.84 516.85L878.97 537.13L914.1 557.41L949.22 577.69L984.35 597.97L1019.48 618.26L1054.62 638.54L1089.75 658.82L1124.88 679.11L1160 699.38L1195.13 719.67L1230.26 739.95L1265.39 760.23L1300.53 780.52L1335.65 800.79L1370.78 821.08L1405.91 841.36L1441.04 861.64L1580.41 942.11Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1615.3 921.83L1475.93 841.37L1440.8 821.08L1405.67 800.8L1370.54 780.52L1335.42 760.24L1300.29 739.96L1265.15 719.67L1230.02 699.39L1194.89 679.11L1159.77 658.83L1124.64 638.55L1089.51 618.26L1054.37 597.98L1019.24 577.7L984.11 557.41L948.99 537.14L913.86 516.85L878.73 496.57L843.6 476.29L808.46 456L773.33 435.72L738.21 415.44L703.08 395.16L667.95 374.88L632.82 354.59L597.68 334.31L562.55 314.03L527.43 293.75L492.3 273.47L457.17 253.18L422.04 232.9L386.9 212.62L351.77 192.33L316.65 172.06L281.52 151.77L246.39 131.49L211.26 111.21L176.12 90.92L141 70.65L105.87 50.36L70.74 30.08L35.61 9.80002L-103.75 -70.67L35.61 9.80002L70.74 30.08L105.87 50.36L141 70.65L176.12 90.92L211.26 111.21L246.39 131.49L281.52 151.77L316.65 172.06L351.77 192.33L386.9 212.62L422.04 232.9L457.17 253.18L492.3 273.47L527.43 293.75L562.55 314.03L597.68 334.31L632.82 354.59L667.95 374.88L703.08 395.16L738.21 415.44L773.33 435.72L808.46 456L843.6 476.29L878.73 496.57L913.86 516.85L948.99 537.14L984.11 557.41L1019.24 577.7L1054.37 597.98L1089.51 618.26L1124.64 638.55L1159.77 658.83L1194.89 679.11L1230.02 699.39L1265.15 719.67L1300.29 739.96L1335.42 760.24L1370.54 780.52L1405.67 800.8L1440.8 821.08L1475.93 841.37L1615.3 921.83Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1650.2 901.55L1510.84 821.08L1475.71 800.8L1440.57 780.52L1405.44 760.23L1370.32 739.96L1335.19 719.67L1300.06 699.39L1264.93 679.11L1229.79 658.82L1194.67 638.55L1159.54 618.26L1124.41 597.98L1089.28 577.7L1054.15 557.41L1019.01 537.13L983.89 516.85L948.76 496.57L913.63 476.29L878.5 456L843.37 435.72L808.24 415.44L773.12 395.16L737.98 374.88L702.85 354.59L667.72 334.31L632.59 314.03L597.46 293.74L562.34 273.47L527.2 253.18L492.07 232.9L456.94 212.62L421.81 192.33L386.68 172.05L351.56 151.77L316.42 131.49L281.29 111.21L246.16 90.92L211.03 70.64L175.91 50.36L140.78 30.08L105.64 9.79999L70.5099 -10.49L-68.8501 -90.95L70.5099 -10.49L105.64 9.79999L140.78 30.08L175.91 50.36L211.03 70.64L246.16 90.92L281.29 111.21L316.42 131.49L351.56 151.77L386.68 172.05L421.81 192.33L456.94 212.62L492.07 232.9L527.2 253.18L562.34 273.47L597.46 293.74L632.59 314.03L667.72 334.31L702.85 354.59L737.98 374.88L773.12 395.16L808.24 415.44L843.37 435.72L878.5 456L913.63 476.29L948.76 496.57L983.89 516.85L1019.01 537.13L1054.15 557.41L1089.28 577.7L1124.41 597.98L1159.54 618.26L1194.67 638.55L1229.79 658.82L1264.93 679.11L1300.06 699.39L1335.19 719.67L1370.32 739.96L1405.44 760.23L1440.57 780.52L1475.71 800.8L1510.84 821.08L1650.2 901.55Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1685.1 881.26L1545.74 800.8L1510.61 780.52L1475.48 760.23L1440.35 739.95L1405.23 719.67L1370.09 699.39L1334.96 679.11L1299.83 658.82L1264.7 638.54L1229.58 618.26L1194.45 597.98L1159.31 577.7L1124.18 557.41L1089.05 537.13L1053.92 516.85L1018.8 496.57L983.67 476.29L948.53 456L913.4 435.72L878.27 415.44L843.14 395.15L808.02 374.88L772.89 354.59L737.75 334.31L702.62 314.03L667.49 293.74L632.36 273.46L597.24 253.18L562.11 232.9L526.98 212.62L491.84 192.33L456.71 172.05L421.58 151.77L386.46 131.49L351.33 111.21L316.2 90.92L281.06 70.64L245.93 50.36L210.81 30.08L175.68 9.80003L140.55 -10.49L105.42 -30.77L-33.9502 -111.23L105.42 -30.77L140.55 -10.49L175.68 9.80003L210.81 30.08L245.93 50.36L281.06 70.64L316.2 90.92L351.33 111.21L386.46 131.49L421.58 151.77L456.71 172.05L491.84 192.33L526.98 212.62L562.11 232.9L597.24 253.18L632.36 273.46L667.49 293.74L702.62 314.03L737.75 334.31L772.89 354.59L808.02 374.88L843.14 395.15L878.27 415.44L913.4 435.72L948.53 456L983.67 476.29L1018.8 496.57L1053.92 516.85L1089.05 537.13L1124.18 557.41L1159.31 577.7L1194.45 597.98L1229.58 618.26L1264.7 638.54L1299.83 658.82L1334.96 679.11L1370.09 699.39L1405.23 719.67L1440.35 739.95L1475.48 760.23L1510.61 780.52L1545.74 800.8L1685.1 881.26Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1720.01 860.98L1580.64 780.52L1545.51 760.23L1510.38 739.95L1475.25 719.67L1440.13 699.39L1405 679.11L1369.87 658.82L1334.73 638.54L1299.6 618.26L1264.48 597.98L1229.35 577.7L1194.22 557.41L1159.09 537.13L1123.95 516.85L1088.82 496.56L1053.7 476.29L1018.57 456L983.44 435.72L948.31 415.44L913.17 395.15L878.04 374.87L842.92 354.59L807.79 334.31L772.66 314.03L737.53 293.74L702.39 273.46L667.26 253.18L632.14 232.9L597.01 212.62L561.88 192.33L526.75 172.05L491.62 151.76L456.48 131.48L421.36 111.21L386.23 90.92L351.1 70.64L315.97 50.35L280.84 30.07L245.72 9.78998L210.58 -10.49L175.45 -30.77L140.32 -51.06L0.959961 -131.52L140.32 -51.06L175.45 -30.77L210.58 -10.49L245.72 9.78998L280.84 30.07L315.97 50.35L351.1 70.64L386.23 90.92L421.36 111.21L456.48 131.48L491.62 151.76L526.75 172.05L561.88 192.33L597.01 212.62L632.14 232.9L667.26 253.18L702.39 273.46L737.53 293.74L772.66 314.03L807.79 334.31L842.92 354.59L878.04 374.87L913.17 395.15L948.31 415.44L983.44 435.72L1018.57 456L1053.7 476.29L1088.82 496.56L1123.95 516.85L1159.09 537.13L1194.22 557.41L1229.35 577.7L1264.48 597.98L1299.6 618.26L1334.73 638.54L1369.87 658.82L1405 679.11L1440.13 699.39L1475.25 719.67L1510.38 739.95L1545.51 760.23L1580.64 780.52L1720.01 860.98Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1754.91 840.69L1615.55 760.23L1580.42 739.95L1545.28 719.67L1510.15 699.38L1475.03 679.11L1439.9 658.82L1404.77 638.54L1369.64 618.26L1334.51 597.97L1299.39 577.7L1264.25 557.41L1229.12 537.13L1193.99 516.85L1158.86 496.56L1123.73 476.28L1088.61 456L1053.47 435.72L1018.34 415.44L983.21 395.15L948.08 374.87L912.95 354.59L877.83 334.31L842.69 314.03L807.56 293.74L772.43 273.46L737.3 253.17L702.17 232.89L667.05 212.61L631.91 192.33L596.78 172.05L561.65 151.76L526.52 131.48L491.39 111.2L456.27 90.92L421.13 70.64L386 50.35L350.87 30.07L315.74 9.79001L280.62 -10.49L245.49 -30.77L210.36 -51.06L175.22 -71.34L35.8599 -151.8L175.22 -71.34L210.36 -51.06L245.49 -30.77L280.62 -10.49L315.74 9.79001L350.87 30.07L386 50.35L421.13 70.64L456.27 90.92L491.39 111.2L526.52 131.48L561.65 151.76L596.78 172.05L631.91 192.33L667.05 212.61L702.17 232.89L737.3 253.17L772.43 273.46L807.56 293.74L842.69 314.03L877.83 334.31L912.95 354.59L948.08 374.87L983.21 395.15L1018.34 415.44L1053.47 435.72L1088.61 456L1123.73 476.28L1158.86 496.56L1193.99 516.85L1229.12 537.13L1264.25 557.41L1299.39 577.7L1334.51 597.97L1369.64 618.26L1404.77 638.54L1439.9 658.82L1475.03 679.11L1510.15 699.38L1545.28 719.67L1580.42 739.95L1615.55 760.23L1754.91 840.69Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1789.82 820.41L1650.45 739.95L1615.32 719.67L1580.19 699.38L1545.06 679.1L1509.94 658.82L1474.8 638.54L1439.67 618.26L1404.54 597.97L1369.41 577.69L1334.29 557.41L1299.16 537.13L1264.02 516.85L1228.89 496.56L1193.76 476.28L1158.63 455.99L1123.51 435.72L1088.38 415.44L1053.25 395.15L1018.11 374.87L982.98 354.58L947.85 334.3L912.73 314.02L877.6 293.74L842.47 273.46L807.33 253.17L772.2 232.89L737.07 212.61L701.95 192.33L666.82 172.05L631.69 151.76L596.55 131.48L561.42 111.2L526.29 90.91L491.17 70.64L456.04 50.35L420.91 30.07L385.77 9.78998L350.64 -10.5L315.52 -30.77L280.39 -51.06L245.26 -71.34L210.13 -91.62L70.7598 -172.08L210.13 -91.62L245.26 -71.34L280.39 -51.06L315.52 -30.77L350.64 -10.5L385.77 9.78998L420.91 30.07L456.04 50.35L491.17 70.64L526.29 90.91L561.42 111.2L596.55 131.48L631.69 151.76L666.82 172.05L701.95 192.33L737.07 212.61L772.2 232.89L807.33 253.17L842.47 273.46L877.6 293.74L912.73 314.02L947.85 334.3L982.98 354.58L1018.11 374.87L1053.25 395.15L1088.38 415.44L1123.51 435.72L1158.63 455.99L1193.76 476.28L1228.89 496.56L1264.02 516.85L1299.16 537.13L1334.29 557.41L1369.41 577.69L1404.54 597.97L1439.67 618.26L1474.8 638.54L1509.94 658.82L1545.06 679.1L1580.19 699.38L1615.32 719.67L1650.45 739.95L1789.82 820.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1824.71 800.13L1685.34 719.67L1650.21 699.39L1615.08 679.11L1579.95 658.82L1544.83 638.55L1509.7 618.26L1474.56 597.98L1439.43 577.7L1404.3 557.41L1369.18 537.14L1334.05 516.85L1298.92 496.57L1263.78 476.28L1228.65 456L1193.52 435.72L1158.4 415.44L1123.27 395.16L1088.14 374.87L1053 354.59L1017.87 334.31L982.74 314.02L947.62 293.75L912.49 273.46L877.36 253.18L842.23 232.9L807.09 212.61L771.96 192.33L736.84 172.05L701.71 151.77L666.58 131.49L631.45 111.2L596.31 90.92L561.18 70.64L526.06 50.36L490.93 30.08L455.8 9.79001L420.67 -10.49L385.53 -30.77L350.41 -51.05L315.28 -71.33L280.15 -91.62L245.02 -111.9L105.66 -192.36L245.02 -111.9L280.15 -91.62L315.28 -71.33L350.41 -51.05L385.53 -30.77L420.67 -10.49L455.8 9.79001L490.93 30.08L526.06 50.36L561.18 70.64L596.31 90.92L631.45 111.2L666.58 131.49L701.71 151.77L736.84 172.05L771.96 192.33L807.09 212.61L842.23 232.9L877.36 253.18L912.49 273.46L947.62 293.75L982.74 314.02L1017.87 334.31L1053 354.59L1088.14 374.87L1123.27 395.16L1158.4 415.44L1193.52 435.72L1228.65 456L1263.78 476.28L1298.92 496.57L1334.05 516.85L1369.18 537.14L1404.3 557.41L1439.43 577.7L1474.56 597.98L1509.7 618.26L1544.83 638.55L1579.95 658.82L1615.08 679.11L1650.21 699.39L1685.34 719.67L1824.71 800.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1859.61 779.85L1720.25 699.39L1685.12 679.11L1649.98 658.82L1614.85 638.54L1579.73 618.26L1544.6 597.98L1509.47 577.69L1474.34 557.41L1439.2 537.13L1404.08 516.85L1368.95 496.57L1333.82 476.28L1298.69 456L1263.56 435.72L1228.42 415.43L1193.3 395.16L1158.17 374.87L1123.04 354.59L1087.91 334.31L1052.78 314.02L1017.64 293.74L982.52 273.46L947.39 253.18L912.26 232.9L877.13 212.61L842 192.33L806.87 172.05L771.75 151.77L736.61 131.49L701.48 111.2L666.35 90.92L631.22 70.64L596.09 50.35L560.97 30.08L525.83 9.78996L490.7 -10.49L455.57 -30.77L420.44 -51.06L385.32 -71.33L350.19 -91.62L315.05 -111.9L279.92 -132.18L140.56 -212.65L279.92 -132.18L315.05 -111.9L350.19 -91.62L385.32 -71.33L420.44 -51.06L455.57 -30.77L490.7 -10.49L525.83 9.78996L560.97 30.08L596.09 50.35L631.22 70.64L666.35 90.92L701.48 111.2L736.61 131.49L771.75 151.77L806.87 172.05L842 192.33L877.13 212.61L912.26 232.9L947.39 253.18L982.52 273.46L1017.64 293.74L1052.78 314.02L1087.91 334.31L1123.04 354.59L1158.17 374.87L1193.3 395.16L1228.42 415.43L1263.56 435.72L1298.69 456L1333.82 476.28L1368.95 496.57L1404.08 516.85L1439.2 537.13L1474.34 557.41L1509.47 577.69L1544.6 597.98L1579.73 618.26L1614.85 638.54L1649.98 658.82L1685.12 679.11L1720.25 699.39L1859.61 779.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1894.51 759.57L1755.15 679.1L1720.02 658.82L1684.89 638.54L1649.76 618.25L1614.64 597.98L1579.5 577.69L1544.37 557.41L1509.24 537.13L1474.11 516.84L1438.99 496.57L1403.86 476.28L1368.72 456L1333.59 435.72L1298.46 415.43L1263.33 395.15L1228.21 374.87L1193.08 354.59L1157.94 334.31L1122.81 314.02L1087.68 293.74L1052.55 273.46L1017.43 253.18L982.3 232.9L947.16 212.61L912.03 192.33L876.9 172.05L841.77 151.76L806.65 131.49L771.52 111.2L736.38 90.92L701.25 70.64L666.12 50.35L630.99 30.07L595.87 9.78999L560.74 -10.49L525.61 -30.77L490.47 -51.06L455.34 -71.34L420.22 -91.62L385.09 -111.9L349.96 -132.18L314.83 -152.47L175.46 -232.93L314.83 -152.47L349.96 -132.18L385.09 -111.9L420.22 -91.62L455.34 -71.34L490.47 -51.06L525.61 -30.77L560.74 -10.49L595.87 9.78999L630.99 30.07L666.12 50.35L701.25 70.64L736.38 90.92L771.52 111.2L806.65 131.49L841.77 151.76L876.9 172.05L912.03 192.33L947.16 212.61L982.3 232.9L1017.43 253.18L1052.55 273.46L1087.68 293.74L1122.81 314.02L1157.94 334.31L1193.08 354.59L1228.21 374.87L1263.33 395.15L1298.46 415.43L1333.59 435.72L1368.72 456L1403.86 476.28L1438.99 496.57L1474.11 516.84L1509.24 537.13L1544.37 557.41L1579.5 577.69L1614.64 597.98L1649.76 618.25L1684.89 638.54L1720.02 658.82L1755.15 679.1L1894.51 759.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1929.42 739.28L1790.05 658.82L1754.92 638.54L1719.79 618.25L1684.66 597.97L1649.54 577.69L1614.41 557.41L1579.27 537.13L1544.14 516.84L1509.01 496.56L1473.89 476.28L1438.76 456L1403.63 435.72L1368.5 415.43L1333.36 395.15L1298.23 374.87L1263.11 354.59L1227.98 334.31L1192.85 314.02L1157.72 293.74L1122.58 273.46L1087.45 253.17L1052.33 232.9L1017.2 212.61L982.07 192.33L946.94 172.05L911.8 151.76L876.67 131.48L841.55 111.2L806.42 90.92L771.29 70.64L736.16 50.35L701.02 30.07L665.89 9.78998L630.77 -10.49L595.64 -30.77L560.51 -51.06L525.38 -71.34L490.25 -91.62L455.13 -111.9L419.99 -132.18L384.86 -152.47L349.73 -172.75L210.37 -253.21L349.73 -172.75L384.86 -152.47L419.99 -132.18L455.13 -111.9L490.25 -91.62L525.38 -71.34L560.51 -51.06L595.64 -30.77L630.77 -10.49L665.89 9.78998L701.02 30.07L736.16 50.35L771.29 70.64L806.42 90.92L841.55 111.2L876.67 131.48L911.8 151.76L946.94 172.05L982.07 192.33L1017.2 212.61L1052.33 232.9L1087.45 253.17L1122.58 273.46L1157.72 293.74L1192.85 314.02L1227.98 334.31L1263.11 354.59L1298.23 374.87L1333.36 395.15L1368.5 415.43L1403.63 435.72L1438.76 456L1473.89 476.28L1509.01 496.56L1544.14 516.84L1579.27 537.13L1614.41 557.41L1649.54 577.69L1684.66 597.97L1719.79 618.25L1754.92 638.54L1790.05 658.82L1929.42 739.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1964.32 719L1824.96 638.54L1789.83 618.25L1754.69 597.97L1719.56 577.69L1684.44 557.41L1649.31 537.13L1614.18 516.84L1579.05 496.56L1543.91 476.28L1508.79 456L1473.66 435.72L1438.53 415.43L1403.4 395.15L1368.27 374.87L1333.14 354.58L1298.02 334.31L1262.88 314.02L1227.75 293.74L1192.62 273.46L1157.49 253.17L1122.36 232.89L1087.24 212.61L1052.1 192.33L1016.97 172.05L981.84 151.76L946.71 131.48L911.58 111.2L876.46 90.92L841.32 70.64L806.19 50.35L771.06 30.07L735.93 9.79001L700.8 -10.5L665.68 -30.77L630.54 -51.06L595.41 -71.34L560.28 -91.62L525.15 -111.91L490.03 -132.18L454.9 -152.47L419.76 -172.75L384.63 -193.03L245.27 -273.5L384.63 -193.03L419.76 -172.75L454.9 -152.47L490.03 -132.18L525.15 -111.91L560.28 -91.62L595.41 -71.34L630.54 -51.06L665.68 -30.77L700.8 -10.5L735.93 9.79001L771.06 30.07L806.19 50.35L841.32 70.64L876.46 90.92L911.58 111.2L946.71 131.48L981.84 151.76L1016.97 172.05L1052.1 192.33L1087.24 212.61L1122.36 232.89L1157.49 253.17L1192.62 273.46L1227.75 293.74L1262.88 314.02L1298.02 334.31L1333.14 354.58L1368.27 374.87L1403.4 395.15L1438.53 415.43L1473.66 435.72L1508.79 456L1543.91 476.28L1579.05 496.56L1614.18 516.84L1649.31 537.13L1684.44 557.41L1719.56 577.69L1754.69 597.97L1789.83 618.25L1824.96 638.54L1964.32 719Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1999.23 698.72L1859.86 618.25L1824.73 597.97L1789.6 577.69L1754.47 557.4L1719.35 537.13L1684.21 516.84L1649.08 496.56L1613.95 476.28L1578.82 455.99L1543.7 435.72L1508.57 415.43L1473.43 395.15L1438.3 374.87L1403.17 354.58L1368.04 334.3L1332.92 314.02L1297.79 293.74L1262.65 273.46L1227.52 253.17L1192.39 232.89L1157.26 212.61L1122.14 192.33L1087.01 172.05L1051.88 151.76L1016.74 131.48L981.61 111.2L946.48 90.91L911.36 70.64L876.23 50.35L841.1 30.07L805.96 9.78998L770.83 -10.5L735.7 -30.78L700.58 -51.06L665.45 -71.34L630.32 -91.62L595.18 -111.91L560.05 -132.19L524.93 -152.47L489.8 -172.75L454.67 -193.04L419.54 -213.32L280.17 -293.78L419.54 -213.32L454.67 -193.04L489.8 -172.75L524.93 -152.47L560.05 -132.19L595.18 -111.91L630.32 -91.62L665.45 -71.34L700.58 -51.06L735.7 -30.78L770.83 -10.5L805.96 9.78998L841.1 30.07L876.23 50.35L911.36 70.64L946.48 90.91L981.61 111.2L1016.74 131.48L1051.88 151.76L1087.01 172.05L1122.14 192.33L1157.26 212.61L1192.39 232.89L1227.52 253.17L1262.65 273.46L1297.79 293.74L1332.92 314.02L1368.04 334.3L1403.17 354.58L1438.3 374.87L1473.43 395.15L1508.57 415.43L1543.7 435.72L1578.82 455.99L1613.95 476.28L1649.08 496.56L1684.21 516.84L1719.35 537.13L1754.47 557.4L1789.6 577.69L1824.73 597.97L1859.86 618.25L1999.23 698.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2034.12 678.44L1894.75 597.98L1859.62 577.69L1824.49 557.41L1789.36 537.13L1754.24 516.85L1719.11 496.57L1683.97 476.28L1648.84 456L1613.71 435.72L1578.59 415.44L1543.46 395.16L1508.33 374.87L1473.19 354.59L1438.06 334.31L1402.93 314.02L1367.81 293.75L1332.68 273.46L1297.55 253.18L1262.41 232.9L1227.28 212.61L1192.15 192.33L1157.03 172.05L1121.9 151.77L1086.77 131.49L1051.64 111.2L1016.5 90.9199L981.37 70.64L946.25 50.3599L911.12 30.0799L875.99 9.78995L840.86 -10.4901L805.72 -30.7701L770.59 -51.0601L735.47 -71.3301L700.34 -91.6201L665.21 -111.9L630.08 -132.19L594.94 -152.47L559.82 -172.75L524.69 -193.03L489.56 -213.31L454.43 -233.6L315.06 -314.06L454.43 -233.6L489.56 -213.31L524.69 -193.03L559.82 -172.75L594.94 -152.47L630.08 -132.19L665.21 -111.9L700.34 -91.6201L735.47 -71.3301L770.59 -51.0601L805.72 -30.7701L840.86 -10.4901L875.99 9.78995L911.12 30.0799L946.25 50.3599L981.37 70.64L1016.5 90.9199L1051.64 111.2L1086.77 131.49L1121.9 151.77L1157.03 172.05L1192.15 192.33L1227.28 212.61L1262.41 232.9L1297.55 253.18L1332.68 273.46L1367.81 293.75L1402.93 314.02L1438.06 334.31L1473.19 354.59L1508.33 374.87L1543.46 395.16L1578.59 415.44L1613.71 435.72L1648.84 456L1683.97 476.28L1719.11 496.57L1754.24 516.85L1789.36 537.13L1824.49 557.41L1859.62 577.69L1894.75 597.98L2034.12 678.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2069.02 658.15L1929.66 577.69L1894.52 557.41L1859.39 537.13L1824.26 516.84L1789.14 496.57L1754.01 476.28L1718.88 456L1683.75 435.72L1648.61 415.43L1613.49 395.16L1578.36 374.87L1543.23 354.59L1508.1 334.31L1472.97 314.02L1437.83 293.74L1402.71 273.46L1367.58 253.18L1332.45 232.9L1297.32 212.61L1262.19 192.33L1227.05 172.05L1191.93 151.77L1156.8 131.49L1121.67 111.2L1086.54 90.92L1051.41 70.63L1016.27 50.35L981.15 30.08L946.02 9.79004L910.89 -10.49L875.76 -30.78L840.63 -51.06L805.5 -71.34L770.38 -91.62L735.24 -111.9L700.11 -132.19L664.98 -152.47L629.85 -172.75L594.73 -193.03L559.6 -213.31L524.46 -233.6L489.33 -253.88L349.97 -334.34L489.33 -253.88L524.46 -233.6L559.6 -213.31L594.73 -193.03L629.85 -172.75L664.98 -152.47L700.11 -132.19L735.24 -111.9L770.38 -91.62L805.5 -71.34L840.63 -51.06L875.76 -30.78L910.89 -10.49L946.02 9.79004L981.15 30.08L1016.27 50.35L1051.41 70.63L1086.54 90.92L1121.67 111.2L1156.8 131.49L1191.93 151.77L1227.05 172.05L1262.19 192.33L1297.32 212.61L1332.45 232.9L1367.58 253.18L1402.71 273.46L1437.83 293.74L1472.97 314.02L1508.1 334.31L1543.23 354.59L1578.36 374.87L1613.49 395.16L1648.61 415.43L1683.75 435.72L1718.88 456L1754.01 476.28L1789.14 496.57L1824.26 516.84L1859.39 537.13L1894.52 557.41L1929.66 577.69L2069.02 658.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2103.92 637.87L1964.56 557.41L1929.43 537.13L1894.3 516.84L1859.16 496.56L1824.04 476.28L1788.91 456L1753.78 435.72L1718.65 415.43L1683.52 395.15L1648.4 374.87L1613.27 354.59L1578.13 334.31L1543 314.02L1507.87 293.74L1472.74 273.46L1437.62 253.18L1402.49 232.9L1367.35 212.61L1332.22 192.33L1297.09 172.04L1261.96 151.76L1226.84 131.48L1191.71 111.2L1156.57 90.92L1121.44 70.63L1086.31 50.35L1051.18 30.07L1016.06 9.79001L980.93 -10.49L945.79 -30.78L910.66 -51.06L875.53 -71.34L840.4 -91.63L805.28 -111.9L770.15 -132.19L735.02 -152.47L699.88 -172.75L664.75 -193.04L629.63 -213.31L594.5 -233.6L559.37 -253.88L524.24 -274.16L384.87 -354.62L524.24 -274.16L559.37 -253.88L594.5 -233.6L629.63 -213.31L664.75 -193.04L699.88 -172.75L735.02 -152.47L770.15 -132.19L805.28 -111.9L840.4 -91.63L875.53 -71.34L910.66 -51.06L945.79 -30.78L980.93 -10.49L1016.06 9.79001L1051.18 30.07L1086.31 50.35L1121.44 70.63L1156.57 90.92L1191.71 111.2L1226.84 131.48L1261.96 151.76L1297.09 172.04L1332.22 192.33L1367.35 212.61L1402.49 232.9L1437.62 253.18L1472.74 273.46L1507.87 293.74L1543 314.02L1578.13 334.31L1613.27 354.59L1648.4 374.87L1683.52 395.15L1718.65 415.43L1753.78 435.72L1788.91 456L1824.04 476.28L1859.16 496.56L1894.3 516.84L1929.43 537.13L1964.56 557.41L2103.92 637.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_4800">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* bijdak card */
export const step13Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
        <g clip-path="url(#clip0_68_4800)">
      <path
        d="M933.03 429.91L765.6 527.22L427.76 332.16L595.19 234.86L933.03 429.91Z"
        fill="#E8E6E0"
      />
      <path
        d="M933.03 429.91L932.28 695.2L764.84 792.5L765.6 527.22L933.03 429.91Z"
        fill="#D7D3CC"
      />
      <path
        d="M765.6 527.22L764.84 792.5L427.01 597.45L427.76 332.16L765.6 527.22Z"
        fill="#E8E6E0"
      />
      <path
        d="M445.39 535.43L444.55 978.39L767.22 791.28L764.74 382.09L445.39 535.43Z"
        fill="#D7D3CC"
      />
      <path
        d="M149.46 808.02L444.55 978.39L445.39 535.43L146.97 365.05L149.46 808.02Z"
        fill="#E8E6E0"
      />
      <path
        d="M764.74 382.09L441.22 195.5L318.43 168.5L146.97 365.05L445.39 535.43L617.38 330.98"
        fill="#B3B4B3"
      />
      <path
        d="M146.97 306.05L445.39 476.43L617.38 271.98L322.3 101.61L146.97 306.05Z"
        fill="#00A3DE"
      />
      <path
        d="M617.38 330.98L764.74 382.09L445.39 535.43L617.38 330.98Z"
        fill="#D7D3CC"
      />
      <path
        d="M322.3 101.61L617.38 271.98L764.74 323.09L441.22 138.5L322.3 101.61Z"
        fill="#00545E"
      />
      <path
        d="M532.86 927.36L618.54 876.96L618.92 637.77L533.08 688.77L532.86 927.36Z"
        fill="#E8E6E0"
      />
      <mask
        id="mask0_68_4800"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="660"
        y="582"
        width="61"
        height="163"
      >
        <path
          d="M661.04 744.23L660.68 616.18L719.67 582.12L720.04 710.17L661.04 744.23Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_4800)">
        <path
          d="M719.67 582.12L660.68 616.18L661.04 744.23L720.04 710.17L719.67 582.12Z"
          fill="#E8E6E0"
        />
      </g>
      <path
        d="M379.47 803.03L379.84 674.98L320.84 640.92L320.48 768.97L379.47 803.03Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask1_68_4800"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="320"
        y="640"
        width="60"
        height="164"
      >
        <path
          d="M379.47 803.03L379.84 674.98L320.84 640.92L320.48 768.97L379.47 803.03Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_68_4800)">
        <path
          d="M320.84 640.92L379.84 674.98L379.47 803.03L320.48 768.97L320.84 640.92Z"
          fill="#B3B4B3"
        />
      </g>
      <path
        d="M262.71 734.99L263.08 606.94L204.08 572.88L203.72 700.93L262.71 734.99Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask2_68_4800"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="203"
        y="572"
        width="61"
        height="163"
      >
        <path
          d="M262.71 734.99L263.08 606.94L204.08 572.88L203.72 700.93L262.71 734.99Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_68_4800)">
        <path
          d="M204.08 572.88L263.08 606.94L262.71 734.99L203.72 700.93L204.08 572.88Z"
          fill="#B3B4B3"
        />
      </g>
      <path
        d="M444.55 978.39L445.39 534.5"
        stroke="#B3B4B3"
        stroke-miterlimit="10"
      />
      <path
        d="M908.57 490.5L802.22 551.9L802.88 674.56L909.22 613.16L908.57 490.5Z"
        fill="#E8E6E0"
      />
      <path
        d="M146.97 306.05V314.1L445.22 484.5L618.3 278.66L764.74 329.38V323.09L617.38 271.98L445.22 476.5L146.97 306.05Z"
        fill="#00545E"
      />
      <path
        opacity="0.3"
        d="M672.96 1469.46L533.6 1388.99L498.46 1368.71L463.33 1348.43L428.2 1328.14L393.08 1307.87L357.95 1287.58L322.82 1267.3L287.69 1247.02L252.55 1226.73L217.43 1206.46L182.3 1186.17L147.17 1165.89L112.04 1145.61L76.9102 1125.32L41.7701 1105.04L6.65015 1084.76L-28.4799 1064.48L-63.6099 1044.2L-98.7399 1023.91L-133.87 1003.63L-169.01 983.35L-204.13 963.07L-239.26 942.79L-274.39 922.5L-309.52 902.22L-344.65 881.94L-379.79 861.65L-414.91 841.38L-450.04 821.09L-485.17 800.81L-520.3 780.53L-555.43 760.24L-590.56 739.96L-625.68 719.68L-660.82 699.4L-695.95 679.12L-731.08 658.83L-766.21 638.55L-801.33 618.27L-836.46 597.99L-871.6 577.71L-906.73 557.42L-1046.09 476.96L-906.73 557.42L-871.6 577.71L-836.46 597.99L-801.33 618.27L-766.21 638.55L-731.08 658.83L-695.95 679.12L-660.82 699.4L-625.68 719.68L-590.56 739.96L-555.43 760.24L-520.3 780.53L-485.17 800.81L-450.04 821.09L-414.91 841.38L-379.79 861.65L-344.65 881.94L-309.52 902.22L-274.39 922.5L-239.26 942.79L-204.13 963.07L-169.01 983.35L-133.87 1003.63L-98.7399 1023.91L-63.6099 1044.2L-28.4799 1064.48L6.65015 1084.76L41.7701 1105.04L76.9102 1125.32L112.04 1145.61L147.17 1165.89L182.3 1186.17L217.43 1206.46L252.55 1226.73L287.69 1247.02L322.82 1267.3L357.95 1287.58L393.08 1307.87L428.2 1328.14L463.33 1348.43L498.46 1368.71L533.6 1388.99L672.96 1469.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M707.86 1449.17L568.5 1368.71L533.37 1348.43L498.24 1328.14L463.1 1307.86L427.98 1287.58L392.85 1267.3L357.72 1247.02L322.59 1226.73L287.46 1206.45L252.34 1186.17L217.2 1165.89L182.07 1145.61L146.94 1125.32L111.81 1105.04L76.6801 1084.76L41.5601 1064.48L6.43005 1044.2L-28.71 1023.91L-63.84 1003.63L-98.97 983.35L-134.1 963.06L-169.22 942.79L-204.35 922.5L-239.49 902.22L-274.62 881.94L-309.75 861.65L-344.88 841.37L-380 821.09L-415.13 800.81L-450.27 780.53L-485.4 760.24L-520.53 739.96L-555.66 719.68L-590.78 699.4L-625.91 679.12L-661.05 658.83L-696.18 638.55L-731.31 618.27L-766.43 597.99L-801.56 577.71L-836.69 557.42L-871.82 537.14L-1011.19 456.68L-871.82 537.14L-836.69 557.42L-801.56 577.71L-766.43 597.99L-731.31 618.27L-696.18 638.55L-661.05 658.83L-625.91 679.12L-590.78 699.4L-555.66 719.68L-520.53 739.96L-485.4 760.24L-450.27 780.53L-415.13 800.81L-380 821.09L-344.88 841.37L-309.75 861.65L-274.62 881.94L-239.49 902.22L-204.35 922.5L-169.22 942.79L-134.1 963.06L-98.97 983.35L-63.84 1003.63L-28.71 1023.91L6.43005 1044.2L41.5601 1064.48L76.6801 1084.76L111.81 1105.04L146.94 1125.32L182.07 1145.61L217.2 1165.89L252.34 1186.17L287.46 1206.45L322.59 1226.73L357.72 1247.02L392.85 1267.3L427.98 1287.58L463.1 1307.86L498.24 1328.14L533.37 1348.43L568.5 1368.71L707.86 1449.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M742.77 1428.89L603.4 1348.43L568.27 1328.14L533.14 1307.86L498.01 1287.58L462.89 1267.3L427.76 1247.02L392.62 1226.73L357.49 1206.45L322.36 1186.17L287.24 1165.89L252.11 1145.61L216.98 1125.32L181.84 1105.04L146.71 1084.76L111.58 1064.47L76.4597 1044.2L41.3298 1023.91L6.19977 1003.63L-28.9302 983.35L-64.0703 963.06L-99.2003 942.78L-134.32 922.5L-169.45 902.22L-204.58 881.94L-239.71 861.65L-274.85 841.37L-309.98 821.09L-345.1 800.81L-380.23 780.53L-415.36 760.24L-450.49 739.96L-485.63 719.68L-520.76 699.39L-555.88 679.12L-591.01 658.83L-626.14 638.55L-661.27 618.27L-696.41 597.98L-731.53 577.71L-766.66 557.42L-801.79 537.14L-836.92 516.85L-976.28 436.39L-836.92 516.85L-801.79 537.14L-766.66 557.42L-731.53 577.71L-696.41 597.98L-661.27 618.27L-626.14 638.55L-591.01 658.83L-555.88 679.12L-520.76 699.39L-485.63 719.68L-450.49 739.96L-415.36 760.24L-380.23 780.53L-345.1 800.81L-309.98 821.09L-274.85 841.37L-239.71 861.65L-204.58 881.94L-169.45 902.22L-134.32 922.5L-99.2003 942.78L-64.0703 963.06L-28.9302 983.35L6.19977 1003.63L41.3298 1023.91L76.4597 1044.2L111.58 1064.47L146.71 1084.76L181.84 1105.04L216.98 1125.32L252.11 1145.61L287.24 1165.89L322.36 1186.17L357.49 1206.45L392.62 1226.73L427.76 1247.02L462.89 1267.3L498.01 1287.58L533.14 1307.86L568.27 1328.14L603.4 1348.43L742.77 1428.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M777.66 1408.61L638.3 1328.15L603.16 1307.87L568.03 1287.58L532.9 1267.3L497.78 1247.02L462.65 1226.74L427.52 1206.46L392.38 1186.17L357.25 1165.89L322.13 1145.61L287 1125.33L251.87 1105.05L216.74 1084.76L181.6 1064.48L146.47 1044.2L111.35 1023.92L76.2198 1003.64L41.0899 983.35L5.9599 963.07L-29.1801 942.79L-64.3101 922.5L-99.4301 902.23L-134.56 881.94L-169.69 861.66L-204.82 841.38L-239.95 821.09L-275.09 800.81L-310.21 780.53L-345.34 760.25L-380.47 739.97L-415.6 719.68L-450.73 699.4L-485.87 679.12L-520.99 658.84L-556.12 638.56L-591.25 618.27L-626.38 597.99L-661.51 577.7L-696.63 557.43L-731.77 537.14L-766.9 516.86L-802.03 496.58L-941.39 416.12L-802.03 496.58L-766.9 516.86L-731.77 537.14L-696.63 557.43L-661.51 577.7L-626.38 597.99L-591.25 618.27L-556.12 638.56L-520.99 658.84L-485.87 679.12L-450.73 699.4L-415.6 719.68L-380.47 739.97L-345.34 760.25L-310.21 780.53L-275.09 800.81L-239.95 821.09L-204.82 841.38L-169.69 861.66L-134.56 881.94L-99.4301 902.23L-64.3101 922.5L-29.1801 942.79L5.9599 963.07L41.0899 983.35L76.2198 1003.64L111.35 1023.92L146.47 1044.2L181.6 1064.48L216.74 1084.76L251.87 1105.05L287 1125.33L322.13 1145.61L357.25 1165.89L392.38 1186.17L427.52 1206.46L462.65 1226.74L497.78 1247.02L532.9 1267.3L568.03 1287.58L603.16 1307.87L638.3 1328.15L777.66 1408.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M812.56 1388.33L673.2 1307.87L638.07 1287.58L602.94 1267.3L567.8 1247.02L532.68 1226.74L497.55 1206.46L462.42 1186.17L427.29 1165.89L392.16 1145.61L357.04 1125.33L321.9 1105.05L286.77 1084.76L251.64 1064.48L216.51 1044.2L181.38 1023.91L146.26 1003.64L111.12 983.35L75.9897 963.07L40.8597 942.79L5.72974 922.5L-29.4003 902.22L-64.5203 881.94L-99.6603 861.66L-134.79 841.38L-169.92 821.09L-205.05 800.81L-240.18 780.52L-275.3 760.25L-310.43 739.97L-345.57 719.68L-380.7 699.4L-415.83 679.11L-450.96 658.83L-486.08 638.55L-521.21 618.27L-556.35 597.99L-591.48 577.7L-626.61 557.42L-661.73 537.14L-696.86 516.86L-731.99 496.58L-767.13 476.29L-906.49 395.83L-767.13 476.29L-731.99 496.58L-696.86 516.86L-661.73 537.14L-626.61 557.42L-591.48 577.7L-556.35 597.99L-521.21 618.27L-486.08 638.55L-450.96 658.83L-415.83 679.11L-380.7 699.4L-345.57 719.68L-310.43 739.97L-275.3 760.25L-240.18 780.52L-205.05 800.81L-169.92 821.09L-134.79 841.38L-99.6603 861.66L-64.5203 881.94L-29.4003 902.22L5.72974 922.5L40.8597 942.79L75.9897 963.07L111.12 983.35L146.26 1003.64L181.38 1023.91L216.51 1044.2L251.64 1064.48L286.77 1084.76L321.9 1105.05L357.04 1125.33L392.16 1145.61L427.29 1165.89L462.42 1186.17L497.55 1206.46L532.68 1226.74L567.8 1247.02L602.94 1267.3L638.07 1287.58L673.2 1307.87L812.56 1388.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M847.47 1368.04L708.1 1287.58L672.97 1267.3L637.84 1247.02L602.71 1226.73L567.59 1206.46L532.46 1186.17L497.32 1165.89L462.19 1145.61L427.06 1125.32L391.94 1105.05L356.81 1084.76L321.68 1064.48L286.54 1044.2L251.41 1023.91L216.28 1003.63L181.16 983.35L146.03 963.07L110.9 942.79L75.7597 922.5L40.6297 902.22L5.49969 881.93L-29.6203 861.66L-64.7503 841.37L-99.8803 821.09L-135.02 800.81L-170.15 780.52L-205.28 760.24L-240.4 739.96L-275.53 719.68L-310.66 699.4L-345.79 679.11L-380.93 658.83L-416.06 638.55L-451.18 618.27L-486.31 597.99L-521.44 577.7L-556.57 557.42L-591.71 537.14L-626.83 516.86L-661.96 496.58L-697.09 476.29L-732.22 456.01L-871.59 375.55L-732.22 456.01L-697.09 476.29L-661.96 496.58L-626.83 516.86L-591.71 537.14L-556.57 557.42L-521.44 577.7L-486.31 597.99L-451.18 618.27L-416.06 638.55L-380.93 658.83L-345.79 679.11L-310.66 699.4L-275.53 719.68L-240.4 739.96L-205.28 760.24L-170.15 780.52L-135.02 800.81L-99.8803 821.09L-64.7503 841.37L-29.6203 861.66L5.49969 881.93L40.6297 902.22L75.7597 922.5L110.9 942.79L146.03 963.07L181.16 983.35L216.28 1003.63L251.41 1023.91L286.54 1044.2L321.68 1064.48L356.81 1084.76L391.94 1105.05L427.06 1125.32L462.19 1145.61L497.32 1165.89L532.46 1186.17L567.59 1206.46L602.71 1226.73L637.84 1247.02L672.97 1267.3L708.1 1287.58L847.47 1368.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M882.37 1347.76L743.01 1267.3L707.87 1247.02L672.74 1226.73L637.61 1206.45L602.49 1186.17L567.36 1165.89L532.23 1145.61L497.09 1125.32L461.96 1105.04L426.84 1084.76L391.71 1064.48L356.58 1044.2L321.45 1023.91L286.32 1003.63L251.18 983.34L216.06 963.07L180.93 942.78L145.8 922.5L110.67 902.22L75.5398 881.93L40.3998 861.65L5.27985 841.37L-29.8502 821.09L-64.9802 800.81L-100.11 780.52L-135.24 760.24L-170.38 739.96L-205.5 719.68L-240.63 699.4L-275.76 679.11L-310.89 658.83L-346.02 638.55L-381.16 618.26L-416.28 597.99L-451.41 577.7L-486.54 557.42L-521.67 537.14L-556.8 516.85L-591.92 496.58L-627.05 476.29L-662.19 456.01L-697.32 435.73L-836.68 355.27L-697.32 435.73L-662.19 456.01L-627.05 476.29L-591.92 496.58L-556.8 516.85L-521.67 537.14L-486.54 557.42L-451.41 577.7L-416.28 597.99L-381.16 618.26L-346.02 638.55L-310.89 658.83L-275.76 679.11L-240.63 699.4L-205.5 719.68L-170.38 739.96L-135.24 760.24L-100.11 780.52L-64.9802 800.81L-29.8502 821.09L5.27985 841.37L40.3998 861.65L75.5398 881.93L110.67 902.22L145.8 922.5L180.93 942.78L216.06 963.07L251.18 983.34L286.32 1003.63L321.45 1023.91L356.58 1044.2L391.71 1064.48L426.84 1084.76L461.96 1105.04L497.09 1125.32L532.23 1145.61L567.36 1165.89L602.49 1186.17L637.61 1206.45L672.74 1226.73L707.87 1247.02L743.01 1267.3L882.37 1347.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M917.27 1327.48L777.91 1247.02L742.78 1226.73L707.65 1206.45L672.51 1186.16L637.39 1165.89L602.26 1145.6L567.13 1125.32L532 1105.04L496.87 1084.75L461.75 1064.48L426.61 1044.19L391.48 1023.91L356.35 1003.63L321.22 983.34L286.09 963.06L250.97 942.78L215.84 922.5L180.7 902.22L145.57 881.93L110.44 861.65L75.3098 841.37L40.1898 821.09L5.05975 800.81L-30.0803 780.52L-65.2103 760.24L-100.34 739.96L-135.47 719.67L-170.59 699.4L-205.72 679.11L-240.86 658.83L-275.99 638.55L-311.12 618.26L-346.25 597.98L-381.37 577.7L-416.5 557.42L-451.64 537.14L-486.77 516.85L-521.9 496.57L-557.02 476.29L-592.15 456.01L-627.28 435.73L-662.42 415.44L-801.78 334.98L-662.42 415.44L-627.28 435.73L-592.15 456.01L-557.02 476.29L-521.9 496.57L-486.77 516.85L-451.64 537.14L-416.5 557.42L-381.37 577.7L-346.25 597.98L-311.12 618.26L-275.99 638.55L-240.86 658.83L-205.72 679.11L-170.59 699.4L-135.47 719.67L-100.34 739.96L-65.2103 760.24L-30.0803 780.52L5.05975 800.81L40.1898 821.09L75.3098 841.37L110.44 861.65L145.57 881.93L180.7 902.22L215.84 922.5L250.97 942.78L286.09 963.06L321.22 983.34L356.35 1003.63L391.48 1023.91L426.61 1044.19L461.75 1064.48L496.87 1084.75L532 1105.04L567.13 1125.32L602.26 1145.6L637.39 1165.89L672.51 1186.16L707.65 1206.45L742.78 1226.73L777.91 1247.02L917.27 1327.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M952.18 1307.19L812.81 1226.73L777.68 1206.45L742.55 1186.16L707.42 1165.88L672.3 1145.6L637.17 1125.32L602.03 1105.04L566.9 1084.75L531.77 1064.47L496.65 1044.19L461.52 1023.91L426.39 1003.63L391.25 983.34L356.12 963.06L320.99 942.78L285.87 922.5L250.74 902.22L215.61 881.93L180.47 861.65L145.34 841.37L110.21 821.08L75.0898 800.81L39.9599 780.52L4.8299 760.24L-30.3001 739.96L-65.4401 719.67L-100.57 699.39L-135.69 679.11L-170.82 658.83L-205.95 638.55L-241.08 618.26L-276.22 597.98L-311.35 577.7L-346.47 557.42L-381.6 537.14L-416.73 516.85L-451.86 496.57L-487 476.29L-522.12 456.01L-557.25 435.73L-592.38 415.44L-627.51 395.16L-766.87 314.7L-627.51 395.16L-592.38 415.44L-557.25 435.73L-522.12 456.01L-487 476.29L-451.86 496.57L-416.73 516.85L-381.6 537.14L-346.47 557.42L-311.35 577.7L-276.22 597.98L-241.08 618.26L-205.95 638.55L-170.82 658.83L-135.69 679.11L-100.57 699.39L-65.4401 719.67L-30.3001 739.96L4.8299 760.24L39.9599 780.52L75.0898 800.81L110.21 821.08L145.34 841.37L180.47 861.65L215.61 881.93L250.74 902.22L285.87 922.5L320.99 942.78L356.12 963.06L391.25 983.34L426.39 1003.63L461.52 1023.91L496.65 1044.19L531.77 1064.47L566.9 1084.75L602.03 1105.04L637.17 1125.32L672.3 1145.6L707.42 1165.88L742.55 1186.16L777.68 1206.45L812.81 1226.73L952.18 1307.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M987.07 1286.92L847.71 1206.45L812.57 1186.17L777.44 1165.89L742.31 1145.6L707.19 1125.33L672.06 1105.04L636.93 1084.76L601.79 1064.48L566.66 1044.19L531.54 1023.92L496.41 1003.63L461.28 983.35L426.15 963.07L391.01 942.78L355.88 922.5L320.76 902.22L285.63 881.94L250.5 861.66L215.37 841.37L180.23 821.09L145.1 800.81L109.98 780.53L74.85 760.25L39.72 739.96L4.59003 719.68L-30.54 699.4L-65.68 679.11L-100.8 658.84L-135.93 638.55L-171.06 618.27L-206.19 597.99L-241.32 577.7L-276.46 557.42L-311.58 537.14L-346.71 516.86L-381.84 496.58L-416.97 476.29L-452.1 456.01L-487.22 435.73L-522.36 415.45L-557.49 395.17L-592.62 374.88L-731.98 294.42L-592.62 374.88L-557.49 395.17L-522.36 415.45L-487.22 435.73L-452.1 456.01L-416.97 476.29L-381.84 496.58L-346.71 516.86L-311.58 537.14L-276.46 557.42L-241.32 577.7L-206.19 597.99L-171.06 618.27L-135.93 638.55L-100.8 658.84L-65.68 679.11L-30.54 699.4L4.59003 719.68L39.72 739.96L74.85 760.25L109.98 780.53L145.1 800.81L180.23 821.09L215.37 841.37L250.5 861.66L285.63 881.94L320.76 902.22L355.88 922.5L391.01 942.78L426.15 963.07L461.28 983.35L496.41 1003.63L531.54 1023.92L566.66 1044.19L601.79 1064.48L636.93 1084.76L672.06 1105.04L707.19 1125.33L742.31 1145.6L777.44 1165.89L812.57 1186.17L847.71 1206.45L987.07 1286.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1021.97 1266.63L882.61 1186.17L847.48 1165.89L812.34 1145.6L777.21 1125.32L742.09 1105.04L706.96 1084.76L671.83 1064.48L636.7 1044.19L601.57 1023.91L566.45 1003.63L531.31 983.35L496.18 963.07L461.05 942.78L425.92 922.5L390.79 902.22L355.67 881.94L320.53 861.66L285.4 841.37L250.27 821.09L215.14 800.81L180.01 780.52L144.89 760.25L109.75 739.96L74.6199 719.68L39.4899 699.4L4.35992 679.11L-30.7701 658.83L-65.8901 638.55L-101.03 618.27L-136.16 597.99L-171.29 577.7L-206.42 557.42L-241.55 537.14L-276.67 516.86L-311.8 496.58L-346.94 476.29L-382.07 456.01L-417.2 435.73L-452.32 415.45L-487.45 395.17L-522.58 374.88L-557.72 354.6L-697.08 274.14L-557.72 354.6L-522.58 374.88L-487.45 395.17L-452.32 415.45L-417.2 435.73L-382.07 456.01L-346.94 476.29L-311.8 496.58L-276.67 516.86L-241.55 537.14L-206.42 557.42L-171.29 577.7L-136.16 597.99L-101.03 618.27L-65.8901 638.55L-30.7701 658.83L4.35992 679.11L39.4899 699.4L74.6199 719.68L109.75 739.96L144.89 760.25L180.01 780.52L215.14 800.81L250.27 821.09L285.4 841.37L320.53 861.66L355.67 881.94L390.79 902.22L425.92 922.5L461.05 942.78L496.18 963.07L531.31 983.35L566.45 1003.63L601.57 1023.91L636.7 1044.19L671.83 1064.48L706.96 1084.76L742.09 1105.04L777.21 1125.32L812.34 1145.6L847.48 1165.89L882.61 1186.17L1021.97 1266.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1056.88 1246.35L917.51 1165.89L882.38 1145.6L847.25 1125.32L812.12 1105.04L777 1084.76L741.86 1064.48L706.73 1044.19L671.6 1023.91L636.47 1003.63L601.35 983.35L566.22 963.07L531.09 942.78L495.95 922.5L460.82 902.22L425.69 881.93L390.57 861.66L355.44 841.37L320.31 821.09L285.17 800.81L250.04 780.52L214.91 760.24L179.79 739.96L144.66 719.68L109.53 699.4L74.3898 679.11L39.2598 658.83L4.12982 638.55L-30.9902 618.27L-66.1202 597.99L-101.25 577.7L-136.39 557.42L-171.52 537.14L-206.65 516.85L-241.77 496.58L-276.9 476.29L-312.03 456.01L-347.17 435.73L-382.3 415.44L-417.42 395.17L-452.55 374.88L-487.68 354.6L-522.81 334.32L-662.18 253.85L-522.81 334.32L-487.68 354.6L-452.55 374.88L-417.42 395.17L-382.3 415.44L-347.17 435.73L-312.03 456.01L-276.9 476.29L-241.77 496.58L-206.65 516.85L-171.52 537.14L-136.39 557.42L-101.25 577.7L-66.1202 597.99L-30.9902 618.27L4.12982 638.55L39.2598 658.83L74.3898 679.11L109.53 699.4L144.66 719.68L179.79 739.96L214.91 760.24L250.04 780.52L285.17 800.81L320.31 821.09L355.44 841.37L390.57 861.66L425.69 881.93L460.82 902.22L495.95 922.5L531.09 942.78L566.22 963.07L601.35 983.35L636.47 1003.63L671.6 1023.91L706.73 1044.19L741.86 1064.48L777 1084.76L812.12 1105.04L847.25 1125.32L882.38 1145.6L917.51 1165.89L1056.88 1246.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1091.78 1226.06L952.42 1145.6L917.28 1125.32L882.15 1105.04L847.02 1084.75L811.9 1064.48L776.77 1044.19L741.64 1023.91L706.5 1003.63L671.37 983.34L636.25 963.07L601.12 942.78L565.99 922.5L530.86 902.22L495.72 881.93L460.59 861.65L425.47 841.37L390.34 821.09L355.21 800.81L320.08 780.52L284.95 760.24L249.81 739.96L214.69 719.68L179.56 699.4L144.43 679.11L109.3 658.83L74.17 638.55L39.03 618.26L3.90997 597.99L-31.22 577.7L-66.35 557.42L-101.48 537.14L-136.61 516.85L-171.75 496.57L-206.87 476.29L-242 456.01L-277.13 435.72L-312.26 415.44L-347.39 395.16L-382.51 374.88L-417.65 354.6L-452.78 334.31L-487.91 314.03L-627.27 233.57L-487.91 314.03L-452.78 334.31L-417.65 354.6L-382.51 374.88L-347.39 395.16L-312.26 415.44L-277.13 435.72L-242 456.01L-206.87 476.29L-171.75 496.57L-136.61 516.85L-101.48 537.14L-66.35 557.42L-31.22 577.7L3.90997 597.99L39.03 618.26L74.17 638.55L109.3 658.83L144.43 679.11L179.56 699.4L214.69 719.68L249.81 739.96L284.95 760.24L320.08 780.52L355.21 800.81L390.34 821.09L425.47 841.37L460.59 861.65L495.72 881.93L530.86 902.22L565.99 922.5L601.12 942.78L636.25 963.07L671.37 983.34L706.5 1003.63L741.64 1023.91L776.77 1044.19L811.9 1064.48L847.02 1084.75L882.15 1105.04L917.28 1125.32L952.42 1145.6L1091.78 1226.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1126.68 1205.78L987.32 1125.32L952.19 1105.04L917.06 1084.75L881.92 1064.47L846.8 1044.19L811.67 1023.91L776.54 1003.63L741.41 983.34L706.28 963.06L671.16 942.78L636.02 922.5L600.89 902.22L565.76 881.93L530.63 861.65L495.5 841.37L460.38 821.09L425.24 800.81L390.11 780.52L354.98 760.24L319.85 739.96L284.72 719.67L249.6 699.4L214.47 679.11L179.33 658.83L144.2 638.54L109.07 618.26L73.9399 597.98L38.8199 577.7L3.68988 557.42L-31.4501 537.13L-66.5801 516.85L-101.71 496.57L-136.84 476.28L-171.96 456.01L-207.09 435.72L-242.23 415.44L-277.36 395.16L-312.49 374.87L-347.61 354.6L-382.74 334.31L-417.87 314.03L-453.01 293.75L-592.37 213.29L-453.01 293.75L-417.87 314.03L-382.74 334.31L-347.61 354.6L-312.49 374.87L-277.36 395.16L-242.23 415.44L-207.09 435.72L-171.96 456.01L-136.84 476.28L-101.71 496.57L-66.5801 516.85L-31.4501 537.13L3.68988 557.42L38.8199 577.7L73.9399 597.98L109.07 618.26L144.2 638.54L179.33 658.83L214.47 679.11L249.6 699.4L284.72 719.67L319.85 739.96L354.98 760.24L390.11 780.52L425.24 800.81L460.38 821.09L495.5 841.37L530.63 861.65L565.76 881.93L600.89 902.22L636.02 922.5L671.16 942.78L706.28 963.06L741.41 983.34L776.54 1003.63L811.67 1023.91L846.8 1044.19L881.92 1064.47L917.06 1084.75L952.19 1105.04L987.32 1125.32L1126.68 1205.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1161.59 1185.5L1022.22 1105.04L987.09 1084.75L951.96 1064.47L916.83 1044.19L881.71 1023.91L846.58 1003.63L811.44 983.34L776.31 963.06L741.18 942.78L706.06 922.5L670.93 902.22L635.8 881.93L600.66 861.65L565.53 841.37L530.4 821.08L495.28 800.81L460.15 780.52L425.02 760.24L389.88 739.95L354.75 719.67L319.62 699.39L284.5 679.11L249.37 658.83L214.24 638.54L179.1 618.26L143.97 597.98L108.84 577.69L73.7197 557.42L38.5898 537.13L3.45978 516.85L-31.6702 496.57L-66.8102 476.28L-101.94 456L-137.06 435.72L-172.19 415.44L-207.32 395.16L-242.45 374.87L-277.59 354.59L-312.71 334.31L-347.84 314.03L-382.97 293.75L-418.1 273.46L-557.47 193L-418.1 273.46L-382.97 293.75L-347.84 314.03L-312.71 334.31L-277.59 354.59L-242.45 374.87L-207.32 395.16L-172.19 415.44L-137.06 435.72L-101.94 456L-66.8102 476.28L-31.6702 496.57L3.45978 516.85L38.5898 537.13L73.7197 557.42L108.84 577.69L143.97 597.98L179.1 618.26L214.24 638.54L249.37 658.83L284.5 679.11L319.62 699.39L354.75 719.67L389.88 739.95L425.02 760.24L460.15 780.52L495.28 800.81L530.4 821.08L565.53 841.37L600.66 861.65L635.8 881.93L670.93 902.22L706.06 922.5L741.18 942.78L776.31 963.06L811.44 983.34L846.58 1003.63L881.71 1023.91L916.83 1044.19L951.96 1064.47L987.09 1084.75L1022.22 1105.04L1161.59 1185.5Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1196.48 1165.22L1057.11 1084.76L1021.98 1064.48L986.85 1044.19L951.72 1023.91L916.6 1003.63L881.47 983.35L846.34 963.07L811.2 942.78L776.07 922.5L740.95 902.22L705.82 881.94L670.69 861.66L635.56 841.37L600.42 821.09L565.29 800.8L530.17 780.53L495.04 760.24L459.91 739.96L424.78 719.68L389.64 699.39L354.51 679.11L319.39 658.83L284.26 638.55L249.13 618.27L214 597.98L178.86 577.7L143.73 557.42L108.61 537.14L73.4797 516.86L38.3497 496.57L3.21967 476.29L-31.9103 456.01L-67.0503 435.72L-102.17 415.45L-137.3 395.16L-172.43 374.88L-207.56 354.6L-242.69 334.31L-277.81 314.04L-312.95 293.75L-348.08 273.47L-383.21 253.19L-522.57 172.73L-383.21 253.19L-348.08 273.47L-312.95 293.75L-277.81 314.04L-242.69 334.31L-207.56 354.6L-172.43 374.88L-137.3 395.16L-102.17 415.45L-67.0503 435.72L-31.9103 456.01L3.21967 476.29L38.3497 496.57L73.4797 516.86L108.61 537.14L143.73 557.42L178.86 577.7L214 597.98L249.13 618.27L284.26 638.55L319.39 658.83L354.51 679.11L389.64 699.39L424.78 719.68L459.91 739.96L495.04 760.24L530.17 780.53L565.29 800.8L600.42 821.09L635.56 841.37L670.69 861.66L705.82 881.94L740.95 902.22L776.07 922.5L811.2 942.78L846.34 963.07L881.47 983.35L916.6 1003.63L951.72 1023.91L986.85 1044.19L1021.98 1064.48L1057.11 1084.76L1196.48 1165.22Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1231.38 1144.94L1092.02 1064.48L1056.89 1044.19L1021.75 1023.91L986.62 1003.62L951.5 983.35L916.37 963.07L881.24 942.78L846.11 922.5L810.97 902.21L775.85 881.94L740.72 861.65L705.59 841.37L670.46 821.09L635.33 800.8L600.2 780.52L565.08 760.24L529.94 739.96L494.81 719.68L459.68 699.39L424.55 679.11L389.42 658.83L354.3 638.55L319.16 618.27L284.03 597.98L248.9 577.7L213.77 557.42L178.64 537.13L143.52 516.86L108.38 496.57L73.2501 476.29L38.1201 456.01L2.99008 435.72L-32.1399 415.44L-67.2599 395.16L-102.4 374.88L-137.53 354.6L-172.66 334.31L-207.79 314.03L-242.91 293.75L-278.04 273.47L-313.17 253.19L-348.31 232.9L-487.67 152.44L-348.31 232.9L-313.17 253.19L-278.04 273.47L-242.91 293.75L-207.79 314.03L-172.66 334.31L-137.53 354.6L-102.4 374.88L-67.2599 395.16L-32.1399 415.44L2.99008 435.72L38.1201 456.01L73.2501 476.29L108.38 496.57L143.52 516.86L178.64 537.13L213.77 557.42L248.9 577.7L284.03 597.98L319.16 618.27L354.3 638.55L389.42 658.83L424.55 679.11L459.68 699.39L494.81 719.68L529.94 739.96L565.08 760.24L600.2 780.52L635.33 800.8L670.46 821.09L705.59 841.37L740.72 861.65L775.85 881.94L810.97 902.21L846.11 922.5L881.24 942.78L916.37 963.07L951.5 983.35L986.62 1003.62L1021.75 1023.91L1056.89 1044.19L1092.02 1064.48L1231.38 1144.94Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1266.29 1124.65L1126.92 1044.19L1091.79 1023.91L1056.66 1003.62L1021.53 983.34L986.41 963.06L951.27 942.78L916.14 922.5L881.01 902.21L845.88 881.93L810.76 861.65L775.63 841.37L740.49 821.09L705.36 800.8L670.23 780.52L635.1 760.24L599.98 739.96L564.85 719.68L529.72 699.39L494.58 679.11L459.45 658.83L424.32 638.54L389.2 618.27L354.07 597.98L318.94 577.7L283.8 557.42L248.67 537.13L213.54 516.85L178.42 496.57L143.29 476.29L108.16 456.01L73.02 435.72L37.89 415.44L2.75998 395.16L-32.36 374.88L-67.49 354.6L-102.62 334.31L-137.76 314.03L-172.89 293.75L-208.01 273.47L-243.14 253.19L-278.27 232.9L-313.4 212.62L-452.77 132.16L-313.4 212.62L-278.27 232.9L-243.14 253.19L-208.01 273.47L-172.89 293.75L-137.76 314.03L-102.62 334.31L-67.49 354.6L-32.36 374.88L2.75998 395.16L37.89 415.44L73.02 435.72L108.16 456.01L143.29 476.29L178.42 496.57L213.54 516.85L248.67 537.13L283.8 557.42L318.94 577.7L354.07 597.98L389.2 618.27L424.32 638.54L459.45 658.83L494.58 679.11L529.72 699.39L564.85 719.68L599.98 739.96L635.1 760.24L670.23 780.52L705.36 800.8L740.49 821.09L775.63 841.37L810.76 861.65L845.88 881.93L881.01 902.21L916.14 922.5L951.27 942.78L986.41 963.06L1021.53 983.34L1056.66 1003.62L1091.79 1023.91L1126.92 1044.19L1266.29 1124.65Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1301.19 1104.37L1161.83 1023.91L1126.69 1003.62L1091.56 983.34L1056.43 963.06L1021.31 942.78L986.18 922.5L951.05 902.21L915.91 881.93L880.78 861.65L845.66 841.37L810.53 821.09L775.4 800.8L740.27 780.52L705.13 760.24L670 739.95L634.88 719.68L599.75 699.39L564.62 679.11L529.49 658.83L494.36 638.54L459.22 618.26L424.1 597.98L388.97 577.7L353.84 557.42L318.71 537.13L283.58 516.85L248.44 496.57L213.32 476.29L178.19 456.01L143.06 435.72L107.93 415.44L72.7996 395.16L37.6596 374.87L2.53964 354.6L-32.5904 334.31L-67.7204 314.03L-102.85 293.75L-137.98 273.46L-173.1 253.19L-208.24 232.9L-243.37 212.62L-278.5 192.34L-417.86 111.87L-278.5 192.34L-243.37 212.62L-208.24 232.9L-173.1 253.19L-137.98 273.46L-102.85 293.75L-67.7204 314.03L-32.5904 334.31L2.53964 354.6L37.6596 374.87L72.7996 395.16L107.93 415.44L143.06 435.72L178.19 456.01L213.32 476.29L248.44 496.57L283.58 516.85L318.71 537.13L353.84 557.42L388.97 577.7L424.1 597.98L459.22 618.26L494.36 638.54L529.49 658.83L564.62 679.11L599.75 699.39L634.88 719.68L670 739.95L705.13 760.24L740.27 780.52L775.4 800.8L810.53 821.09L845.66 841.37L880.78 861.65L915.91 881.93L951.05 902.21L986.18 922.5L1021.31 942.78L1056.43 963.06L1091.56 983.34L1126.69 1003.62L1161.83 1023.91L1301.19 1104.37Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1336.09 1084.09L1196.73 1003.62L1161.6 983.34L1126.47 963.06L1091.33 942.77L1056.21 922.5L1021.08 902.21L985.95 881.93L950.82 861.65L915.69 841.36L880.57 821.09L845.43 800.8L810.3 780.52L775.17 760.24L740.04 739.95L704.91 719.67L669.79 699.39L634.65 679.11L599.52 658.83L564.39 638.54L529.26 618.26L494.13 597.98L459.01 577.7L423.87 557.42L388.74 537.13L353.61 516.85L318.48 496.57L283.35 476.28L248.23 456.01L213.1 435.72L177.96 415.44L142.83 395.16L107.7 374.87L72.57 354.59L37.45 334.31L2.32004 314.03L-32.82 293.75L-67.95 273.46L-103.08 253.18L-138.2 232.9L-173.33 212.62L-208.46 192.34L-243.6 172.05L-382.96 91.59L-243.6 172.05L-208.46 192.34L-173.33 212.62L-138.2 232.9L-103.08 253.18L-67.95 273.46L-32.82 293.75L2.32004 314.03L37.45 334.31L72.57 354.59L107.7 374.87L142.83 395.16L177.96 415.44L213.1 435.72L248.23 456.01L283.35 476.28L318.48 496.57L353.61 516.85L388.74 537.13L423.87 557.42L459.01 577.7L494.13 597.98L529.26 618.26L564.39 638.54L599.52 658.83L634.65 679.11L669.79 699.39L704.91 719.67L740.04 739.95L775.17 760.24L810.3 780.52L845.43 800.8L880.57 821.09L915.69 841.36L950.82 861.65L985.95 881.93L1021.08 902.21L1056.21 922.5L1091.33 942.77L1126.47 963.06L1161.6 983.34L1196.73 1003.62L1336.09 1084.09Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1371 1063.8L1231.63 983.34L1196.5 963.06L1161.37 942.77L1126.24 922.49L1091.12 902.21L1055.99 881.93L1020.85 861.65L985.72 841.36L950.59 821.08L915.47 800.8L880.34 780.52L845.21 760.24L810.07 739.95L774.94 719.67L739.81 699.39L704.69 679.11L669.56 658.83L634.43 638.54L599.29 618.26L564.16 597.98L529.03 577.69L493.91 557.42L458.78 537.13L423.65 516.85L388.51 496.57L353.38 476.28L318.25 456L283.13 435.72L248 415.44L212.87 395.16L177.73 374.87L142.6 354.59L107.47 334.31L72.3499 314.03L37.2199 293.75L2.08994 273.46L-33.0401 253.18L-68.1801 232.89L-103.3 212.62L-138.43 192.34L-173.56 172.05L-208.69 151.77L-348.06 71.31L-208.69 151.77L-173.56 172.05L-138.43 192.34L-103.3 212.62L-68.1801 232.89L-33.0401 253.18L2.08994 273.46L37.2199 293.75L72.3499 314.03L107.47 334.31L142.6 354.59L177.73 374.87L212.87 395.16L248 415.44L283.13 435.72L318.25 456L353.38 476.28L388.51 496.57L423.65 516.85L458.78 537.13L493.91 557.42L529.03 577.69L564.16 597.98L599.29 618.26L634.43 638.54L669.56 658.83L704.69 679.11L739.81 699.39L774.94 719.67L810.07 739.95L845.21 760.24L880.34 780.52L915.47 800.8L950.59 821.08L985.72 841.36L1020.85 861.65L1055.99 881.93L1091.12 902.21L1126.24 922.49L1161.37 942.77L1196.5 963.06L1231.63 983.34L1371 1063.8Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1405.89 1043.53L1266.52 963.06L1231.39 942.78L1196.26 922.5L1161.13 902.21L1126.01 881.94L1090.88 861.65L1055.74 841.37L1020.61 821.09L985.48 800.8L950.36 780.53L915.23 760.24L880.1 739.96L844.97 719.68L809.83 699.39L774.7 679.11L739.58 658.83L704.45 638.55L669.32 618.27L634.19 597.98L599.05 577.7L563.92 557.42L528.8 537.14L493.67 516.86L458.54 496.57L423.41 476.29L388.27 456.01L353.14 435.72L318.02 415.45L282.89 395.16L247.76 374.88L212.63 354.6L177.49 334.31L142.36 314.03L107.24 293.75L72.1099 273.47L36.9799 253.19L1.84985 232.9L-33.2802 212.62L-68.4001 192.34L-103.54 172.06L-138.67 151.77L-173.8 131.49L-313.16 51.03L-173.8 131.49L-138.67 151.77L-103.54 172.06L-68.4001 192.34L-33.2802 212.62L1.84985 232.9L36.9799 253.19L72.1099 273.47L107.24 293.75L142.36 314.03L177.49 334.31L212.63 354.6L247.76 374.88L282.89 395.16L318.02 415.45L353.14 435.72L388.27 456.01L423.41 476.29L458.54 496.57L493.67 516.86L528.8 537.14L563.92 557.42L599.05 577.7L634.19 597.98L669.32 618.27L704.45 638.55L739.58 658.83L774.7 679.11L809.83 699.39L844.97 719.68L880.1 739.96L915.23 760.24L950.36 780.53L985.48 800.8L1020.61 821.09L1055.74 841.37L1090.88 861.65L1126.01 881.94L1161.13 902.21L1196.26 922.5L1231.39 942.78L1266.52 963.06L1405.89 1043.53Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1440.79 1023.24L1301.43 942.78L1266.3 922.5L1231.16 902.21L1196.03 881.93L1160.91 861.65L1125.78 841.37L1090.65 821.09L1055.52 800.8L1020.38 780.52L985.26 760.24L950.13 739.96L915 719.68L879.87 699.39L844.74 679.11L809.61 658.83L774.49 638.55L739.35 618.27L704.22 597.98L669.09 577.7L633.96 557.42L598.83 537.13L563.71 516.86L528.57 496.57L493.44 476.29L458.31 456.01L423.18 435.72L388.05 415.44L352.93 395.16L317.79 374.88L282.66 354.59L247.53 334.31L212.4 314.03L177.27 293.74L142.15 273.47L107.01 253.18L71.8798 232.9L36.7498 212.62L1.61975 192.33L-33.5002 172.06L-68.6302 151.77L-103.77 131.49L-138.9 111.21L-278.26 30.75L-138.9 111.21L-103.77 131.49L-68.6302 151.77L-33.5002 172.06L1.61975 192.33L36.7498 212.62L71.8798 232.9L107.01 253.18L142.15 273.47L177.27 293.74L212.4 314.03L247.53 334.31L282.66 354.59L317.79 374.88L352.93 395.16L388.05 415.44L423.18 435.72L458.31 456.01L493.44 476.29L528.57 496.57L563.71 516.86L598.83 537.13L633.96 557.42L669.09 577.7L704.22 597.98L739.35 618.27L774.49 638.55L809.61 658.83L844.74 679.11L879.87 699.39L915 719.68L950.13 739.96L985.26 760.24L1020.38 780.52L1055.52 800.8L1090.65 821.09L1125.78 841.37L1160.91 861.65L1196.03 881.93L1231.16 902.21L1266.3 922.5L1301.43 942.78L1440.79 1023.24Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1475.7 1002.96L1336.33 922.5L1301.2 902.21L1266.07 881.93L1230.94 861.65L1195.82 841.37L1160.68 821.09L1125.55 800.8L1090.42 780.52L1055.29 760.24L1020.17 739.96L985.04 719.68L949.9 699.39L914.77 679.11L879.64 658.83L844.51 638.54L809.39 618.27L774.26 597.98L739.12 577.7L703.99 557.41L668.86 537.13L633.73 516.85L598.61 496.57L563.48 476.29L528.35 456L493.21 435.72L458.08 415.44L422.95 395.15L387.83 374.88L352.7 354.59L317.57 334.31L282.43 314.03L247.3 293.74L212.17 273.46L177.05 253.18L141.92 232.9L106.79 212.62L71.6497 192.33L36.5197 172.05L1.39964 151.77L-33.7303 131.49L-68.8604 111.21L-103.99 90.92L-243.36 10.46L-103.99 90.92L-68.8604 111.21L-33.7303 131.49L1.39964 151.77L36.5197 172.05L71.6497 192.33L106.79 212.62L141.92 232.9L177.05 253.18L212.17 273.46L247.3 293.74L282.43 314.03L317.57 334.31L352.7 354.59L387.83 374.88L422.95 395.15L458.08 415.44L493.21 435.72L528.35 456L563.48 476.29L598.61 496.57L633.73 516.85L668.86 537.13L703.99 557.41L739.12 577.7L774.26 597.98L809.39 618.27L844.51 638.54L879.64 658.83L914.77 679.11L949.9 699.39L985.04 719.68L1020.17 739.96L1055.29 760.24L1090.42 780.52L1125.55 800.8L1160.68 821.09L1195.82 841.37L1230.94 861.65L1266.07 881.93L1301.2 902.21L1336.33 922.5L1475.7 1002.96Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1510.6 982.67L1371.24 902.21L1336.1 881.93L1300.97 861.65L1265.84 841.36L1230.72 821.09L1195.59 800.8L1160.46 780.52L1125.32 760.24L1090.19 739.95L1055.07 719.68L1019.94 699.39L984.81 679.11L949.68 658.82L914.54 638.54L879.41 618.26L844.29 597.98L809.16 577.7L774.03 557.41L738.9 537.13L703.76 516.85L668.63 496.56L633.51 476.29L598.38 456L563.25 435.72L528.12 415.44L492.99 395.15L457.85 374.87L422.73 354.59L387.6 334.31L352.47 314.03L317.34 293.74L282.21 273.46L247.07 253.18L211.95 232.9L176.82 212.62L141.69 192.33L106.56 172.05L71.4298 151.77L36.3098 131.49L1.1698 111.21L-33.9602 90.92L-69.0902 70.64L-208.45 -9.82001L-69.0902 70.64L-33.9602 90.92L1.1698 111.21L36.3098 131.49L71.4298 151.77L106.56 172.05L141.69 192.33L176.82 212.62L211.95 232.9L247.07 253.18L282.21 273.46L317.34 293.74L352.47 314.03L387.6 334.31L422.73 354.59L457.85 374.87L492.99 395.15L528.12 415.44L563.25 435.72L598.38 456L633.51 476.29L668.63 496.56L703.76 516.85L738.9 537.13L774.03 557.41L809.16 577.7L844.29 597.98L879.41 618.26L914.54 638.54L949.68 658.82L984.81 679.11L1019.94 699.39L1055.07 719.68L1090.19 739.95L1125.32 760.24L1160.46 780.52L1195.59 800.8L1230.72 821.09L1265.84 841.36L1300.97 861.65L1336.1 881.93L1371.24 902.21L1510.6 982.67Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1545.5 962.39L1406.14 881.93L1371.01 861.64L1335.87 841.36L1300.74 821.08L1265.62 800.8L1230.49 780.52L1195.36 760.23L1160.23 739.95L1125.1 719.67L1089.98 699.39L1054.84 679.11L1019.71 658.82L984.58 638.54L949.45 618.26L914.32 597.97L879.2 577.7L844.06 557.41L808.93 537.13L773.8 516.85L738.67 496.56L703.54 476.28L668.42 456L633.28 435.72L598.15 415.44L563.02 395.15L527.89 374.87L492.76 354.59L457.64 334.31L422.5 314.03L387.37 293.74L352.24 273.46L317.11 253.18L281.98 232.89L246.86 212.62L211.73 192.33L176.59 172.05L141.46 151.77L106.33 131.48L71.2097 111.21L36.0797 90.92L0.949707 70.64L-34.1903 50.36L-173.55 -30.11L-34.1903 50.36L0.949707 70.64L36.0797 90.92L71.2097 111.21L106.33 131.48L141.46 151.77L176.59 172.05L211.73 192.33L246.86 212.62L281.98 232.89L317.11 253.18L352.24 273.46L387.37 293.74L422.5 314.03L457.64 334.31L492.76 354.59L527.89 374.87L563.02 395.15L598.15 415.44L633.28 435.72L668.42 456L703.54 476.28L738.67 496.56L773.8 516.85L808.93 537.13L844.06 557.41L879.2 577.7L914.32 597.97L949.45 618.26L984.58 638.54L1019.71 658.82L1054.84 679.11L1089.98 699.39L1125.1 719.67L1160.23 739.95L1195.36 760.23L1230.49 780.52L1265.62 800.8L1300.74 821.08L1335.87 841.36L1371.01 861.64L1406.14 881.93L1545.5 962.39Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1580.41 942.11L1441.04 861.64L1405.91 841.36L1370.78 821.08L1335.65 800.79L1300.53 780.52L1265.39 760.23L1230.26 739.95L1195.13 719.67L1160 699.38L1124.88 679.11L1089.75 658.82L1054.62 638.54L1019.48 618.26L984.35 597.97L949.22 577.69L914.1 557.41L878.97 537.13L843.84 516.85L808.7 496.56L773.57 476.28L738.44 456L703.32 435.72L668.19 415.44L633.06 395.15L597.92 374.87L562.79 354.59L527.66 334.3L492.54 314.03L457.41 293.74L422.28 273.46L387.14 253.18L352.01 232.89L316.88 212.61L281.76 192.33L246.63 172.05L211.5 151.77L176.37 131.48L141.23 111.2L106.11 90.92L70.9796 70.64L35.8496 50.36L0.719604 30.07L-138.65 -50.39L0.719604 30.07L35.8496 50.36L70.9796 70.64L106.11 90.92L141.23 111.2L176.37 131.48L211.5 151.77L246.63 172.05L281.76 192.33L316.88 212.61L352.01 232.89L387.14 253.18L422.28 273.46L457.41 293.74L492.54 314.03L527.66 334.3L562.79 354.59L597.92 374.87L633.06 395.15L668.19 415.44L703.32 435.72L738.44 456L773.57 476.28L808.7 496.56L843.84 516.85L878.97 537.13L914.1 557.41L949.22 577.69L984.35 597.97L1019.48 618.26L1054.62 638.54L1089.75 658.82L1124.88 679.11L1160 699.38L1195.13 719.67L1230.26 739.95L1265.39 760.23L1300.53 780.52L1335.65 800.79L1370.78 821.08L1405.91 841.36L1441.04 861.64L1580.41 942.11Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1615.3 921.83L1475.93 841.37L1440.8 821.08L1405.67 800.8L1370.54 780.52L1335.42 760.24L1300.29 739.96L1265.15 719.67L1230.02 699.39L1194.89 679.11L1159.77 658.83L1124.64 638.55L1089.51 618.26L1054.37 597.98L1019.24 577.7L984.11 557.41L948.99 537.14L913.86 516.85L878.73 496.57L843.6 476.29L808.46 456L773.33 435.72L738.21 415.44L703.08 395.16L667.95 374.88L632.82 354.59L597.68 334.31L562.55 314.03L527.43 293.75L492.3 273.47L457.17 253.18L422.04 232.9L386.9 212.62L351.77 192.33L316.65 172.06L281.52 151.77L246.39 131.49L211.26 111.21L176.12 90.92L141 70.65L105.87 50.36L70.74 30.08L35.61 9.80002L-103.75 -70.67L35.61 9.80002L70.74 30.08L105.87 50.36L141 70.65L176.12 90.92L211.26 111.21L246.39 131.49L281.52 151.77L316.65 172.06L351.77 192.33L386.9 212.62L422.04 232.9L457.17 253.18L492.3 273.47L527.43 293.75L562.55 314.03L597.68 334.31L632.82 354.59L667.95 374.88L703.08 395.16L738.21 415.44L773.33 435.72L808.46 456L843.6 476.29L878.73 496.57L913.86 516.85L948.99 537.14L984.11 557.41L1019.24 577.7L1054.37 597.98L1089.51 618.26L1124.64 638.55L1159.77 658.83L1194.89 679.11L1230.02 699.39L1265.15 719.67L1300.29 739.96L1335.42 760.24L1370.54 780.52L1405.67 800.8L1440.8 821.08L1475.93 841.37L1615.3 921.83Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1650.2 901.55L1510.84 821.08L1475.71 800.8L1440.57 780.52L1405.44 760.23L1370.32 739.96L1335.19 719.67L1300.06 699.39L1264.93 679.11L1229.79 658.82L1194.67 638.55L1159.54 618.26L1124.41 597.98L1089.28 577.7L1054.15 557.41L1019.01 537.13L983.89 516.85L948.76 496.57L913.63 476.29L878.5 456L843.37 435.72L808.24 415.44L773.12 395.16L737.98 374.88L702.85 354.59L667.72 334.31L632.59 314.03L597.46 293.74L562.34 273.47L527.2 253.18L492.07 232.9L456.94 212.62L421.81 192.33L386.68 172.05L351.56 151.77L316.42 131.49L281.29 111.21L246.16 90.92L211.03 70.64L175.91 50.36L140.78 30.08L105.64 9.79999L70.5099 -10.49L-68.8501 -90.95L70.5099 -10.49L105.64 9.79999L140.78 30.08L175.91 50.36L211.03 70.64L246.16 90.92L281.29 111.21L316.42 131.49L351.56 151.77L386.68 172.05L421.81 192.33L456.94 212.62L492.07 232.9L527.2 253.18L562.34 273.47L597.46 293.74L632.59 314.03L667.72 334.31L702.85 354.59L737.98 374.88L773.12 395.16L808.24 415.44L843.37 435.72L878.5 456L913.63 476.29L948.76 496.57L983.89 516.85L1019.01 537.13L1054.15 557.41L1089.28 577.7L1124.41 597.98L1159.54 618.26L1194.67 638.55L1229.79 658.82L1264.93 679.11L1300.06 699.39L1335.19 719.67L1370.32 739.96L1405.44 760.23L1440.57 780.52L1475.71 800.8L1510.84 821.08L1650.2 901.55Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1685.1 881.26L1545.74 800.8L1510.61 780.52L1475.48 760.23L1440.35 739.95L1405.23 719.67L1370.09 699.39L1334.96 679.11L1299.83 658.82L1264.7 638.54L1229.58 618.26L1194.45 597.98L1159.31 577.7L1124.18 557.41L1089.05 537.13L1053.92 516.85L1018.8 496.57L983.67 476.29L948.53 456L913.4 435.72L878.27 415.44L843.14 395.15L808.02 374.88L772.89 354.59L737.75 334.31L702.62 314.03L667.49 293.74L632.36 273.46L597.24 253.18L562.11 232.9L526.98 212.62L491.84 192.33L456.71 172.05L421.58 151.77L386.46 131.49L351.33 111.21L316.2 90.92L281.06 70.64L245.93 50.36L210.81 30.08L175.68 9.80003L140.55 -10.49L105.42 -30.77L-33.9502 -111.23L105.42 -30.77L140.55 -10.49L175.68 9.80003L210.81 30.08L245.93 50.36L281.06 70.64L316.2 90.92L351.33 111.21L386.46 131.49L421.58 151.77L456.71 172.05L491.84 192.33L526.98 212.62L562.11 232.9L597.24 253.18L632.36 273.46L667.49 293.74L702.62 314.03L737.75 334.31L772.89 354.59L808.02 374.88L843.14 395.15L878.27 415.44L913.4 435.72L948.53 456L983.67 476.29L1018.8 496.57L1053.92 516.85L1089.05 537.13L1124.18 557.41L1159.31 577.7L1194.45 597.98L1229.58 618.26L1264.7 638.54L1299.83 658.82L1334.96 679.11L1370.09 699.39L1405.23 719.67L1440.35 739.95L1475.48 760.23L1510.61 780.52L1545.74 800.8L1685.1 881.26Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1720.01 860.98L1580.64 780.52L1545.51 760.23L1510.38 739.95L1475.25 719.67L1440.13 699.39L1405 679.11L1369.87 658.82L1334.73 638.54L1299.6 618.26L1264.48 597.98L1229.35 577.7L1194.22 557.41L1159.09 537.13L1123.95 516.85L1088.82 496.56L1053.7 476.29L1018.57 456L983.44 435.72L948.31 415.44L913.17 395.15L878.04 374.87L842.92 354.59L807.79 334.31L772.66 314.03L737.53 293.74L702.39 273.46L667.26 253.18L632.14 232.9L597.01 212.62L561.88 192.33L526.75 172.05L491.62 151.76L456.48 131.48L421.36 111.21L386.23 90.92L351.1 70.64L315.97 50.35L280.84 30.07L245.72 9.78998L210.58 -10.49L175.45 -30.77L140.32 -51.06L0.959961 -131.52L140.32 -51.06L175.45 -30.77L210.58 -10.49L245.72 9.78998L280.84 30.07L315.97 50.35L351.1 70.64L386.23 90.92L421.36 111.21L456.48 131.48L491.62 151.76L526.75 172.05L561.88 192.33L597.01 212.62L632.14 232.9L667.26 253.18L702.39 273.46L737.53 293.74L772.66 314.03L807.79 334.31L842.92 354.59L878.04 374.87L913.17 395.15L948.31 415.44L983.44 435.72L1018.57 456L1053.7 476.29L1088.82 496.56L1123.95 516.85L1159.09 537.13L1194.22 557.41L1229.35 577.7L1264.48 597.98L1299.6 618.26L1334.73 638.54L1369.87 658.82L1405 679.11L1440.13 699.39L1475.25 719.67L1510.38 739.95L1545.51 760.23L1580.64 780.52L1720.01 860.98Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1754.91 840.69L1615.55 760.23L1580.42 739.95L1545.28 719.67L1510.15 699.38L1475.03 679.11L1439.9 658.82L1404.77 638.54L1369.64 618.26L1334.51 597.97L1299.39 577.7L1264.25 557.41L1229.12 537.13L1193.99 516.85L1158.86 496.56L1123.73 476.28L1088.61 456L1053.47 435.72L1018.34 415.44L983.21 395.15L948.08 374.87L912.95 354.59L877.83 334.31L842.69 314.03L807.56 293.74L772.43 273.46L737.3 253.17L702.17 232.89L667.05 212.61L631.91 192.33L596.78 172.05L561.65 151.76L526.52 131.48L491.39 111.2L456.27 90.92L421.13 70.64L386 50.35L350.87 30.07L315.74 9.79001L280.62 -10.49L245.49 -30.77L210.36 -51.06L175.22 -71.34L35.8599 -151.8L175.22 -71.34L210.36 -51.06L245.49 -30.77L280.62 -10.49L315.74 9.79001L350.87 30.07L386 50.35L421.13 70.64L456.27 90.92L491.39 111.2L526.52 131.48L561.65 151.76L596.78 172.05L631.91 192.33L667.05 212.61L702.17 232.89L737.3 253.17L772.43 273.46L807.56 293.74L842.69 314.03L877.83 334.31L912.95 354.59L948.08 374.87L983.21 395.15L1018.34 415.44L1053.47 435.72L1088.61 456L1123.73 476.28L1158.86 496.56L1193.99 516.85L1229.12 537.13L1264.25 557.41L1299.39 577.7L1334.51 597.97L1369.64 618.26L1404.77 638.54L1439.9 658.82L1475.03 679.11L1510.15 699.38L1545.28 719.67L1580.42 739.95L1615.55 760.23L1754.91 840.69Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1789.82 820.41L1650.45 739.95L1615.32 719.67L1580.19 699.38L1545.06 679.1L1509.94 658.82L1474.8 638.54L1439.67 618.26L1404.54 597.97L1369.41 577.69L1334.29 557.41L1299.16 537.13L1264.02 516.85L1228.89 496.56L1193.76 476.28L1158.63 455.99L1123.51 435.72L1088.38 415.44L1053.25 395.15L1018.11 374.87L982.98 354.58L947.85 334.3L912.73 314.02L877.6 293.74L842.47 273.46L807.33 253.17L772.2 232.89L737.07 212.61L701.95 192.33L666.82 172.05L631.69 151.76L596.55 131.48L561.42 111.2L526.29 90.91L491.17 70.64L456.04 50.35L420.91 30.07L385.77 9.78998L350.64 -10.5L315.52 -30.77L280.39 -51.06L245.26 -71.34L210.13 -91.62L70.7598 -172.08L210.13 -91.62L245.26 -71.34L280.39 -51.06L315.52 -30.77L350.64 -10.5L385.77 9.78998L420.91 30.07L456.04 50.35L491.17 70.64L526.29 90.91L561.42 111.2L596.55 131.48L631.69 151.76L666.82 172.05L701.95 192.33L737.07 212.61L772.2 232.89L807.33 253.17L842.47 273.46L877.6 293.74L912.73 314.02L947.85 334.3L982.98 354.58L1018.11 374.87L1053.25 395.15L1088.38 415.44L1123.51 435.72L1158.63 455.99L1193.76 476.28L1228.89 496.56L1264.02 516.85L1299.16 537.13L1334.29 557.41L1369.41 577.69L1404.54 597.97L1439.67 618.26L1474.8 638.54L1509.94 658.82L1545.06 679.1L1580.19 699.38L1615.32 719.67L1650.45 739.95L1789.82 820.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1824.71 800.13L1685.34 719.67L1650.21 699.39L1615.08 679.11L1579.95 658.82L1544.83 638.55L1509.7 618.26L1474.56 597.98L1439.43 577.7L1404.3 557.41L1369.18 537.14L1334.05 516.85L1298.92 496.57L1263.78 476.28L1228.65 456L1193.52 435.72L1158.4 415.44L1123.27 395.16L1088.14 374.87L1053 354.59L1017.87 334.31L982.74 314.02L947.62 293.75L912.49 273.46L877.36 253.18L842.23 232.9L807.09 212.61L771.96 192.33L736.84 172.05L701.71 151.77L666.58 131.49L631.45 111.2L596.31 90.92L561.18 70.64L526.06 50.36L490.93 30.08L455.8 9.79001L420.67 -10.49L385.53 -30.77L350.41 -51.05L315.28 -71.33L280.15 -91.62L245.02 -111.9L105.66 -192.36L245.02 -111.9L280.15 -91.62L315.28 -71.33L350.41 -51.05L385.53 -30.77L420.67 -10.49L455.8 9.79001L490.93 30.08L526.06 50.36L561.18 70.64L596.31 90.92L631.45 111.2L666.58 131.49L701.71 151.77L736.84 172.05L771.96 192.33L807.09 212.61L842.23 232.9L877.36 253.18L912.49 273.46L947.62 293.75L982.74 314.02L1017.87 334.31L1053 354.59L1088.14 374.87L1123.27 395.16L1158.4 415.44L1193.52 435.72L1228.65 456L1263.78 476.28L1298.92 496.57L1334.05 516.85L1369.18 537.14L1404.3 557.41L1439.43 577.7L1474.56 597.98L1509.7 618.26L1544.83 638.55L1579.95 658.82L1615.08 679.11L1650.21 699.39L1685.34 719.67L1824.71 800.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1859.61 779.85L1720.25 699.39L1685.12 679.11L1649.98 658.82L1614.85 638.54L1579.73 618.26L1544.6 597.98L1509.47 577.69L1474.34 557.41L1439.2 537.13L1404.08 516.85L1368.95 496.57L1333.82 476.28L1298.69 456L1263.56 435.72L1228.42 415.43L1193.3 395.16L1158.17 374.87L1123.04 354.59L1087.91 334.31L1052.78 314.02L1017.64 293.74L982.52 273.46L947.39 253.18L912.26 232.9L877.13 212.61L842 192.33L806.87 172.05L771.75 151.77L736.61 131.49L701.48 111.2L666.35 90.92L631.22 70.64L596.09 50.35L560.97 30.08L525.83 9.78996L490.7 -10.49L455.57 -30.77L420.44 -51.06L385.32 -71.33L350.19 -91.62L315.05 -111.9L279.92 -132.18L140.56 -212.65L279.92 -132.18L315.05 -111.9L350.19 -91.62L385.32 -71.33L420.44 -51.06L455.57 -30.77L490.7 -10.49L525.83 9.78996L560.97 30.08L596.09 50.35L631.22 70.64L666.35 90.92L701.48 111.2L736.61 131.49L771.75 151.77L806.87 172.05L842 192.33L877.13 212.61L912.26 232.9L947.39 253.18L982.52 273.46L1017.64 293.74L1052.78 314.02L1087.91 334.31L1123.04 354.59L1158.17 374.87L1193.3 395.16L1228.42 415.43L1263.56 435.72L1298.69 456L1333.82 476.28L1368.95 496.57L1404.08 516.85L1439.2 537.13L1474.34 557.41L1509.47 577.69L1544.6 597.98L1579.73 618.26L1614.85 638.54L1649.98 658.82L1685.12 679.11L1720.25 699.39L1859.61 779.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1894.51 759.57L1755.15 679.1L1720.02 658.82L1684.89 638.54L1649.76 618.25L1614.64 597.98L1579.5 577.69L1544.37 557.41L1509.24 537.13L1474.11 516.84L1438.99 496.57L1403.86 476.28L1368.72 456L1333.59 435.72L1298.46 415.43L1263.33 395.15L1228.21 374.87L1193.08 354.59L1157.94 334.31L1122.81 314.02L1087.68 293.74L1052.55 273.46L1017.43 253.18L982.3 232.9L947.16 212.61L912.03 192.33L876.9 172.05L841.77 151.76L806.65 131.49L771.52 111.2L736.38 90.92L701.25 70.64L666.12 50.35L630.99 30.07L595.87 9.78999L560.74 -10.49L525.61 -30.77L490.47 -51.06L455.34 -71.34L420.22 -91.62L385.09 -111.9L349.96 -132.18L314.83 -152.47L175.46 -232.93L314.83 -152.47L349.96 -132.18L385.09 -111.9L420.22 -91.62L455.34 -71.34L490.47 -51.06L525.61 -30.77L560.74 -10.49L595.87 9.78999L630.99 30.07L666.12 50.35L701.25 70.64L736.38 90.92L771.52 111.2L806.65 131.49L841.77 151.76L876.9 172.05L912.03 192.33L947.16 212.61L982.3 232.9L1017.43 253.18L1052.55 273.46L1087.68 293.74L1122.81 314.02L1157.94 334.31L1193.08 354.59L1228.21 374.87L1263.33 395.15L1298.46 415.43L1333.59 435.72L1368.72 456L1403.86 476.28L1438.99 496.57L1474.11 516.84L1509.24 537.13L1544.37 557.41L1579.5 577.69L1614.64 597.98L1649.76 618.25L1684.89 638.54L1720.02 658.82L1755.15 679.1L1894.51 759.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1929.42 739.28L1790.05 658.82L1754.92 638.54L1719.79 618.25L1684.66 597.97L1649.54 577.69L1614.41 557.41L1579.27 537.13L1544.14 516.84L1509.01 496.56L1473.89 476.28L1438.76 456L1403.63 435.72L1368.5 415.43L1333.36 395.15L1298.23 374.87L1263.11 354.59L1227.98 334.31L1192.85 314.02L1157.72 293.74L1122.58 273.46L1087.45 253.17L1052.33 232.9L1017.2 212.61L982.07 192.33L946.94 172.05L911.8 151.76L876.67 131.48L841.55 111.2L806.42 90.92L771.29 70.64L736.16 50.35L701.02 30.07L665.89 9.78998L630.77 -10.49L595.64 -30.77L560.51 -51.06L525.38 -71.34L490.25 -91.62L455.13 -111.9L419.99 -132.18L384.86 -152.47L349.73 -172.75L210.37 -253.21L349.73 -172.75L384.86 -152.47L419.99 -132.18L455.13 -111.9L490.25 -91.62L525.38 -71.34L560.51 -51.06L595.64 -30.77L630.77 -10.49L665.89 9.78998L701.02 30.07L736.16 50.35L771.29 70.64L806.42 90.92L841.55 111.2L876.67 131.48L911.8 151.76L946.94 172.05L982.07 192.33L1017.2 212.61L1052.33 232.9L1087.45 253.17L1122.58 273.46L1157.72 293.74L1192.85 314.02L1227.98 334.31L1263.11 354.59L1298.23 374.87L1333.36 395.15L1368.5 415.43L1403.63 435.72L1438.76 456L1473.89 476.28L1509.01 496.56L1544.14 516.84L1579.27 537.13L1614.41 557.41L1649.54 577.69L1684.66 597.97L1719.79 618.25L1754.92 638.54L1790.05 658.82L1929.42 739.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1964.32 719L1824.96 638.54L1789.83 618.25L1754.69 597.97L1719.56 577.69L1684.44 557.41L1649.31 537.13L1614.18 516.84L1579.05 496.56L1543.91 476.28L1508.79 456L1473.66 435.72L1438.53 415.43L1403.4 395.15L1368.27 374.87L1333.14 354.58L1298.02 334.31L1262.88 314.02L1227.75 293.74L1192.62 273.46L1157.49 253.17L1122.36 232.89L1087.24 212.61L1052.1 192.33L1016.97 172.05L981.84 151.76L946.71 131.48L911.58 111.2L876.46 90.92L841.32 70.64L806.19 50.35L771.06 30.07L735.93 9.79001L700.8 -10.5L665.68 -30.77L630.54 -51.06L595.41 -71.34L560.28 -91.62L525.15 -111.91L490.03 -132.18L454.9 -152.47L419.76 -172.75L384.63 -193.03L245.27 -273.5L384.63 -193.03L419.76 -172.75L454.9 -152.47L490.03 -132.18L525.15 -111.91L560.28 -91.62L595.41 -71.34L630.54 -51.06L665.68 -30.77L700.8 -10.5L735.93 9.79001L771.06 30.07L806.19 50.35L841.32 70.64L876.46 90.92L911.58 111.2L946.71 131.48L981.84 151.76L1016.97 172.05L1052.1 192.33L1087.24 212.61L1122.36 232.89L1157.49 253.17L1192.62 273.46L1227.75 293.74L1262.88 314.02L1298.02 334.31L1333.14 354.58L1368.27 374.87L1403.4 395.15L1438.53 415.43L1473.66 435.72L1508.79 456L1543.91 476.28L1579.05 496.56L1614.18 516.84L1649.31 537.13L1684.44 557.41L1719.56 577.69L1754.69 597.97L1789.83 618.25L1824.96 638.54L1964.32 719Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1999.23 698.72L1859.86 618.25L1824.73 597.97L1789.6 577.69L1754.47 557.4L1719.35 537.13L1684.21 516.84L1649.08 496.56L1613.95 476.28L1578.82 455.99L1543.7 435.72L1508.57 415.43L1473.43 395.15L1438.3 374.87L1403.17 354.58L1368.04 334.3L1332.92 314.02L1297.79 293.74L1262.65 273.46L1227.52 253.17L1192.39 232.89L1157.26 212.61L1122.14 192.33L1087.01 172.05L1051.88 151.76L1016.74 131.48L981.61 111.2L946.48 90.91L911.36 70.64L876.23 50.35L841.1 30.07L805.96 9.78998L770.83 -10.5L735.7 -30.78L700.58 -51.06L665.45 -71.34L630.32 -91.62L595.18 -111.91L560.05 -132.19L524.93 -152.47L489.8 -172.75L454.67 -193.04L419.54 -213.32L280.17 -293.78L419.54 -213.32L454.67 -193.04L489.8 -172.75L524.93 -152.47L560.05 -132.19L595.18 -111.91L630.32 -91.62L665.45 -71.34L700.58 -51.06L735.7 -30.78L770.83 -10.5L805.96 9.78998L841.1 30.07L876.23 50.35L911.36 70.64L946.48 90.91L981.61 111.2L1016.74 131.48L1051.88 151.76L1087.01 172.05L1122.14 192.33L1157.26 212.61L1192.39 232.89L1227.52 253.17L1262.65 273.46L1297.79 293.74L1332.92 314.02L1368.04 334.3L1403.17 354.58L1438.3 374.87L1473.43 395.15L1508.57 415.43L1543.7 435.72L1578.82 455.99L1613.95 476.28L1649.08 496.56L1684.21 516.84L1719.35 537.13L1754.47 557.4L1789.6 577.69L1824.73 597.97L1859.86 618.25L1999.23 698.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2034.12 678.44L1894.75 597.98L1859.62 577.69L1824.49 557.41L1789.36 537.13L1754.24 516.85L1719.11 496.57L1683.97 476.28L1648.84 456L1613.71 435.72L1578.59 415.44L1543.46 395.16L1508.33 374.87L1473.19 354.59L1438.06 334.31L1402.93 314.02L1367.81 293.75L1332.68 273.46L1297.55 253.18L1262.41 232.9L1227.28 212.61L1192.15 192.33L1157.03 172.05L1121.9 151.77L1086.77 131.49L1051.64 111.2L1016.5 90.9199L981.37 70.64L946.25 50.3599L911.12 30.0799L875.99 9.78995L840.86 -10.4901L805.72 -30.7701L770.59 -51.0601L735.47 -71.3301L700.34 -91.6201L665.21 -111.9L630.08 -132.19L594.94 -152.47L559.82 -172.75L524.69 -193.03L489.56 -213.31L454.43 -233.6L315.06 -314.06L454.43 -233.6L489.56 -213.31L524.69 -193.03L559.82 -172.75L594.94 -152.47L630.08 -132.19L665.21 -111.9L700.34 -91.6201L735.47 -71.3301L770.59 -51.0601L805.72 -30.7701L840.86 -10.4901L875.99 9.78995L911.12 30.0799L946.25 50.3599L981.37 70.64L1016.5 90.9199L1051.64 111.2L1086.77 131.49L1121.9 151.77L1157.03 172.05L1192.15 192.33L1227.28 212.61L1262.41 232.9L1297.55 253.18L1332.68 273.46L1367.81 293.75L1402.93 314.02L1438.06 334.31L1473.19 354.59L1508.33 374.87L1543.46 395.16L1578.59 415.44L1613.71 435.72L1648.84 456L1683.97 476.28L1719.11 496.57L1754.24 516.85L1789.36 537.13L1824.49 557.41L1859.62 577.69L1894.75 597.98L2034.12 678.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2069.02 658.15L1929.66 577.69L1894.52 557.41L1859.39 537.13L1824.26 516.84L1789.14 496.57L1754.01 476.28L1718.88 456L1683.75 435.72L1648.61 415.43L1613.49 395.16L1578.36 374.87L1543.23 354.59L1508.1 334.31L1472.97 314.02L1437.83 293.74L1402.71 273.46L1367.58 253.18L1332.45 232.9L1297.32 212.61L1262.19 192.33L1227.05 172.05L1191.93 151.77L1156.8 131.49L1121.67 111.2L1086.54 90.92L1051.41 70.63L1016.27 50.35L981.15 30.08L946.02 9.79004L910.89 -10.49L875.76 -30.78L840.63 -51.06L805.5 -71.34L770.38 -91.62L735.24 -111.9L700.11 -132.19L664.98 -152.47L629.85 -172.75L594.73 -193.03L559.6 -213.31L524.46 -233.6L489.33 -253.88L349.97 -334.34L489.33 -253.88L524.46 -233.6L559.6 -213.31L594.73 -193.03L629.85 -172.75L664.98 -152.47L700.11 -132.19L735.24 -111.9L770.38 -91.62L805.5 -71.34L840.63 -51.06L875.76 -30.78L910.89 -10.49L946.02 9.79004L981.15 30.08L1016.27 50.35L1051.41 70.63L1086.54 90.92L1121.67 111.2L1156.8 131.49L1191.93 151.77L1227.05 172.05L1262.19 192.33L1297.32 212.61L1332.45 232.9L1367.58 253.18L1402.71 273.46L1437.83 293.74L1472.97 314.02L1508.1 334.31L1543.23 354.59L1578.36 374.87L1613.49 395.16L1648.61 415.43L1683.75 435.72L1718.88 456L1754.01 476.28L1789.14 496.57L1824.26 516.84L1859.39 537.13L1894.52 557.41L1929.66 577.69L2069.02 658.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2103.92 637.87L1964.56 557.41L1929.43 537.13L1894.3 516.84L1859.16 496.56L1824.04 476.28L1788.91 456L1753.78 435.72L1718.65 415.43L1683.52 395.15L1648.4 374.87L1613.27 354.59L1578.13 334.31L1543 314.02L1507.87 293.74L1472.74 273.46L1437.62 253.18L1402.49 232.9L1367.35 212.61L1332.22 192.33L1297.09 172.04L1261.96 151.76L1226.84 131.48L1191.71 111.2L1156.57 90.92L1121.44 70.63L1086.31 50.35L1051.18 30.07L1016.06 9.79001L980.93 -10.49L945.79 -30.78L910.66 -51.06L875.53 -71.34L840.4 -91.63L805.28 -111.9L770.15 -132.19L735.02 -152.47L699.88 -172.75L664.75 -193.04L629.63 -213.31L594.5 -233.6L559.37 -253.88L524.24 -274.16L384.87 -354.62L524.24 -274.16L559.37 -253.88L594.5 -233.6L629.63 -213.31L664.75 -193.04L699.88 -172.75L735.02 -152.47L770.15 -132.19L805.28 -111.9L840.4 -91.63L875.53 -71.34L910.66 -51.06L945.79 -30.78L980.93 -10.49L1016.06 9.79001L1051.18 30.07L1086.31 50.35L1121.44 70.63L1156.57 90.92L1191.71 111.2L1226.84 131.48L1261.96 151.76L1297.09 172.04L1332.22 192.33L1367.35 212.61L1402.49 232.9L1437.62 253.18L1472.74 273.46L1507.87 293.74L1543 314.02L1578.13 334.31L1613.27 354.59L1648.4 374.87L1683.52 395.15L1718.65 415.43L1753.78 435.72L1788.91 456L1824.04 476.28L1859.16 496.56L1894.3 516.84L1929.43 537.13L1964.56 557.41L2103.92 637.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_4800">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* Ramen leefruimte card */
export const step15Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_426)">
      <path
        d="M603.46 147.23L606.47 679.79L304.42 854.18L301.42 321.61L603.46 147.23Z"
        fill="#F8B8C4"
      />
      <path
        d="M304.42 854.18L298.11 850.52L295.12 317.94L301.42 321.61L304.42 854.18Z"
        fill="#940731"
      />
      <path
        d="M301.42 321.61L295.12 317.94L597.16 143.56L603.46 147.23L301.42 321.61Z"
        fill="#CD163F"
      />
      <path
        d="M659.33 174.95L662.34 707.51L360.28 881.9L357.29 349.33L659.33 174.95Z"
        fill="#BBE5F0"
      />
      <path
        d="M360.28 881.9L353.98 878.24L350.99 345.67L357.29 349.33L360.28 881.9Z"
        fill="#00A3DE"
      />
      <path
        d="M357.29 349.33L350.99 345.67L653.02 171.28L659.33 174.95L357.29 349.33Z"
        fill="#00A3DE"
      />
      <path
        d="M783.26 159.65L427.76 364.9L429.38 936.44L784.88 731.19L783.26 159.65ZM448.12 884.75L446.7 384.33L753.77 207.05L755.19 707.47L448.12 884.75Z"
        fill="#00A3DE"
      />
      <path
        d="M446.7 384.33L448.12 884.75L755.19 707.46L753.77 207.04L446.7 384.33Z"
        fill="#BBE5F0"
      />
      <path
        d="M429.38 936.44L406.65 923.23L405.03 351.69L427.76 364.9L429.38 936.44Z"
        fill="#71CFEC"
      />
      <path
        d="M427.76 364.9L405.03 351.69L760.53 146.44L783.26 159.65L427.76 364.9Z"
        fill="#00545E"
      />
      <path
        d="M765.94 711.77L764.49 200.85L753.77 207.04L755.19 707.46L448.12 884.75L448.15 895.24L765.94 711.77Z"
        fill="#71CFEC"
      />
      <path
        opacity="0.3"
        d="M587.82 2063.41L448.45 1982.95L413.32 1962.67L378.19 1942.38L343.06 1922.1L307.94 1901.82L272.8 1881.54L237.67 1861.26L202.54 1840.97L167.41 1820.69L132.29 1800.41L97.16 1780.13L62.02 1759.85L26.89 1739.56L-8.23999 1719.28L-43.37 1698.99L-78.49 1678.72L-113.62 1658.44L-148.75 1638.15L-183.89 1617.87L-219.02 1597.58L-254.15 1577.3L-289.27 1557.02L-324.4 1536.74L-359.53 1516.46L-394.67 1496.17L-429.8 1475.89L-464.93 1455.61L-500.05 1435.33L-535.18 1415.05L-570.31 1394.76L-605.45 1374.48L-640.58 1354.2L-675.71 1333.91L-710.83 1313.64L-745.96 1293.35L-781.09 1273.07L-816.23 1252.79L-851.36 1232.5L-886.48 1212.23L-921.61 1191.94L-956.74 1171.66L-991.87 1151.38L-1131.24 1070.92L-991.87 1151.38L-956.74 1171.66L-921.61 1191.94L-886.48 1212.23L-851.36 1232.5L-816.23 1252.79L-781.09 1273.07L-745.96 1293.35L-710.83 1313.64L-675.71 1333.91L-640.58 1354.2L-605.45 1374.48L-570.31 1394.76L-535.18 1415.05L-500.05 1435.33L-464.93 1455.61L-429.8 1475.89L-394.67 1496.17L-359.53 1516.46L-324.4 1536.74L-289.27 1557.02L-254.15 1577.3L-219.02 1597.58L-183.89 1617.87L-148.75 1638.15L-113.62 1658.44L-78.49 1678.72L-43.37 1698.99L-8.23999 1719.28L26.89 1739.56L62.02 1759.85L97.16 1780.13L132.29 1800.41L167.41 1820.69L202.54 1840.97L237.67 1861.26L272.8 1881.54L307.94 1901.82L343.06 1922.1L378.19 1942.38L413.32 1962.67L448.45 1982.95L587.82 2063.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M622.71 2043.13L483.34 1962.67L448.21 1942.39L413.08 1922.11L377.95 1901.82L342.83 1881.55L307.7 1861.26L272.56 1840.98L237.43 1820.7L202.3 1800.41L167.18 1780.14L132.05 1759.85L96.92 1739.57L61.78 1719.29L26.65 1699L-8.47998 1678.72L-43.6 1658.44L-78.73 1638.16L-113.86 1617.87L-149 1597.59L-184.13 1577.31L-219.26 1557.02L-254.38 1536.75L-289.51 1516.46L-324.64 1496.18L-359.77 1475.9L-394.91 1455.61L-430.04 1435.33L-465.16 1415.05L-500.29 1394.77L-535.42 1374.49L-570.55 1354.2L-605.69 1333.92L-640.82 1313.64L-675.94 1293.36L-711.07 1273.08L-746.2 1252.79L-781.33 1232.51L-816.47 1212.23L-851.59 1191.95L-886.72 1171.67L-921.85 1151.38L-956.98 1131.1L-1096.34 1050.64L-956.98 1131.1L-921.85 1151.38L-886.72 1171.67L-851.59 1191.95L-816.47 1212.23L-781.33 1232.51L-746.2 1252.79L-711.07 1273.08L-675.94 1293.36L-640.82 1313.64L-605.69 1333.92L-570.55 1354.2L-535.42 1374.49L-500.29 1394.77L-465.16 1415.05L-430.04 1435.33L-394.91 1455.61L-359.77 1475.9L-324.64 1496.18L-289.51 1516.46L-254.38 1536.75L-219.26 1557.02L-184.13 1577.31L-149 1597.59L-113.86 1617.87L-78.73 1638.16L-43.6 1658.44L-8.47998 1678.72L26.65 1699L61.78 1719.29L96.92 1739.57L132.05 1759.85L167.18 1780.14L202.3 1800.41L237.43 1820.7L272.56 1840.98L307.7 1861.26L342.83 1881.55L377.95 1901.82L413.08 1922.11L448.21 1942.39L483.34 1962.67L622.71 2043.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M657.61 2022.85L518.25 1942.39L483.12 1922.11L447.98 1901.82L412.85 1881.54L377.73 1861.26L342.6 1840.98L307.47 1820.69L272.34 1800.41L237.2 1780.13L202.08 1759.85L166.95 1739.57L131.82 1719.28L96.6899 1699L61.5601 1678.72L26.42 1658.43L-8.69995 1638.16L-43.83 1617.87L-78.96 1597.59L-114.09 1577.31L-149.22 1557.02L-184.36 1536.74L-219.48 1516.46L-254.61 1496.18L-289.74 1475.9L-324.87 1455.61L-360 1435.33L-395.13 1415.05L-430.25 1394.77L-465.39 1374.49L-500.52 1354.2L-535.65 1333.92L-570.78 1313.64L-605.91 1293.35L-641.03 1273.08L-676.17 1252.79L-711.3 1232.51L-746.43 1212.23L-781.56 1191.94L-816.68 1171.67L-851.81 1151.38L-886.95 1131.1L-922.08 1110.82L-1061.44 1030.35L-922.08 1110.82L-886.95 1131.1L-851.81 1151.38L-816.68 1171.67L-781.56 1191.94L-746.43 1212.23L-711.3 1232.51L-676.17 1252.79L-641.03 1273.08L-605.91 1293.35L-570.78 1313.64L-535.65 1333.92L-500.52 1354.2L-465.39 1374.49L-430.25 1394.77L-395.13 1415.05L-360 1435.33L-324.87 1455.61L-289.74 1475.9L-254.61 1496.18L-219.48 1516.46L-184.36 1536.74L-149.22 1557.02L-114.09 1577.31L-78.96 1597.59L-43.83 1617.87L-8.69995 1638.16L26.42 1658.43L61.5601 1678.72L96.6899 1699L131.82 1719.28L166.95 1739.57L202.08 1759.85L237.2 1780.13L272.34 1800.41L307.47 1820.69L342.6 1840.98L377.73 1861.26L412.85 1881.54L447.98 1901.82L483.12 1922.11L518.25 1942.39L657.61 2022.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M692.51 2002.57L553.15 1922.1L518.02 1901.82L482.89 1881.54L447.76 1861.25L412.64 1840.98L377.5 1820.69L342.37 1800.41L307.24 1780.13L272.11 1759.84L236.99 1739.57L201.86 1719.28L166.72 1699L131.59 1678.72L96.46 1658.43L61.33 1638.15L26.21 1617.87L-8.91998 1597.59L-44.06 1577.31L-79.19 1557.02L-114.32 1536.74L-149.45 1516.46L-184.57 1496.18L-219.7 1475.9L-254.84 1455.61L-289.97 1435.33L-325.1 1415.05L-360.23 1394.76L-395.35 1374.49L-430.48 1354.2L-465.62 1333.92L-500.75 1313.64L-535.88 1293.35L-571.01 1273.07L-606.13 1252.79L-641.26 1232.51L-676.39 1212.23L-711.53 1191.94L-746.66 1171.66L-781.78 1151.38L-816.91 1131.1L-852.04 1110.82L-887.17 1090.53L-1026.54 1010.07L-887.17 1090.53L-852.04 1110.82L-816.91 1131.1L-781.78 1151.38L-746.66 1171.66L-711.53 1191.94L-676.39 1212.23L-641.26 1232.51L-606.13 1252.79L-571.01 1273.07L-535.88 1293.35L-500.75 1313.64L-465.62 1333.92L-430.48 1354.2L-395.35 1374.49L-360.23 1394.76L-325.1 1415.05L-289.97 1435.33L-254.84 1455.61L-219.7 1475.9L-184.57 1496.18L-149.45 1516.46L-114.32 1536.74L-79.19 1557.02L-44.06 1577.31L-8.91998 1597.59L26.21 1617.87L61.33 1638.15L96.46 1658.43L131.59 1678.72L166.72 1699L201.86 1719.28L236.99 1739.57L272.11 1759.84L307.24 1780.13L342.37 1800.41L377.5 1820.69L412.64 1840.98L447.76 1861.25L482.89 1881.54L518.02 1901.82L553.15 1922.1L692.51 2002.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M727.42 1982.28L588.05 1901.82L552.92 1881.54L517.79 1861.25L482.66 1840.97L447.54 1820.69L412.41 1800.41L377.27 1780.13L342.14 1759.84L307.01 1739.56L271.89 1719.28L236.76 1699L201.63 1678.72L166.5 1658.43L131.36 1638.15L96.23 1617.87L61.11 1597.59L25.98 1577.31L-9.15002 1557.02L-44.28 1536.74L-79.42 1516.46L-114.55 1496.17L-149.67 1475.9L-184.8 1455.61L-219.93 1435.33L-255.06 1415.05L-290.2 1394.76L-325.33 1374.48L-360.45 1354.2L-395.58 1333.92L-430.71 1313.64L-465.84 1293.35L-500.98 1273.07L-536.11 1252.79L-571.23 1232.51L-606.36 1212.23L-641.49 1191.94L-676.62 1171.66L-711.75 1151.38L-746.87 1131.1L-782.01 1110.82L-817.14 1090.53L-852.27 1070.25L-991.63 989.79L-852.27 1070.25L-817.14 1090.53L-782.01 1110.82L-746.87 1131.1L-711.75 1151.38L-676.62 1171.66L-641.49 1191.94L-606.36 1212.23L-571.23 1232.51L-536.11 1252.79L-500.98 1273.07L-465.84 1293.35L-430.71 1313.64L-395.58 1333.92L-360.45 1354.2L-325.33 1374.48L-290.2 1394.76L-255.06 1415.05L-219.93 1435.33L-184.8 1455.61L-149.67 1475.9L-114.55 1496.17L-79.42 1516.46L-44.28 1536.74L-9.15002 1557.02L25.98 1577.31L61.11 1597.59L96.23 1617.87L131.36 1638.15L166.5 1658.43L201.63 1678.72L236.76 1699L271.89 1719.28L307.01 1739.56L342.14 1759.84L377.27 1780.13L412.41 1800.41L447.54 1820.69L482.66 1840.97L517.79 1861.25L552.92 1881.54L588.05 1901.82L727.42 1982.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M762.32 1962L622.96 1881.54L587.83 1861.25L552.69 1840.97L517.56 1820.69L482.44 1800.41L447.31 1780.13L412.18 1759.84L377.05 1739.56L341.91 1719.28L306.79 1699L271.66 1678.72L236.53 1658.43L201.4 1638.15L166.27 1617.87L131.14 1597.58L96.02 1577.31L60.88 1557.02L25.75 1536.74L-9.38 1516.46L-44.51 1496.17L-79.64 1475.89L-114.76 1455.61L-149.9 1435.33L-185.03 1415.05L-220.16 1394.76L-255.29 1374.48L-290.42 1354.2L-325.54 1333.92L-360.68 1313.64L-395.81 1293.35L-430.94 1273.07L-466.07 1252.79L-501.2 1232.5L-536.32 1212.23L-571.46 1191.94L-606.59 1171.66L-641.72 1151.38L-676.85 1131.09L-711.97 1110.82L-747.1 1090.53L-782.24 1070.25L-817.37 1049.97L-956.73 969.5L-817.37 1049.97L-782.24 1070.25L-747.1 1090.53L-711.97 1110.82L-676.85 1131.09L-641.72 1151.38L-606.59 1171.66L-571.46 1191.94L-536.32 1212.23L-501.2 1232.5L-466.07 1252.79L-430.94 1273.07L-395.81 1293.35L-360.68 1313.64L-325.54 1333.92L-290.42 1354.2L-255.29 1374.48L-220.16 1394.76L-185.03 1415.05L-149.9 1435.33L-114.76 1455.61L-79.64 1475.89L-44.51 1496.17L-9.38 1516.46L25.75 1536.74L60.88 1557.02L96.02 1577.31L131.14 1597.58L166.27 1617.87L201.4 1638.15L236.53 1658.43L271.66 1678.72L306.79 1699L341.91 1719.28L377.05 1739.56L412.18 1759.84L447.31 1780.13L482.44 1800.41L517.56 1820.69L552.69 1840.97L587.83 1861.25L622.96 1881.54L762.32 1962Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M797.23 1941.72L657.86 1861.25L622.73 1840.97L587.6 1820.69L552.47 1800.4L517.35 1780.13L482.21 1759.84L447.08 1739.56L411.95 1719.28L376.82 1698.99L341.7 1678.72L306.57 1658.43L271.43 1638.15L236.3 1617.87L201.17 1597.58L166.04 1577.3L130.92 1557.02L95.7899 1536.74L60.65 1516.46L25.52 1496.17L-9.61005 1475.89L-44.7401 1455.61L-79.86 1435.33L-114.99 1415.05L-150.12 1394.76L-185.26 1374.48L-220.39 1354.2L-255.52 1333.91L-290.64 1313.64L-325.77 1293.35L-360.9 1273.07L-396.04 1252.79L-431.17 1232.5L-466.3 1212.22L-501.42 1191.94L-536.55 1171.66L-571.68 1151.38L-606.82 1131.09L-641.95 1110.81L-677.07 1090.53L-712.2 1070.25L-747.33 1049.96L-782.46 1029.68L-921.83 949.22L-782.46 1029.68L-747.33 1049.96L-712.2 1070.25L-677.07 1090.53L-641.95 1110.81L-606.82 1131.09L-571.68 1151.38L-536.55 1171.66L-501.42 1191.94L-466.3 1212.22L-431.17 1232.5L-396.04 1252.79L-360.9 1273.07L-325.77 1293.35L-290.64 1313.64L-255.52 1333.91L-220.39 1354.2L-185.26 1374.48L-150.12 1394.76L-114.99 1415.05L-79.86 1435.33L-44.7401 1455.61L-9.61005 1475.89L25.52 1496.17L60.65 1516.46L95.7899 1536.74L130.92 1557.02L166.04 1577.3L201.17 1597.58L236.3 1617.87L271.43 1638.15L306.57 1658.43L341.7 1678.72L376.82 1698.99L411.95 1719.28L447.08 1739.56L482.21 1759.84L517.35 1780.13L552.47 1800.4L587.6 1820.69L622.73 1840.97L657.86 1861.25L797.23 1941.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M832.12 1921.44L692.75 1840.98L657.62 1820.69L622.49 1800.41L587.36 1780.13L552.24 1759.85L517.11 1739.57L481.97 1719.28L446.84 1699L411.71 1678.72L376.59 1658.44L341.46 1638.16L306.33 1617.87L271.19 1597.59L236.06 1577.31L200.93 1557.02L165.81 1536.75L130.68 1516.46L95.55 1496.18L60.41 1475.9L25.2801 1455.61L-9.84991 1435.33L-44.97 1415.05L-80.0999 1394.77L-115.23 1374.49L-150.36 1354.2L-185.5 1333.92L-220.63 1313.64L-255.75 1293.36L-290.88 1273.08L-326.01 1252.79L-361.14 1232.51L-396.28 1212.23L-431.41 1191.94L-466.53 1171.67L-501.66 1151.38L-536.79 1131.1L-571.92 1110.81L-607.06 1090.53L-642.18 1070.25L-677.31 1049.97L-712.44 1029.69L-747.57 1009.4L-886.94 928.94L-747.57 1009.4L-712.44 1029.69L-677.31 1049.97L-642.18 1070.25L-607.06 1090.53L-571.92 1110.81L-536.79 1131.1L-501.66 1151.38L-466.53 1171.67L-431.41 1191.94L-396.28 1212.23L-361.14 1232.51L-326.01 1252.79L-290.88 1273.08L-255.75 1293.36L-220.63 1313.64L-185.5 1333.92L-150.36 1354.2L-115.23 1374.49L-80.0999 1394.77L-44.97 1415.05L-9.84991 1435.33L25.2801 1455.61L60.41 1475.9L95.55 1496.18L130.68 1516.46L165.81 1536.75L200.93 1557.02L236.06 1577.31L271.19 1597.59L306.33 1617.87L341.46 1638.16L376.59 1658.44L411.71 1678.72L446.84 1699L481.97 1719.28L517.11 1739.57L552.24 1759.85L587.36 1780.13L622.49 1800.41L657.62 1820.69L692.75 1840.98L832.12 1921.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M867.02 1901.15L727.66 1820.69L692.52 1800.41L657.39 1780.13L622.26 1759.84L587.14 1739.57L552.01 1719.28L516.88 1699L481.75 1678.72L446.61 1658.43L411.49 1638.16L376.36 1617.87L341.23 1597.59L306.1 1577.31L270.97 1557.02L235.83 1536.74L200.71 1516.46L165.58 1496.18L130.45 1475.9L95.32 1455.61L60.19 1435.33L25.05 1415.05L-10.07 1394.77L-45.2 1374.49L-80.33 1354.2L-115.46 1333.92L-150.59 1313.64L-185.73 1293.35L-220.85 1273.08L-255.98 1252.79L-291.11 1232.51L-326.24 1212.22L-361.37 1191.94L-396.5 1171.66L-431.62 1151.38L-466.76 1131.1L-501.89 1110.81L-537.02 1090.53L-572.15 1070.25L-607.27 1049.97L-642.4 1029.69L-677.54 1009.4L-712.67 989.12L-852.03 908.66L-712.67 989.12L-677.54 1009.4L-642.4 1029.69L-607.27 1049.97L-572.15 1070.25L-537.02 1090.53L-501.89 1110.81L-466.76 1131.1L-431.62 1151.38L-396.5 1171.66L-361.37 1191.94L-326.24 1212.22L-291.11 1232.51L-255.98 1252.79L-220.85 1273.08L-185.73 1293.35L-150.59 1313.64L-115.46 1333.92L-80.33 1354.2L-45.2 1374.49L-10.07 1394.77L25.05 1415.05L60.19 1435.33L95.32 1455.61L130.45 1475.9L165.58 1496.18L200.71 1516.46L235.83 1536.74L270.97 1557.02L306.1 1577.31L341.23 1597.59L376.36 1617.87L411.49 1638.16L446.61 1658.43L481.75 1678.72L516.88 1699L552.01 1719.28L587.14 1739.57L622.26 1759.84L657.39 1780.13L692.52 1800.41L727.66 1820.69L867.02 1901.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M901.92 1880.87L762.56 1800.41L727.43 1780.13L692.3 1759.84L657.16 1739.56L622.04 1719.28L586.91 1699L551.78 1678.72L516.65 1658.43L481.52 1638.15L446.4 1617.87L411.27 1597.59L376.13 1577.31L341 1557.02L305.87 1536.74L270.74 1516.46L235.62 1496.18L200.49 1475.9L165.35 1455.61L130.22 1435.33L95.09 1415.04L59.96 1394.76L24.84 1374.48L-10.29 1354.2L-45.43 1333.92L-80.56 1313.63L-115.69 1293.35L-150.82 1273.07L-185.94 1252.79L-221.07 1232.51L-256.21 1212.22L-291.34 1191.94L-326.47 1171.66L-361.6 1151.37L-396.72 1131.1L-431.85 1110.81L-466.98 1090.53L-502.12 1070.25L-537.25 1049.96L-572.37 1029.69L-607.5 1009.4L-642.63 989.12L-677.76 968.84L-817.13 888.38L-677.76 968.84L-642.63 989.12L-607.5 1009.4L-572.37 1029.69L-537.25 1049.96L-502.12 1070.25L-466.98 1090.53L-431.85 1110.81L-396.72 1131.1L-361.6 1151.37L-326.47 1171.66L-291.34 1191.94L-256.21 1212.22L-221.07 1232.51L-185.94 1252.79L-150.82 1273.07L-115.69 1293.35L-80.56 1313.63L-45.43 1333.92L-10.29 1354.2L24.84 1374.48L59.96 1394.76L95.09 1415.04L130.22 1435.33L165.35 1455.61L200.49 1475.9L235.62 1496.18L270.74 1516.46L305.87 1536.74L341 1557.02L376.13 1577.31L411.27 1597.59L446.4 1617.87L481.52 1638.15L516.65 1658.43L551.78 1678.72L586.91 1699L622.04 1719.28L657.16 1739.56L692.3 1759.84L727.43 1780.13L762.56 1800.41L901.92 1880.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_426">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* Ramen slaapkamers card */
export const step16Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_426)">
      <path
        d="M603.46 147.23L606.47 679.79L304.42 854.18L301.42 321.61L603.46 147.23Z"
        fill="#F8B8C4"
      />
      <path
        d="M304.42 854.18L298.11 850.52L295.12 317.94L301.42 321.61L304.42 854.18Z"
        fill="#940731"
      />
      <path
        d="M301.42 321.61L295.12 317.94L597.16 143.56L603.46 147.23L301.42 321.61Z"
        fill="#CD163F"
      />
      <path
        d="M659.33 174.95L662.34 707.51L360.28 881.9L357.29 349.33L659.33 174.95Z"
        fill="#BBE5F0"
      />
      <path
        d="M360.28 881.9L353.98 878.24L350.99 345.67L357.29 349.33L360.28 881.9Z"
        fill="#00A3DE"
      />
      <path
        d="M357.29 349.33L350.99 345.67L653.02 171.28L659.33 174.95L357.29 349.33Z"
        fill="#00A3DE"
      />
      <path
        d="M783.26 159.65L427.76 364.9L429.38 936.44L784.88 731.19L783.26 159.65ZM448.12 884.75L446.7 384.33L753.77 207.05L755.19 707.47L448.12 884.75Z"
        fill="#00A3DE"
      />
      <path
        d="M446.7 384.33L448.12 884.75L755.19 707.46L753.77 207.04L446.7 384.33Z"
        fill="#BBE5F0"
      />
      <path
        d="M429.38 936.44L406.65 923.23L405.03 351.69L427.76 364.9L429.38 936.44Z"
        fill="#71CFEC"
      />
      <path
        d="M427.76 364.9L405.03 351.69L760.53 146.44L783.26 159.65L427.76 364.9Z"
        fill="#00545E"
      />
      <path
        d="M765.94 711.77L764.49 200.85L753.77 207.04L755.19 707.46L448.12 884.75L448.15 895.24L765.94 711.77Z"
        fill="#71CFEC"
      />
      <path
        opacity="0.3"
        d="M587.82 2063.41L448.45 1982.95L413.32 1962.67L378.19 1942.38L343.06 1922.1L307.94 1901.82L272.8 1881.54L237.67 1861.26L202.54 1840.97L167.41 1820.69L132.29 1800.41L97.16 1780.13L62.02 1759.85L26.89 1739.56L-8.23999 1719.28L-43.37 1698.99L-78.49 1678.72L-113.62 1658.44L-148.75 1638.15L-183.89 1617.87L-219.02 1597.58L-254.15 1577.3L-289.27 1557.02L-324.4 1536.74L-359.53 1516.46L-394.67 1496.17L-429.8 1475.89L-464.93 1455.61L-500.05 1435.33L-535.18 1415.05L-570.31 1394.76L-605.45 1374.48L-640.58 1354.2L-675.71 1333.91L-710.83 1313.64L-745.96 1293.35L-781.09 1273.07L-816.23 1252.79L-851.36 1232.5L-886.48 1212.23L-921.61 1191.94L-956.74 1171.66L-991.87 1151.38L-1131.24 1070.92L-991.87 1151.38L-956.74 1171.66L-921.61 1191.94L-886.48 1212.23L-851.36 1232.5L-816.23 1252.79L-781.09 1273.07L-745.96 1293.35L-710.83 1313.64L-675.71 1333.91L-640.58 1354.2L-605.45 1374.48L-570.31 1394.76L-535.18 1415.05L-500.05 1435.33L-464.93 1455.61L-429.8 1475.89L-394.67 1496.17L-359.53 1516.46L-324.4 1536.74L-289.27 1557.02L-254.15 1577.3L-219.02 1597.58L-183.89 1617.87L-148.75 1638.15L-113.62 1658.44L-78.49 1678.72L-43.37 1698.99L-8.23999 1719.28L26.89 1739.56L62.02 1759.85L97.16 1780.13L132.29 1800.41L167.41 1820.69L202.54 1840.97L237.67 1861.26L272.8 1881.54L307.94 1901.82L343.06 1922.1L378.19 1942.38L413.32 1962.67L448.45 1982.95L587.82 2063.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M622.71 2043.13L483.34 1962.67L448.21 1942.39L413.08 1922.11L377.95 1901.82L342.83 1881.55L307.7 1861.26L272.56 1840.98L237.43 1820.7L202.3 1800.41L167.18 1780.14L132.05 1759.85L96.92 1739.57L61.78 1719.29L26.65 1699L-8.47998 1678.72L-43.6 1658.44L-78.73 1638.16L-113.86 1617.87L-149 1597.59L-184.13 1577.31L-219.26 1557.02L-254.38 1536.75L-289.51 1516.46L-324.64 1496.18L-359.77 1475.9L-394.91 1455.61L-430.04 1435.33L-465.16 1415.05L-500.29 1394.77L-535.42 1374.49L-570.55 1354.2L-605.69 1333.92L-640.82 1313.64L-675.94 1293.36L-711.07 1273.08L-746.2 1252.79L-781.33 1232.51L-816.47 1212.23L-851.59 1191.95L-886.72 1171.67L-921.85 1151.38L-956.98 1131.1L-1096.34 1050.64L-956.98 1131.1L-921.85 1151.38L-886.72 1171.67L-851.59 1191.95L-816.47 1212.23L-781.33 1232.51L-746.2 1252.79L-711.07 1273.08L-675.94 1293.36L-640.82 1313.64L-605.69 1333.92L-570.55 1354.2L-535.42 1374.49L-500.29 1394.77L-465.16 1415.05L-430.04 1435.33L-394.91 1455.61L-359.77 1475.9L-324.64 1496.18L-289.51 1516.46L-254.38 1536.75L-219.26 1557.02L-184.13 1577.31L-149 1597.59L-113.86 1617.87L-78.73 1638.16L-43.6 1658.44L-8.47998 1678.72L26.65 1699L61.78 1719.29L96.92 1739.57L132.05 1759.85L167.18 1780.14L202.3 1800.41L237.43 1820.7L272.56 1840.98L307.7 1861.26L342.83 1881.55L377.95 1901.82L413.08 1922.11L448.21 1942.39L483.34 1962.67L622.71 2043.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M657.61 2022.85L518.25 1942.39L483.12 1922.11L447.98 1901.82L412.85 1881.54L377.73 1861.26L342.6 1840.98L307.47 1820.69L272.34 1800.41L237.2 1780.13L202.08 1759.85L166.95 1739.57L131.82 1719.28L96.6899 1699L61.5601 1678.72L26.42 1658.43L-8.69995 1638.16L-43.83 1617.87L-78.96 1597.59L-114.09 1577.31L-149.22 1557.02L-184.36 1536.74L-219.48 1516.46L-254.61 1496.18L-289.74 1475.9L-324.87 1455.61L-360 1435.33L-395.13 1415.05L-430.25 1394.77L-465.39 1374.49L-500.52 1354.2L-535.65 1333.92L-570.78 1313.64L-605.91 1293.35L-641.03 1273.08L-676.17 1252.79L-711.3 1232.51L-746.43 1212.23L-781.56 1191.94L-816.68 1171.67L-851.81 1151.38L-886.95 1131.1L-922.08 1110.82L-1061.44 1030.35L-922.08 1110.82L-886.95 1131.1L-851.81 1151.38L-816.68 1171.67L-781.56 1191.94L-746.43 1212.23L-711.3 1232.51L-676.17 1252.79L-641.03 1273.08L-605.91 1293.35L-570.78 1313.64L-535.65 1333.92L-500.52 1354.2L-465.39 1374.49L-430.25 1394.77L-395.13 1415.05L-360 1435.33L-324.87 1455.61L-289.74 1475.9L-254.61 1496.18L-219.48 1516.46L-184.36 1536.74L-149.22 1557.02L-114.09 1577.31L-78.96 1597.59L-43.83 1617.87L-8.69995 1638.16L26.42 1658.43L61.5601 1678.72L96.6899 1699L131.82 1719.28L166.95 1739.57L202.08 1759.85L237.2 1780.13L272.34 1800.41L307.47 1820.69L342.6 1840.98L377.73 1861.26L412.85 1881.54L447.98 1901.82L483.12 1922.11L518.25 1942.39L657.61 2022.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M692.51 2002.57L553.15 1922.1L518.02 1901.82L482.89 1881.54L447.76 1861.25L412.64 1840.98L377.5 1820.69L342.37 1800.41L307.24 1780.13L272.11 1759.84L236.99 1739.57L201.86 1719.28L166.72 1699L131.59 1678.72L96.46 1658.43L61.33 1638.15L26.21 1617.87L-8.91998 1597.59L-44.06 1577.31L-79.19 1557.02L-114.32 1536.74L-149.45 1516.46L-184.57 1496.18L-219.7 1475.9L-254.84 1455.61L-289.97 1435.33L-325.1 1415.05L-360.23 1394.76L-395.35 1374.49L-430.48 1354.2L-465.62 1333.92L-500.75 1313.64L-535.88 1293.35L-571.01 1273.07L-606.13 1252.79L-641.26 1232.51L-676.39 1212.23L-711.53 1191.94L-746.66 1171.66L-781.78 1151.38L-816.91 1131.1L-852.04 1110.82L-887.17 1090.53L-1026.54 1010.07L-887.17 1090.53L-852.04 1110.82L-816.91 1131.1L-781.78 1151.38L-746.66 1171.66L-711.53 1191.94L-676.39 1212.23L-641.26 1232.51L-606.13 1252.79L-571.01 1273.07L-535.88 1293.35L-500.75 1313.64L-465.62 1333.92L-430.48 1354.2L-395.35 1374.49L-360.23 1394.76L-325.1 1415.05L-289.97 1435.33L-254.84 1455.61L-219.7 1475.9L-184.57 1496.18L-149.45 1516.46L-114.32 1536.74L-79.19 1557.02L-44.06 1577.31L-8.91998 1597.59L26.21 1617.87L61.33 1638.15L96.46 1658.43L131.59 1678.72L166.72 1699L201.86 1719.28L236.99 1739.57L272.11 1759.84L307.24 1780.13L342.37 1800.41L377.5 1820.69L412.64 1840.98L447.76 1861.25L482.89 1881.54L518.02 1901.82L553.15 1922.1L692.51 2002.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M727.42 1982.28L588.05 1901.82L552.92 1881.54L517.79 1861.25L482.66 1840.97L447.54 1820.69L412.41 1800.41L377.27 1780.13L342.14 1759.84L307.01 1739.56L271.89 1719.28L236.76 1699L201.63 1678.72L166.5 1658.43L131.36 1638.15L96.23 1617.87L61.11 1597.59L25.98 1577.31L-9.15002 1557.02L-44.28 1536.74L-79.42 1516.46L-114.55 1496.17L-149.67 1475.9L-184.8 1455.61L-219.93 1435.33L-255.06 1415.05L-290.2 1394.76L-325.33 1374.48L-360.45 1354.2L-395.58 1333.92L-430.71 1313.64L-465.84 1293.35L-500.98 1273.07L-536.11 1252.79L-571.23 1232.51L-606.36 1212.23L-641.49 1191.94L-676.62 1171.66L-711.75 1151.38L-746.87 1131.1L-782.01 1110.82L-817.14 1090.53L-852.27 1070.25L-991.63 989.79L-852.27 1070.25L-817.14 1090.53L-782.01 1110.82L-746.87 1131.1L-711.75 1151.38L-676.62 1171.66L-641.49 1191.94L-606.36 1212.23L-571.23 1232.51L-536.11 1252.79L-500.98 1273.07L-465.84 1293.35L-430.71 1313.64L-395.58 1333.92L-360.45 1354.2L-325.33 1374.48L-290.2 1394.76L-255.06 1415.05L-219.93 1435.33L-184.8 1455.61L-149.67 1475.9L-114.55 1496.17L-79.42 1516.46L-44.28 1536.74L-9.15002 1557.02L25.98 1577.31L61.11 1597.59L96.23 1617.87L131.36 1638.15L166.5 1658.43L201.63 1678.72L236.76 1699L271.89 1719.28L307.01 1739.56L342.14 1759.84L377.27 1780.13L412.41 1800.41L447.54 1820.69L482.66 1840.97L517.79 1861.25L552.92 1881.54L588.05 1901.82L727.42 1982.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M762.32 1962L622.96 1881.54L587.83 1861.25L552.69 1840.97L517.56 1820.69L482.44 1800.41L447.31 1780.13L412.18 1759.84L377.05 1739.56L341.91 1719.28L306.79 1699L271.66 1678.72L236.53 1658.43L201.4 1638.15L166.27 1617.87L131.14 1597.58L96.02 1577.31L60.88 1557.02L25.75 1536.74L-9.38 1516.46L-44.51 1496.17L-79.64 1475.89L-114.76 1455.61L-149.9 1435.33L-185.03 1415.05L-220.16 1394.76L-255.29 1374.48L-290.42 1354.2L-325.54 1333.92L-360.68 1313.64L-395.81 1293.35L-430.94 1273.07L-466.07 1252.79L-501.2 1232.5L-536.32 1212.23L-571.46 1191.94L-606.59 1171.66L-641.72 1151.38L-676.85 1131.09L-711.97 1110.82L-747.1 1090.53L-782.24 1070.25L-817.37 1049.97L-956.73 969.5L-817.37 1049.97L-782.24 1070.25L-747.1 1090.53L-711.97 1110.82L-676.85 1131.09L-641.72 1151.38L-606.59 1171.66L-571.46 1191.94L-536.32 1212.23L-501.2 1232.5L-466.07 1252.79L-430.94 1273.07L-395.81 1293.35L-360.68 1313.64L-325.54 1333.92L-290.42 1354.2L-255.29 1374.48L-220.16 1394.76L-185.03 1415.05L-149.9 1435.33L-114.76 1455.61L-79.64 1475.89L-44.51 1496.17L-9.38 1516.46L25.75 1536.74L60.88 1557.02L96.02 1577.31L131.14 1597.58L166.27 1617.87L201.4 1638.15L236.53 1658.43L271.66 1678.72L306.79 1699L341.91 1719.28L377.05 1739.56L412.18 1759.84L447.31 1780.13L482.44 1800.41L517.56 1820.69L552.69 1840.97L587.83 1861.25L622.96 1881.54L762.32 1962Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M797.23 1941.72L657.86 1861.25L622.73 1840.97L587.6 1820.69L552.47 1800.4L517.35 1780.13L482.21 1759.84L447.08 1739.56L411.95 1719.28L376.82 1698.99L341.7 1678.72L306.57 1658.43L271.43 1638.15L236.3 1617.87L201.17 1597.58L166.04 1577.3L130.92 1557.02L95.7899 1536.74L60.65 1516.46L25.52 1496.17L-9.61005 1475.89L-44.7401 1455.61L-79.86 1435.33L-114.99 1415.05L-150.12 1394.76L-185.26 1374.48L-220.39 1354.2L-255.52 1333.91L-290.64 1313.64L-325.77 1293.35L-360.9 1273.07L-396.04 1252.79L-431.17 1232.5L-466.3 1212.22L-501.42 1191.94L-536.55 1171.66L-571.68 1151.38L-606.82 1131.09L-641.95 1110.81L-677.07 1090.53L-712.2 1070.25L-747.33 1049.96L-782.46 1029.68L-921.83 949.22L-782.46 1029.68L-747.33 1049.96L-712.2 1070.25L-677.07 1090.53L-641.95 1110.81L-606.82 1131.09L-571.68 1151.38L-536.55 1171.66L-501.42 1191.94L-466.3 1212.22L-431.17 1232.5L-396.04 1252.79L-360.9 1273.07L-325.77 1293.35L-290.64 1313.64L-255.52 1333.91L-220.39 1354.2L-185.26 1374.48L-150.12 1394.76L-114.99 1415.05L-79.86 1435.33L-44.7401 1455.61L-9.61005 1475.89L25.52 1496.17L60.65 1516.46L95.7899 1536.74L130.92 1557.02L166.04 1577.3L201.17 1597.58L236.3 1617.87L271.43 1638.15L306.57 1658.43L341.7 1678.72L376.82 1698.99L411.95 1719.28L447.08 1739.56L482.21 1759.84L517.35 1780.13L552.47 1800.4L587.6 1820.69L622.73 1840.97L657.86 1861.25L797.23 1941.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M832.12 1921.44L692.75 1840.98L657.62 1820.69L622.49 1800.41L587.36 1780.13L552.24 1759.85L517.11 1739.57L481.97 1719.28L446.84 1699L411.71 1678.72L376.59 1658.44L341.46 1638.16L306.33 1617.87L271.19 1597.59L236.06 1577.31L200.93 1557.02L165.81 1536.75L130.68 1516.46L95.55 1496.18L60.41 1475.9L25.2801 1455.61L-9.84991 1435.33L-44.97 1415.05L-80.0999 1394.77L-115.23 1374.49L-150.36 1354.2L-185.5 1333.92L-220.63 1313.64L-255.75 1293.36L-290.88 1273.08L-326.01 1252.79L-361.14 1232.51L-396.28 1212.23L-431.41 1191.94L-466.53 1171.67L-501.66 1151.38L-536.79 1131.1L-571.92 1110.81L-607.06 1090.53L-642.18 1070.25L-677.31 1049.97L-712.44 1029.69L-747.57 1009.4L-886.94 928.94L-747.57 1009.4L-712.44 1029.69L-677.31 1049.97L-642.18 1070.25L-607.06 1090.53L-571.92 1110.81L-536.79 1131.1L-501.66 1151.38L-466.53 1171.67L-431.41 1191.94L-396.28 1212.23L-361.14 1232.51L-326.01 1252.79L-290.88 1273.08L-255.75 1293.36L-220.63 1313.64L-185.5 1333.92L-150.36 1354.2L-115.23 1374.49L-80.0999 1394.77L-44.97 1415.05L-9.84991 1435.33L25.2801 1455.61L60.41 1475.9L95.55 1496.18L130.68 1516.46L165.81 1536.75L200.93 1557.02L236.06 1577.31L271.19 1597.59L306.33 1617.87L341.46 1638.16L376.59 1658.44L411.71 1678.72L446.84 1699L481.97 1719.28L517.11 1739.57L552.24 1759.85L587.36 1780.13L622.49 1800.41L657.62 1820.69L692.75 1840.98L832.12 1921.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M867.02 1901.15L727.66 1820.69L692.52 1800.41L657.39 1780.13L622.26 1759.84L587.14 1739.57L552.01 1719.28L516.88 1699L481.75 1678.72L446.61 1658.43L411.49 1638.16L376.36 1617.87L341.23 1597.59L306.1 1577.31L270.97 1557.02L235.83 1536.74L200.71 1516.46L165.58 1496.18L130.45 1475.9L95.32 1455.61L60.19 1435.33L25.05 1415.05L-10.07 1394.77L-45.2 1374.49L-80.33 1354.2L-115.46 1333.92L-150.59 1313.64L-185.73 1293.35L-220.85 1273.08L-255.98 1252.79L-291.11 1232.51L-326.24 1212.22L-361.37 1191.94L-396.5 1171.66L-431.62 1151.38L-466.76 1131.1L-501.89 1110.81L-537.02 1090.53L-572.15 1070.25L-607.27 1049.97L-642.4 1029.69L-677.54 1009.4L-712.67 989.12L-852.03 908.66L-712.67 989.12L-677.54 1009.4L-642.4 1029.69L-607.27 1049.97L-572.15 1070.25L-537.02 1090.53L-501.89 1110.81L-466.76 1131.1L-431.62 1151.38L-396.5 1171.66L-361.37 1191.94L-326.24 1212.22L-291.11 1232.51L-255.98 1252.79L-220.85 1273.08L-185.73 1293.35L-150.59 1313.64L-115.46 1333.92L-80.33 1354.2L-45.2 1374.49L-10.07 1394.77L25.05 1415.05L60.19 1435.33L95.32 1455.61L130.45 1475.9L165.58 1496.18L200.71 1516.46L235.83 1536.74L270.97 1557.02L306.1 1577.31L341.23 1597.59L376.36 1617.87L411.49 1638.16L446.61 1658.43L481.75 1678.72L516.88 1699L552.01 1719.28L587.14 1739.57L622.26 1759.84L657.39 1780.13L692.52 1800.41L727.66 1820.69L867.02 1901.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M901.92 1880.87L762.56 1800.41L727.43 1780.13L692.3 1759.84L657.16 1739.56L622.04 1719.28L586.91 1699L551.78 1678.72L516.65 1658.43L481.52 1638.15L446.4 1617.87L411.27 1597.59L376.13 1577.31L341 1557.02L305.87 1536.74L270.74 1516.46L235.62 1496.18L200.49 1475.9L165.35 1455.61L130.22 1435.33L95.09 1415.04L59.96 1394.76L24.84 1374.48L-10.29 1354.2L-45.43 1333.92L-80.56 1313.63L-115.69 1293.35L-150.82 1273.07L-185.94 1252.79L-221.07 1232.51L-256.21 1212.22L-291.34 1191.94L-326.47 1171.66L-361.6 1151.37L-396.72 1131.1L-431.85 1110.81L-466.98 1090.53L-502.12 1070.25L-537.25 1049.96L-572.37 1029.69L-607.5 1009.4L-642.63 989.12L-677.76 968.84L-817.13 888.38L-677.76 968.84L-642.63 989.12L-607.5 1009.4L-572.37 1029.69L-537.25 1049.96L-502.12 1070.25L-466.98 1090.53L-431.85 1110.81L-396.72 1131.1L-361.6 1151.37L-326.47 1171.66L-291.34 1191.94L-256.21 1212.22L-221.07 1232.51L-185.94 1252.79L-150.82 1273.07L-115.69 1293.35L-80.56 1313.63L-45.43 1333.92L-10.29 1354.2L24.84 1374.48L59.96 1394.76L95.09 1415.04L130.22 1435.33L165.35 1455.61L200.49 1475.9L235.62 1496.18L270.74 1516.46L305.87 1536.74L341 1557.02L376.13 1577.31L411.27 1597.59L446.4 1617.87L481.52 1638.15L516.65 1658.43L551.78 1678.72L586.91 1699L622.04 1719.28L657.16 1739.56L692.3 1759.84L727.43 1780.13L762.56 1800.41L901.92 1880.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_426">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* Voorgevel card */
export const step17Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_1566)">
      <path
        d="M145.76 808.23L459.63 990.94L460.62 465.71L142.8 283.13L145.76 808.23Z"
        fill="#71CFEC"
      />
      <path
        d="M142.8 283.13L460.62 465.71L664.55 215.98L350.68 33.26L142.8 283.13Z"
        fill="#00545E"
      />
      <path
        d="M350.68 33.26L664.55 215.98L839.25 282.29L493.2 82.96L350.68 33.26Z"
        fill="#003D42"
      />
      <path
        d="M842.2 755.34L839.25 282.29L664.55 215.98L492.95 426.12V565.95L842.2 755.34Z"
        fill="#BBE5F0"
      />
      <mask
        id="mask0_68_1566"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="312"
        y="596"
        width="71"
        height="190"
      >
        <path
          d="M382.47 785.8L382.9 633.97L312.95 596.1L312.52 747.93L382.47 785.8Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_1566)">
        <path
          d="M312.95 596.1L382.9 633.97L382.47 785.8L312.52 747.93L312.95 596.1Z"
          fill="#BBE5F0"
        />
      </g>
      <mask
        id="mask1_68_1566"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="174"
        y="520"
        width="71"
        height="191"
      >
        <path
          d="M244.04 710.1L244.47 558.27L174.52 520.4L174.09 672.24L244.04 710.1Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_68_1566)">
        <path
          d="M174.52 520.4L244.47 558.27L244.04 710.1L174.09 672.24L174.52 520.4Z"
          fill="#BBE5F0"
        />
      </g>
      <path
        d="M459.63 990.94L460.62 465.71"
        stroke="#71CFEC"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M492.95 426.12L460.62 465.71L460.39 585.97L492.95 565.95V426.12Z"
        fill="#00A3DE"
      />
      <path
        d="M842.2 755.34L492.95 565.95L460.39 585.97L459.63 990.94L842.2 755.34Z"
        fill="#00545E"
      />
      <path
        d="M526.77 1031.92L553.06 1046.74L554.06 519.4L523.8 504.57L526.77 1031.92Z"
        fill="#F8B8C4"
      />
      <path
        d="M523.8 504.57L554.06 519.4L758.82 276.01L732.53 261.19L523.8 504.57Z"
        fill="#940731"
      />
      <path
        d="M934.24 336.85L758.82 276.01L554.06 519.4L553.06 1046.74L937.2 823.98L934.24 336.85Z"
        fill="#CD163F"
      />
      <path
        d="M732.53 261.19L758.82 276.01L934.24 336.85L732.53 261.19Z"
        fill="#940731"
      />
      <path
        d="M523.8 504.57L554.06 519.4L758.82 276.01"
        stroke="#CD163F"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M553.06 1046.74L554.06 519.4"
        stroke="#CD163F"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M633.52 997.97L735.52 937.97L735.97 653.22L633.78 713.94L633.52 997.97Z"
        fill="#940731"
      />
      <path
        d="M855.91 586.98L785.68 627.53L786.11 779.96L856.35 739.41L855.91 586.98Z"
        fill="#940731"
      />
      <path
        opacity="0.3"
        d="M2323.96 1544.46L2184.6 1463.99L2149.46 1443.71L2114.33 1423.43L2079.2 1403.14L2044.08 1382.87L2008.95 1362.58L1973.82 1342.3L1938.69 1322.02L1903.55 1301.73L1868.43 1281.46L1833.3 1261.17L1798.17 1240.89L1763.04 1220.61L1727.91 1200.32L1692.77 1180.04L1657.65 1159.76L1622.52 1139.48L1587.39 1119.2L1552.26 1098.91L1517.13 1078.63L1481.99 1058.35L1446.87 1038.07L1411.74 1017.79L1376.61 997.5L1341.48 977.22L1306.35 956.94L1271.21 936.65L1236.09 916.38L1200.96 896.09L1165.83 875.81L1130.7 855.53L1095.57 835.24L1060.44 814.96L1025.32 794.68L990.18 774.4L955.05 754.12L919.92 733.83L884.79 713.55L849.67 693.27L814.54 672.99L779.4 652.71L744.27 632.42L604.91 551.96L744.27 632.42L779.4 652.71L814.54 672.99L849.67 693.27L884.79 713.55L919.92 733.83L955.05 754.12L990.18 774.4L1025.32 794.68L1060.44 814.96L1095.57 835.24L1130.7 855.53L1165.83 875.81L1200.96 896.09L1236.09 916.38L1271.21 936.65L1306.35 956.94L1341.48 977.22L1376.61 997.5L1411.74 1017.79L1446.87 1038.07L1481.99 1058.35L1517.13 1078.63L1552.26 1098.91L1587.39 1119.2L1622.52 1139.48L1657.65 1159.76L1692.77 1180.04L1727.91 1200.32L1763.04 1220.61L1798.17 1240.89L1833.3 1261.17L1868.43 1281.46L1903.55 1301.73L1938.69 1322.02L1973.82 1342.3L2008.95 1362.58L2044.08 1382.87L2079.2 1403.14L2114.33 1423.43L2149.46 1443.71L2184.6 1463.99L2323.96 1544.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2358.86 1524.17L2219.5 1443.71L2184.37 1423.43L2149.24 1403.14L2114.1 1382.86L2078.98 1362.58L2043.85 1342.3L2008.72 1322.02L1973.59 1301.73L1938.46 1281.45L1903.34 1261.17L1868.2 1240.89L1833.07 1220.61L1797.94 1200.32L1762.81 1180.04L1727.68 1159.76L1692.56 1139.48L1657.43 1119.2L1622.29 1098.91L1587.16 1078.63L1552.03 1058.35L1516.9 1038.06L1481.78 1017.79L1446.65 997.5L1411.51 977.22L1376.38 956.94L1341.25 936.65L1306.12 916.37L1271 896.09L1235.87 875.81L1200.73 855.53L1165.6 835.24L1130.47 814.96L1095.34 794.68L1060.22 774.4L1025.09 754.12L989.95 733.83L954.82 713.55L919.69 693.27L884.57 672.99L849.44 652.71L814.31 632.42L779.18 612.14L639.81 531.68L779.18 612.14L814.31 632.42L849.44 652.71L884.57 672.99L919.69 693.27L954.82 713.55L989.95 733.83L1025.09 754.12L1060.22 774.4L1095.34 794.68L1130.47 814.96L1165.6 835.24L1200.73 855.53L1235.87 875.81L1271 896.09L1306.12 916.37L1341.25 936.65L1376.38 956.94L1411.51 977.22L1446.65 997.5L1481.78 1017.79L1516.9 1038.06L1552.03 1058.35L1587.16 1078.63L1622.29 1098.91L1657.43 1119.2L1692.56 1139.48L1727.68 1159.76L1762.81 1180.04L1797.94 1200.32L1833.07 1220.61L1868.2 1240.89L1903.34 1261.17L1938.46 1281.45L1973.59 1301.73L2008.72 1322.02L2043.85 1342.3L2078.98 1362.58L2114.1 1382.86L2149.24 1403.14L2184.37 1423.43L2219.5 1443.71L2358.86 1524.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2393.77 1503.89L2254.4 1423.43L2219.27 1403.14L2184.14 1382.86L2149.01 1362.58L2113.89 1342.3L2078.76 1322.02L2043.62 1301.73L2008.49 1281.45L1973.36 1261.17L1938.24 1240.89L1903.11 1220.61L1867.98 1200.32L1832.84 1180.04L1797.71 1159.76L1762.58 1139.47L1727.46 1119.2L1692.33 1098.91L1657.2 1078.63L1622.07 1058.35L1586.93 1038.06L1551.8 1017.78L1516.68 997.5L1481.55 977.22L1446.42 956.94L1411.29 936.65L1376.15 916.37L1341.02 896.09L1305.9 875.81L1270.77 855.53L1235.64 835.24L1200.51 814.96L1165.37 794.68L1130.24 774.39L1095.12 754.12L1059.99 733.83L1024.86 713.55L989.73 693.27L954.59 672.98L919.47 652.71L884.34 632.42L849.21 612.14L814.08 591.85L674.72 511.39L814.08 591.85L849.21 612.14L884.34 632.42L919.47 652.71L954.59 672.98L989.73 693.27L1024.86 713.55L1059.99 733.83L1095.12 754.12L1130.24 774.39L1165.37 794.68L1200.51 814.96L1235.64 835.24L1270.77 855.53L1305.9 875.81L1341.02 896.09L1376.15 916.37L1411.29 936.65L1446.42 956.94L1481.55 977.22L1516.68 997.5L1551.8 1017.78L1586.93 1038.06L1622.07 1058.35L1657.2 1078.63L1692.33 1098.91L1727.46 1119.2L1762.58 1139.47L1797.71 1159.76L1832.84 1180.04L1867.98 1200.32L1903.11 1220.61L1938.24 1240.89L1973.36 1261.17L2008.49 1281.45L2043.62 1301.73L2078.76 1322.02L2113.89 1342.3L2149.01 1362.58L2184.14 1382.86L2219.27 1403.14L2254.4 1423.43L2393.77 1503.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2428.66 1483.61L2289.3 1403.15L2254.16 1382.87L2219.03 1362.58L2183.9 1342.3L2148.78 1322.02L2113.65 1301.74L2078.52 1281.46L2043.38 1261.17L2008.25 1240.89L1973.13 1220.61L1938 1200.33L1902.87 1180.05L1867.74 1159.76L1832.6 1139.48L1797.47 1119.2L1762.35 1098.92L1727.22 1078.64L1692.09 1058.35L1656.96 1038.07L1621.82 1017.79L1586.69 997.5L1551.57 977.23L1516.44 956.94L1481.31 936.66L1446.18 916.38L1411.05 896.09L1375.91 875.81L1340.79 855.53L1305.66 835.25L1270.53 814.97L1235.4 794.68L1200.27 774.4L1165.13 754.12L1130.01 733.84L1094.88 713.56L1059.75 693.27L1024.62 672.99L989.49 652.7L954.37 632.43L919.23 612.14L884.1 591.86L848.97 571.58L709.61 491.12L848.97 571.58L884.1 591.86L919.23 612.14L954.37 632.43L989.49 652.7L1024.62 672.99L1059.75 693.27L1094.88 713.56L1130.01 733.84L1165.13 754.12L1200.27 774.4L1235.4 794.68L1270.53 814.97L1305.66 835.25L1340.79 855.53L1375.91 875.81L1411.05 896.09L1446.18 916.38L1481.31 936.66L1516.44 956.94L1551.57 977.23L1586.69 997.5L1621.82 1017.79L1656.96 1038.07L1692.09 1058.35L1727.22 1078.64L1762.35 1098.92L1797.47 1119.2L1832.6 1139.48L1867.74 1159.76L1902.87 1180.05L1938 1200.33L1973.13 1220.61L2008.25 1240.89L2043.38 1261.17L2078.52 1281.46L2113.65 1301.74L2148.78 1322.02L2183.9 1342.3L2219.03 1362.58L2254.16 1382.87L2289.3 1403.15L2428.66 1483.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2463.56 1463.33L2324.2 1382.87L2289.07 1362.58L2253.94 1342.3L2218.8 1322.02L2183.68 1301.74L2148.55 1281.46L2113.42 1261.17L2078.29 1240.89L2043.16 1220.61L2008.04 1200.33L1972.9 1180.05L1937.77 1159.76L1902.64 1139.48L1867.51 1119.2L1832.38 1098.91L1797.26 1078.64L1762.12 1058.35L1726.99 1038.07L1691.86 1017.79L1656.73 997.5L1621.6 977.22L1586.48 956.94L1551.34 936.66L1516.21 916.38L1481.08 896.09L1445.95 875.81L1410.82 855.52L1375.7 835.25L1340.57 814.97L1305.43 794.68L1270.3 774.4L1235.17 754.11L1200.04 733.83L1164.92 713.55L1129.79 693.27L1094.65 672.99L1059.52 652.7L1024.39 632.42L989.27 612.14L954.14 591.86L919.01 571.58L883.87 551.29L744.51 470.83L883.87 551.29L919.01 571.58L954.14 591.86L989.27 612.14L1024.39 632.42L1059.52 652.7L1094.65 672.99L1129.79 693.27L1164.92 713.55L1200.04 733.83L1235.17 754.11L1270.3 774.4L1305.43 794.68L1340.57 814.97L1375.7 835.25L1410.82 855.52L1445.95 875.81L1481.08 896.09L1516.21 916.38L1551.34 936.66L1586.48 956.94L1621.6 977.22L1656.73 997.5L1691.86 1017.79L1726.99 1038.07L1762.12 1058.35L1797.26 1078.64L1832.38 1098.91L1867.51 1119.2L1902.64 1139.48L1937.77 1159.76L1972.9 1180.05L2008.04 1200.33L2043.16 1220.61L2078.29 1240.89L2113.42 1261.17L2148.55 1281.46L2183.68 1301.74L2218.8 1322.02L2253.94 1342.3L2289.07 1362.58L2324.2 1382.87L2463.56 1463.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2498.47 1443.04L2359.1 1362.58L2323.97 1342.3L2288.84 1322.02L2253.71 1301.73L2218.59 1281.46L2183.46 1261.17L2148.32 1240.89L2113.19 1220.61L2078.06 1200.32L2042.94 1180.05L2007.81 1159.76L1972.68 1139.48L1937.54 1119.2L1902.41 1098.91L1867.28 1078.63L1832.16 1058.35L1797.03 1038.07L1761.9 1017.79L1726.76 997.5L1691.63 977.22L1656.5 956.93L1621.38 936.66L1586.25 916.37L1551.12 896.09L1515.98 875.81L1480.85 855.52L1445.72 835.24L1410.6 814.96L1375.47 794.68L1340.34 774.4L1305.2 754.11L1270.07 733.83L1234.94 713.55L1199.82 693.27L1164.69 672.99L1129.56 652.7L1094.43 632.42L1059.29 612.14L1024.17 591.86L989.04 571.58L953.91 551.29L918.78 531.01L779.41 450.55L918.78 531.01L953.91 551.29L989.04 571.58L1024.17 591.86L1059.29 612.14L1094.43 632.42L1129.56 652.7L1164.69 672.99L1199.82 693.27L1234.94 713.55L1270.07 733.83L1305.2 754.11L1340.34 774.4L1375.47 794.68L1410.6 814.96L1445.72 835.24L1480.85 855.52L1515.98 875.81L1551.12 896.09L1586.25 916.37L1621.38 936.66L1656.5 956.93L1691.63 977.22L1726.76 997.5L1761.9 1017.79L1797.03 1038.07L1832.16 1058.35L1867.28 1078.63L1902.41 1098.91L1937.54 1119.2L1972.68 1139.48L2007.81 1159.76L2042.94 1180.05L2078.06 1200.32L2113.19 1220.61L2148.32 1240.89L2183.46 1261.17L2218.59 1281.46L2253.71 1301.73L2288.84 1322.02L2323.97 1342.3L2359.1 1362.58L2498.47 1443.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2533.37 1422.76L2394.01 1342.3L2358.87 1322.02L2323.74 1301.73L2288.61 1281.45L2253.49 1261.17L2218.36 1240.89L2183.23 1220.61L2148.09 1200.32L2112.96 1180.04L2077.84 1159.76L2042.71 1139.48L2007.58 1119.2L1972.45 1098.91L1937.32 1078.63L1902.18 1058.34L1867.06 1038.07L1831.93 1017.78L1796.8 997.5L1761.67 977.22L1726.54 956.93L1691.4 936.65L1656.28 916.37L1621.15 896.09L1586.02 875.81L1550.89 855.52L1515.76 835.24L1480.62 814.96L1445.5 794.68L1410.37 774.4L1375.24 754.11L1340.11 733.83L1304.98 713.55L1269.84 693.26L1234.72 672.99L1199.59 652.7L1164.46 632.42L1129.33 612.14L1094.2 591.85L1059.08 571.58L1023.95 551.29L988.81 531.01L953.68 510.73L814.32 430.27L953.68 510.73L988.81 531.01L1023.95 551.29L1059.08 571.58L1094.2 591.85L1129.33 612.14L1164.46 632.42L1199.59 652.7L1234.72 672.99L1269.84 693.26L1304.98 713.55L1340.11 733.83L1375.24 754.11L1410.37 774.4L1445.5 794.68L1480.62 814.96L1515.76 835.24L1550.89 855.52L1586.02 875.81L1621.15 896.09L1656.28 916.37L1691.4 936.65L1726.54 956.93L1761.67 977.22L1796.8 997.5L1831.93 1017.78L1867.06 1038.07L1902.18 1058.34L1937.32 1078.63L1972.45 1098.91L2007.58 1119.2L2042.71 1139.48L2077.84 1159.76L2112.96 1180.04L2148.09 1200.32L2183.23 1220.61L2218.36 1240.89L2253.49 1261.17L2288.61 1281.45L2323.74 1301.73L2358.87 1322.02L2394.01 1342.3L2533.37 1422.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2568.27 1402.48L2428.91 1322.02L2393.78 1301.73L2358.65 1281.45L2323.51 1261.16L2288.39 1240.89L2253.26 1220.6L2218.13 1200.32L2183 1180.04L2147.87 1159.75L2112.75 1139.48L2077.61 1119.19L2042.48 1098.91L2007.35 1078.63L1972.22 1058.34L1937.09 1038.06L1901.97 1017.78L1866.84 997.5L1831.7 977.22L1796.57 956.93L1761.44 936.65L1726.31 916.37L1691.19 896.09L1656.06 875.81L1620.92 855.52L1585.79 835.24L1550.66 814.96L1515.53 794.67L1480.41 774.4L1445.28 754.11L1410.14 733.83L1375.01 713.55L1339.88 693.26L1304.75 672.98L1269.63 652.7L1234.5 632.42L1199.36 612.14L1164.23 591.85L1129.1 571.57L1093.98 551.29L1058.85 531.01L1023.72 510.73L988.58 490.44L849.22 409.98L988.58 490.44L1023.72 510.73L1058.85 531.01L1093.98 551.29L1129.1 571.57L1164.23 591.85L1199.36 612.14L1234.5 632.42L1269.63 652.7L1304.75 672.98L1339.88 693.26L1375.01 713.55L1410.14 733.83L1445.28 754.11L1480.41 774.4L1515.53 794.67L1550.66 814.96L1585.79 835.24L1620.92 855.52L1656.06 875.81L1691.19 896.09L1726.31 916.37L1761.44 936.65L1796.57 956.93L1831.7 977.22L1866.84 997.5L1901.97 1017.78L1937.09 1038.06L1972.22 1058.34L2007.35 1078.63L2042.48 1098.91L2077.61 1119.19L2112.75 1139.48L2147.87 1159.75L2183 1180.04L2218.13 1200.32L2253.26 1220.6L2288.39 1240.89L2323.51 1261.16L2358.65 1281.45L2393.78 1301.73L2428.91 1322.02L2568.27 1402.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2603.18 1382.19L2463.81 1301.73L2428.68 1281.45L2393.55 1261.16L2358.42 1240.88L2323.3 1220.6L2288.17 1200.32L2253.03 1180.04L2217.9 1159.75L2182.77 1139.47L2147.65 1119.19L2112.52 1098.91L2077.39 1078.63L2042.25 1058.34L2007.12 1038.06L1971.99 1017.78L1936.87 997.5L1901.74 977.22L1866.61 956.93L1831.47 936.65L1796.34 916.37L1761.21 896.08L1726.09 875.81L1690.96 855.52L1655.83 835.24L1620.7 814.96L1585.56 794.67L1550.43 774.39L1515.31 754.11L1480.18 733.83L1445.05 713.55L1409.92 693.26L1374.78 672.98L1339.65 652.7L1304.53 632.42L1269.4 612.14L1234.27 591.85L1199.14 571.57L1164 551.29L1128.88 531.01L1093.75 510.73L1058.62 490.44L1023.49 470.16L884.13 389.7L1023.49 470.16L1058.62 490.44L1093.75 510.73L1128.88 531.01L1164 551.29L1199.14 571.57L1234.27 591.85L1269.4 612.14L1304.53 632.42L1339.65 652.7L1374.78 672.98L1409.92 693.26L1445.05 713.55L1480.18 733.83L1515.31 754.11L1550.43 774.39L1585.56 794.67L1620.7 814.96L1655.83 835.24L1690.96 855.52L1726.09 875.81L1761.21 896.08L1796.34 916.37L1831.47 936.65L1866.61 956.93L1901.74 977.22L1936.87 997.5L1971.99 1017.78L2007.12 1038.06L2042.25 1058.34L2077.39 1078.63L2112.52 1098.91L2147.65 1119.19L2182.77 1139.47L2217.9 1159.75L2253.03 1180.04L2288.17 1200.32L2323.3 1220.6L2358.42 1240.88L2393.55 1261.16L2428.68 1281.45L2463.81 1301.73L2603.18 1382.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2638.07 1361.92L2498.71 1281.45L2463.57 1261.17L2428.44 1240.89L2393.31 1220.6L2358.19 1200.33L2323.06 1180.04L2287.93 1159.76L2252.79 1139.48L2217.66 1119.19L2182.54 1098.92L2147.41 1078.63L2112.28 1058.35L2077.15 1038.07L2042.01 1017.78L2006.88 997.5L1971.76 977.22L1936.63 956.94L1901.5 936.66L1866.37 916.37L1831.23 896.09L1796.1 875.81L1760.98 855.53L1725.85 835.25L1690.72 814.96L1655.59 794.68L1620.46 774.4L1585.32 754.11L1550.2 733.84L1515.07 713.55L1479.94 693.27L1444.81 672.99L1409.68 652.7L1374.54 632.42L1339.42 612.14L1304.29 591.86L1269.16 571.58L1234.03 551.29L1198.9 531.01L1163.78 510.73L1128.64 490.45L1093.51 470.17L1058.38 449.88L919.02 369.42L1058.38 449.88L1093.51 470.17L1128.64 490.45L1163.78 510.73L1198.9 531.01L1234.03 551.29L1269.16 571.58L1304.29 591.86L1339.42 612.14L1374.54 632.42L1409.68 652.7L1444.81 672.99L1479.94 693.27L1515.07 713.55L1550.2 733.84L1585.32 754.11L1620.46 774.4L1655.59 794.68L1690.72 814.96L1725.85 835.25L1760.98 855.53L1796.1 875.81L1831.23 896.09L1866.37 916.37L1901.5 936.66L1936.63 956.94L1971.76 977.22L2006.88 997.5L2042.01 1017.78L2077.15 1038.07L2112.28 1058.35L2147.41 1078.63L2182.54 1098.92L2217.66 1119.19L2252.79 1139.48L2287.93 1159.76L2323.06 1180.04L2358.19 1200.33L2393.31 1220.6L2428.44 1240.89L2463.57 1261.17L2498.71 1281.45L2638.07 1361.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2672.97 1341.63L2533.61 1261.17L2498.48 1240.89L2463.34 1220.6L2428.21 1200.32L2393.09 1180.04L2357.96 1159.76L2322.83 1139.48L2287.7 1119.19L2252.57 1098.91L2217.45 1078.63L2182.31 1058.35L2147.18 1038.07L2112.05 1017.78L2076.92 997.5L2041.79 977.22L2006.67 956.94L1971.53 936.66L1936.4 916.37L1901.27 896.09L1866.14 875.81L1831.01 855.52L1795.89 835.25L1760.75 814.96L1725.62 794.68L1690.49 774.4L1655.36 754.11L1620.23 733.83L1585.11 713.55L1549.97 693.27L1514.84 672.99L1479.71 652.7L1444.58 632.42L1409.45 612.14L1374.33 591.86L1339.2 571.58L1304.06 551.29L1268.93 531.01L1233.8 510.73L1198.68 490.45L1163.55 470.17L1128.42 449.88L1093.28 429.6L953.92 349.14L1093.28 429.6L1128.42 449.88L1163.55 470.17L1198.68 490.45L1233.8 510.73L1268.93 531.01L1304.06 551.29L1339.2 571.58L1374.33 591.86L1409.45 612.14L1444.58 632.42L1479.71 652.7L1514.84 672.99L1549.97 693.27L1585.11 713.55L1620.23 733.83L1655.36 754.11L1690.49 774.4L1725.62 794.68L1760.75 814.96L1795.89 835.25L1831.01 855.52L1866.14 875.81L1901.27 896.09L1936.4 916.37L1971.53 936.66L2006.67 956.94L2041.79 977.22L2076.92 997.5L2112.05 1017.78L2147.18 1038.07L2182.31 1058.35L2217.45 1078.63L2252.57 1098.91L2287.7 1119.19L2322.83 1139.48L2357.96 1159.76L2393.09 1180.04L2428.21 1200.32L2463.34 1220.6L2498.48 1240.89L2533.61 1261.17L2672.97 1341.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2707.88 1321.35L2568.51 1240.89L2533.38 1220.6L2498.25 1200.32L2463.12 1180.04L2428 1159.76L2392.86 1139.48L2357.73 1119.19L2322.6 1098.91L2287.47 1078.63L2252.35 1058.35L2217.22 1038.07L2182.09 1017.78L2146.95 997.5L2111.82 977.22L2076.69 956.93L2041.57 936.66L2006.44 916.37L1971.31 896.09L1936.17 875.81L1901.04 855.52L1865.91 835.24L1830.79 814.96L1795.66 794.68L1760.53 774.4L1725.39 754.11L1690.26 733.83L1655.13 713.55L1620.01 693.27L1584.88 672.99L1549.75 652.7L1514.61 632.42L1479.48 612.14L1444.35 591.85L1409.23 571.58L1374.1 551.29L1338.97 531.01L1303.83 510.73L1268.7 490.44L1233.58 470.17L1198.45 449.88L1163.32 429.6L1128.19 409.32L988.82 328.85L1128.19 409.32L1163.32 429.6L1198.45 449.88L1233.58 470.17L1268.7 490.44L1303.83 510.73L1338.97 531.01L1374.1 551.29L1409.23 571.58L1444.35 591.85L1479.48 612.14L1514.61 632.42L1549.75 652.7L1584.88 672.99L1620.01 693.27L1655.13 713.55L1690.26 733.83L1725.39 754.11L1760.53 774.4L1795.66 794.68L1830.79 814.96L1865.91 835.24L1901.04 855.52L1936.17 875.81L1971.31 896.09L2006.44 916.37L2041.57 936.66L2076.69 956.93L2111.82 977.22L2146.95 997.5L2182.09 1017.78L2217.22 1038.07L2252.35 1058.35L2287.47 1078.63L2322.6 1098.91L2357.73 1119.19L2392.86 1139.48L2428 1159.76L2463.12 1180.04L2498.25 1200.32L2533.38 1220.6L2568.51 1240.89L2707.88 1321.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2742.78 1301.06L2603.42 1220.6L2568.28 1200.32L2533.15 1180.04L2498.02 1159.75L2462.9 1139.48L2427.77 1119.19L2392.64 1098.91L2357.5 1078.63L2322.37 1058.34L2287.25 1038.07L2252.12 1017.78L2216.99 997.5L2181.86 977.22L2146.72 956.93L2111.59 936.65L2076.47 916.37L2041.34 896.09L2006.21 875.81L1971.08 855.52L1935.95 835.24L1900.81 814.96L1865.69 794.68L1830.56 774.4L1795.43 754.11L1760.3 733.83L1725.17 713.55L1690.03 693.26L1654.91 672.99L1619.78 652.7L1584.65 632.42L1549.52 612.14L1514.39 591.85L1479.25 571.57L1444.13 551.29L1409 531.01L1373.87 510.72L1338.74 490.44L1303.61 470.16L1268.49 449.88L1233.35 429.6L1198.22 409.31L1163.09 389.03L1023.73 308.57L1163.09 389.03L1198.22 409.31L1233.35 429.6L1268.49 449.88L1303.61 470.16L1338.74 490.44L1373.87 510.72L1409 531.01L1444.13 551.29L1479.25 571.57L1514.39 591.85L1549.52 612.14L1584.65 632.42L1619.78 652.7L1654.91 672.99L1690.03 693.26L1725.17 713.55L1760.3 733.83L1795.43 754.11L1830.56 774.4L1865.69 794.68L1900.81 814.96L1935.95 835.24L1971.08 855.52L2006.21 875.81L2041.34 896.09L2076.47 916.37L2111.59 936.65L2146.72 956.93L2181.86 977.22L2216.99 997.5L2252.12 1017.78L2287.25 1038.07L2322.37 1058.34L2357.5 1078.63L2392.64 1098.91L2427.77 1119.19L2462.9 1139.48L2498.02 1159.75L2533.15 1180.04L2568.28 1200.32L2603.42 1220.6L2742.78 1301.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2777.68 1280.78L2638.32 1200.32L2603.19 1180.04L2568.06 1159.75L2532.92 1139.47L2497.8 1119.19L2462.67 1098.91L2427.54 1078.63L2392.41 1058.34L2357.28 1038.06L2322.16 1017.78L2287.02 997.5L2251.89 977.22L2216.76 956.93L2181.63 936.65L2146.5 916.37L2111.38 896.09L2076.24 875.81L2041.11 855.52L2005.98 835.24L1970.85 814.96L1935.72 794.67L1900.6 774.4L1865.47 754.11L1830.33 733.83L1795.2 713.54L1760.07 693.26L1724.94 672.98L1689.82 652.7L1654.69 632.42L1619.55 612.13L1584.42 591.85L1549.29 571.57L1514.16 551.28L1479.04 531.01L1443.91 510.72L1408.77 490.44L1373.64 470.16L1338.51 449.87L1303.39 429.6L1268.26 409.31L1233.13 389.03L1197.99 368.75L1058.63 288.29L1197.99 368.75L1233.13 389.03L1268.26 409.31L1303.39 429.6L1338.51 449.87L1373.64 470.16L1408.77 490.44L1443.91 510.72L1479.04 531.01L1514.16 551.28L1549.29 571.57L1584.42 591.85L1619.55 612.13L1654.69 632.42L1689.82 652.7L1724.94 672.98L1760.07 693.26L1795.2 713.54L1830.33 733.83L1865.47 754.11L1900.6 774.4L1935.72 794.67L1970.85 814.96L2005.98 835.24L2041.11 855.52L2076.24 875.81L2111.38 896.09L2146.5 916.37L2181.63 936.65L2216.76 956.93L2251.89 977.22L2287.02 997.5L2322.16 1017.78L2357.28 1038.06L2392.41 1058.34L2427.54 1078.63L2462.67 1098.91L2497.8 1119.19L2532.92 1139.47L2568.06 1159.75L2603.19 1180.04L2638.32 1200.32L2777.68 1280.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M26.12 678.44L-113.25 597.98L-148.38 577.69L-183.51 557.41L-218.64 537.13L-253.76 516.85L-288.89 496.57L-324.03 476.28L-359.16 456L-394.29 435.72L-429.41 415.44L-464.54 395.16L-499.67 374.87L-534.81 354.59L-569.94 334.31L-605.07 314.02L-640.19 293.75L-675.32 273.46L-710.45 253.18L-745.59 232.9L-780.72 212.61L-815.85 192.33L-850.97 172.05L-886.1 151.77L-921.23 131.49L-956.36 111.2L-991.5 90.9199L-1026.63 70.64L-1061.75 50.3599L-1096.88 30.0799L-1132.01 9.78995L-1167.14 -10.4901L-1202.28 -30.7701L-1237.41 -51.0601L-1272.53 -71.3301L-1307.66 -91.6201L-1342.79 -111.9L-1377.92 -132.19L-1413.06 -152.47L-1448.18 -172.75L-1483.31 -193.03L-1518.44 -213.31L-1553.57 -233.6L-1692.94 -314.06L-1553.57 -233.6L-1518.44 -213.31L-1483.31 -193.03L-1448.18 -172.75L-1413.06 -152.47L-1377.92 -132.19L-1342.79 -111.9L-1307.66 -91.6201L-1272.53 -71.3301L-1237.41 -51.0601L-1202.28 -30.7701L-1167.14 -10.4901L-1132.01 9.78995L-1096.88 30.0799L-1061.75 50.3599L-1026.63 70.64L-991.5 90.9199L-956.36 111.2L-921.23 131.49L-886.1 151.77L-850.97 172.05L-815.85 192.33L-780.72 212.61L-745.59 232.9L-710.45 253.18L-675.32 273.46L-640.19 293.75L-605.07 314.02L-569.94 334.31L-534.81 354.59L-499.67 374.87L-464.54 395.16L-429.41 415.44L-394.29 435.72L-359.16 456L-324.03 476.28L-288.89 496.57L-253.76 516.85L-218.64 537.13L-183.51 557.41L-148.38 577.69L-113.25 597.98L26.12 678.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M61.02 658.15L-78.34 577.69L-113.48 557.41L-148.61 537.13L-183.74 516.84L-218.86 496.57L-253.99 476.28L-289.12 456L-324.25 435.72L-359.39 415.43L-394.51 395.16L-429.64 374.87L-464.77 354.59L-499.9 334.31L-535.03 314.02L-570.17 293.74L-605.29 273.46L-640.42 253.18L-675.55 232.9L-710.68 212.61L-745.81 192.33L-780.95 172.05L-816.07 151.77L-851.2 131.49L-886.33 111.2L-921.46 90.92L-956.59 70.63L-991.73 50.35L-1026.85 30.08L-1061.98 9.79004L-1097.11 -10.49L-1132.24 -30.78L-1167.37 -51.06L-1202.5 -71.34L-1237.62 -91.62L-1272.76 -111.9L-1307.89 -132.19L-1343.02 -152.47L-1378.15 -172.75L-1413.27 -193.03L-1448.4 -213.31L-1483.54 -233.6L-1518.67 -253.88L-1658.03 -334.34L-1518.67 -253.88L-1483.54 -233.6L-1448.4 -213.31L-1413.27 -193.03L-1378.15 -172.75L-1343.02 -152.47L-1307.89 -132.19L-1272.76 -111.9L-1237.62 -91.62L-1202.5 -71.34L-1167.37 -51.06L-1132.24 -30.78L-1097.11 -10.49L-1061.98 9.79004L-1026.85 30.08L-991.73 50.35L-956.59 70.63L-921.46 90.92L-886.33 111.2L-851.2 131.49L-816.07 151.77L-780.95 172.05L-745.81 192.33L-710.68 212.61L-675.55 232.9L-640.42 253.18L-605.29 273.46L-570.17 293.74L-535.03 314.02L-499.9 334.31L-464.77 354.59L-429.64 374.87L-394.51 395.16L-359.39 415.43L-324.25 435.72L-289.12 456L-253.99 476.28L-218.86 496.57L-183.74 516.84L-148.61 537.13L-113.48 557.41L-78.34 577.69L61.02 658.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M95.9202 637.87L-43.4398 557.41L-78.5698 537.13L-113.7 516.84L-148.84 496.56L-183.96 476.28L-219.09 456L-254.22 435.72L-289.35 415.43L-324.48 395.15L-359.6 374.87L-394.73 354.59L-429.87 334.31L-465 314.02L-500.13 293.74L-535.26 273.46L-570.38 253.18L-605.51 232.9L-640.65 212.61L-675.78 192.33L-710.91 172.04L-746.04 151.76L-781.16 131.48L-816.29 111.2L-851.43 90.92L-886.56 70.63L-921.69 50.35L-956.82 30.07L-991.94 9.79001L-1027.07 -10.49L-1062.21 -30.78L-1097.34 -51.06L-1132.47 -71.34L-1167.6 -91.63L-1202.72 -111.9L-1237.85 -132.19L-1272.98 -152.47L-1308.12 -172.75L-1343.25 -193.04L-1378.37 -213.31L-1413.5 -233.6L-1448.63 -253.88L-1483.76 -274.16L-1623.13 -354.62L-1483.76 -274.16L-1448.63 -253.88L-1413.5 -233.6L-1378.37 -213.31L-1343.25 -193.04L-1308.12 -172.75L-1272.98 -152.47L-1237.85 -132.19L-1202.72 -111.9L-1167.6 -91.63L-1132.47 -71.34L-1097.34 -51.06L-1062.21 -30.78L-1027.07 -10.49L-991.94 9.79001L-956.82 30.07L-921.69 50.35L-886.56 70.63L-851.43 90.92L-816.29 111.2L-781.16 131.48L-746.04 151.76L-710.91 172.04L-675.78 192.33L-640.65 212.61L-605.51 232.9L-570.38 253.18L-535.26 273.46L-500.13 293.74L-465 314.02L-429.87 334.31L-394.73 354.59L-359.6 374.87L-324.48 395.15L-289.35 415.43L-254.22 435.72L-219.09 456L-183.96 476.28L-148.84 496.56L-113.7 516.84L-78.5698 537.13L-43.4398 557.41L95.9202 637.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_1566">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* Achter & Zij gevels card */
/* en om onbekende reden ook Verwarming card */
export const step18Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_1566)">
      <path
        d="M145.76 808.23L459.63 990.94L460.62 465.71L142.8 283.13L145.76 808.23Z"
        fill="#71CFEC"
      />
      <path
        d="M142.8 283.13L460.62 465.71L664.55 215.98L350.68 33.26L142.8 283.13Z"
        fill="#00545E"
      />
      <path
        d="M350.68 33.26L664.55 215.98L839.25 282.29L493.2 82.96L350.68 33.26Z"
        fill="#003D42"
      />
      <path
        d="M842.2 755.34L839.25 282.29L664.55 215.98L492.95 426.12V565.95L842.2 755.34Z"
        fill="#BBE5F0"
      />
      <mask
        id="mask0_68_1566"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="312"
        y="596"
        width="71"
        height="190"
      >
        <path
          d="M382.47 785.8L382.9 633.97L312.95 596.1L312.52 747.93L382.47 785.8Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_1566)">
        <path
          d="M312.95 596.1L382.9 633.97L382.47 785.8L312.52 747.93L312.95 596.1Z"
          fill="#BBE5F0"
        />
      </g>
      <mask
        id="mask1_68_1566"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="174"
        y="520"
        width="71"
        height="191"
      >
        <path
          d="M244.04 710.1L244.47 558.27L174.52 520.4L174.09 672.24L244.04 710.1Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_68_1566)">
        <path
          d="M174.52 520.4L244.47 558.27L244.04 710.1L174.09 672.24L174.52 520.4Z"
          fill="#BBE5F0"
        />
      </g>
      <path
        d="M459.63 990.94L460.62 465.71"
        stroke="#71CFEC"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M492.95 426.12L460.62 465.71L460.39 585.97L492.95 565.95V426.12Z"
        fill="#00A3DE"
      />
      <path
        d="M842.2 755.34L492.95 565.95L460.39 585.97L459.63 990.94L842.2 755.34Z"
        fill="#00545E"
      />
      <path
        d="M526.77 1031.92L553.06 1046.74L554.06 519.4L523.8 504.57L526.77 1031.92Z"
        fill="#F8B8C4"
      />
      <path
        d="M523.8 504.57L554.06 519.4L758.82 276.01L732.53 261.19L523.8 504.57Z"
        fill="#940731"
      />
      <path
        d="M934.24 336.85L758.82 276.01L554.06 519.4L553.06 1046.74L937.2 823.98L934.24 336.85Z"
        fill="#CD163F"
      />
      <path
        d="M732.53 261.19L758.82 276.01L934.24 336.85L732.53 261.19Z"
        fill="#940731"
      />
      <path
        d="M523.8 504.57L554.06 519.4L758.82 276.01"
        stroke="#CD163F"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M553.06 1046.74L554.06 519.4"
        stroke="#CD163F"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M633.52 997.97L735.52 937.97L735.97 653.22L633.78 713.94L633.52 997.97Z"
        fill="#940731"
      />
      <path
        d="M855.91 586.98L785.68 627.53L786.11 779.96L856.35 739.41L855.91 586.98Z"
        fill="#940731"
      />
      <path
        opacity="0.3"
        d="M2323.96 1544.46L2184.6 1463.99L2149.46 1443.71L2114.33 1423.43L2079.2 1403.14L2044.08 1382.87L2008.95 1362.58L1973.82 1342.3L1938.69 1322.02L1903.55 1301.73L1868.43 1281.46L1833.3 1261.17L1798.17 1240.89L1763.04 1220.61L1727.91 1200.32L1692.77 1180.04L1657.65 1159.76L1622.52 1139.48L1587.39 1119.2L1552.26 1098.91L1517.13 1078.63L1481.99 1058.35L1446.87 1038.07L1411.74 1017.79L1376.61 997.5L1341.48 977.22L1306.35 956.94L1271.21 936.65L1236.09 916.38L1200.96 896.09L1165.83 875.81L1130.7 855.53L1095.57 835.24L1060.44 814.96L1025.32 794.68L990.18 774.4L955.05 754.12L919.92 733.83L884.79 713.55L849.67 693.27L814.54 672.99L779.4 652.71L744.27 632.42L604.91 551.96L744.27 632.42L779.4 652.71L814.54 672.99L849.67 693.27L884.79 713.55L919.92 733.83L955.05 754.12L990.18 774.4L1025.32 794.68L1060.44 814.96L1095.57 835.24L1130.7 855.53L1165.83 875.81L1200.96 896.09L1236.09 916.38L1271.21 936.65L1306.35 956.94L1341.48 977.22L1376.61 997.5L1411.74 1017.79L1446.87 1038.07L1481.99 1058.35L1517.13 1078.63L1552.26 1098.91L1587.39 1119.2L1622.52 1139.48L1657.65 1159.76L1692.77 1180.04L1727.91 1200.32L1763.04 1220.61L1798.17 1240.89L1833.3 1261.17L1868.43 1281.46L1903.55 1301.73L1938.69 1322.02L1973.82 1342.3L2008.95 1362.58L2044.08 1382.87L2079.2 1403.14L2114.33 1423.43L2149.46 1443.71L2184.6 1463.99L2323.96 1544.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2358.86 1524.17L2219.5 1443.71L2184.37 1423.43L2149.24 1403.14L2114.1 1382.86L2078.98 1362.58L2043.85 1342.3L2008.72 1322.02L1973.59 1301.73L1938.46 1281.45L1903.34 1261.17L1868.2 1240.89L1833.07 1220.61L1797.94 1200.32L1762.81 1180.04L1727.68 1159.76L1692.56 1139.48L1657.43 1119.2L1622.29 1098.91L1587.16 1078.63L1552.03 1058.35L1516.9 1038.06L1481.78 1017.79L1446.65 997.5L1411.51 977.22L1376.38 956.94L1341.25 936.65L1306.12 916.37L1271 896.09L1235.87 875.81L1200.73 855.53L1165.6 835.24L1130.47 814.96L1095.34 794.68L1060.22 774.4L1025.09 754.12L989.95 733.83L954.82 713.55L919.69 693.27L884.57 672.99L849.44 652.71L814.31 632.42L779.18 612.14L639.81 531.68L779.18 612.14L814.31 632.42L849.44 652.71L884.57 672.99L919.69 693.27L954.82 713.55L989.95 733.83L1025.09 754.12L1060.22 774.4L1095.34 794.68L1130.47 814.96L1165.6 835.24L1200.73 855.53L1235.87 875.81L1271 896.09L1306.12 916.37L1341.25 936.65L1376.38 956.94L1411.51 977.22L1446.65 997.5L1481.78 1017.79L1516.9 1038.06L1552.03 1058.35L1587.16 1078.63L1622.29 1098.91L1657.43 1119.2L1692.56 1139.48L1727.68 1159.76L1762.81 1180.04L1797.94 1200.32L1833.07 1220.61L1868.2 1240.89L1903.34 1261.17L1938.46 1281.45L1973.59 1301.73L2008.72 1322.02L2043.85 1342.3L2078.98 1362.58L2114.1 1382.86L2149.24 1403.14L2184.37 1423.43L2219.5 1443.71L2358.86 1524.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2393.77 1503.89L2254.4 1423.43L2219.27 1403.14L2184.14 1382.86L2149.01 1362.58L2113.89 1342.3L2078.76 1322.02L2043.62 1301.73L2008.49 1281.45L1973.36 1261.17L1938.24 1240.89L1903.11 1220.61L1867.98 1200.32L1832.84 1180.04L1797.71 1159.76L1762.58 1139.47L1727.46 1119.2L1692.33 1098.91L1657.2 1078.63L1622.07 1058.35L1586.93 1038.06L1551.8 1017.78L1516.68 997.5L1481.55 977.22L1446.42 956.94L1411.29 936.65L1376.15 916.37L1341.02 896.09L1305.9 875.81L1270.77 855.53L1235.64 835.24L1200.51 814.96L1165.37 794.68L1130.24 774.39L1095.12 754.12L1059.99 733.83L1024.86 713.55L989.73 693.27L954.59 672.98L919.47 652.71L884.34 632.42L849.21 612.14L814.08 591.85L674.72 511.39L814.08 591.85L849.21 612.14L884.34 632.42L919.47 652.71L954.59 672.98L989.73 693.27L1024.86 713.55L1059.99 733.83L1095.12 754.12L1130.24 774.39L1165.37 794.68L1200.51 814.96L1235.64 835.24L1270.77 855.53L1305.9 875.81L1341.02 896.09L1376.15 916.37L1411.29 936.65L1446.42 956.94L1481.55 977.22L1516.68 997.5L1551.8 1017.78L1586.93 1038.06L1622.07 1058.35L1657.2 1078.63L1692.33 1098.91L1727.46 1119.2L1762.58 1139.47L1797.71 1159.76L1832.84 1180.04L1867.98 1200.32L1903.11 1220.61L1938.24 1240.89L1973.36 1261.17L2008.49 1281.45L2043.62 1301.73L2078.76 1322.02L2113.89 1342.3L2149.01 1362.58L2184.14 1382.86L2219.27 1403.14L2254.4 1423.43L2393.77 1503.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2428.66 1483.61L2289.3 1403.15L2254.16 1382.87L2219.03 1362.58L2183.9 1342.3L2148.78 1322.02L2113.65 1301.74L2078.52 1281.46L2043.38 1261.17L2008.25 1240.89L1973.13 1220.61L1938 1200.33L1902.87 1180.05L1867.74 1159.76L1832.6 1139.48L1797.47 1119.2L1762.35 1098.92L1727.22 1078.64L1692.09 1058.35L1656.96 1038.07L1621.82 1017.79L1586.69 997.5L1551.57 977.23L1516.44 956.94L1481.31 936.66L1446.18 916.38L1411.05 896.09L1375.91 875.81L1340.79 855.53L1305.66 835.25L1270.53 814.97L1235.4 794.68L1200.27 774.4L1165.13 754.12L1130.01 733.84L1094.88 713.56L1059.75 693.27L1024.62 672.99L989.49 652.7L954.37 632.43L919.23 612.14L884.1 591.86L848.97 571.58L709.61 491.12L848.97 571.58L884.1 591.86L919.23 612.14L954.37 632.43L989.49 652.7L1024.62 672.99L1059.75 693.27L1094.88 713.56L1130.01 733.84L1165.13 754.12L1200.27 774.4L1235.4 794.68L1270.53 814.97L1305.66 835.25L1340.79 855.53L1375.91 875.81L1411.05 896.09L1446.18 916.38L1481.31 936.66L1516.44 956.94L1551.57 977.23L1586.69 997.5L1621.82 1017.79L1656.96 1038.07L1692.09 1058.35L1727.22 1078.64L1762.35 1098.92L1797.47 1119.2L1832.6 1139.48L1867.74 1159.76L1902.87 1180.05L1938 1200.33L1973.13 1220.61L2008.25 1240.89L2043.38 1261.17L2078.52 1281.46L2113.65 1301.74L2148.78 1322.02L2183.9 1342.3L2219.03 1362.58L2254.16 1382.87L2289.3 1403.15L2428.66 1483.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2463.56 1463.33L2324.2 1382.87L2289.07 1362.58L2253.94 1342.3L2218.8 1322.02L2183.68 1301.74L2148.55 1281.46L2113.42 1261.17L2078.29 1240.89L2043.16 1220.61L2008.04 1200.33L1972.9 1180.05L1937.77 1159.76L1902.64 1139.48L1867.51 1119.2L1832.38 1098.91L1797.26 1078.64L1762.12 1058.35L1726.99 1038.07L1691.86 1017.79L1656.73 997.5L1621.6 977.22L1586.48 956.94L1551.34 936.66L1516.21 916.38L1481.08 896.09L1445.95 875.81L1410.82 855.52L1375.7 835.25L1340.57 814.97L1305.43 794.68L1270.3 774.4L1235.17 754.11L1200.04 733.83L1164.92 713.55L1129.79 693.27L1094.65 672.99L1059.52 652.7L1024.39 632.42L989.27 612.14L954.14 591.86L919.01 571.58L883.87 551.29L744.51 470.83L883.87 551.29L919.01 571.58L954.14 591.86L989.27 612.14L1024.39 632.42L1059.52 652.7L1094.65 672.99L1129.79 693.27L1164.92 713.55L1200.04 733.83L1235.17 754.11L1270.3 774.4L1305.43 794.68L1340.57 814.97L1375.7 835.25L1410.82 855.52L1445.95 875.81L1481.08 896.09L1516.21 916.38L1551.34 936.66L1586.48 956.94L1621.6 977.22L1656.73 997.5L1691.86 1017.79L1726.99 1038.07L1762.12 1058.35L1797.26 1078.64L1832.38 1098.91L1867.51 1119.2L1902.64 1139.48L1937.77 1159.76L1972.9 1180.05L2008.04 1200.33L2043.16 1220.61L2078.29 1240.89L2113.42 1261.17L2148.55 1281.46L2183.68 1301.74L2218.8 1322.02L2253.94 1342.3L2289.07 1362.58L2324.2 1382.87L2463.56 1463.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2498.47 1443.04L2359.1 1362.58L2323.97 1342.3L2288.84 1322.02L2253.71 1301.73L2218.59 1281.46L2183.46 1261.17L2148.32 1240.89L2113.19 1220.61L2078.06 1200.32L2042.94 1180.05L2007.81 1159.76L1972.68 1139.48L1937.54 1119.2L1902.41 1098.91L1867.28 1078.63L1832.16 1058.35L1797.03 1038.07L1761.9 1017.79L1726.76 997.5L1691.63 977.22L1656.5 956.93L1621.38 936.66L1586.25 916.37L1551.12 896.09L1515.98 875.81L1480.85 855.52L1445.72 835.24L1410.6 814.96L1375.47 794.68L1340.34 774.4L1305.2 754.11L1270.07 733.83L1234.94 713.55L1199.82 693.27L1164.69 672.99L1129.56 652.7L1094.43 632.42L1059.29 612.14L1024.17 591.86L989.04 571.58L953.91 551.29L918.78 531.01L779.41 450.55L918.78 531.01L953.91 551.29L989.04 571.58L1024.17 591.86L1059.29 612.14L1094.43 632.42L1129.56 652.7L1164.69 672.99L1199.82 693.27L1234.94 713.55L1270.07 733.83L1305.2 754.11L1340.34 774.4L1375.47 794.68L1410.6 814.96L1445.72 835.24L1480.85 855.52L1515.98 875.81L1551.12 896.09L1586.25 916.37L1621.38 936.66L1656.5 956.93L1691.63 977.22L1726.76 997.5L1761.9 1017.79L1797.03 1038.07L1832.16 1058.35L1867.28 1078.63L1902.41 1098.91L1937.54 1119.2L1972.68 1139.48L2007.81 1159.76L2042.94 1180.05L2078.06 1200.32L2113.19 1220.61L2148.32 1240.89L2183.46 1261.17L2218.59 1281.46L2253.71 1301.73L2288.84 1322.02L2323.97 1342.3L2359.1 1362.58L2498.47 1443.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2533.37 1422.76L2394.01 1342.3L2358.87 1322.02L2323.74 1301.73L2288.61 1281.45L2253.49 1261.17L2218.36 1240.89L2183.23 1220.61L2148.09 1200.32L2112.96 1180.04L2077.84 1159.76L2042.71 1139.48L2007.58 1119.2L1972.45 1098.91L1937.32 1078.63L1902.18 1058.34L1867.06 1038.07L1831.93 1017.78L1796.8 997.5L1761.67 977.22L1726.54 956.93L1691.4 936.65L1656.28 916.37L1621.15 896.09L1586.02 875.81L1550.89 855.52L1515.76 835.24L1480.62 814.96L1445.5 794.68L1410.37 774.4L1375.24 754.11L1340.11 733.83L1304.98 713.55L1269.84 693.26L1234.72 672.99L1199.59 652.7L1164.46 632.42L1129.33 612.14L1094.2 591.85L1059.08 571.58L1023.95 551.29L988.81 531.01L953.68 510.73L814.32 430.27L953.68 510.73L988.81 531.01L1023.95 551.29L1059.08 571.58L1094.2 591.85L1129.33 612.14L1164.46 632.42L1199.59 652.7L1234.72 672.99L1269.84 693.26L1304.98 713.55L1340.11 733.83L1375.24 754.11L1410.37 774.4L1445.5 794.68L1480.62 814.96L1515.76 835.24L1550.89 855.52L1586.02 875.81L1621.15 896.09L1656.28 916.37L1691.4 936.65L1726.54 956.93L1761.67 977.22L1796.8 997.5L1831.93 1017.78L1867.06 1038.07L1902.18 1058.34L1937.32 1078.63L1972.45 1098.91L2007.58 1119.2L2042.71 1139.48L2077.84 1159.76L2112.96 1180.04L2148.09 1200.32L2183.23 1220.61L2218.36 1240.89L2253.49 1261.17L2288.61 1281.45L2323.74 1301.73L2358.87 1322.02L2394.01 1342.3L2533.37 1422.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2568.27 1402.48L2428.91 1322.02L2393.78 1301.73L2358.65 1281.45L2323.51 1261.16L2288.39 1240.89L2253.26 1220.6L2218.13 1200.32L2183 1180.04L2147.87 1159.75L2112.75 1139.48L2077.61 1119.19L2042.48 1098.91L2007.35 1078.63L1972.22 1058.34L1937.09 1038.06L1901.97 1017.78L1866.84 997.5L1831.7 977.22L1796.57 956.93L1761.44 936.65L1726.31 916.37L1691.19 896.09L1656.06 875.81L1620.92 855.52L1585.79 835.24L1550.66 814.96L1515.53 794.67L1480.41 774.4L1445.28 754.11L1410.14 733.83L1375.01 713.55L1339.88 693.26L1304.75 672.98L1269.63 652.7L1234.5 632.42L1199.36 612.14L1164.23 591.85L1129.1 571.57L1093.98 551.29L1058.85 531.01L1023.72 510.73L988.58 490.44L849.22 409.98L988.58 490.44L1023.72 510.73L1058.85 531.01L1093.98 551.29L1129.1 571.57L1164.23 591.85L1199.36 612.14L1234.5 632.42L1269.63 652.7L1304.75 672.98L1339.88 693.26L1375.01 713.55L1410.14 733.83L1445.28 754.11L1480.41 774.4L1515.53 794.67L1550.66 814.96L1585.79 835.24L1620.92 855.52L1656.06 875.81L1691.19 896.09L1726.31 916.37L1761.44 936.65L1796.57 956.93L1831.7 977.22L1866.84 997.5L1901.97 1017.78L1937.09 1038.06L1972.22 1058.34L2007.35 1078.63L2042.48 1098.91L2077.61 1119.19L2112.75 1139.48L2147.87 1159.75L2183 1180.04L2218.13 1200.32L2253.26 1220.6L2288.39 1240.89L2323.51 1261.16L2358.65 1281.45L2393.78 1301.73L2428.91 1322.02L2568.27 1402.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2603.18 1382.19L2463.81 1301.73L2428.68 1281.45L2393.55 1261.16L2358.42 1240.88L2323.3 1220.6L2288.17 1200.32L2253.03 1180.04L2217.9 1159.75L2182.77 1139.47L2147.65 1119.19L2112.52 1098.91L2077.39 1078.63L2042.25 1058.34L2007.12 1038.06L1971.99 1017.78L1936.87 997.5L1901.74 977.22L1866.61 956.93L1831.47 936.65L1796.34 916.37L1761.21 896.08L1726.09 875.81L1690.96 855.52L1655.83 835.24L1620.7 814.96L1585.56 794.67L1550.43 774.39L1515.31 754.11L1480.18 733.83L1445.05 713.55L1409.92 693.26L1374.78 672.98L1339.65 652.7L1304.53 632.42L1269.4 612.14L1234.27 591.85L1199.14 571.57L1164 551.29L1128.88 531.01L1093.75 510.73L1058.62 490.44L1023.49 470.16L884.13 389.7L1023.49 470.16L1058.62 490.44L1093.75 510.73L1128.88 531.01L1164 551.29L1199.14 571.57L1234.27 591.85L1269.4 612.14L1304.53 632.42L1339.65 652.7L1374.78 672.98L1409.92 693.26L1445.05 713.55L1480.18 733.83L1515.31 754.11L1550.43 774.39L1585.56 794.67L1620.7 814.96L1655.83 835.24L1690.96 855.52L1726.09 875.81L1761.21 896.08L1796.34 916.37L1831.47 936.65L1866.61 956.93L1901.74 977.22L1936.87 997.5L1971.99 1017.78L2007.12 1038.06L2042.25 1058.34L2077.39 1078.63L2112.52 1098.91L2147.65 1119.19L2182.77 1139.47L2217.9 1159.75L2253.03 1180.04L2288.17 1200.32L2323.3 1220.6L2358.42 1240.88L2393.55 1261.16L2428.68 1281.45L2463.81 1301.73L2603.18 1382.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2638.07 1361.92L2498.71 1281.45L2463.57 1261.17L2428.44 1240.89L2393.31 1220.6L2358.19 1200.33L2323.06 1180.04L2287.93 1159.76L2252.79 1139.48L2217.66 1119.19L2182.54 1098.92L2147.41 1078.63L2112.28 1058.35L2077.15 1038.07L2042.01 1017.78L2006.88 997.5L1971.76 977.22L1936.63 956.94L1901.5 936.66L1866.37 916.37L1831.23 896.09L1796.1 875.81L1760.98 855.53L1725.85 835.25L1690.72 814.96L1655.59 794.68L1620.46 774.4L1585.32 754.11L1550.2 733.84L1515.07 713.55L1479.94 693.27L1444.81 672.99L1409.68 652.7L1374.54 632.42L1339.42 612.14L1304.29 591.86L1269.16 571.58L1234.03 551.29L1198.9 531.01L1163.78 510.73L1128.64 490.45L1093.51 470.17L1058.38 449.88L919.02 369.42L1058.38 449.88L1093.51 470.17L1128.64 490.45L1163.78 510.73L1198.9 531.01L1234.03 551.29L1269.16 571.58L1304.29 591.86L1339.42 612.14L1374.54 632.42L1409.68 652.7L1444.81 672.99L1479.94 693.27L1515.07 713.55L1550.2 733.84L1585.32 754.11L1620.46 774.4L1655.59 794.68L1690.72 814.96L1725.85 835.25L1760.98 855.53L1796.1 875.81L1831.23 896.09L1866.37 916.37L1901.5 936.66L1936.63 956.94L1971.76 977.22L2006.88 997.5L2042.01 1017.78L2077.15 1038.07L2112.28 1058.35L2147.41 1078.63L2182.54 1098.92L2217.66 1119.19L2252.79 1139.48L2287.93 1159.76L2323.06 1180.04L2358.19 1200.33L2393.31 1220.6L2428.44 1240.89L2463.57 1261.17L2498.71 1281.45L2638.07 1361.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2672.97 1341.63L2533.61 1261.17L2498.48 1240.89L2463.34 1220.6L2428.21 1200.32L2393.09 1180.04L2357.96 1159.76L2322.83 1139.48L2287.7 1119.19L2252.57 1098.91L2217.45 1078.63L2182.31 1058.35L2147.18 1038.07L2112.05 1017.78L2076.92 997.5L2041.79 977.22L2006.67 956.94L1971.53 936.66L1936.4 916.37L1901.27 896.09L1866.14 875.81L1831.01 855.52L1795.89 835.25L1760.75 814.96L1725.62 794.68L1690.49 774.4L1655.36 754.11L1620.23 733.83L1585.11 713.55L1549.97 693.27L1514.84 672.99L1479.71 652.7L1444.58 632.42L1409.45 612.14L1374.33 591.86L1339.2 571.58L1304.06 551.29L1268.93 531.01L1233.8 510.73L1198.68 490.45L1163.55 470.17L1128.42 449.88L1093.28 429.6L953.92 349.14L1093.28 429.6L1128.42 449.88L1163.55 470.17L1198.68 490.45L1233.8 510.73L1268.93 531.01L1304.06 551.29L1339.2 571.58L1374.33 591.86L1409.45 612.14L1444.58 632.42L1479.71 652.7L1514.84 672.99L1549.97 693.27L1585.11 713.55L1620.23 733.83L1655.36 754.11L1690.49 774.4L1725.62 794.68L1760.75 814.96L1795.89 835.25L1831.01 855.52L1866.14 875.81L1901.27 896.09L1936.4 916.37L1971.53 936.66L2006.67 956.94L2041.79 977.22L2076.92 997.5L2112.05 1017.78L2147.18 1038.07L2182.31 1058.35L2217.45 1078.63L2252.57 1098.91L2287.7 1119.19L2322.83 1139.48L2357.96 1159.76L2393.09 1180.04L2428.21 1200.32L2463.34 1220.6L2498.48 1240.89L2533.61 1261.17L2672.97 1341.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2707.88 1321.35L2568.51 1240.89L2533.38 1220.6L2498.25 1200.32L2463.12 1180.04L2428 1159.76L2392.86 1139.48L2357.73 1119.19L2322.6 1098.91L2287.47 1078.63L2252.35 1058.35L2217.22 1038.07L2182.09 1017.78L2146.95 997.5L2111.82 977.22L2076.69 956.93L2041.57 936.66L2006.44 916.37L1971.31 896.09L1936.17 875.81L1901.04 855.52L1865.91 835.24L1830.79 814.96L1795.66 794.68L1760.53 774.4L1725.39 754.11L1690.26 733.83L1655.13 713.55L1620.01 693.27L1584.88 672.99L1549.75 652.7L1514.61 632.42L1479.48 612.14L1444.35 591.85L1409.23 571.58L1374.1 551.29L1338.97 531.01L1303.83 510.73L1268.7 490.44L1233.58 470.17L1198.45 449.88L1163.32 429.6L1128.19 409.32L988.82 328.85L1128.19 409.32L1163.32 429.6L1198.45 449.88L1233.58 470.17L1268.7 490.44L1303.83 510.73L1338.97 531.01L1374.1 551.29L1409.23 571.58L1444.35 591.85L1479.48 612.14L1514.61 632.42L1549.75 652.7L1584.88 672.99L1620.01 693.27L1655.13 713.55L1690.26 733.83L1725.39 754.11L1760.53 774.4L1795.66 794.68L1830.79 814.96L1865.91 835.24L1901.04 855.52L1936.17 875.81L1971.31 896.09L2006.44 916.37L2041.57 936.66L2076.69 956.93L2111.82 977.22L2146.95 997.5L2182.09 1017.78L2217.22 1038.07L2252.35 1058.35L2287.47 1078.63L2322.6 1098.91L2357.73 1119.19L2392.86 1139.48L2428 1159.76L2463.12 1180.04L2498.25 1200.32L2533.38 1220.6L2568.51 1240.89L2707.88 1321.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2742.78 1301.06L2603.42 1220.6L2568.28 1200.32L2533.15 1180.04L2498.02 1159.75L2462.9 1139.48L2427.77 1119.19L2392.64 1098.91L2357.5 1078.63L2322.37 1058.34L2287.25 1038.07L2252.12 1017.78L2216.99 997.5L2181.86 977.22L2146.72 956.93L2111.59 936.65L2076.47 916.37L2041.34 896.09L2006.21 875.81L1971.08 855.52L1935.95 835.24L1900.81 814.96L1865.69 794.68L1830.56 774.4L1795.43 754.11L1760.3 733.83L1725.17 713.55L1690.03 693.26L1654.91 672.99L1619.78 652.7L1584.65 632.42L1549.52 612.14L1514.39 591.85L1479.25 571.57L1444.13 551.29L1409 531.01L1373.87 510.72L1338.74 490.44L1303.61 470.16L1268.49 449.88L1233.35 429.6L1198.22 409.31L1163.09 389.03L1023.73 308.57L1163.09 389.03L1198.22 409.31L1233.35 429.6L1268.49 449.88L1303.61 470.16L1338.74 490.44L1373.87 510.72L1409 531.01L1444.13 551.29L1479.25 571.57L1514.39 591.85L1549.52 612.14L1584.65 632.42L1619.78 652.7L1654.91 672.99L1690.03 693.26L1725.17 713.55L1760.3 733.83L1795.43 754.11L1830.56 774.4L1865.69 794.68L1900.81 814.96L1935.95 835.24L1971.08 855.52L2006.21 875.81L2041.34 896.09L2076.47 916.37L2111.59 936.65L2146.72 956.93L2181.86 977.22L2216.99 997.5L2252.12 1017.78L2287.25 1038.07L2322.37 1058.34L2357.5 1078.63L2392.64 1098.91L2427.77 1119.19L2462.9 1139.48L2498.02 1159.75L2533.15 1180.04L2568.28 1200.32L2603.42 1220.6L2742.78 1301.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2777.68 1280.78L2638.32 1200.32L2603.19 1180.04L2568.06 1159.75L2532.92 1139.47L2497.8 1119.19L2462.67 1098.91L2427.54 1078.63L2392.41 1058.34L2357.28 1038.06L2322.16 1017.78L2287.02 997.5L2251.89 977.22L2216.76 956.93L2181.63 936.65L2146.5 916.37L2111.38 896.09L2076.24 875.81L2041.11 855.52L2005.98 835.24L1970.85 814.96L1935.72 794.67L1900.6 774.4L1865.47 754.11L1830.33 733.83L1795.2 713.54L1760.07 693.26L1724.94 672.98L1689.82 652.7L1654.69 632.42L1619.55 612.13L1584.42 591.85L1549.29 571.57L1514.16 551.28L1479.04 531.01L1443.91 510.72L1408.77 490.44L1373.64 470.16L1338.51 449.87L1303.39 429.6L1268.26 409.31L1233.13 389.03L1197.99 368.75L1058.63 288.29L1197.99 368.75L1233.13 389.03L1268.26 409.31L1303.39 429.6L1338.51 449.87L1373.64 470.16L1408.77 490.44L1443.91 510.72L1479.04 531.01L1514.16 551.28L1549.29 571.57L1584.42 591.85L1619.55 612.13L1654.69 632.42L1689.82 652.7L1724.94 672.98L1760.07 693.26L1795.2 713.54L1830.33 733.83L1865.47 754.11L1900.6 774.4L1935.72 794.67L1970.85 814.96L2005.98 835.24L2041.11 855.52L2076.24 875.81L2111.38 896.09L2146.5 916.37L2181.63 936.65L2216.76 956.93L2251.89 977.22L2287.02 997.5L2322.16 1017.78L2357.28 1038.06L2392.41 1058.34L2427.54 1078.63L2462.67 1098.91L2497.8 1119.19L2532.92 1139.47L2568.06 1159.75L2603.19 1180.04L2638.32 1200.32L2777.68 1280.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M26.12 678.44L-113.25 597.98L-148.38 577.69L-183.51 557.41L-218.64 537.13L-253.76 516.85L-288.89 496.57L-324.03 476.28L-359.16 456L-394.29 435.72L-429.41 415.44L-464.54 395.16L-499.67 374.87L-534.81 354.59L-569.94 334.31L-605.07 314.02L-640.19 293.75L-675.32 273.46L-710.45 253.18L-745.59 232.9L-780.72 212.61L-815.85 192.33L-850.97 172.05L-886.1 151.77L-921.23 131.49L-956.36 111.2L-991.5 90.9199L-1026.63 70.64L-1061.75 50.3599L-1096.88 30.0799L-1132.01 9.78995L-1167.14 -10.4901L-1202.28 -30.7701L-1237.41 -51.0601L-1272.53 -71.3301L-1307.66 -91.6201L-1342.79 -111.9L-1377.92 -132.19L-1413.06 -152.47L-1448.18 -172.75L-1483.31 -193.03L-1518.44 -213.31L-1553.57 -233.6L-1692.94 -314.06L-1553.57 -233.6L-1518.44 -213.31L-1483.31 -193.03L-1448.18 -172.75L-1413.06 -152.47L-1377.92 -132.19L-1342.79 -111.9L-1307.66 -91.6201L-1272.53 -71.3301L-1237.41 -51.0601L-1202.28 -30.7701L-1167.14 -10.4901L-1132.01 9.78995L-1096.88 30.0799L-1061.75 50.3599L-1026.63 70.64L-991.5 90.9199L-956.36 111.2L-921.23 131.49L-886.1 151.77L-850.97 172.05L-815.85 192.33L-780.72 212.61L-745.59 232.9L-710.45 253.18L-675.32 273.46L-640.19 293.75L-605.07 314.02L-569.94 334.31L-534.81 354.59L-499.67 374.87L-464.54 395.16L-429.41 415.44L-394.29 435.72L-359.16 456L-324.03 476.28L-288.89 496.57L-253.76 516.85L-218.64 537.13L-183.51 557.41L-148.38 577.69L-113.25 597.98L26.12 678.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M61.02 658.15L-78.34 577.69L-113.48 557.41L-148.61 537.13L-183.74 516.84L-218.86 496.57L-253.99 476.28L-289.12 456L-324.25 435.72L-359.39 415.43L-394.51 395.16L-429.64 374.87L-464.77 354.59L-499.9 334.31L-535.03 314.02L-570.17 293.74L-605.29 273.46L-640.42 253.18L-675.55 232.9L-710.68 212.61L-745.81 192.33L-780.95 172.05L-816.07 151.77L-851.2 131.49L-886.33 111.2L-921.46 90.92L-956.59 70.63L-991.73 50.35L-1026.85 30.08L-1061.98 9.79004L-1097.11 -10.49L-1132.24 -30.78L-1167.37 -51.06L-1202.5 -71.34L-1237.62 -91.62L-1272.76 -111.9L-1307.89 -132.19L-1343.02 -152.47L-1378.15 -172.75L-1413.27 -193.03L-1448.4 -213.31L-1483.54 -233.6L-1518.67 -253.88L-1658.03 -334.34L-1518.67 -253.88L-1483.54 -233.6L-1448.4 -213.31L-1413.27 -193.03L-1378.15 -172.75L-1343.02 -152.47L-1307.89 -132.19L-1272.76 -111.9L-1237.62 -91.62L-1202.5 -71.34L-1167.37 -51.06L-1132.24 -30.78L-1097.11 -10.49L-1061.98 9.79004L-1026.85 30.08L-991.73 50.35L-956.59 70.63L-921.46 90.92L-886.33 111.2L-851.2 131.49L-816.07 151.77L-780.95 172.05L-745.81 192.33L-710.68 212.61L-675.55 232.9L-640.42 253.18L-605.29 273.46L-570.17 293.74L-535.03 314.02L-499.9 334.31L-464.77 354.59L-429.64 374.87L-394.51 395.16L-359.39 415.43L-324.25 435.72L-289.12 456L-253.99 476.28L-218.86 496.57L-183.74 516.84L-148.61 537.13L-113.48 557.41L-78.34 577.69L61.02 658.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M95.9202 637.87L-43.4398 557.41L-78.5698 537.13L-113.7 516.84L-148.84 496.56L-183.96 476.28L-219.09 456L-254.22 435.72L-289.35 415.43L-324.48 395.15L-359.6 374.87L-394.73 354.59L-429.87 334.31L-465 314.02L-500.13 293.74L-535.26 273.46L-570.38 253.18L-605.51 232.9L-640.65 212.61L-675.78 192.33L-710.91 172.04L-746.04 151.76L-781.16 131.48L-816.29 111.2L-851.43 90.92L-886.56 70.63L-921.69 50.35L-956.82 30.07L-991.94 9.79001L-1027.07 -10.49L-1062.21 -30.78L-1097.34 -51.06L-1132.47 -71.34L-1167.6 -91.63L-1202.72 -111.9L-1237.85 -132.19L-1272.98 -152.47L-1308.12 -172.75L-1343.25 -193.04L-1378.37 -213.31L-1413.5 -233.6L-1448.63 -253.88L-1483.76 -274.16L-1623.13 -354.62L-1483.76 -274.16L-1448.63 -253.88L-1413.5 -233.6L-1378.37 -213.31L-1343.25 -193.04L-1308.12 -172.75L-1272.98 -152.47L-1237.85 -132.19L-1202.72 -111.9L-1167.6 -91.63L-1132.47 -71.34L-1097.34 -51.06L-1062.21 -30.78L-1027.07 -10.49L-991.94 9.79001L-956.82 30.07L-921.69 50.35L-886.56 70.63L-851.43 90.92L-816.29 111.2L-781.16 131.48L-746.04 151.76L-710.91 172.04L-675.78 192.33L-640.65 212.61L-605.51 232.9L-570.38 253.18L-535.26 273.46L-500.13 293.74L-465 314.02L-429.87 334.31L-394.73 354.59L-359.6 374.87L-324.48 395.15L-289.35 415.43L-254.22 435.72L-219.09 456L-183.96 476.28L-148.84 496.56L-113.7 516.84L-78.5698 537.13L-43.4398 557.41L95.9202 637.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_1566">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
/* Vloerisolatie card */
export const step20Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_5446)">
      <path
        d="M932.28 673.95L444.55 957.14L149.46 786.77L149.22 770.25L472.72 600.75L427.72 573.75L428.22 557.25L594.72 476.75L932.22 658.25L932.28 673.95Z"
        fill="#940731"
      />
      <path
        d="M594.72 460.75V199.16"
        stroke="#E8E6E0"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M427.72 557.75V296.16"
        stroke="#E8E6E0"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M932.28 657.95L444.55 941.14L149.46 770.77L472.72 584.75L427.72 557.75L594.72 460.75L932.28 657.95Z"
        fill="#CD163F"
      />
      <path
        d="M933.03 392.66L765.6 489.97L427.76 294.91L595.19 197.61L933.03 392.66Z"
        fill="#B3B4B3"
      />
      <path
        d="M151.01 334.71L441.38 500.5L440.56 934.22L153.45 768.45L151.01 334.71ZM146.97 327.8L149.46 770.77L444.55 941.14L445.39 498.18L146.97 327.8Z"
        fill="#E8E6E0"
      />
      <path
        d="M618.67 298.41L760.75 347.69L761.61 489.98L761.65 496.88L767.62 493.41L929 399.62L928.27 655.64L448.56 934.18L449.38 499.64L618.67 298.41ZM617.38 293.73L445.38 498.18L444.54 941.14L932.27 657.95L933.02 392.67L765.61 489.96L764.73 344.84L617.38 293.73Z"
        fill="#D7D3CC"
      />
      <path
        d="M146.97 327.3L445.39 497.68L617.38 293.23L322.3 122.86L146.97 327.3Z"
        fill="#B3B4B3"
      />
      <path
        d="M322.3 122.86L617.38 293.23L764.74 344.34L441.22 159.75L322.3 122.86Z"
        fill="#E8E6E0"
      />
      <path
        opacity="0.5"
        d="M532.86 890.11L618.54 839.71L618.92 600.52L533.08 651.52L532.86 890.11Z"
        fill="#E8E6E0"
      />
      <mask
        id="mask0_68_5446"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="660"
        y="544"
        width="61"
        height="163"
      >
        <path
          d="M661.04 706.98L660.68 578.93L719.67 544.87L720.04 672.92L661.04 706.98Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_5446)">
        <g opacity="0.5">
          <path
            d="M719.67 544.87L660.68 578.93L661.04 706.98L720.04 672.92L719.67 544.87Z"
            fill="#E8E6E0"
          />
        </g>
      </g>
      <path
        opacity="0.5"
        d="M379.47 765.78L379.84 637.73L320.84 603.67L320.48 731.72L379.47 765.78Z"
        fill="#E8E6E0"
      />
      <path
        d="M262.71 697.74L263.08 569.69L204.08 535.63L203.72 663.68L262.71 697.74Z"
        fill="#F3F2EF"
      />
      <g opacity="0.5">
        <path
          d="M908.57 453.25L802.22 514.65L802.88 637.31L909.22 575.91L908.57 453.25Z"
          fill="#E8E6E0"
        />
      </g>
      <path
        opacity="0.3"
        d="M672.96 1469.46L533.6 1388.99L498.46 1368.71L463.33 1348.43L428.2 1328.14L393.08 1307.87L357.95 1287.58L322.82 1267.3L287.69 1247.02L252.55 1226.73L217.43 1206.46L182.3 1186.17L147.17 1165.89L112.04 1145.61L76.9099 1125.32L41.7699 1105.04L6.6499 1084.76L-28.4801 1064.48L-63.6101 1044.2L-98.7401 1023.91L-133.87 1003.63L-169.01 983.35L-204.13 963.07L-239.26 942.79L-274.39 922.5L-309.52 902.22L-344.65 881.94L-379.79 861.65L-414.91 841.38L-450.04 821.09L-485.17 800.81L-520.3 780.53L-555.43 760.24L-590.56 739.96L-625.68 719.68L-660.82 699.4L-695.95 679.12L-731.08 658.83L-766.21 638.55L-801.33 618.27L-836.46 597.99L-871.6 577.71L-906.73 557.42L-1046.09 476.96L-906.73 557.42L-871.6 577.71L-836.46 597.99L-801.33 618.27L-766.21 638.55L-731.08 658.83L-695.95 679.12L-660.82 699.4L-625.68 719.68L-590.56 739.96L-555.43 760.24L-520.3 780.53L-485.17 800.81L-450.04 821.09L-414.91 841.38L-379.79 861.65L-344.65 881.94L-309.52 902.22L-274.39 922.5L-239.26 942.79L-204.13 963.07L-169.01 983.35L-133.87 1003.63L-98.7401 1023.91L-63.6101 1044.2L-28.4801 1064.48L6.6499 1084.76L41.7699 1105.04L76.9099 1125.32L112.04 1145.61L147.17 1165.89L182.3 1186.17L217.43 1206.46L252.55 1226.73L287.69 1247.02L322.82 1267.3L357.95 1287.58L393.08 1307.87L428.2 1328.14L463.33 1348.43L498.46 1368.71L533.6 1388.99L672.96 1469.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M707.86 1449.17L568.5 1368.71L533.37 1348.43L498.24 1328.14L463.1 1307.86L427.98 1287.58L392.85 1267.3L357.72 1247.02L322.59 1226.73L287.46 1206.45L252.34 1186.17L217.2 1165.89L182.07 1145.61L146.94 1125.32L111.81 1105.04L76.6798 1084.76L41.5598 1064.48L6.42981 1044.2L-28.7102 1023.91L-63.8402 1003.63L-98.9702 983.35L-134.1 963.06L-169.22 942.79L-204.35 922.5L-239.49 902.22L-274.62 881.94L-309.75 861.65L-344.88 841.37L-380 821.09L-415.13 800.81L-450.27 780.53L-485.4 760.24L-520.53 739.96L-555.66 719.68L-590.78 699.4L-625.91 679.12L-661.05 658.83L-696.18 638.55L-731.31 618.27L-766.43 597.99L-801.56 577.71L-836.69 557.42L-871.82 537.14L-1011.19 456.68L-871.82 537.14L-836.69 557.42L-801.56 577.71L-766.43 597.99L-731.31 618.27L-696.18 638.55L-661.05 658.83L-625.91 679.12L-590.78 699.4L-555.66 719.68L-520.53 739.96L-485.4 760.24L-450.27 780.53L-415.13 800.81L-380 821.09L-344.88 841.37L-309.75 861.65L-274.62 881.94L-239.49 902.22L-204.35 922.5L-169.22 942.79L-134.1 963.06L-98.9702 983.35L-63.8402 1003.63L-28.7102 1023.91L6.42981 1044.2L41.5598 1064.48L76.6798 1084.76L111.81 1105.04L146.94 1125.32L182.07 1145.61L217.2 1165.89L252.34 1186.17L287.46 1206.45L322.59 1226.73L357.72 1247.02L392.85 1267.3L427.98 1287.58L463.1 1307.86L498.24 1328.14L533.37 1348.43L568.5 1368.71L707.86 1449.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M742.77 1428.89L603.4 1348.43L568.27 1328.14L533.14 1307.86L498.01 1287.58L462.89 1267.3L427.76 1247.02L392.62 1226.73L357.49 1206.45L322.36 1186.17L287.24 1165.89L252.11 1145.61L216.98 1125.32L181.84 1105.04L146.71 1084.76L111.58 1064.47L76.4597 1044.2L41.3298 1023.91L6.19977 1003.63L-28.9302 983.35L-64.0703 963.06L-99.2003 942.78L-134.32 922.5L-169.45 902.22L-204.58 881.94L-239.71 861.65L-274.85 841.37L-309.98 821.09L-345.1 800.81L-380.23 780.53L-415.36 760.24L-450.49 739.96L-485.63 719.68L-520.76 699.39L-555.88 679.12L-591.01 658.83L-626.14 638.55L-661.27 618.27L-696.41 597.98L-731.53 577.71L-766.66 557.42L-801.79 537.14L-836.92 516.85L-976.28 436.39L-836.92 516.85L-801.79 537.14L-766.66 557.42L-731.53 577.71L-696.41 597.98L-661.27 618.27L-626.14 638.55L-591.01 658.83L-555.88 679.12L-520.76 699.39L-485.63 719.68L-450.49 739.96L-415.36 760.24L-380.23 780.53L-345.1 800.81L-309.98 821.09L-274.85 841.37L-239.71 861.65L-204.58 881.94L-169.45 902.22L-134.32 922.5L-99.2003 942.78L-64.0703 963.06L-28.9302 983.35L6.19977 1003.63L41.3298 1023.91L76.4597 1044.2L111.58 1064.47L146.71 1084.76L181.84 1105.04L216.98 1125.32L252.11 1145.61L287.24 1165.89L322.36 1186.17L357.49 1206.45L392.62 1226.73L427.76 1247.02L462.89 1267.3L498.01 1287.58L533.14 1307.86L568.27 1328.14L603.4 1348.43L742.77 1428.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M777.66 1408.61L638.3 1328.15L603.16 1307.87L568.03 1287.58L532.9 1267.3L497.78 1247.02L462.65 1226.74L427.52 1206.46L392.38 1186.17L357.25 1165.89L322.13 1145.61L287 1125.33L251.87 1105.05L216.74 1084.76L181.6 1064.48L146.47 1044.2L111.35 1023.92L76.2198 1003.64L41.0899 983.35L5.9599 963.07L-29.1801 942.79L-64.3101 922.5L-99.4301 902.23L-134.56 881.94L-169.69 861.66L-204.82 841.38L-239.95 821.09L-275.09 800.81L-310.21 780.53L-345.34 760.25L-380.47 739.97L-415.6 719.68L-450.73 699.4L-485.87 679.12L-520.99 658.84L-556.12 638.56L-591.25 618.27L-626.38 597.99L-661.51 577.7L-696.63 557.43L-731.77 537.14L-766.9 516.86L-802.03 496.58L-941.39 416.12L-802.03 496.58L-766.9 516.86L-731.77 537.14L-696.63 557.43L-661.51 577.7L-626.38 597.99L-591.25 618.27L-556.12 638.56L-520.99 658.84L-485.87 679.12L-450.73 699.4L-415.6 719.68L-380.47 739.97L-345.34 760.25L-310.21 780.53L-275.09 800.81L-239.95 821.09L-204.82 841.38L-169.69 861.66L-134.56 881.94L-99.4301 902.23L-64.3101 922.5L-29.1801 942.79L5.9599 963.07L41.0899 983.35L76.2198 1003.64L111.35 1023.92L146.47 1044.2L181.6 1064.48L216.74 1084.76L251.87 1105.05L287 1125.33L322.13 1145.61L357.25 1165.89L392.38 1186.17L427.52 1206.46L462.65 1226.74L497.78 1247.02L532.9 1267.3L568.03 1287.58L603.16 1307.87L638.3 1328.15L777.66 1408.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M812.56 1388.33L673.2 1307.87L638.07 1287.58L602.94 1267.3L567.8 1247.02L532.68 1226.74L497.55 1206.46L462.42 1186.17L427.29 1165.89L392.16 1145.61L357.04 1125.33L321.9 1105.05L286.77 1084.76L251.64 1064.48L216.51 1044.2L181.38 1023.91L146.26 1003.64L111.12 983.35L75.9897 963.07L40.8597 942.79L5.72974 922.5L-29.4003 902.22L-64.5203 881.94L-99.6603 861.66L-134.79 841.38L-169.92 821.09L-205.05 800.81L-240.18 780.52L-275.3 760.25L-310.43 739.97L-345.57 719.68L-380.7 699.4L-415.83 679.11L-450.96 658.83L-486.08 638.55L-521.21 618.27L-556.35 597.99L-591.48 577.7L-626.61 557.42L-661.73 537.14L-696.86 516.86L-731.99 496.58L-767.13 476.29L-906.49 395.83L-767.13 476.29L-731.99 496.58L-696.86 516.86L-661.73 537.14L-626.61 557.42L-591.48 577.7L-556.35 597.99L-521.21 618.27L-486.08 638.55L-450.96 658.83L-415.83 679.11L-380.7 699.4L-345.57 719.68L-310.43 739.97L-275.3 760.25L-240.18 780.52L-205.05 800.81L-169.92 821.09L-134.79 841.38L-99.6603 861.66L-64.5203 881.94L-29.4003 902.22L5.72974 922.5L40.8597 942.79L75.9897 963.07L111.12 983.35L146.26 1003.64L181.38 1023.91L216.51 1044.2L251.64 1064.48L286.77 1084.76L321.9 1105.05L357.04 1125.33L392.16 1145.61L427.29 1165.89L462.42 1186.17L497.55 1206.46L532.68 1226.74L567.8 1247.02L602.94 1267.3L638.07 1287.58L673.2 1307.87L812.56 1388.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M847.47 1368.04L708.1 1287.58L672.97 1267.3L637.84 1247.02L602.71 1226.73L567.59 1206.46L532.46 1186.17L497.32 1165.89L462.19 1145.61L427.06 1125.32L391.94 1105.05L356.81 1084.76L321.68 1064.48L286.54 1044.2L251.41 1023.91L216.28 1003.63L181.16 983.35L146.03 963.07L110.9 942.79L75.7599 922.5L40.6299 902.22L5.49994 881.93L-29.6201 861.66L-64.7501 841.37L-99.8801 821.09L-135.02 800.81L-170.15 780.52L-205.28 760.24L-240.4 739.96L-275.53 719.68L-310.66 699.4L-345.79 679.11L-380.93 658.83L-416.06 638.55L-451.18 618.27L-486.31 597.99L-521.44 577.7L-556.57 557.42L-591.71 537.14L-626.83 516.86L-661.96 496.58L-697.09 476.29L-732.22 456.01L-871.59 375.55L-732.22 456.01L-697.09 476.29L-661.96 496.58L-626.83 516.86L-591.71 537.14L-556.57 557.42L-521.44 577.7L-486.31 597.99L-451.18 618.27L-416.06 638.55L-380.93 658.83L-345.79 679.11L-310.66 699.4L-275.53 719.68L-240.4 739.96L-205.28 760.24L-170.15 780.52L-135.02 800.81L-99.8801 821.09L-64.7501 841.37L-29.6201 861.66L5.49994 881.93L40.6299 902.22L75.7599 922.5L110.9 942.79L146.03 963.07L181.16 983.35L216.28 1003.63L251.41 1023.91L286.54 1044.2L321.68 1064.48L356.81 1084.76L391.94 1105.05L427.06 1125.32L462.19 1145.61L497.32 1165.89L532.46 1186.17L567.59 1206.46L602.71 1226.73L637.84 1247.02L672.97 1267.3L708.1 1287.58L847.47 1368.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M882.37 1347.76L743.01 1267.3L707.87 1247.02L672.74 1226.73L637.61 1206.45L602.49 1186.17L567.36 1165.89L532.23 1145.61L497.09 1125.32L461.96 1105.04L426.84 1084.76L391.71 1064.48L356.58 1044.2L321.45 1023.91L286.32 1003.63L251.18 983.34L216.06 963.07L180.93 942.78L145.8 922.5L110.67 902.22L75.5398 881.93L40.3998 861.65L5.27985 841.37L-29.8502 821.09L-64.9802 800.81L-100.11 780.52L-135.24 760.24L-170.38 739.96L-205.5 719.68L-240.63 699.4L-275.76 679.11L-310.89 658.83L-346.02 638.55L-381.16 618.26L-416.28 597.99L-451.41 577.7L-486.54 557.42L-521.67 537.14L-556.8 516.85L-591.92 496.58L-627.05 476.29L-662.19 456.01L-697.32 435.73L-836.68 355.27L-697.32 435.73L-662.19 456.01L-627.05 476.29L-591.92 496.58L-556.8 516.85L-521.67 537.14L-486.54 557.42L-451.41 577.7L-416.28 597.99L-381.16 618.26L-346.02 638.55L-310.89 658.83L-275.76 679.11L-240.63 699.4L-205.5 719.68L-170.38 739.96L-135.24 760.24L-100.11 780.52L-64.9802 800.81L-29.8502 821.09L5.27985 841.37L40.3998 861.65L75.5398 881.93L110.67 902.22L145.8 922.5L180.93 942.78L216.06 963.07L251.18 983.34L286.32 1003.63L321.45 1023.91L356.58 1044.2L391.71 1064.48L426.84 1084.76L461.96 1105.04L497.09 1125.32L532.23 1145.61L567.36 1165.89L602.49 1186.17L637.61 1206.45L672.74 1226.73L707.87 1247.02L743.01 1267.3L882.37 1347.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M917.27 1327.48L777.91 1247.02L742.78 1226.73L707.65 1206.45L672.51 1186.16L637.39 1165.89L602.26 1145.6L567.13 1125.32L532 1105.04L496.87 1084.75L461.75 1064.48L426.61 1044.19L391.48 1023.91L356.35 1003.63L321.22 983.34L286.09 963.06L250.97 942.78L215.84 922.5L180.7 902.22L145.57 881.93L110.44 861.65L75.3098 841.37L40.1898 821.09L5.05975 800.81L-30.0803 780.52L-65.2103 760.24L-100.34 739.96L-135.47 719.67L-170.59 699.4L-205.72 679.11L-240.86 658.83L-275.99 638.55L-311.12 618.26L-346.25 597.98L-381.37 577.7L-416.5 557.42L-451.64 537.14L-486.77 516.85L-521.9 496.57L-557.02 476.29L-592.15 456.01L-627.28 435.73L-662.42 415.44L-801.78 334.98L-662.42 415.44L-627.28 435.73L-592.15 456.01L-557.02 476.29L-521.9 496.57L-486.77 516.85L-451.64 537.14L-416.5 557.42L-381.37 577.7L-346.25 597.98L-311.12 618.26L-275.99 638.55L-240.86 658.83L-205.72 679.11L-170.59 699.4L-135.47 719.67L-100.34 739.96L-65.2103 760.24L-30.0803 780.52L5.05975 800.81L40.1898 821.09L75.3098 841.37L110.44 861.65L145.57 881.93L180.7 902.22L215.84 922.5L250.97 942.78L286.09 963.06L321.22 983.34L356.35 1003.63L391.48 1023.91L426.61 1044.19L461.75 1064.48L496.87 1084.75L532 1105.04L567.13 1125.32L602.26 1145.6L637.39 1165.89L672.51 1186.16L707.65 1206.45L742.78 1226.73L777.91 1247.02L917.27 1327.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M952.18 1307.19L812.81 1226.73L777.68 1206.45L742.55 1186.16L707.42 1165.88L672.3 1145.6L637.17 1125.32L602.03 1105.04L566.9 1084.75L531.77 1064.47L496.65 1044.19L461.52 1023.91L426.39 1003.63L391.25 983.34L356.12 963.06L320.99 942.78L285.87 922.5L250.74 902.22L215.61 881.93L180.47 861.65L145.34 841.37L110.21 821.08L75.0898 800.81L39.9599 780.52L4.8299 760.24L-30.3001 739.96L-65.4401 719.67L-100.57 699.39L-135.69 679.11L-170.82 658.83L-205.95 638.55L-241.08 618.26L-276.22 597.98L-311.35 577.7L-346.47 557.42L-381.6 537.14L-416.73 516.85L-451.86 496.57L-487 476.29L-522.12 456.01L-557.25 435.73L-592.38 415.44L-627.51 395.16L-766.87 314.7L-627.51 395.16L-592.38 415.44L-557.25 435.73L-522.12 456.01L-487 476.29L-451.86 496.57L-416.73 516.85L-381.6 537.14L-346.47 557.42L-311.35 577.7L-276.22 597.98L-241.08 618.26L-205.95 638.55L-170.82 658.83L-135.69 679.11L-100.57 699.39L-65.4401 719.67L-30.3001 739.96L4.8299 760.24L39.9599 780.52L75.0898 800.81L110.21 821.08L145.34 841.37L180.47 861.65L215.61 881.93L250.74 902.22L285.87 922.5L320.99 942.78L356.12 963.06L391.25 983.34L426.39 1003.63L461.52 1023.91L496.65 1044.19L531.77 1064.47L566.9 1084.75L602.03 1105.04L637.17 1125.32L672.3 1145.6L707.42 1165.88L742.55 1186.16L777.68 1206.45L812.81 1226.73L952.18 1307.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M987.07 1286.92L847.71 1206.45L812.57 1186.17L777.44 1165.89L742.31 1145.6L707.19 1125.33L672.06 1105.04L636.93 1084.76L601.79 1064.48L566.66 1044.19L531.54 1023.92L496.41 1003.63L461.28 983.35L426.15 963.07L391.01 942.78L355.88 922.5L320.76 902.22L285.63 881.94L250.5 861.66L215.37 841.37L180.23 821.09L145.1 800.81L109.98 780.53L74.85 760.25L39.72 739.96L4.59003 719.68L-30.54 699.4L-65.68 679.11L-100.8 658.84L-135.93 638.55L-171.06 618.27L-206.19 597.99L-241.32 577.7L-276.46 557.42L-311.58 537.14L-346.71 516.86L-381.84 496.58L-416.97 476.29L-452.1 456.01L-487.22 435.73L-522.36 415.45L-557.49 395.17L-592.62 374.88L-731.98 294.42L-592.62 374.88L-557.49 395.17L-522.36 415.45L-487.22 435.73L-452.1 456.01L-416.97 476.29L-381.84 496.58L-346.71 516.86L-311.58 537.14L-276.46 557.42L-241.32 577.7L-206.19 597.99L-171.06 618.27L-135.93 638.55L-100.8 658.84L-65.68 679.11L-30.54 699.4L4.59003 719.68L39.72 739.96L74.85 760.25L109.98 780.53L145.1 800.81L180.23 821.09L215.37 841.37L250.5 861.66L285.63 881.94L320.76 902.22L355.88 922.5L391.01 942.78L426.15 963.07L461.28 983.35L496.41 1003.63L531.54 1023.92L566.66 1044.19L601.79 1064.48L636.93 1084.76L672.06 1105.04L707.19 1125.33L742.31 1145.6L777.44 1165.89L812.57 1186.17L847.71 1206.45L987.07 1286.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1021.97 1266.63L882.61 1186.17L847.48 1165.89L812.34 1145.6L777.21 1125.32L742.09 1105.04L706.96 1084.76L671.83 1064.48L636.7 1044.19L601.57 1023.91L566.45 1003.63L531.31 983.35L496.18 963.07L461.05 942.78L425.92 922.5L390.79 902.22L355.67 881.94L320.53 861.66L285.4 841.37L250.27 821.09L215.14 800.81L180.01 780.52L144.89 760.25L109.75 739.96L74.6199 719.68L39.4899 699.4L4.35992 679.11L-30.7701 658.83L-65.8901 638.55L-101.03 618.27L-136.16 597.99L-171.29 577.7L-206.42 557.42L-241.55 537.14L-276.67 516.86L-311.8 496.58L-346.94 476.29L-382.07 456.01L-417.2 435.73L-452.32 415.45L-487.45 395.17L-522.58 374.88L-557.72 354.6L-697.08 274.14L-557.72 354.6L-522.58 374.88L-487.45 395.17L-452.32 415.45L-417.2 435.73L-382.07 456.01L-346.94 476.29L-311.8 496.58L-276.67 516.86L-241.55 537.14L-206.42 557.42L-171.29 577.7L-136.16 597.99L-101.03 618.27L-65.8901 638.55L-30.7701 658.83L4.35992 679.11L39.4899 699.4L74.6199 719.68L109.75 739.96L144.89 760.25L180.01 780.52L215.14 800.81L250.27 821.09L285.4 841.37L320.53 861.66L355.67 881.94L390.79 902.22L425.92 922.5L461.05 942.78L496.18 963.07L531.31 983.35L566.45 1003.63L601.57 1023.91L636.7 1044.19L671.83 1064.48L706.96 1084.76L742.09 1105.04L777.21 1125.32L812.34 1145.6L847.48 1165.89L882.61 1186.17L1021.97 1266.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1056.88 1246.35L917.51 1165.89L882.38 1145.6L847.25 1125.32L812.12 1105.04L777 1084.76L741.86 1064.48L706.73 1044.19L671.6 1023.91L636.47 1003.63L601.35 983.35L566.22 963.07L531.09 942.78L495.95 922.5L460.82 902.22L425.69 881.93L390.57 861.66L355.44 841.37L320.31 821.09L285.17 800.81L250.04 780.52L214.91 760.24L179.79 739.96L144.66 719.68L109.53 699.4L74.3898 679.11L39.2598 658.83L4.12982 638.55L-30.9902 618.27L-66.1202 597.99L-101.25 577.7L-136.39 557.42L-171.52 537.14L-206.65 516.85L-241.77 496.58L-276.9 476.29L-312.03 456.01L-347.17 435.73L-382.3 415.44L-417.42 395.17L-452.55 374.88L-487.68 354.6L-522.81 334.32L-662.18 253.85L-522.81 334.32L-487.68 354.6L-452.55 374.88L-417.42 395.17L-382.3 415.44L-347.17 435.73L-312.03 456.01L-276.9 476.29L-241.77 496.58L-206.65 516.85L-171.52 537.14L-136.39 557.42L-101.25 577.7L-66.1202 597.99L-30.9902 618.27L4.12982 638.55L39.2598 658.83L74.3898 679.11L109.53 699.4L144.66 719.68L179.79 739.96L214.91 760.24L250.04 780.52L285.17 800.81L320.31 821.09L355.44 841.37L390.57 861.66L425.69 881.93L460.82 902.22L495.95 922.5L531.09 942.78L566.22 963.07L601.35 983.35L636.47 1003.63L671.6 1023.91L706.73 1044.19L741.86 1064.48L777 1084.76L812.12 1105.04L847.25 1125.32L882.38 1145.6L917.51 1165.89L1056.88 1246.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1091.78 1226.06L952.42 1145.6L917.28 1125.32L882.15 1105.04L847.02 1084.75L811.9 1064.48L776.77 1044.19L741.64 1023.91L706.5 1003.63L671.37 983.34L636.25 963.07L601.12 942.78L565.99 922.5L530.86 902.22L495.72 881.93L460.59 861.65L425.47 841.37L390.34 821.09L355.21 800.81L320.08 780.52L284.95 760.24L249.81 739.96L214.69 719.68L179.56 699.4L144.43 679.11L109.3 658.83L74.17 638.55L39.03 618.26L3.90997 597.99L-31.22 577.7L-66.35 557.42L-101.48 537.14L-136.61 516.85L-171.75 496.57L-206.87 476.29L-242 456.01L-277.13 435.72L-312.26 415.44L-347.39 395.16L-382.51 374.88L-417.65 354.6L-452.78 334.31L-487.91 314.03L-627.27 233.57L-487.91 314.03L-452.78 334.31L-417.65 354.6L-382.51 374.88L-347.39 395.16L-312.26 415.44L-277.13 435.72L-242 456.01L-206.87 476.29L-171.75 496.57L-136.61 516.85L-101.48 537.14L-66.35 557.42L-31.22 577.7L3.90997 597.99L39.03 618.26L74.17 638.55L109.3 658.83L144.43 679.11L179.56 699.4L214.69 719.68L249.81 739.96L284.95 760.24L320.08 780.52L355.21 800.81L390.34 821.09L425.47 841.37L460.59 861.65L495.72 881.93L530.86 902.22L565.99 922.5L601.12 942.78L636.25 963.07L671.37 983.34L706.5 1003.63L741.64 1023.91L776.77 1044.19L811.9 1064.48L847.02 1084.75L882.15 1105.04L917.28 1125.32L952.42 1145.6L1091.78 1226.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1126.68 1205.78L987.32 1125.32L952.19 1105.04L917.06 1084.75L881.92 1064.47L846.8 1044.19L811.67 1023.91L776.54 1003.63L741.41 983.34L706.28 963.06L671.16 942.78L636.02 922.5L600.89 902.22L565.76 881.93L530.63 861.65L495.5 841.37L460.38 821.09L425.24 800.81L390.11 780.52L354.98 760.24L319.85 739.96L284.72 719.67L249.6 699.4L214.47 679.11L179.33 658.83L144.2 638.54L109.07 618.26L73.9399 597.98L38.8199 577.7L3.68988 557.42L-31.4501 537.13L-66.5801 516.85L-101.71 496.57L-136.84 476.28L-171.96 456.01L-207.09 435.72L-242.23 415.44L-277.36 395.16L-312.49 374.87L-347.61 354.6L-382.74 334.31L-417.87 314.03L-453.01 293.75L-592.37 213.29L-453.01 293.75L-417.87 314.03L-382.74 334.31L-347.61 354.6L-312.49 374.87L-277.36 395.16L-242.23 415.44L-207.09 435.72L-171.96 456.01L-136.84 476.28L-101.71 496.57L-66.5801 516.85L-31.4501 537.13L3.68988 557.42L38.8199 577.7L73.9399 597.98L109.07 618.26L144.2 638.54L179.33 658.83L214.47 679.11L249.6 699.4L284.72 719.67L319.85 739.96L354.98 760.24L390.11 780.52L425.24 800.81L460.38 821.09L495.5 841.37L530.63 861.65L565.76 881.93L600.89 902.22L636.02 922.5L671.16 942.78L706.28 963.06L741.41 983.34L776.54 1003.63L811.67 1023.91L846.8 1044.19L881.92 1064.47L917.06 1084.75L952.19 1105.04L987.32 1125.32L1126.68 1205.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1161.59 1185.5L1022.22 1105.04L987.09 1084.75L951.96 1064.47L916.83 1044.19L881.71 1023.91L846.58 1003.63L811.44 983.34L776.31 963.06L741.18 942.78L706.06 922.5L670.93 902.22L635.8 881.93L600.66 861.65L565.53 841.37L530.4 821.08L495.28 800.81L460.15 780.52L425.02 760.24L389.88 739.95L354.75 719.67L319.62 699.39L284.5 679.11L249.37 658.83L214.24 638.54L179.1 618.26L143.97 597.98L108.84 577.69L73.7197 557.42L38.5898 537.13L3.45978 516.85L-31.6702 496.57L-66.8102 476.28L-101.94 456L-137.06 435.72L-172.19 415.44L-207.32 395.16L-242.45 374.87L-277.59 354.59L-312.71 334.31L-347.84 314.03L-382.97 293.75L-418.1 273.46L-557.47 193L-418.1 273.46L-382.97 293.75L-347.84 314.03L-312.71 334.31L-277.59 354.59L-242.45 374.87L-207.32 395.16L-172.19 415.44L-137.06 435.72L-101.94 456L-66.8102 476.28L-31.6702 496.57L3.45978 516.85L38.5898 537.13L73.7197 557.42L108.84 577.69L143.97 597.98L179.1 618.26L214.24 638.54L249.37 658.83L284.5 679.11L319.62 699.39L354.75 719.67L389.88 739.95L425.02 760.24L460.15 780.52L495.28 800.81L530.4 821.08L565.53 841.37L600.66 861.65L635.8 881.93L670.93 902.22L706.06 922.5L741.18 942.78L776.31 963.06L811.44 983.34L846.58 1003.63L881.71 1023.91L916.83 1044.19L951.96 1064.47L987.09 1084.75L1022.22 1105.04L1161.59 1185.5Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1196.48 1165.22L1057.11 1084.76L1021.98 1064.48L986.85 1044.19L951.72 1023.91L916.6 1003.63L881.47 983.35L846.34 963.07L811.2 942.78L776.07 922.5L740.95 902.22L705.82 881.94L670.69 861.66L635.56 841.37L600.42 821.09L565.29 800.8L530.17 780.53L495.04 760.24L459.91 739.96L424.78 719.68L389.64 699.39L354.51 679.11L319.39 658.83L284.26 638.55L249.13 618.27L214 597.98L178.86 577.7L143.73 557.42L108.61 537.14L73.4799 516.86L38.3499 496.57L3.21991 476.29L-31.9101 456.01L-67.05 435.72L-102.17 415.45L-137.3 395.16L-172.43 374.88L-207.56 354.6L-242.69 334.31L-277.81 314.04L-312.95 293.75L-348.08 273.47L-383.21 253.19L-522.57 172.73L-383.21 253.19L-348.08 273.47L-312.95 293.75L-277.81 314.04L-242.69 334.31L-207.56 354.6L-172.43 374.88L-137.3 395.16L-102.17 415.45L-67.05 435.72L-31.9101 456.01L3.21991 476.29L38.3499 496.57L73.4799 516.86L108.61 537.14L143.73 557.42L178.86 577.7L214 597.98L249.13 618.27L284.26 638.55L319.39 658.83L354.51 679.11L389.64 699.39L424.78 719.68L459.91 739.96L495.04 760.24L530.17 780.53L565.29 800.8L600.42 821.09L635.56 841.37L670.69 861.66L705.82 881.94L740.95 902.22L776.07 922.5L811.2 942.78L846.34 963.07L881.47 983.35L916.6 1003.63L951.72 1023.91L986.85 1044.19L1021.98 1064.48L1057.11 1084.76L1196.48 1165.22Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1231.38 1144.94L1092.02 1064.48L1056.89 1044.19L1021.75 1023.91L986.62 1003.62L951.5 983.35L916.37 963.07L881.24 942.78L846.11 922.5L810.97 902.21L775.85 881.94L740.72 861.65L705.59 841.37L670.46 821.09L635.33 800.8L600.2 780.52L565.08 760.24L529.94 739.96L494.81 719.68L459.68 699.39L424.55 679.11L389.42 658.83L354.3 638.55L319.16 618.27L284.03 597.98L248.9 577.7L213.77 557.42L178.64 537.13L143.52 516.86L108.38 496.57L73.2499 476.29L38.1199 456.01L2.98984 435.72L-32.1401 415.44L-67.2602 395.16L-102.4 374.88L-137.53 354.6L-172.66 334.31L-207.79 314.03L-242.91 293.75L-278.04 273.47L-313.17 253.19L-348.31 232.9L-487.67 152.44L-348.31 232.9L-313.17 253.19L-278.04 273.47L-242.91 293.75L-207.79 314.03L-172.66 334.31L-137.53 354.6L-102.4 374.88L-67.2602 395.16L-32.1401 415.44L2.98984 435.72L38.1199 456.01L73.2499 476.29L108.38 496.57L143.52 516.86L178.64 537.13L213.77 557.42L248.9 577.7L284.03 597.98L319.16 618.27L354.3 638.55L389.42 658.83L424.55 679.11L459.68 699.39L494.81 719.68L529.94 739.96L565.08 760.24L600.2 780.52L635.33 800.8L670.46 821.09L705.59 841.37L740.72 861.65L775.85 881.94L810.97 902.21L846.11 922.5L881.24 942.78L916.37 963.07L951.5 983.35L986.62 1003.62L1021.75 1023.91L1056.89 1044.19L1092.02 1064.48L1231.38 1144.94Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1266.29 1124.65L1126.92 1044.19L1091.79 1023.91L1056.66 1003.62L1021.53 983.34L986.41 963.06L951.27 942.78L916.14 922.5L881.01 902.21L845.88 881.93L810.76 861.65L775.63 841.37L740.49 821.09L705.36 800.8L670.23 780.52L635.1 760.24L599.98 739.96L564.85 719.68L529.72 699.39L494.58 679.11L459.45 658.83L424.32 638.54L389.2 618.27L354.07 597.98L318.94 577.7L283.8 557.42L248.67 537.13L213.54 516.85L178.42 496.57L143.29 476.29L108.16 456.01L73.02 435.72L37.89 415.44L2.75998 395.16L-32.36 374.88L-67.49 354.6L-102.62 334.31L-137.76 314.03L-172.89 293.75L-208.01 273.47L-243.14 253.19L-278.27 232.9L-313.4 212.62L-452.77 132.16L-313.4 212.62L-278.27 232.9L-243.14 253.19L-208.01 273.47L-172.89 293.75L-137.76 314.03L-102.62 334.31L-67.49 354.6L-32.36 374.88L2.75998 395.16L37.89 415.44L73.02 435.72L108.16 456.01L143.29 476.29L178.42 496.57L213.54 516.85L248.67 537.13L283.8 557.42L318.94 577.7L354.07 597.98L389.2 618.27L424.32 638.54L459.45 658.83L494.58 679.11L529.72 699.39L564.85 719.68L599.98 739.96L635.1 760.24L670.23 780.52L705.36 800.8L740.49 821.09L775.63 841.37L810.76 861.65L845.88 881.93L881.01 902.21L916.14 922.5L951.27 942.78L986.41 963.06L1021.53 983.34L1056.66 1003.62L1091.79 1023.91L1126.92 1044.19L1266.29 1124.65Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1301.19 1104.37L1161.83 1023.91L1126.69 1003.62L1091.56 983.34L1056.43 963.06L1021.31 942.78L986.18 922.5L951.05 902.21L915.91 881.93L880.78 861.65L845.66 841.37L810.53 821.09L775.4 800.8L740.27 780.52L705.13 760.24L670 739.95L634.88 719.68L599.75 699.39L564.62 679.11L529.49 658.83L494.36 638.54L459.22 618.26L424.1 597.98L388.97 577.7L353.84 557.42L318.71 537.13L283.58 516.85L248.44 496.57L213.32 476.29L178.19 456.01L143.06 435.72L107.93 415.44L72.7999 395.16L37.6599 374.87L2.53989 354.6L-32.5901 334.31L-67.7201 314.03L-102.85 293.75L-137.98 273.46L-173.1 253.19L-208.24 232.9L-243.37 212.62L-278.5 192.34L-417.86 111.87L-278.5 192.34L-243.37 212.62L-208.24 232.9L-173.1 253.19L-137.98 273.46L-102.85 293.75L-67.7201 314.03L-32.5901 334.31L2.53989 354.6L37.6599 374.87L72.7999 395.16L107.93 415.44L143.06 435.72L178.19 456.01L213.32 476.29L248.44 496.57L283.58 516.85L318.71 537.13L353.84 557.42L388.97 577.7L424.1 597.98L459.22 618.26L494.36 638.54L529.49 658.83L564.62 679.11L599.75 699.39L634.88 719.68L670 739.95L705.13 760.24L740.27 780.52L775.4 800.8L810.53 821.09L845.66 841.37L880.78 861.65L915.91 881.93L951.05 902.21L986.18 922.5L1021.31 942.78L1056.43 963.06L1091.56 983.34L1126.69 1003.62L1161.83 1023.91L1301.19 1104.37Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1336.09 1084.09L1196.73 1003.62L1161.6 983.34L1126.47 963.06L1091.33 942.77L1056.21 922.5L1021.08 902.21L985.95 881.93L950.82 861.65L915.69 841.36L880.57 821.09L845.43 800.8L810.3 780.52L775.17 760.24L740.04 739.95L704.91 719.67L669.79 699.39L634.65 679.11L599.52 658.83L564.39 638.54L529.26 618.26L494.13 597.98L459.01 577.7L423.87 557.42L388.74 537.13L353.61 516.85L318.48 496.57L283.35 476.28L248.23 456.01L213.1 435.72L177.96 415.44L142.83 395.16L107.7 374.87L72.57 354.59L37.45 334.31L2.32004 314.03L-32.82 293.75L-67.95 273.46L-103.08 253.18L-138.2 232.9L-173.33 212.62L-208.46 192.34L-243.6 172.05L-382.96 91.59L-243.6 172.05L-208.46 192.34L-173.33 212.62L-138.2 232.9L-103.08 253.18L-67.95 273.46L-32.82 293.75L2.32004 314.03L37.45 334.31L72.57 354.59L107.7 374.87L142.83 395.16L177.96 415.44L213.1 435.72L248.23 456.01L283.35 476.28L318.48 496.57L353.61 516.85L388.74 537.13L423.87 557.42L459.01 577.7L494.13 597.98L529.26 618.26L564.39 638.54L599.52 658.83L634.65 679.11L669.79 699.39L704.91 719.67L740.04 739.95L775.17 760.24L810.3 780.52L845.43 800.8L880.57 821.09L915.69 841.36L950.82 861.65L985.95 881.93L1021.08 902.21L1056.21 922.5L1091.33 942.77L1126.47 963.06L1161.6 983.34L1196.73 1003.62L1336.09 1084.09Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1371 1063.8L1231.63 983.34L1196.5 963.06L1161.37 942.77L1126.24 922.49L1091.12 902.21L1055.99 881.93L1020.85 861.65L985.72 841.36L950.59 821.08L915.47 800.8L880.34 780.52L845.21 760.24L810.07 739.95L774.94 719.67L739.81 699.39L704.69 679.11L669.56 658.83L634.43 638.54L599.29 618.26L564.16 597.98L529.03 577.69L493.91 557.42L458.78 537.13L423.65 516.85L388.51 496.57L353.38 476.28L318.25 456L283.13 435.72L248 415.44L212.87 395.16L177.73 374.87L142.6 354.59L107.47 334.31L72.3499 314.03L37.2199 293.75L2.08994 273.46L-33.0401 253.18L-68.1801 232.89L-103.3 212.62L-138.43 192.34L-173.56 172.05L-208.69 151.77L-348.06 71.31L-208.69 151.77L-173.56 172.05L-138.43 192.34L-103.3 212.62L-68.1801 232.89L-33.0401 253.18L2.08994 273.46L37.2199 293.75L72.3499 314.03L107.47 334.31L142.6 354.59L177.73 374.87L212.87 395.16L248 415.44L283.13 435.72L318.25 456L353.38 476.28L388.51 496.57L423.65 516.85L458.78 537.13L493.91 557.42L529.03 577.69L564.16 597.98L599.29 618.26L634.43 638.54L669.56 658.83L704.69 679.11L739.81 699.39L774.94 719.67L810.07 739.95L845.21 760.24L880.34 780.52L915.47 800.8L950.59 821.08L985.72 841.36L1020.85 861.65L1055.99 881.93L1091.12 902.21L1126.24 922.49L1161.37 942.77L1196.5 963.06L1231.63 983.34L1371 1063.8Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1405.89 1043.53L1266.52 963.06L1231.39 942.78L1196.26 922.5L1161.13 902.21L1126.01 881.94L1090.88 861.65L1055.74 841.37L1020.61 821.09L985.48 800.8L950.36 780.53L915.23 760.24L880.1 739.96L844.97 719.68L809.83 699.39L774.7 679.11L739.58 658.83L704.45 638.55L669.32 618.27L634.19 597.98L599.05 577.7L563.92 557.42L528.8 537.14L493.67 516.86L458.54 496.57L423.41 476.29L388.27 456.01L353.14 435.72L318.02 415.45L282.89 395.16L247.76 374.88L212.63 354.6L177.49 334.31L142.36 314.03L107.24 293.75L72.1099 273.47L36.9799 253.19L1.84985 232.9L-33.2802 212.62L-68.4001 192.34L-103.54 172.06L-138.67 151.77L-173.8 131.49L-313.16 51.03L-173.8 131.49L-138.67 151.77L-103.54 172.06L-68.4001 192.34L-33.2802 212.62L1.84985 232.9L36.9799 253.19L72.1099 273.47L107.24 293.75L142.36 314.03L177.49 334.31L212.63 354.6L247.76 374.88L282.89 395.16L318.02 415.45L353.14 435.72L388.27 456.01L423.41 476.29L458.54 496.57L493.67 516.86L528.8 537.14L563.92 557.42L599.05 577.7L634.19 597.98L669.32 618.27L704.45 638.55L739.58 658.83L774.7 679.11L809.83 699.39L844.97 719.68L880.1 739.96L915.23 760.24L950.36 780.53L985.48 800.8L1020.61 821.09L1055.74 841.37L1090.88 861.65L1126.01 881.94L1161.13 902.21L1196.26 922.5L1231.39 942.78L1266.52 963.06L1405.89 1043.53Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1440.79 1023.24L1301.43 942.78L1266.3 922.5L1231.16 902.21L1196.03 881.93L1160.91 861.65L1125.78 841.37L1090.65 821.09L1055.52 800.8L1020.38 780.52L985.26 760.24L950.13 739.96L915 719.68L879.87 699.39L844.74 679.11L809.61 658.83L774.49 638.55L739.35 618.27L704.22 597.98L669.09 577.7L633.96 557.42L598.83 537.13L563.71 516.86L528.57 496.57L493.44 476.29L458.31 456.01L423.18 435.72L388.05 415.44L352.93 395.16L317.79 374.88L282.66 354.59L247.53 334.31L212.4 314.03L177.27 293.74L142.15 273.47L107.01 253.18L71.8798 232.9L36.7498 212.62L1.61975 192.33L-33.5002 172.06L-68.6302 151.77L-103.77 131.49L-138.9 111.21L-278.26 30.75L-138.9 111.21L-103.77 131.49L-68.6302 151.77L-33.5002 172.06L1.61975 192.33L36.7498 212.62L71.8798 232.9L107.01 253.18L142.15 273.47L177.27 293.74L212.4 314.03L247.53 334.31L282.66 354.59L317.79 374.88L352.93 395.16L388.05 415.44L423.18 435.72L458.31 456.01L493.44 476.29L528.57 496.57L563.71 516.86L598.83 537.13L633.96 557.42L669.09 577.7L704.22 597.98L739.35 618.27L774.49 638.55L809.61 658.83L844.74 679.11L879.87 699.39L915 719.68L950.13 739.96L985.26 760.24L1020.38 780.52L1055.52 800.8L1090.65 821.09L1125.78 841.37L1160.91 861.65L1196.03 881.93L1231.16 902.21L1266.3 922.5L1301.43 942.78L1440.79 1023.24Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1475.7 1002.96L1336.33 922.5L1301.2 902.21L1266.07 881.93L1230.94 861.65L1195.82 841.37L1160.68 821.09L1125.55 800.8L1090.42 780.52L1055.29 760.24L1020.17 739.96L985.04 719.68L949.9 699.39L914.77 679.11L879.64 658.83L844.51 638.54L809.39 618.27L774.26 597.98L739.12 577.7L703.99 557.41L668.86 537.13L633.73 516.85L598.61 496.57L563.48 476.29L528.35 456L493.21 435.72L458.08 415.44L422.95 395.15L387.83 374.88L352.7 354.59L317.57 334.31L282.43 314.03L247.3 293.74L212.17 273.46L177.05 253.18L141.92 232.9L106.79 212.62L71.6499 192.33L36.5199 172.05L1.39989 151.77L-33.7301 131.49L-68.8601 111.21L-103.99 90.92L-243.36 10.46L-103.99 90.92L-68.8601 111.21L-33.7301 131.49L1.39989 151.77L36.5199 172.05L71.6499 192.33L106.79 212.62L141.92 232.9L177.05 253.18L212.17 273.46L247.3 293.74L282.43 314.03L317.57 334.31L352.7 354.59L387.83 374.88L422.95 395.15L458.08 415.44L493.21 435.72L528.35 456L563.48 476.29L598.61 496.57L633.73 516.85L668.86 537.13L703.99 557.41L739.12 577.7L774.26 597.98L809.39 618.27L844.51 638.54L879.64 658.83L914.77 679.11L949.9 699.39L985.04 719.68L1020.17 739.96L1055.29 760.24L1090.42 780.52L1125.55 800.8L1160.68 821.09L1195.82 841.37L1230.94 861.65L1266.07 881.93L1301.2 902.21L1336.33 922.5L1475.7 1002.96Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1510.6 982.67L1371.24 902.21L1336.1 881.93L1300.97 861.65L1265.84 841.36L1230.72 821.09L1195.59 800.8L1160.46 780.52L1125.32 760.24L1090.19 739.95L1055.07 719.68L1019.94 699.39L984.81 679.11L949.68 658.82L914.54 638.54L879.41 618.26L844.29 597.98L809.16 577.7L774.03 557.41L738.9 537.13L703.76 516.85L668.63 496.56L633.51 476.29L598.38 456L563.25 435.72L528.12 415.44L492.99 395.15L457.85 374.87L422.73 354.59L387.6 334.31L352.47 314.03L317.34 293.74L282.21 273.46L247.07 253.18L211.95 232.9L176.82 212.62L141.69 192.33L106.56 172.05L71.4298 151.77L36.3098 131.49L1.1698 111.21L-33.9602 90.92L-69.0902 70.64L-208.45 -9.82001L-69.0902 70.64L-33.9602 90.92L1.1698 111.21L36.3098 131.49L71.4298 151.77L106.56 172.05L141.69 192.33L176.82 212.62L211.95 232.9L247.07 253.18L282.21 273.46L317.34 293.74L352.47 314.03L387.6 334.31L422.73 354.59L457.85 374.87L492.99 395.15L528.12 415.44L563.25 435.72L598.38 456L633.51 476.29L668.63 496.56L703.76 516.85L738.9 537.13L774.03 557.41L809.16 577.7L844.29 597.98L879.41 618.26L914.54 638.54L949.68 658.82L984.81 679.11L1019.94 699.39L1055.07 719.68L1090.19 739.95L1125.32 760.24L1160.46 780.52L1195.59 800.8L1230.72 821.09L1265.84 841.36L1300.97 861.65L1336.1 881.93L1371.24 902.21L1510.6 982.67Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1545.5 962.39L1406.14 881.93L1371.01 861.64L1335.87 841.36L1300.74 821.08L1265.62 800.8L1230.49 780.52L1195.36 760.23L1160.23 739.95L1125.1 719.67L1089.98 699.39L1054.84 679.11L1019.71 658.82L984.58 638.54L949.45 618.26L914.32 597.97L879.2 577.7L844.06 557.41L808.93 537.13L773.8 516.85L738.67 496.56L703.54 476.28L668.42 456L633.28 435.72L598.15 415.44L563.02 395.15L527.89 374.87L492.76 354.59L457.64 334.31L422.5 314.03L387.37 293.74L352.24 273.46L317.11 253.18L281.98 232.89L246.86 212.62L211.73 192.33L176.59 172.05L141.46 151.77L106.33 131.48L71.21 111.21L36.08 90.92L0.949951 70.64L-34.19 50.36L-173.55 -30.11L-34.19 50.36L0.949951 70.64L36.08 90.92L71.21 111.21L106.33 131.48L141.46 151.77L176.59 172.05L211.73 192.33L246.86 212.62L281.98 232.89L317.11 253.18L352.24 273.46L387.37 293.74L422.5 314.03L457.64 334.31L492.76 354.59L527.89 374.87L563.02 395.15L598.15 415.44L633.28 435.72L668.42 456L703.54 476.28L738.67 496.56L773.8 516.85L808.93 537.13L844.06 557.41L879.2 577.7L914.32 597.97L949.45 618.26L984.58 638.54L1019.71 658.82L1054.84 679.11L1089.98 699.39L1125.1 719.67L1160.23 739.95L1195.36 760.23L1230.49 780.52L1265.62 800.8L1300.74 821.08L1335.87 841.36L1371.01 861.64L1406.14 881.93L1545.5 962.39Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1580.41 942.11L1441.04 861.64L1405.91 841.36L1370.78 821.08L1335.65 800.79L1300.53 780.52L1265.39 760.23L1230.26 739.95L1195.13 719.67L1160 699.38L1124.88 679.11L1089.75 658.82L1054.62 638.54L1019.48 618.26L984.35 597.97L949.22 577.69L914.1 557.41L878.97 537.13L843.84 516.85L808.7 496.56L773.57 476.28L738.44 456L703.32 435.72L668.19 415.44L633.06 395.15L597.92 374.87L562.79 354.59L527.66 334.3L492.54 314.03L457.41 293.74L422.28 273.46L387.14 253.18L352.01 232.89L316.88 212.61L281.76 192.33L246.63 172.05L211.5 151.77L176.37 131.48L141.23 111.2L106.11 90.92L70.9799 70.64L35.8499 50.36L0.719849 30.07L-138.65 -50.39L0.719849 30.07L35.8499 50.36L70.9799 70.64L106.11 90.92L141.23 111.2L176.37 131.48L211.5 151.77L246.63 172.05L281.76 192.33L316.88 212.61L352.01 232.89L387.14 253.18L422.28 273.46L457.41 293.74L492.54 314.03L527.66 334.3L562.79 354.59L597.92 374.87L633.06 395.15L668.19 415.44L703.32 435.72L738.44 456L773.57 476.28L808.7 496.56L843.84 516.85L878.97 537.13L914.1 557.41L949.22 577.69L984.35 597.97L1019.48 618.26L1054.62 638.54L1089.75 658.82L1124.88 679.11L1160 699.38L1195.13 719.67L1230.26 739.95L1265.39 760.23L1300.53 780.52L1335.65 800.79L1370.78 821.08L1405.91 841.36L1441.04 861.64L1580.41 942.11Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1615.3 921.83L1475.93 841.37L1440.8 821.08L1405.67 800.8L1370.54 780.52L1335.42 760.24L1300.29 739.96L1265.15 719.67L1230.02 699.39L1194.89 679.11L1159.77 658.83L1124.64 638.55L1089.51 618.26L1054.37 597.98L1019.24 577.7L984.11 557.41L948.99 537.14L913.86 516.85L878.73 496.57L843.6 476.29L808.46 456L773.33 435.72L738.21 415.44L703.08 395.16L667.95 374.88L632.82 354.59L597.68 334.31L562.55 314.03L527.43 293.75L492.3 273.47L457.17 253.18L422.04 232.9L386.9 212.62L351.77 192.33L316.65 172.06L281.52 151.77L246.39 131.49L211.26 111.21L176.12 90.92L141 70.65L105.87 50.36L70.74 30.08L35.61 9.80002L-103.75 -70.67L35.61 9.80002L70.74 30.08L105.87 50.36L141 70.65L176.12 90.92L211.26 111.21L246.39 131.49L281.52 151.77L316.65 172.06L351.77 192.33L386.9 212.62L422.04 232.9L457.17 253.18L492.3 273.47L527.43 293.75L562.55 314.03L597.68 334.31L632.82 354.59L667.95 374.88L703.08 395.16L738.21 415.44L773.33 435.72L808.46 456L843.6 476.29L878.73 496.57L913.86 516.85L948.99 537.14L984.11 557.41L1019.24 577.7L1054.37 597.98L1089.51 618.26L1124.64 638.55L1159.77 658.83L1194.89 679.11L1230.02 699.39L1265.15 719.67L1300.29 739.96L1335.42 760.24L1370.54 780.52L1405.67 800.8L1440.8 821.08L1475.93 841.37L1615.3 921.83Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1650.2 901.55L1510.84 821.08L1475.71 800.8L1440.57 780.52L1405.44 760.23L1370.32 739.96L1335.19 719.67L1300.06 699.39L1264.93 679.11L1229.79 658.82L1194.67 638.55L1159.54 618.26L1124.41 597.98L1089.28 577.7L1054.15 557.41L1019.01 537.13L983.89 516.85L948.76 496.57L913.63 476.29L878.5 456L843.37 435.72L808.24 415.44L773.12 395.16L737.98 374.88L702.85 354.59L667.72 334.31L632.59 314.03L597.46 293.74L562.34 273.47L527.2 253.18L492.07 232.9L456.94 212.62L421.81 192.33L386.68 172.05L351.56 151.77L316.42 131.49L281.29 111.21L246.16 90.92L211.03 70.64L175.91 50.36L140.78 30.08L105.64 9.79999L70.5099 -10.49L-68.8501 -90.95L70.5099 -10.49L105.64 9.79999L140.78 30.08L175.91 50.36L211.03 70.64L246.16 90.92L281.29 111.21L316.42 131.49L351.56 151.77L386.68 172.05L421.81 192.33L456.94 212.62L492.07 232.9L527.2 253.18L562.34 273.47L597.46 293.74L632.59 314.03L667.72 334.31L702.85 354.59L737.98 374.88L773.12 395.16L808.24 415.44L843.37 435.72L878.5 456L913.63 476.29L948.76 496.57L983.89 516.85L1019.01 537.13L1054.15 557.41L1089.28 577.7L1124.41 597.98L1159.54 618.26L1194.67 638.55L1229.79 658.82L1264.93 679.11L1300.06 699.39L1335.19 719.67L1370.32 739.96L1405.44 760.23L1440.57 780.52L1475.71 800.8L1510.84 821.08L1650.2 901.55Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1685.1 881.26L1545.74 800.8L1510.61 780.52L1475.48 760.23L1440.35 739.95L1405.23 719.67L1370.09 699.39L1334.96 679.11L1299.83 658.82L1264.7 638.54L1229.58 618.26L1194.45 597.98L1159.31 577.7L1124.18 557.41L1089.05 537.13L1053.92 516.85L1018.8 496.57L983.67 476.29L948.53 456L913.4 435.72L878.27 415.44L843.14 395.15L808.02 374.88L772.89 354.59L737.75 334.31L702.62 314.03L667.49 293.74L632.36 273.46L597.24 253.18L562.11 232.9L526.98 212.62L491.84 192.33L456.71 172.05L421.58 151.77L386.46 131.49L351.33 111.21L316.2 90.92L281.06 70.64L245.93 50.36L210.81 30.08L175.68 9.8L140.55 -10.49L105.42 -30.77L-33.9502 -111.23L105.42 -30.77L140.55 -10.49L175.68 9.8L210.81 30.08L245.93 50.36L281.06 70.64L316.2 90.92L351.33 111.21L386.46 131.49L421.58 151.77L456.71 172.05L491.84 192.33L526.98 212.62L562.11 232.9L597.24 253.18L632.36 273.46L667.49 293.74L702.62 314.03L737.75 334.31L772.89 354.59L808.02 374.88L843.14 395.15L878.27 415.44L913.4 435.72L948.53 456L983.67 476.29L1018.8 496.57L1053.92 516.85L1089.05 537.13L1124.18 557.41L1159.31 577.7L1194.45 597.98L1229.58 618.26L1264.7 638.54L1299.83 658.82L1334.96 679.11L1370.09 699.39L1405.23 719.67L1440.35 739.95L1475.48 760.23L1510.61 780.52L1545.74 800.8L1685.1 881.26Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1720.01 860.98L1580.64 780.52L1545.51 760.23L1510.38 739.95L1475.25 719.67L1440.13 699.39L1405 679.11L1369.87 658.82L1334.73 638.54L1299.6 618.26L1264.48 597.98L1229.35 577.7L1194.22 557.41L1159.09 537.13L1123.95 516.85L1088.82 496.56L1053.7 476.29L1018.57 456L983.44 435.72L948.31 415.44L913.17 395.15L878.04 374.87L842.92 354.59L807.79 334.31L772.66 314.03L737.53 293.74L702.39 273.46L667.26 253.18L632.14 232.9L597.01 212.62L561.88 192.33L526.75 172.05L491.62 151.76L456.48 131.48L421.36 111.21L386.23 90.92L351.1 70.64L315.97 50.35L280.84 30.07L245.72 9.78999L210.58 -10.49L175.45 -30.77L140.32 -51.06L0.959961 -131.52L140.32 -51.06L175.45 -30.77L210.58 -10.49L245.72 9.78999L280.84 30.07L315.97 50.35L351.1 70.64L386.23 90.92L421.36 111.21L456.48 131.48L491.62 151.76L526.75 172.05L561.88 192.33L597.01 212.62L632.14 232.9L667.26 253.18L702.39 273.46L737.53 293.74L772.66 314.03L807.79 334.31L842.92 354.59L878.04 374.87L913.17 395.15L948.31 415.44L983.44 435.72L1018.57 456L1053.7 476.29L1088.82 496.56L1123.95 516.85L1159.09 537.13L1194.22 557.41L1229.35 577.7L1264.48 597.98L1299.6 618.26L1334.73 638.54L1369.87 658.82L1405 679.11L1440.13 699.39L1475.25 719.67L1510.38 739.95L1545.51 760.23L1580.64 780.52L1720.01 860.98Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1754.91 840.69L1615.55 760.23L1580.42 739.95L1545.28 719.67L1510.15 699.38L1475.03 679.11L1439.9 658.82L1404.77 638.54L1369.64 618.26L1334.51 597.97L1299.39 577.7L1264.25 557.41L1229.12 537.13L1193.99 516.85L1158.86 496.56L1123.73 476.28L1088.61 456L1053.47 435.72L1018.34 415.44L983.21 395.15L948.08 374.87L912.95 354.59L877.83 334.31L842.69 314.03L807.56 293.74L772.43 273.46L737.3 253.17L702.17 232.89L667.05 212.61L631.91 192.33L596.78 172.05L561.65 151.76L526.52 131.48L491.39 111.2L456.27 90.92L421.13 70.64L386 50.35L350.87 30.07L315.74 9.78999L280.62 -10.49L245.49 -30.77L210.36 -51.06L175.22 -71.34L35.8599 -151.8L175.22 -71.34L210.36 -51.06L245.49 -30.77L280.62 -10.49L315.74 9.78999L350.87 30.07L386 50.35L421.13 70.64L456.27 90.92L491.39 111.2L526.52 131.48L561.65 151.76L596.78 172.05L631.91 192.33L667.05 212.61L702.17 232.89L737.3 253.17L772.43 273.46L807.56 293.74L842.69 314.03L877.83 334.31L912.95 354.59L948.08 374.87L983.21 395.15L1018.34 415.44L1053.47 435.72L1088.61 456L1123.73 476.28L1158.86 496.56L1193.99 516.85L1229.12 537.13L1264.25 557.41L1299.39 577.7L1334.51 597.97L1369.64 618.26L1404.77 638.54L1439.9 658.82L1475.03 679.11L1510.15 699.38L1545.28 719.67L1580.42 739.95L1615.55 760.23L1754.91 840.69Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1789.82 820.41L1650.45 739.95L1615.32 719.67L1580.19 699.38L1545.06 679.1L1509.94 658.82L1474.8 638.54L1439.67 618.26L1404.54 597.97L1369.41 577.69L1334.29 557.41L1299.16 537.13L1264.02 516.85L1228.89 496.56L1193.76 476.28L1158.63 455.99L1123.51 435.72L1088.38 415.44L1053.25 395.15L1018.11 374.87L982.98 354.58L947.85 334.3L912.73 314.02L877.6 293.74L842.47 273.46L807.33 253.17L772.2 232.89L737.07 212.61L701.95 192.33L666.82 172.05L631.69 151.76L596.55 131.48L561.42 111.2L526.29 90.91L491.17 70.64L456.04 50.35L420.91 30.07L385.77 9.78999L350.64 -10.5L315.52 -30.77L280.39 -51.06L245.26 -71.34L210.13 -91.62L70.7598 -172.08L210.13 -91.62L245.26 -71.34L280.39 -51.06L315.52 -30.77L350.64 -10.5L385.77 9.78999L420.91 30.07L456.04 50.35L491.17 70.64L526.29 90.91L561.42 111.2L596.55 131.48L631.69 151.76L666.82 172.05L701.95 192.33L737.07 212.61L772.2 232.89L807.33 253.17L842.47 273.46L877.6 293.74L912.73 314.02L947.85 334.3L982.98 354.58L1018.11 374.87L1053.25 395.15L1088.38 415.44L1123.51 435.72L1158.63 455.99L1193.76 476.28L1228.89 496.56L1264.02 516.85L1299.16 537.13L1334.29 557.41L1369.41 577.69L1404.54 597.97L1439.67 618.26L1474.8 638.54L1509.94 658.82L1545.06 679.1L1580.19 699.38L1615.32 719.67L1650.45 739.95L1789.82 820.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1824.71 800.13L1685.34 719.67L1650.21 699.39L1615.08 679.11L1579.95 658.82L1544.83 638.55L1509.7 618.26L1474.56 597.98L1439.43 577.7L1404.3 557.41L1369.18 537.14L1334.05 516.85L1298.92 496.57L1263.78 476.28L1228.65 456L1193.52 435.72L1158.4 415.44L1123.27 395.16L1088.14 374.87L1053 354.59L1017.87 334.31L982.74 314.02L947.62 293.75L912.49 273.46L877.36 253.18L842.23 232.9L807.09 212.61L771.96 192.33L736.84 172.05L701.71 151.77L666.58 131.49L631.45 111.2L596.31 90.92L561.18 70.64L526.06 50.36L490.93 30.08L455.8 9.78999L420.67 -10.49L385.53 -30.77L350.41 -51.05L315.28 -71.33L280.15 -91.62L245.02 -111.9L105.66 -192.36L245.02 -111.9L280.15 -91.62L315.28 -71.33L350.41 -51.05L385.53 -30.77L420.67 -10.49L455.8 9.78999L490.93 30.08L526.06 50.36L561.18 70.64L596.31 90.92L631.45 111.2L666.58 131.49L701.71 151.77L736.84 172.05L771.96 192.33L807.09 212.61L842.23 232.9L877.36 253.18L912.49 273.46L947.62 293.75L982.74 314.02L1017.87 334.31L1053 354.59L1088.14 374.87L1123.27 395.16L1158.4 415.44L1193.52 435.72L1228.65 456L1263.78 476.28L1298.92 496.57L1334.05 516.85L1369.18 537.14L1404.3 557.41L1439.43 577.7L1474.56 597.98L1509.7 618.26L1544.83 638.55L1579.95 658.82L1615.08 679.11L1650.21 699.39L1685.34 719.67L1824.71 800.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1859.61 779.85L1720.25 699.39L1685.12 679.11L1649.98 658.82L1614.85 638.54L1579.73 618.26L1544.6 597.98L1509.47 577.69L1474.34 557.41L1439.2 537.13L1404.08 516.85L1368.95 496.57L1333.82 476.28L1298.69 456L1263.56 435.72L1228.42 415.43L1193.3 395.16L1158.17 374.87L1123.04 354.59L1087.91 334.31L1052.78 314.02L1017.64 293.74L982.52 273.46L947.39 253.18L912.26 232.9L877.13 212.61L842 192.33L806.87 172.05L771.75 151.77L736.61 131.49L701.48 111.2L666.35 90.92L631.22 70.64L596.09 50.35L560.97 30.08L525.83 9.78999L490.7 -10.49L455.57 -30.77L420.44 -51.06L385.32 -71.33L350.19 -91.62L315.05 -111.9L279.92 -132.18L140.56 -212.65L279.92 -132.18L315.05 -111.9L350.19 -91.62L385.32 -71.33L420.44 -51.06L455.57 -30.77L490.7 -10.49L525.83 9.78999L560.97 30.08L596.09 50.35L631.22 70.64L666.35 90.92L701.48 111.2L736.61 131.49L771.75 151.77L806.87 172.05L842 192.33L877.13 212.61L912.26 232.9L947.39 253.18L982.52 273.46L1017.64 293.74L1052.78 314.02L1087.91 334.31L1123.04 354.59L1158.17 374.87L1193.3 395.16L1228.42 415.43L1263.56 435.72L1298.69 456L1333.82 476.28L1368.95 496.57L1404.08 516.85L1439.2 537.13L1474.34 557.41L1509.47 577.69L1544.6 597.98L1579.73 618.26L1614.85 638.54L1649.98 658.82L1685.12 679.11L1720.25 699.39L1859.61 779.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1894.51 759.57L1755.15 679.1L1720.02 658.82L1684.89 638.54L1649.76 618.25L1614.64 597.98L1579.5 577.69L1544.37 557.41L1509.24 537.13L1474.11 516.84L1438.99 496.57L1403.86 476.28L1368.72 456L1333.59 435.72L1298.46 415.43L1263.33 395.15L1228.21 374.87L1193.08 354.59L1157.94 334.31L1122.81 314.02L1087.68 293.74L1052.55 273.46L1017.43 253.18L982.3 232.9L947.16 212.61L912.03 192.33L876.9 172.05L841.77 151.76L806.65 131.49L771.52 111.2L736.38 90.92L701.25 70.64L666.12 50.35L630.99 30.07L595.87 9.78999L560.74 -10.49L525.61 -30.77L490.47 -51.06L455.34 -71.34L420.22 -91.62L385.09 -111.9L349.96 -132.18L314.83 -152.47L175.46 -232.93L314.83 -152.47L349.96 -132.18L385.09 -111.9L420.22 -91.62L455.34 -71.34L490.47 -51.06L525.61 -30.77L560.74 -10.49L595.87 9.78999L630.99 30.07L666.12 50.35L701.25 70.64L736.38 90.92L771.52 111.2L806.65 131.49L841.77 151.76L876.9 172.05L912.03 192.33L947.16 212.61L982.3 232.9L1017.43 253.18L1052.55 273.46L1087.68 293.74L1122.81 314.02L1157.94 334.31L1193.08 354.59L1228.21 374.87L1263.33 395.15L1298.46 415.43L1333.59 435.72L1368.72 456L1403.86 476.28L1438.99 496.57L1474.11 516.84L1509.24 537.13L1544.37 557.41L1579.5 577.69L1614.64 597.98L1649.76 618.25L1684.89 638.54L1720.02 658.82L1755.15 679.1L1894.51 759.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1929.42 739.28L1790.05 658.82L1754.92 638.54L1719.79 618.25L1684.66 597.97L1649.54 577.69L1614.41 557.41L1579.27 537.13L1544.14 516.84L1509.01 496.56L1473.89 476.28L1438.76 456L1403.63 435.72L1368.5 415.43L1333.36 395.15L1298.23 374.87L1263.11 354.59L1227.98 334.31L1192.85 314.02L1157.72 293.74L1122.58 273.46L1087.45 253.17L1052.33 232.9L1017.2 212.61L982.07 192.33L946.94 172.05L911.8 151.76L876.67 131.48L841.55 111.2L806.42 90.92L771.29 70.64L736.16 50.35L701.02 30.07L665.89 9.78999L630.77 -10.49L595.64 -30.77L560.51 -51.06L525.38 -71.34L490.25 -91.62L455.13 -111.9L419.99 -132.18L384.86 -152.47L349.73 -172.75L210.37 -253.21L349.73 -172.75L384.86 -152.47L419.99 -132.18L455.13 -111.9L490.25 -91.62L525.38 -71.34L560.51 -51.06L595.64 -30.77L630.77 -10.49L665.89 9.78999L701.02 30.07L736.16 50.35L771.29 70.64L806.42 90.92L841.55 111.2L876.67 131.48L911.8 151.76L946.94 172.05L982.07 192.33L1017.2 212.61L1052.33 232.9L1087.45 253.17L1122.58 273.46L1157.72 293.74L1192.85 314.02L1227.98 334.31L1263.11 354.59L1298.23 374.87L1333.36 395.15L1368.5 415.43L1403.63 435.72L1438.76 456L1473.89 476.28L1509.01 496.56L1544.14 516.84L1579.27 537.13L1614.41 557.41L1649.54 577.69L1684.66 597.97L1719.79 618.25L1754.92 638.54L1790.05 658.82L1929.42 739.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1964.32 719L1824.96 638.54L1789.83 618.25L1754.69 597.97L1719.56 577.69L1684.44 557.41L1649.31 537.13L1614.18 516.84L1579.05 496.56L1543.91 476.28L1508.79 456L1473.66 435.72L1438.53 415.43L1403.4 395.15L1368.27 374.87L1333.14 354.58L1298.02 334.31L1262.88 314.02L1227.75 293.74L1192.62 273.46L1157.49 253.17L1122.36 232.89L1087.24 212.61L1052.1 192.33L1016.97 172.05L981.84 151.76L946.71 131.48L911.58 111.2L876.46 90.92L841.32 70.64L806.19 50.35L771.06 30.07L735.93 9.79001L700.8 -10.5L665.68 -30.77L630.54 -51.06L595.41 -71.34L560.28 -91.62L525.15 -111.91L490.03 -132.18L454.9 -152.47L419.76 -172.75L384.63 -193.03L245.27 -273.5L384.63 -193.03L419.76 -172.75L454.9 -152.47L490.03 -132.18L525.15 -111.91L560.28 -91.62L595.41 -71.34L630.54 -51.06L665.68 -30.77L700.8 -10.5L735.93 9.79001L771.06 30.07L806.19 50.35L841.32 70.64L876.46 90.92L911.58 111.2L946.71 131.48L981.84 151.76L1016.97 172.05L1052.1 192.33L1087.24 212.61L1122.36 232.89L1157.49 253.17L1192.62 273.46L1227.75 293.74L1262.88 314.02L1298.02 334.31L1333.14 354.58L1368.27 374.87L1403.4 395.15L1438.53 415.43L1473.66 435.72L1508.79 456L1543.91 476.28L1579.05 496.56L1614.18 516.84L1649.31 537.13L1684.44 557.41L1719.56 577.69L1754.69 597.97L1789.83 618.25L1824.96 638.54L1964.32 719Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1999.23 698.72L1859.86 618.25L1824.73 597.97L1789.6 577.69L1754.47 557.4L1719.35 537.13L1684.21 516.84L1649.08 496.56L1613.95 476.28L1578.82 455.99L1543.7 435.72L1508.57 415.43L1473.43 395.15L1438.3 374.87L1403.17 354.58L1368.04 334.3L1332.92 314.02L1297.79 293.74L1262.65 273.46L1227.52 253.17L1192.39 232.89L1157.26 212.61L1122.14 192.33L1087.01 172.05L1051.88 151.76L1016.74 131.48L981.61 111.2L946.48 90.91L911.36 70.64L876.23 50.35L841.1 30.07L805.96 9.79001L770.83 -10.5L735.7 -30.78L700.58 -51.06L665.45 -71.34L630.32 -91.62L595.18 -111.91L560.05 -132.19L524.93 -152.47L489.8 -172.75L454.67 -193.04L419.54 -213.32L280.17 -293.78L419.54 -213.32L454.67 -193.04L489.8 -172.75L524.93 -152.47L560.05 -132.19L595.18 -111.91L630.32 -91.62L665.45 -71.34L700.58 -51.06L735.7 -30.78L770.83 -10.5L805.96 9.79001L841.1 30.07L876.23 50.35L911.36 70.64L946.48 90.91L981.61 111.2L1016.74 131.48L1051.88 151.76L1087.01 172.05L1122.14 192.33L1157.26 212.61L1192.39 232.89L1227.52 253.17L1262.65 273.46L1297.79 293.74L1332.92 314.02L1368.04 334.3L1403.17 354.58L1438.3 374.87L1473.43 395.15L1508.57 415.43L1543.7 435.72L1578.82 455.99L1613.95 476.28L1649.08 496.56L1684.21 516.84L1719.35 537.13L1754.47 557.4L1789.6 577.69L1824.73 597.97L1859.86 618.25L1999.23 698.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2034.12 678.44L1894.75 597.98L1859.62 577.69L1824.49 557.41L1789.36 537.13L1754.24 516.85L1719.11 496.57L1683.97 476.28L1648.84 456L1613.71 435.72L1578.59 415.44L1543.46 395.16L1508.33 374.87L1473.19 354.59L1438.06 334.31L1402.93 314.02L1367.81 293.75L1332.68 273.46L1297.55 253.18L1262.41 232.9L1227.28 212.61L1192.15 192.33L1157.03 172.05L1121.9 151.77L1086.77 131.49L1051.64 111.2L1016.5 90.92L981.37 70.64L946.25 50.36L911.12 30.08L875.99 9.79001L840.86 -10.49L805.72 -30.77L770.59 -51.06L735.47 -71.33L700.34 -91.62L665.21 -111.9L630.08 -132.19L594.94 -152.47L559.82 -172.75L524.69 -193.03L489.56 -213.31L454.43 -233.6L315.06 -314.06L454.43 -233.6L489.56 -213.31L524.69 -193.03L559.82 -172.75L594.94 -152.47L630.08 -132.19L665.21 -111.9L700.34 -91.62L735.47 -71.33L770.59 -51.06L805.72 -30.77L840.86 -10.49L875.99 9.79001L911.12 30.08L946.25 50.36L981.37 70.64L1016.5 90.92L1051.64 111.2L1086.77 131.49L1121.9 151.77L1157.03 172.05L1192.15 192.33L1227.28 212.61L1262.41 232.9L1297.55 253.18L1332.68 273.46L1367.81 293.75L1402.93 314.02L1438.06 334.31L1473.19 354.59L1508.33 374.87L1543.46 395.16L1578.59 415.44L1613.71 435.72L1648.84 456L1683.97 476.28L1719.11 496.57L1754.24 516.85L1789.36 537.13L1824.49 557.41L1859.62 577.69L1894.75 597.98L2034.12 678.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2069.02 658.15L1929.66 577.69L1894.52 557.41L1859.39 537.13L1824.26 516.84L1789.14 496.57L1754.01 476.28L1718.88 456L1683.75 435.72L1648.61 415.43L1613.49 395.16L1578.36 374.87L1543.23 354.59L1508.1 334.31L1472.97 314.02L1437.83 293.74L1402.71 273.46L1367.58 253.18L1332.45 232.9L1297.32 212.61L1262.19 192.33L1227.05 172.05L1191.93 151.77L1156.8 131.49L1121.67 111.2L1086.54 90.92L1051.41 70.63L1016.27 50.35L981.15 30.08L946.02 9.79001L910.89 -10.49L875.76 -30.78L840.63 -51.06L805.5 -71.34L770.38 -91.62L735.24 -111.9L700.11 -132.19L664.98 -152.47L629.85 -172.75L594.73 -193.03L559.6 -213.31L524.46 -233.6L489.33 -253.88L349.97 -334.34L489.33 -253.88L524.46 -233.6L559.6 -213.31L594.73 -193.03L629.85 -172.75L664.98 -152.47L700.11 -132.19L735.24 -111.9L770.38 -91.62L805.5 -71.34L840.63 -51.06L875.76 -30.78L910.89 -10.49L946.02 9.79001L981.15 30.08L1016.27 50.35L1051.41 70.63L1086.54 90.92L1121.67 111.2L1156.8 131.49L1191.93 151.77L1227.05 172.05L1262.19 192.33L1297.32 212.61L1332.45 232.9L1367.58 253.18L1402.71 273.46L1437.83 293.74L1472.97 314.02L1508.1 334.31L1543.23 354.59L1578.36 374.87L1613.49 395.16L1648.61 415.43L1683.75 435.72L1718.88 456L1754.01 476.28L1789.14 496.57L1824.26 516.84L1859.39 537.13L1894.52 557.41L1929.66 577.69L2069.02 658.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2103.92 637.87L1964.56 557.41L1929.43 537.13L1894.3 516.84L1859.16 496.56L1824.04 476.28L1788.91 456L1753.78 435.72L1718.65 415.43L1683.52 395.15L1648.4 374.87L1613.27 354.59L1578.13 334.31L1543 314.02L1507.87 293.74L1472.74 273.46L1437.62 253.18L1402.49 232.9L1367.35 212.61L1332.22 192.33L1297.09 172.04L1261.96 151.76L1226.84 131.48L1191.71 111.2L1156.57 90.92L1121.44 70.63L1086.31 50.35L1051.18 30.07L1016.06 9.79001L980.93 -10.49L945.79 -30.78L910.66 -51.06L875.53 -71.34L840.4 -91.63L805.28 -111.9L770.15 -132.19L735.02 -152.47L699.88 -172.75L664.75 -193.04L629.63 -213.31L594.5 -233.6L559.37 -253.88L524.24 -274.16L384.87 -354.62L524.24 -274.16L559.37 -253.88L594.5 -233.6L629.63 -213.31L664.75 -193.04L699.88 -172.75L735.02 -152.47L770.15 -132.19L805.28 -111.9L840.4 -91.63L875.53 -71.34L910.66 -51.06L945.79 -30.78L980.93 -10.49L1016.06 9.79001L1051.18 30.07L1086.31 50.35L1121.44 70.63L1156.57 90.92L1191.71 111.2L1226.84 131.48L1261.96 151.76L1297.09 172.04L1332.22 192.33L1367.35 212.61L1402.49 232.9L1437.62 253.18L1472.74 273.46L1507.87 293.74L1543 314.02L1578.13 334.31L1613.27 354.59L1648.4 374.87L1683.52 395.15L1718.65 415.43L1753.78 435.72L1788.91 456L1824.04 476.28L1859.16 496.56L1894.3 516.84L1929.43 537.13L1964.56 557.41L2103.92 637.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_5446">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* Verwarming */
/* TODO: deze werkt niet, om 1 of ander reden wordt step18Improvement getoond waar deze zou moete staan */
export const step7Improvement = (
  <svg
    width="70px"
    height="70px"
    viewBox="0 0 128 128"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <g clip-path="url(#clip0_68_47)">
<path d="M458.32 508.28L457.33 1035.62L841.46 812.86L838.5 325.73L458.32 508.28Z" fill="#BBE5F0"/>
<path d="M106.03 832.8L457.33 1035.62L458.32 508.28L103.06 305.45L106.03 832.8Z" fill="#71CFEC"/>
<path d="M103.06 305.45L458.32 508.28L663.08 264.89L311.79 62.0701L103.06 305.45Z" fill="#00545E"/>
<path d="M663.08 264.89L838.5 325.73L458.32 508.28L663.08 264.89Z" fill="#BBE5F0"/>
<path d="M311.79 62.0701L663.08 264.89L838.5 325.73L455.46 103.86L311.79 62.0701Z" fill="#CD163F"/>
<path d="M562.46 974.86L664.46 914.86L664.91 630.11L562.72 690.82L562.46 974.86Z" fill="#71CFEC"/>
<mask id="mask0_68_47" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="714" y="563" width="72" height="194">
<path d="M715.06 756.85L714.62 604.42L784.86 563.87L785.29 716.3L715.06 756.85Z" fill="white"/>
</mask>
<g mask="url(#mask0_68_47)">
<path d="M784.86 563.87L714.62 604.42L715.06 756.85L785.29 716.3L784.86 563.87Z" fill="#71CFEC"/>
</g>
<mask id="mask1_68_47" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="309" y="633" width="72" height="194">
<path d="M379.86 826.85L380.29 674.42L310.06 633.87L309.62 786.3L379.86 826.85Z" fill="white"/>
</mask>
<g mask="url(#mask1_68_47)">
<path d="M310.06 633.87L380.29 674.42L379.86 826.85L309.62 786.3L310.06 633.87Z" fill="#BBE5F0"/>
</g>
<mask id="mask2_68_47" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="170" y="552" width="72" height="194">
<path d="M240.86 745.85L241.29 593.42L171.06 552.87L170.62 705.3L240.86 745.85Z" fill="white"/>
</mask>
<g mask="url(#mask2_68_47)">
<path d="M171.06 552.87L241.29 593.42L240.86 745.85L170.62 705.3L171.06 552.87Z" fill="#BBE5F0"/>
</g>
<path d="M103.06 305.45L458.32 508.28L663.08 264.89" stroke="#71CFEC" stroke-width="2" stroke-miterlimit="10"/>
<path d="M457.33 1035.62L458.32 508.28" stroke="#71CFEC" stroke-width="2" stroke-miterlimit="10"/>
<path d="M770.73 539.73L748.98 527.09C748.31 526.7 747.87 525.87 747.86 524.57L769.61 537.21C769.62 538.51 770.06 539.34 770.73 539.73Z" fill="#CD163F"/>
<path d="M860.93 306.35C860.9 295.06 856.92 287.12 850.49 283.38L872.24 296.02C878.66 299.76 882.64 307.71 882.68 318.99C882.74 341.73 866.76 369.43 846.97 380.85L825.22 368.21C845.01 356.78 861 329.08 860.93 306.35Z" fill="#CD163F"/>
<path d="M843.71 294.95L865.46 307.59C870.3 310.41 873.15 316.42 873.18 324.48C873.23 341.16 861.46 361.54 846.95 369.92L825.2 357.28C839.71 348.9 851.48 328.52 851.43 311.84C851.4 303.78 848.55 297.76 843.71 294.95Z" fill="white"/>
<path d="M825.23 342.09C832.66 337.8 838.67 327.4 838.64 318.86C845.89 323.07 853.14 327.29 860.39 331.5C860.41 340.04 854.41 350.44 846.98 354.73C840.45 350.93 831.75 345.88 825.23 342.09Z" fill="#CD163F"/>
<path d="M836.02 311.22L836.01 311.21C843.25 315.42 850.49 319.62 857.73 323.83C849.3 318.94 836.02 311.22 836.02 311.22Z" fill="#CD163F"/>
<path d="M799.68 370.68C806.2 374.49 815.24 373.97 825.22 368.21L846.97 380.85C836.99 386.61 827.95 387.13 821.43 383.32C814.91 379.53 806.21 374.47 799.68 370.68Z" fill="#CD163F"/>
<path d="M828.22 371.73L806.47 359.09C811.26 361.87 817.89 361.49 825.19 357.27L846.94 369.91C839.63 374.13 833.01 374.51 828.22 371.73Z" fill="white"/>
<path d="M838.64 318.86C838.63 314.63 837.14 311.65 834.73 310.24L856.48 322.88C856.93 323.14 857.35 323.46 857.73 323.83C850.49 319.62 843.25 315.42 836.01 311.21L836.02 311.22C840.73 313.96 855.53 322.56 857.72 323.83C858.55 324.64 859.22 325.7 859.68 326.99C852.44 322.78 845.2 318.58 837.96 314.37C841.58 316.47 845.2 318.58 848.82 320.68C852.44 322.78 856.06 324.89 859.68 326.99C860.13 328.27 860.38 329.78 860.39 331.5C853.14 327.28 845.89 323.07 838.64 318.86Z" fill="#CD163F"/>
<path d="M815.64 343.01C818.09 344.44 821.48 344.24 825.23 342.08L846.98 354.72C843.23 356.88 839.84 357.08 837.39 355.65C830.86 351.86 822.16 346.81 815.64 343.01Z" fill="#CD163F"/>
<path d="M834.71 310.23L856.46 322.87C854.95 321.99 853.09 321.73 851.01 322.16L829.26 309.52C831.35 309.09 833.21 309.36 834.71 310.23Z" fill="white"/>
<path d="M769.61 537.22L747.87 524.58L747.65 449.62L769.4 462.25L769.61 537.22Z" fill="#940731"/>
<path d="M838.18 293.24C840.22 293.42 842.07 294 843.69 294.94L865.44 307.58C863.82 306.64 861.97 306.06 859.93 305.88C853.4 302.08 844.71 297.03 838.18 293.24Z" fill="#CD163F"/>
<path d="M850.48 283.36L872.23 296C869.99 294.7 867.44 293.9 864.67 293.66L842.92 281.02C845.7 281.27 848.24 282.06 850.48 283.36Z" fill="white"/>
<path d="M837.4 355.66L815.65 343.02C813.23 341.62 811.74 338.63 811.73 334.39L833.48 347.03C833.49 351.27 834.98 354.26 837.4 355.66Z" fill="white"/>
<path d="M855.17 305.46L833.42 292.82L838.18 293.24L859.93 305.88L855.17 305.46Z" fill="#CD163F"/>
<path d="M806.48 359.1C802.95 357.05 800.41 353.28 799.35 348.05C806.6 352.26 813.85 356.48 821.1 360.69C822.17 365.92 824.7 369.69 828.23 371.74C821.7 367.95 813.01 362.89 806.48 359.1Z" fill="#CD163F"/>
<path d="M833.48 347.03L811.73 334.39C811.71 327.5 815.61 319.41 821.02 314.28L842.77 326.92C837.36 332.05 833.46 340.14 833.48 347.03Z" fill="white"/>
<path d="M798.79 342.23C798.78 337.53 799.73 332.41 801.48 327.29C808.73 331.5 815.98 335.72 823.23 339.93C821.48 345.05 820.53 350.17 820.54 354.87C820.55 356.97 820.74 358.92 821.1 360.69C813.85 356.48 806.6 352.26 799.35 348.05C798.99 346.27 798.79 344.33 798.79 342.23Z" fill="#CD163F"/>
<path d="M864.67 293.66L842.92 281.02L842.56 152.91L864.3 165.54L864.67 293.66Z" fill="#CD163F"/>
<path d="M855.17 305.46L833.42 292.82L833.04 158.4L854.79 171.04L855.17 305.46Z" fill="white"/>
<path d="M851.01 322.16L829.26 309.53L828.85 164.16L850.6 176.8L851.01 322.16Z" fill="#CD163F"/>
<path d="M842.77 326.92L821.02 314.29L820.61 168.92L842.35 181.56L842.77 326.92Z" fill="white"/>
<path d="M833.62 321.06L811.88 308.43L816.62 302.52L838.37 315.16L833.62 321.06Z" fill="#CD163F"/>
<path d="M801.47 327.29C803.73 320.7 807.31 314.11 811.87 308.43L833.62 321.07C829.06 326.75 825.47 333.34 823.22 339.93C815.97 335.71 808.72 331.5 801.47 327.29Z" fill="#CD163F"/>
<path d="M821.47 383.34L799.72 370.7C793.29 366.96 789.3 359.01 789.27 347.72C789.23 332.48 796.39 315.02 807.09 301.71L828.84 314.35C818.15 327.65 810.98 345.12 811.02 360.36C811.05 371.65 815.04 379.6 821.47 383.34Z" fill="white"/>
<path d="M725.84 477.08L704.09 464.44C698.66 461.28 695.29 454.57 695.26 445.03L717.01 457.67C717.04 467.21 720.4 473.93 725.84 477.08Z" fill="#940731"/>
<path d="M945.72 46.4301L967.47 59.0701C961.97 55.8701 954.35 56.3201 945.94 61.1801L924.19 48.5401C932.59 43.6801 940.21 43.2301 945.72 46.4301Z" fill="#940731"/>
<path d="M828.85 164.16C828.85 162.86 828.39 161.95 827.65 161.52L849.4 174.16C850.14 174.59 850.59 175.5 850.6 176.8C844.07 173.01 835.38 167.95 828.85 164.16Z" fill="#CD163F"/>
<path d="M842.56 152.91C842.54 147.27 840.56 143.3 837.35 141.43L859.1 154.07C862.3 155.94 864.29 159.91 864.31 165.55C857.78 161.75 849.08 156.7 842.56 152.91Z" fill="#CD163F"/>
<path d="M830.59 153.01L852.34 165.65C853.85 166.53 854.79 168.39 854.8 171.04L833.05 158.4C833.04 155.75 832.1 153.89 830.59 153.01Z" fill="white"/>
<path d="M827.65 161.52L849.4 174.16C848.65 173.72 847.61 173.79 846.47 174.45L824.72 161.81C825.86 161.14 826.9 161.08 827.65 161.52Z" fill="white"/>
<path d="M842.35 181.56L820.6 168.92C820.59 166.31 822.43 163.12 824.71 161.8L846.46 174.44C844.19 175.75 842.35 178.94 842.35 181.56Z" fill="white"/>
<path d="M838.37 315.16L816.62 302.52L816.24 168.1L837.99 180.74L838.37 315.16Z" fill="#CD163F"/>
<path d="M830.59 153.01L852.34 165.65C850.81 164.76 848.7 164.88 846.37 166.23C841.74 168.91 837.98 175.42 838 180.74L816.25 168.1C816.23 162.77 819.99 156.26 824.62 153.59C826.95 152.25 829.06 152.12 830.59 153.01Z" fill="#CD163F"/>
<path d="M837.33 141.42L859.08 154.06C855.82 152.17 851.31 152.43 846.33 155.31C836.44 161.02 828.44 174.87 828.47 186.24L806.72 173.6C806.69 162.23 814.68 148.38 824.58 142.67C829.56 139.79 834.07 139.52 837.33 141.42Z" fill="white"/>
<path d="M864.67 293.66C875.4 294.6 882.64 303.74 882.68 318.98C882.74 341.72 866.76 369.42 846.97 380.84C827.18 392.26 811.08 383.09 811.02 360.35C810.98 345.11 818.14 327.65 828.84 314.34L828.48 186.22C828.45 174.85 836.44 161 846.34 155.29C856.24 149.58 864.28 154.16 864.32 165.53L864.67 293.66ZM846.94 369.91C861.45 361.53 873.22 341.15 873.17 324.47C873.14 313.71 868.06 306.58 859.93 305.87L855.17 305.45L854.79 171.03C854.77 165.71 850.99 163.55 846.36 166.22C841.73 168.9 837.97 175.41 837.99 180.73L838.37 315.15L833.63 321.05C825.52 331.14 820.51 344.09 820.54 354.85C820.58 371.54 832.42 378.29 846.94 369.91Z" fill="white"/>
<path d="M859.93 305.88C868.07 306.59 873.14 313.71 873.17 324.48C873.22 341.16 861.45 361.54 846.94 369.92C832.43 378.3 820.58 371.55 820.54 354.87C820.51 344.11 825.52 331.16 833.63 321.07L838.37 315.17L837.99 180.75C837.97 175.42 841.73 168.91 846.36 166.24C850.99 163.56 854.77 165.72 854.79 171.05L855.17 305.47L859.93 305.88ZM846.97 354.72C854.4 350.43 860.41 340.03 860.38 331.49C860.36 324.6 856.42 321.04 851.01 322.16L850.6 176.8C850.59 174.19 848.74 173.13 846.46 174.44C844.18 175.75 842.34 178.94 842.35 181.56L842.76 326.93C837.35 332.06 833.45 340.15 833.47 347.04C833.5 355.57 839.54 359.01 846.97 354.72Z" fill="#CD163F"/>
<path d="M846.46 174.44C848.74 173.13 850.59 174.18 850.6 176.8L851.01 322.17C856.43 321.05 860.36 324.61 860.38 331.5C860.4 340.04 854.4 350.44 846.97 354.73C839.54 359.02 833.5 355.58 833.47 347.04C833.45 340.15 837.35 332.06 842.76 326.93L842.35 181.56C842.35 178.94 844.19 175.75 846.46 174.44Z" fill="white"/>
<path d="M828.83 314.35L807.09 301.71L806.72 173.6L828.47 186.23L828.83 314.35Z" fill="white"/>
<path d="M717.01 457.67L695.26 445.04L694.61 215.9L716.36 228.54L717.01 457.67Z" fill="#940731"/>
<path d="M746.52 176.3L724.77 163.66L924.18 48.54L945.93 61.17L746.52 176.3Z" fill="#940731"/>
<path d="M945.93 61.1701C962.64 51.5201 976.23 59.2601 976.29 78.4701L976.94 307.6C976.99 326.8 963.49 350.19 946.78 359.84L838.35 422.44L776.45 536.32C775.64 537.81 774.61 538.86 773.59 539.45C771.58 540.61 769.63 539.95 769.62 537.22L769.41 462.25L747.38 474.97C730.67 484.62 717.08 476.87 717.02 457.67L716.37 228.54C716.32 209.33 729.82 185.95 746.53 176.3L945.93 61.1701ZM846.97 380.84C866.76 369.42 882.75 341.72 882.68 318.98C882.64 303.74 875.39 294.6 864.67 293.66L864.31 165.54C864.28 154.17 856.23 149.58 846.34 155.3C836.45 161.01 828.45 174.86 828.48 186.23L828.84 314.35C818.15 327.65 810.98 345.12 811.02 360.36C811.08 383.1 827.18 392.27 846.97 380.84Z" fill="#CD163F"/>
<path d="M716.36 228.54L694.61 215.9C694.56 196.69 708.06 173.31 724.77 163.66L746.52 176.3C729.81 185.95 716.31 209.33 716.36 228.54Z" fill="#940731"/>
</g>
<defs>
<clipPath id="clip0_68_47">
<rect width="1080" height="1080" fill="white"/>
</clipPath>
</defs>
  </svg>
)

/* Ventilatie */
export const step21Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M430.84 294.42L101.12 484.78L102.62 1014.87L432.34 824.51L430.84 294.42ZM120.01 966.93L118.69 502.8L403.49 338.37L404.81 802.5L120.01 966.93Z"
      fill="#00A3DE"
    />
    <path
      d="M414.78 806.49L413.44 332.63L403.49 338.37L404.81 802.5L120.01 966.93L120.03 976.66L414.78 806.49Z"
      fill="#71CFEC"
    />
    <path
      d="M118.69 502.8L120.01 752.68L404.81 588.25L403.49 338.37L118.69 502.8Z"
      fill="#BBE5F0"
    />
    <path
      d="M102.63 1014.87L81.54 1002.62L80.04 472.52L101.12 484.78L102.63 1014.87Z"
      fill="#71CFEC"
    />
    <path
      d="M101.12 484.78L80.04 472.52L409.76 282.16L430.84 294.41L101.12 484.78Z"
      fill="#00545E"
    />
    <path
      d="M819.46 77.38L489.74 267.75L491.24 797.84L820.96 607.48L819.46 77.38ZM508.62 749.9L507.3 285.77L792.1 121.34L793.42 585.47L508.62 749.9Z"
      fill="#00A3DE"
    />
    <path
      d="M803.4 589.46L802.05 115.59L792.11 121.34L793.42 585.47L508.62 749.9L508.65 759.63L803.4 589.46Z"
      fill="#71CFEC"
    />
    <path
      d="M507.31 285.77L508.62 535.65L793.42 371.22L792.11 121.34L507.31 285.77Z"
      fill="#BBE5F0"
    />
    <path
      d="M491.24 797.84L470.16 785.58L468.65 255.49L489.74 267.75L491.24 797.84Z"
      fill="#71CFEC"
    />
    <path
      d="M489.74 267.75L468.65 255.49L798.37 65.13L819.46 77.38L489.74 267.75Z"
      fill="#00545E"
    />
    <path
      d="M366.61 907.09C370.02 905.12 370.03 901.93 366.65 899.96L304.49 863.84C301.1 861.87 295.59 861.87 292.18 863.84C288.77 865.81 288.76 868.99 292.15 870.96L354.31 907.08C357.7 909.05 363.21 909.06 366.61 907.09Z"
      fill="#BBE5F0"
    />
    <path
      d="M279.92 856.71C283.33 854.74 283.35 851.55 279.97 849.59L178.1 790.39C174.72 788.42 169.2 788.42 165.79 790.39C162.38 792.36 162.37 795.55 165.76 797.51L267.63 856.71C271.02 858.68 276.52 858.68 279.92 856.71Z"
      fill="#BBE5F0"
    />
    <path
      d="M591 925.99C602.26 919.49 607.91 910.95 607.96 902.47C608.02 893.98 602.43 885.43 591.27 878.95L531.57 844.26C525.15 840.53 516.66 838.65 508.22 838.65C499.79 838.65 491.27 840.53 484.81 844.26C478.35 847.99 475.07 852.92 475.05 857.8C475.01 862.69 478.23 867.62 484.66 871.34L512.72 887.65C516.1 889.62 521.61 889.62 525.02 887.66C528.44 885.69 528.44 882.5 525.06 880.53L497 864.22C493.93 862.43 492.44 860.14 492.45 857.8C492.47 855.46 493.99 853.17 497.07 851.38C500.17 849.6 504.13 848.73 508.17 848.72C512.2 848.73 516.15 849.6 519.24 851.38L578.94 886.07C586.74 890.61 590.59 896.52 590.56 902.46C590.52 908.41 586.61 914.32 578.74 918.86C570.88 923.4 560.66 925.64 550.4 925.65C540.12 925.65 529.94 923.4 522.13 918.86L279.48 777.85C276.09 775.88 270.59 775.88 267.17 777.85C263.76 779.82 263.75 783 267.14 784.97L509.8 925.99C520.95 932.48 535.68 935.74 550.35 935.73C565.01 935.73 579.79 932.47 591 925.99Z"
      fill="#BBE5F0"
    />
    <path
      d="M456.74 839.86C459.8 838.09 461.95 836.07 463.27 833.92C464.62 831.76 465.14 829.47 464.92 827.21C464.49 822.69 461.14 818.23 455.15 814.74L407.37 786.97C403.99 785 398.48 785.01 395.07 786.98C391.66 788.95 391.65 792.13 395.03 794.1L442.81 821.87C445.09 823.2 446.52 824.74 447.17 826.28C447.83 827.82 447.67 829.32 446.91 830.54C446.39 831.36 445.63 832.08 444.55 832.7C443.47 833.33 442.22 833.77 440.81 834.06C439.38 834.35 437.78 834.49 436.07 834.43C432.65 834.32 428.89 833.42 425.85 831.66L282.8 748.53C279.42 746.56 273.92 746.56 270.51 748.53C267.09 750.5 267.08 753.69 270.46 755.65L413.51 838.78C418.01 841.39 423.47 843.15 429.2 843.97C434.91 844.79 440.97 844.7 446.58 843.54C450.29 842.8 453.8 841.56 456.74 839.86Z"
      fill="#BBE5F0"
    />
    <path
      d="M418.37 929.61C429.07 923.43 434.48 915.29 434.53 907.2C434.58 899.11 429.25 890.97 418.62 884.79L202.39 759.12C199.01 757.15 193.49 757.15 190.08 759.12C186.67 761.09 186.67 764.28 190.05 766.24L406.29 891.9C413.56 896.14 417.15 901.64 417.14 907.19C417.09 912.74 413.44 918.24 406.12 922.48C398.78 926.71 389.27 928.8 379.69 928.81C370.1 928.8 360.61 926.71 353.33 922.48L313.24 899.18C309.86 897.21 304.35 897.21 300.93 899.18C297.52 901.15 297.51 904.34 300.9 906.3L340.99 929.6C351.61 935.78 365.65 938.89 379.63 938.89C393.59 938.9 407.67 935.79 418.37 929.61Z"
      fill="#BBE5F0"
    />
    <path
      d="M233.93 861.85C233.93 861.85 198.39 904.06 259.56 930.19C259.56 930.18 290.65 890.61 233.93 861.85Z"
      fill="#00664F"
    />
    <path
      d="M345.16 755.87C345.16 755.87 362.21 788.12 393.4 757.13C393.39 757.12 376.86 728.25 345.16 755.87Z"
      fill="#00664F"
    />
    <path
      d="M455.49 970.33C455.49 970.33 490.19 946.95 469.48 929.32C469.48 929.32 433.97 937.6 455.49 970.33Z"
      fill="#00664F"
    />
    <path
      d="M573.33 827.15C573.33 827.15 581.61 786.14 554.49 788.13C554.49 788.12 535.03 818.96 573.33 827.15Z"
      fill="#00664F"
    />
    <path
      d="M307.61 824.28C307.61 824.28 336.17 854.85 350.32 831.63C350.32 831.63 336.54 797.87 307.61 824.28Z"
      fill="#00664F"
    />
    <path
      d="M758.61 670.09C762.02 668.12 762.03 664.93 758.65 662.96L696.49 626.84C693.1 624.87 687.59 624.87 684.18 626.84C680.77 628.81 680.76 631.99 684.15 633.96L746.31 670.08C749.7 672.05 755.21 672.06 758.61 670.09Z"
      fill="#BBE5F0"
    />
    <path
      d="M671.92 619.71C675.33 617.74 675.35 614.55 671.97 612.59L570.1 553.39C566.72 551.42 561.2 551.42 557.79 553.39C554.38 555.36 554.37 558.55 557.76 560.51L659.63 619.71C663.02 621.68 668.52 621.68 671.92 619.71Z"
      fill="#BBE5F0"
    />
    <path
      d="M983 688.99C994.26 682.49 999.91 673.95 999.96 665.47C1000.02 656.98 994.43 648.43 983.27 641.95L923.57 607.26C917.15 603.53 908.66 601.65 900.22 601.65C891.79 601.65 883.27 603.53 876.81 607.26C870.35 610.99 867.07 615.92 867.05 620.8C867.01 625.69 870.23 630.62 876.66 634.34L904.72 650.65C908.1 652.62 913.61 652.62 917.02 650.66C920.44 648.69 920.44 645.5 917.06 643.53L889 627.22C885.93 625.43 884.44 623.14 884.45 620.8C884.47 618.46 885.99 616.17 889.07 614.38C892.17 612.6 896.13 611.73 900.17 611.72C904.2 611.73 908.15 612.6 911.24 614.38L970.94 649.07C978.74 653.61 982.59 659.52 982.56 665.46C982.52 671.41 978.61 677.32 970.74 681.86C962.88 686.4 952.66 688.64 942.4 688.65C932.12 688.65 921.94 686.4 914.13 681.86L671.48 540.85C668.09 538.88 662.59 538.88 659.17 540.85C655.76 542.82 655.75 546 659.14 547.97L901.8 688.99C912.95 695.48 927.68 698.74 942.35 698.73C957.01 698.73 971.79 695.47 983 688.99Z"
      fill="#BBE5F0"
    />
    <path
      d="M848.74 602.86C851.8 601.09 853.95 599.07 855.27 596.92C856.62 594.76 857.14 592.47 856.92 590.21C856.49 585.69 853.14 581.23 847.15 577.74L799.37 549.97C795.99 548 790.48 548.01 787.07 549.98C783.66 551.95 783.65 555.13 787.03 557.1L834.81 584.87C837.09 586.2 838.52 587.74 839.17 589.28C839.83 590.82 839.67 592.32 838.91 593.54C838.39 594.36 837.63 595.08 836.55 595.7C835.47 596.33 834.22 596.77 832.81 597.06C831.38 597.35 829.78 597.49 828.07 597.43C824.65 597.32 820.89 596.42 817.85 594.66L674.8 511.53C671.42 509.56 665.92 509.56 662.51 511.53C659.09 513.5 659.08 516.69 662.46 518.65L805.51 601.78C810.01 604.39 815.47 606.15 821.2 606.97C826.91 607.79 832.97 607.7 838.58 606.54C842.29 605.8 845.8 604.56 848.74 602.86Z"
      fill="#BBE5F0"
    />
    <path
      d="M810.37 692.61C821.07 686.43 826.48 678.29 826.53 670.2C826.58 662.11 821.25 653.97 810.62 647.79L594.39 522.12C591.01 520.15 585.49 520.15 582.08 522.12C578.67 524.09 578.67 527.28 582.05 529.24L798.29 654.9C805.56 659.14 809.15 664.64 809.14 670.19C809.09 675.74 805.44 681.24 798.12 685.48C790.78 689.71 781.27 691.8 771.69 691.81C762.1 691.8 752.61 689.71 745.33 685.48L705.24 662.18C701.86 660.21 696.35 660.21 692.93 662.18C689.52 664.15 689.51 667.34 692.9 669.3L732.99 692.6C743.61 698.78 757.65 701.89 771.63 701.89C785.59 701.9 799.67 698.79 810.37 692.61Z"
      fill="#BBE5F0"
    />
    <path
      d="M625.93 624.85C625.93 624.85 590.39 667.06 651.56 693.19C651.56 693.18 682.65 653.61 625.93 624.85Z"
      fill="#00664F"
    />
    <path
      d="M737.16 518.87C737.16 518.87 754.21 551.12 785.4 520.13C785.39 520.12 768.86 491.25 737.16 518.87Z"
      fill="#00664F"
    />
    <path
      d="M847.49 733.33C847.49 733.33 882.19 709.95 861.48 692.32C861.48 692.32 825.97 700.6 847.49 733.33Z"
      fill="#00664F"
    />
    <path
      d="M965.33 590.15C965.33 590.15 973.61 549.14 946.49 551.13C946.49 551.12 927.03 581.96 965.33 590.15Z"
      fill="#00664F"
    />
    <path
      d="M699.61 587.28C699.61 587.28 728.17 617.85 742.32 594.63C742.32 594.63 728.54 560.87 699.61 587.28Z"
      fill="#00664F"
    />
  </svg>
)

/* Zonnepanelen */
export const step22Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <path
        d="M414.59 69.74C526.19 5.31001 616.95 57 617.32 185.24C617.68 313.48 527.51 469.67 415.91 534.1C304.31 598.53 213.55 546.81 213.18 418.57C212.81 290.33 302.99 134.17 414.59 69.74Z"
        fill="#CD163F"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M414.75 126.02C499.3 77.21 568.06 116.37 568.34 213.52C568.62 310.67 500.3 429 415.75 477.82C331.2 526.64 262.44 487.45 262.16 390.3C261.88 293.15 330.2 174.83 414.75 126.02Z"
        fill="#CD163F"
      />
    </g>
    <path
      d="M414.92 186.42C470.44 154.37 515.59 180.08 515.77 243.88C515.95 307.67 471.09 385.37 415.57 417.42C360.05 449.47 314.9 423.74 314.72 359.95C314.54 296.16 359.4 218.47 414.92 186.42Z"
      fill="#CD163F"
    />
    <path
      d="M565.85 623.74L565.07 1037.98L866.82 863L864.5 480.33L565.85 623.74Z"
      fill="#BBE5F0"
    />
    <path
      d="M289.11 878.66L565.07 1037.98L565.85 623.74L286.77 464.4L289.11 878.66Z"
      fill="#71CFEC"
    />
    <path
      d="M286.77 464.4L565.85 623.74L726.7 432.54L450.74 273.21L286.77 464.4Z"
      fill="#00545E"
    />
    <path
      d="M726.7 432.54L864.5 480.33L565.85 623.74L726.7 432.54Z"
      fill="#BBE5F0"
    />
    <path
      d="M450.74 273.21L726.7 432.54L864.5 480.33L563.6 306.04L450.74 273.21Z"
      fill="#CD163F"
    />
    <path
      d="M647.65 990.26L727.78 943.13L728.13 719.44L647.86 767.13L647.65 990.26Z"
      fill="#71CFEC"
    />
    <mask
      id="mask0_68_982"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="767"
      y="667"
      width="56"
      height="152"
    >
      <path
        d="M767.53 819L767.19 699.25L822.36 667.4L822.7 787.15L767.53 819Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_68_982)">
      <path
        d="M822.36 667.4L767.19 699.25L767.53 819L822.7 787.15L822.36 667.4Z"
        fill="#71CFEC"
      />
    </g>
    <mask
      id="mask1_68_982"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="449"
      y="722"
      width="56"
      height="152"
    >
      <path
        d="M504.21 873.99L504.55 754.24L449.38 722.39L449.04 842.14L504.21 873.99Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_68_982)">
      <path
        d="M449.38 722.39L504.55 754.24L504.21 873.99L449.04 842.14L449.38 722.39Z"
        fill="#BBE5F0"
      />
    </g>
    <mask
      id="mask2_68_982"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="339"
      y="658"
      width="57"
      height="153"
    >
      <path
        d="M395.02 810.36L395.36 690.61L340.19 658.76L339.85 778.51L395.02 810.36Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_68_982)">
      <path
        d="M340.19 658.76L395.36 690.61L395.02 810.36L339.85 778.51L340.19 658.76Z"
        fill="#BBE5F0"
      />
    </g>
    <path
      d="M286.77 464.4L565.85 623.74L726.7 432.54"
      stroke="#71CFEC"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M565.07 1037.98L565.85 623.74"
      stroke="#71CFEC"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M467.26 324.93L351.68 459.7L426.92 502.66L542.23 368.21L467.26 324.93Z"
      fill="#71CFEC"
    />
    <path
      d="M467.26 320.21L351.68 454.99L426.92 497.95L542.23 363.5L467.26 320.21Z"
      fill="#BBE5F0"
    />
    <path d="M351.68 459.7V454.99L358.69 458.99L351.68 459.7Z" fill="#71CFEC" />
    <path d="M542.23 363.5V368.21L533.54 373.63L542.23 363.5Z" fill="#71CFEC" />
    <path
      d="M461.06 334.85L366.28 445.37L432.98 483.45L527.51 373.22L461.06 334.85Z"
      fill="#00545E"
    />
    <path
      d="M531.41 368.67L464.96 330.3L461.06 334.85L527.51 373.22L531.41 368.67Z"
      fill="#00A3DE"
    />
    <path
      d="M454.9 342.03L521.37 380.38L525.26 375.85L458.79 337.5L454.9 342.03Z"
      fill="#00A3DE"
    />
    <path
      d="M448.74 349.22L515.22 387.55L519.11 383.02L452.62 344.69L448.74 349.22Z"
      fill="#00A3DE"
    />
    <path
      d="M442.58 356.4L509.08 394.71L512.95 390.19L446.45 351.88L442.58 356.4Z"
      fill="#00A3DE"
    />
    <path
      d="M436.42 363.58L502.93 401.88L506.8 397.37L440.29 359.07L436.42 363.58Z"
      fill="#00A3DE"
    />
    <path
      d="M430.26 370.76L496.79 409.04L500.65 404.54L434.12 366.26L430.26 370.76Z"
      fill="#00A3DE"
    />
    <path
      d="M424.1 377.95L490.65 416.21L494.5 411.71L427.95 373.45L424.1 377.95Z"
      fill="#00A3DE"
    />
    <path
      d="M417.94 385.13L484.5 423.37L488.35 418.89L421.78 380.65L417.94 385.13Z"
      fill="#00A3DE"
    />
    <path
      d="M411.78 392.31L478.36 430.54L482.19 426.06L415.62 387.84L411.78 392.31Z"
      fill="#00A3DE"
    />
    <path
      d="M405.62 399.49L472.21 437.7L476.04 433.24L409.45 395.03L405.62 399.49Z"
      fill="#00A3DE"
    />
    <path
      d="M399.46 406.68L466.07 444.87L469.89 440.41L403.28 402.22L399.46 406.68Z"
      fill="#00A3DE"
    />
    <path
      d="M393.3 413.86L459.92 452.03L463.74 447.58L397.11 409.41L393.3 413.86Z"
      fill="#00A3DE"
    />
    <path
      d="M387.14 421.04L453.78 459.2L457.58 454.76L390.95 416.6L387.14 421.04Z"
      fill="#00A3DE"
    />
    <path
      d="M380.98 428.23L447.63 466.36L451.43 461.93L384.78 423.8L380.98 428.23Z"
      fill="#00A3DE"
    />
    <path
      d="M374.82 435.41L441.49 473.53L445.28 469.1L378.61 430.99L374.82 435.41Z"
      fill="#00A3DE"
    />
    <path
      d="M368.66 442.59L435.34 480.69L439.13 476.28L372.44 438.18L368.66 442.59Z"
      fill="#00A3DE"
    />
    <path
      d="M362.5 449.77L429.2 487.86L432.98 483.45L366.28 445.37L362.5 449.77Z"
      fill="#00A3DE"
    />
    <path
      d="M446.83 403.67L389.21 470.86L393.66 473.41L451.27 406.24L446.83 403.67Z"
      fill="#BBE5F0"
    />
    <path
      d="M498.01 344.09L446.55 404.09L451.01 406.63L501.34 347.94C500.08 346.78 498.91 345.5 498.01 344.09Z"
      fill="#BBE5F0"
    />
    <path
      d="M489.34 425.27L414.38 381.98L411.91 384.85L486.88 428.14L489.34 425.27Z"
      fill="#BBE5F0"
    />
    <path
      d="M576.45 386.98L460.87 521.76L536.12 564.72L651.42 430.27L576.45 386.98Z"
      fill="#71CFEC"
    />
    <path
      d="M576.45 382.27L460.87 517.05L536.12 560.01L651.42 425.55L576.45 382.27Z"
      fill="#BBE5F0"
    />
    <path
      d="M460.87 521.76V517.05L467.88 521.05L460.87 521.76Z"
      fill="#71CFEC"
    />
    <path
      d="M651.42 425.55V430.27L642.73 435.69L651.42 425.55Z"
      fill="#71CFEC"
    />
    <path
      d="M570.25 396.91L475.47 507.43L542.17 545.51L636.7 435.28L570.25 396.91Z"
      fill="#00545E"
    />
    <path
      d="M640.6 430.73L574.15 392.36L570.25 396.91L636.7 435.28L640.6 430.73Z"
      fill="#00A3DE"
    />
    <path
      d="M564.09 404.09L630.56 442.44L634.45 437.9L567.98 399.55L564.09 404.09Z"
      fill="#00A3DE"
    />
    <path
      d="M557.93 411.27L624.42 449.61L628.3 445.08L561.81 406.75L557.93 411.27Z"
      fill="#00A3DE"
    />
    <path
      d="M551.77 418.46L618.27 456.77L622.15 452.25L555.65 413.94L551.77 418.46Z"
      fill="#00A3DE"
    />
    <path
      d="M545.61 425.64L612.13 463.94L615.99 459.43L549.48 421.13L545.61 425.64Z"
      fill="#00A3DE"
    />
    <path
      d="M539.45 432.82L605.98 471.1L609.84 466.6L543.31 428.32L539.45 432.82Z"
      fill="#00A3DE"
    />
    <path
      d="M533.29 440.01L599.84 478.27L603.69 473.77L537.14 435.51L533.29 440.01Z"
      fill="#00A3DE"
    />
    <path
      d="M527.13 447.19L593.69 485.43L597.54 480.95L530.98 442.7L527.13 447.19Z"
      fill="#00A3DE"
    />
    <path
      d="M520.97 454.37L587.55 492.6L591.39 488.12L524.81 449.9L520.97 454.37Z"
      fill="#00A3DE"
    />
    <path
      d="M514.81 461.55L581.4 499.76L585.23 495.29L518.64 457.09L514.81 461.55Z"
      fill="#00A3DE"
    />
    <path
      d="M508.65 468.74L575.26 506.93L579.08 502.47L512.47 464.28L508.65 468.74Z"
      fill="#00A3DE"
    />
    <path
      d="M502.49 475.92L569.11 514.09L572.93 509.64L506.3 471.47L502.49 475.92Z"
      fill="#00A3DE"
    />
    <path
      d="M496.33 483.1L562.97 521.25L566.78 516.82L500.14 478.66L496.33 483.1Z"
      fill="#00A3DE"
    />
    <path
      d="M490.17 490.28L556.83 528.42L560.62 523.99L493.97 485.85L490.17 490.28Z"
      fill="#00A3DE"
    />
    <path
      d="M484.01 497.47L550.68 535.58L554.47 531.16L487.8 493.05L484.01 497.47Z"
      fill="#00A3DE"
    />
    <path
      d="M477.85 504.65L544.54 542.75L548.32 538.34L481.63 500.24L477.85 504.65Z"
      fill="#00A3DE"
    />
    <path
      d="M471.69 511.83L538.39 549.91L542.17 545.51L475.47 507.43L471.69 511.83Z"
      fill="#00A3DE"
    />
    <path
      d="M556.02 465.73L498.4 532.92L502.85 535.47L560.46 468.29L556.02 465.73Z"
      fill="#BBE5F0"
    />
    <path
      d="M607.2 406.15L555.74 466.15L560.2 468.69L610.53 410C609.27 408.84 608.1 407.56 607.2 406.15Z"
      fill="#BBE5F0"
    />
    <path
      d="M598.54 487.33L523.57 444.04L521.1 446.91L596.07 490.2L598.54 487.33Z"
      fill="#BBE5F0"
    />
  </svg>
)

/* Water */
export const step11Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_4455)">
      <path
        d="M339.86 835.19V943.85C339.86 961.48 358.96 972.48 374.2 963.64L659 798.54C672.67 790.61 681.09 776.01 681.09 760.21V543.31"
        stroke="#CD163F"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M414.89 631.4L352.34 667.75C353.75 666.93 354.63 665.18 354.64 662.7L417.19 626.35C417.18 628.83 416.3 630.58 414.89 631.4Z"
        fill="#00A3DE"
      />
      <path
        d="M512.64 687.85L450.09 724.2C451.5 723.38 452.38 721.63 452.39 719.15L514.94 682.8C514.93 685.28 514.06 687.03 512.64 687.85Z"
        fill="#00A3DE"
      />
      <path
        d="M452.52 672.36L515.07 636.01L514.94 682.79L452.39 719.14L452.52 672.36Z"
        fill="#00A3DE"
      />
      <path
        d="M452.15 669.34L514.7 632.99C514.94 634.01 515.08 635.08 515.07 636.01L452.52 672.36C452.53 671.43 452.39 670.36 452.15 669.34Z"
        fill="#00A3DE"
      />
      <path
        d="M355.52 350.12L418.07 313.77L417.18 626.35L354.63 662.7L355.52 350.12Z"
        fill="#00A3DE"
      />
      <path
        d="M342.11 324.6L404.66 288.25C404.88 288.12 405.11 288.02 405.36 287.93C406.77 287.47 408.52 287.79 410.28 288.82L347.73 325.17C345.96 324.15 344.21 323.82 342.81 324.28C342.56 324.36 342.33 324.47 342.11 324.6Z"
        fill="#BBE5F0"
      />
      <path
        d="M347.73 325.17L410.28 288.82C410.87 289.16 411.45 289.57 412.03 290.06L349.48 326.41C348.91 325.92 348.32 325.5 347.73 325.17Z"
        fill="#BBE5F0"
      />
      <path
        d="M349.49 326.41L412.04 290.06L432.67 307.52L370.12 343.87L349.49 326.41Z"
        fill="#BBE5F0"
      />
      <path
        d="M375.82 353.17L438.37 316.82L514.7 632.99L452.15 669.34L375.82 353.17Z"
        fill="#00A3DE"
      />
      <path
        d="M370.12 343.87L432.67 307.52C435.36 309.79 437.51 313.3 438.36 316.82L375.81 353.17C374.96 349.64 372.81 346.14 370.12 343.87Z"
        fill="#BBE5F0"
      />
      <path
        d="M347.73 325.17C348.32 325.51 348.9 325.92 349.48 326.41L370.12 343.87C372.81 346.14 374.96 349.65 375.81 353.17L452.14 669.34C452.38 670.36 452.52 671.43 452.51 672.36L452.38 719.14C452.37 724.14 448.83 726.15 444.49 723.65C440.14 721.14 436.62 715.05 436.64 710.05L436.77 664.7L362.2 355.78L355.52 350.13L354.63 662.71C354.62 667.71 351.08 669.72 346.73 667.21C342.38 664.7 338.88 658.62 338.89 653.62L339.81 329.66C339.82 326.91 340.92 324.9 342.81 324.28C344.22 323.82 345.97 324.15 347.73 325.17Z"
        fill="#71CFEC"
      />
      <path
        d="M725.51 458.34L662.96 494.69C664.37 493.87 665.25 492.12 665.26 489.64L727.81 453.29C727.8 455.77 726.92 457.52 725.51 458.34Z"
        fill="#71CFEC"
      />
      <path
        d="M823.26 514.79L760.71 551.14C762.12 550.32 763 548.57 763.01 546.09L825.56 509.74C825.56 512.22 824.68 513.97 823.26 514.79Z"
        fill="#00A3DE"
      />
      <path
        d="M763.15 499.3L825.7 462.95L825.56 509.73L763.01 546.08L763.15 499.3Z"
        fill="#00A3DE"
      />
      <path
        d="M762.77 496.28L825.32 459.93C825.56 460.95 825.7 462.02 825.69 462.95L763.14 499.3C763.15 498.37 763.01 497.3 762.77 496.28Z"
        fill="#71CFEC"
      />
      <path
        d="M666.14 177.06L728.69 140.71L727.8 453.29L665.25 489.64L666.14 177.06Z"
        fill="#71CFEC"
      />
      <path
        d="M652.73 151.53L715.28 115.18C715.5 115.05 715.73 114.95 715.98 114.86C717.39 114.4 719.14 114.72 720.9 115.75L658.35 152.1C656.58 151.08 654.83 150.75 653.43 151.21C653.18 151.3 652.95 151.41 652.73 151.53Z"
        fill="#71CFEC"
      />
      <path
        d="M658.35 152.1L720.9 115.75C721.49 116.09 722.07 116.5 722.65 116.99L660.1 153.34C659.53 152.86 658.94 152.44 658.35 152.1Z"
        fill="#71CFEC"
      />
      <path
        d="M660.11 153.35L722.66 117L743.29 134.46L680.74 170.81L660.11 153.35Z"
        fill="#71CFEC"
      />
      <path
        d="M686.44 180.11L748.99 143.76L825.32 459.93L762.77 496.28L686.44 180.11Z"
        fill="#71CFEC"
      />
      <path
        d="M680.74 170.81L743.29 134.46C745.98 136.73 748.13 140.24 748.98 143.76L686.43 180.11C685.58 176.58 683.43 173.08 680.74 170.81Z"
        fill="#71CFEC"
      />
      <path
        d="M658.35 152.1C658.94 152.44 659.52 152.85 660.1 153.34L680.74 170.8C683.43 173.07 685.58 176.58 686.43 180.1L762.76 496.27C763 497.29 763.14 498.36 763.13 499.29L763 546.07C762.99 551.07 759.45 553.08 755.11 550.58C750.76 548.07 747.24 541.98 747.26 536.98L747.39 491.63L672.81 182.7L666.13 177.05L665.24 489.63C665.23 494.63 661.69 496.64 657.34 494.13C652.99 491.62 649.49 485.54 649.5 480.54L650.42 156.58C650.43 153.83 651.53 151.82 653.42 151.2C654.84 150.76 656.59 151.08 658.35 152.1Z"
        fill="#71CFEC"
      />
      <path
        d="M433.88 602.41L795.98 391.98C802.16 388.39 810.71 388.9 820.15 394.35L458.05 604.78C448.61 599.33 440.05 598.82 433.88 602.41Z"
        fill="#BBE5F0"
      />
      <path
        d="M458.04 604.78C476.8 615.61 491.96 641.87 491.89 663.42C491.83 684.97 476.57 693.67 457.81 682.84C439.05 672.01 423.89 645.76 423.96 624.2C424.03 602.64 439.29 593.95 458.04 604.78Z"
        fill="#71CFEC"
      />
      <path
        d="M844.09 474.77L481.99 685.2C488.09 681.66 491.87 674.12 491.9 663.42C491.96 641.87 476.8 615.61 458.05 604.78L820.15 394.35C838.91 405.18 854.07 431.44 854 452.99C853.97 463.69 850.19 471.23 844.09 474.77Z"
        fill="#BBE5F0"
      />
      <path
        d="M323.51 250.9L683.09 41.9299C690.87 37.4099 701.64 38.0499 713.53 44.9099L353.95 253.88C342.06 247.02 331.29 246.38 323.51 250.9Z"
        fill="#BBE5F0"
      />
      <path
        d="M353.95 253.88C377.57 267.51 396.66 300.58 396.59 327.73C396.51 354.87 377.29 365.82 353.68 352.19C330.05 338.55 310.97 305.48 311.04 278.34C311.11 251.18 330.32 240.24 353.95 253.88Z"
        fill="#71CFEC"
      />
      <path
        d="M743.68 146.2L384.1 355.16C391.78 350.7 396.54 341.21 396.58 327.73C396.66 300.58 377.56 267.52 353.94 253.88L713.53 44.9199C737.15 58.5499 756.24 91.6199 756.17 118.77C756.13 132.25 751.36 141.73 743.68 146.2Z"
        fill="#BBE5F0"
      />
      <path
        d="M410.26 311.24L482.86 611.97"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M433.6 297.44L506.2 598.18"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M456.94 283.65L529.54 584.39"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M480.28 269.86L552.88 570.59"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M503.62 256.07L576.22 556.8"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M526.96 242.28L599.56 543.01"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M550.3 228.48L622.9 529.22"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M573.64 214.69L646.24 515.43"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M596.98 200.9L669.58 501.63"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M620.32 187.11L692.92 487.84"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M643.66 173.32L716.26 474.05"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M667 159.52L739.6 460.26"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M690.34 145.73L762.95 446.47"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M713.68 131.94L786.29 432.67"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M737.02 118.15L809.63 418.88"
        stroke="#00545E"
        stroke-width="19"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M304.36 911.8V1036.04"
        stroke="#00A3DE"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M384.77 911.56C383.23 912.5 381.11 913.65 379.47 914.4C372.41 917.62 372.41 917.62 368.22 919.1C366.46 919.72 364.09 920.52 362.29 921C358.62 921.97 353.69 923.19 349.96 923.9C345.89 924.68 339.24 925.69 330.67 926.07C323.27 926.4 318.27 926.18 311.11 925.64C311.02 925.63 301.66 924.88 292.04 922.6C284.74 920.87 280.48 919.39 274.23 916.91C270.78 915.54 269.06 914.62 263.38 911.57C261.8 910.72 259.83 909.33 258.31 908.37C255.47 906.56 251.94 903.7 249.61 901.26C247.37 898.91 244.39 895.75 242.55 893.09C241.64 891.78 240.44 890.03 239.65 888.65C238.58 886.77 237.24 884.2 236.39 882.21C231.44 870.62 229.2 858.65 227.54 849.55C226.44 843.53 225 833.11 224.03 824.22C223.37 818.13 223.35 817.88 222.27 804.99C222.01 801.91 221.78 797.8 221.57 794.71C221.21 789.38 221.14 787.84 220.54 773.15C220.4 769.78 220.32 765.29 220.22 761.93C220.06 756.08 220.04 752.02 220 738.58C220.04 723.22 230.14 707.89 250.3 696.17C290.88 672.59 356.89 672.59 397.73 696.17C397.51 772.72 390.7 838.64 382.51 843.4C349.94 824.58 297.2 824.58 264.84 843.4C232.46 862.21 232.62 892.76 265.21 911.57C297.79 930.39 350.54 930.39 382.9 911.57C415.28 892.76 415.11 862.22 382.5 843.4C390.69 838.64 397.51 772.72 397.72 696.17C418.28 708.04 428.55 723.62 428.5 739.18C428.35 790.59 424.09 837.73 417.1 865.29C412.68 882.7 407.93 889.5 405.38 893.15C403.53 895.8 400.56 898.98 398.34 901.34C397.2 902.55 395.45 903.93 394.21 905.04C391.65 907.31 387.72 909.77 384.77 911.56Z"
        fill="#71CFEC"
      />
      <path
        d="M428.51 739.16C428.39 752.61 428.35 756.67 428.16 762.51C428.05 765.88 427.94 770.37 427.78 773.73C427.09 788.42 427.02 789.96 426.63 795.29C426.4 798.37 426.14 802.48 425.87 805.56C424.7 818.69 424.7 818.69 424 824.77C422.98 833.66 421.48 844.07 420.35 850.08C418.62 859.28 416.31 871.17 411.32 882.68C410.46 884.67 409.1 887.22 408.02 889.1C407.23 890.48 406.01 892.23 405.1 893.53C403.24 896.18 400.39 899.49 397.98 901.67C394.56 904.77 391.33 907.7 384.78 911.54C383.24 912.44 381.16 913.61 379.54 914.35C371.96 917.81 371.66 917.94 368.26 919.07C366.48 919.66 364.12 920.5 362.31 920.97C355.58 922.74 353.32 923.33 349.97 923.87C342.38 925.09 341.76 925.19 337.18 925.61C333.28 925.96 328.06 926.2 324.15 926.19C322.3 926.19 317.4 926.17 311.12 925.61C307.26 925.26 302.14 924.49 298.31 923.88C294.97 923.34 292.88 922.79 285.95 920.98C284.14 920.51 281.78 919.68 280 919.08C276.48 917.9 276.33 917.84 268.71 914.38C267.06 913.63 264.95 912.46 263.39 911.54C261.24 910.28 255.52 906.92 249.73 901.33C247.4 899.08 244.54 895.8 242.64 893.17C239.09 888.24 237.22 883.99 237.2 883.95C236.54 882.42 235.62 880.41 235.04 878.85C234.17 876.49 233.05 873.32 232.37 870.9C230.27 863.42 229.47 860.06 227.61 849.85C226.3 842.69 224.89 831.91 224.07 824.4C222.84 813.18 222.37 806.26 221.59 794.8C221.23 789.5 221.16 787.96 220.56 773.23C220.42 769.87 220.34 765.38 220.24 762.01C220.24 761.92 219.98 751.82 220.02 738.55C219.98 754.1 230.24 769.68 250.79 781.54C291.64 805.13 357.66 805.12 398.24 781.54C418.38 769.84 428.47 754.51 428.51 739.16Z"
        fill="#00545E"
      />
      <path
        d="M428.61 704.51L428.51 739.16C428.47 754.51 418.38 769.84 398.22 781.56C357.64 805.14 291.62 805.15 250.77 781.56C230.22 769.7 219.96 754.12 220 738.57L220.1 703.92C220.06 719.47 230.32 735.05 250.87 746.91C291.72 770.5 357.74 770.49 398.32 746.91C418.48 735.19 428.57 719.86 428.61 704.51Z"
        fill="#71CFEC"
      />
      <path
        d="M383.39 531.51C415.99 550.32 416.16 580.87 383.79 599.68C351.43 618.5 298.68 618.5 266.1 599.68C233.51 580.88 233.35 550.32 265.73 531.51C298.08 512.69 350.82 512.69 383.39 531.51Z"
        fill="#BBE5F0"
      />
      <path
        d="M385.23 531.5C388.16 533.34 392.15 535.73 394.75 538.01C395.99 539.1 397.75 540.46 398.89 541.66C401.09 543.96 404.01 547.09 405.89 549.66C408.97 553.86 410.75 557.52 412.08 560.55C412.79 562.15 413.67 564.31 414.24 565.96C415.16 568.63 416.36 572.22 417.05 574.96C417.62 577.22 418.43 580.21 418.9 582.49C420.19 588.7 420.97 592.44 421.75 597.56C423.77 610.75 424.51 617.07 425.95 633.17C426.4 638.21 427.46 653.42 427.87 664.53C428.45 680.05 428.61 688.64 428.61 704.51C428.57 719.86 418.48 735.19 398.32 746.91C357.74 770.49 291.72 770.5 250.87 746.91C230.32 735.05 220.06 719.47 220.1 703.92C220.2 692.33 220.27 684.67 220.61 675.31C220.82 669.54 221.31 656.01 222.32 643C222.83 636.36 223.61 626.32 225.11 614.1C225.73 609.06 226.73 602.36 227.51 597.34C227.88 594.97 228.5 591.82 228.92 589.46C229.36 587.03 230.85 580.05 232.13 575.39C234.25 567.69 235.8 563.9 236.22 562.92C237.22 560.62 238.61 557.57 239.77 555.35C240.49 553.98 241.62 552.25 242.46 550.95C244.17 548.29 246.97 545.08 249.16 542.79C251.46 540.39 254.95 537.64 257.56 535.59C258.92 534.52 260.92 533.33 262.38 532.39C262.43 532.36 268.24 528.78 274.64 526.18C282.68 522.91 287.82 521.57 292.39 520.49C294.25 520.05 296.75 519.49 298.64 519.18C302.46 518.57 307.57 517.77 311.43 517.45C314.75 517.17 321.67 516.6 330.99 517.02C339.56 517.4 346.22 518.41 350.3 519.19C359 520.82 372.07 523.9 385.23 531.5C384.63 531.15 384.02 531.15 383.39 531.51C350.82 512.69 298.08 512.69 265.72 531.51C233.34 550.32 233.5 580.87 266.09 599.68C298.67 618.5 351.42 618.5 383.78 599.68C416.16 580.87 415.99 550.33 383.38 531.51C384.01 531.15 384.63 531.15 385.23 531.5Z"
        fill="#71CFEC"
      />
      <path
        opacity="0.3"
        d="M940.96 1495.46L801.6 1414.99L766.46 1394.71L731.33 1374.43L696.2 1354.14L661.08 1333.87L625.95 1313.58L590.82 1293.3L555.69 1273.02L520.55 1252.73L485.43 1232.46L450.3 1212.17L415.17 1191.89L380.04 1171.61L344.91 1151.32L309.77 1131.04L274.65 1110.76L239.52 1090.48L204.39 1070.2L169.26 1049.91L134.13 1029.63L98.9899 1009.35L63.8699 989.07L28.7399 968.79L-6.39008 948.5L-41.5201 928.22L-76.6501 907.94L-111.79 887.65L-146.91 867.38L-182.04 847.09L-217.17 826.81L-252.3 806.53L-287.43 786.24L-322.56 765.96L-357.68 745.68L-392.82 725.4L-427.95 705.12L-463.08 684.83L-498.21 664.55L-533.33 644.27L-568.46 623.99L-603.6 603.71L-638.73 583.42L-778.09 502.96L-638.73 583.42L-603.6 603.71L-568.46 623.99L-533.33 644.27L-498.21 664.55L-463.08 684.83L-427.95 705.12L-392.82 725.4L-357.68 745.68L-322.56 765.96L-287.43 786.24L-252.3 806.53L-217.17 826.81L-182.04 847.09L-146.91 867.38L-111.79 887.65L-76.6501 907.94L-41.5201 928.22L-6.39008 948.5L28.7399 968.79L63.8699 989.07L98.9899 1009.35L134.13 1029.63L169.26 1049.91L204.39 1070.2L239.52 1090.48L274.65 1110.76L309.77 1131.04L344.91 1151.32L380.04 1171.61L415.17 1191.89L450.3 1212.17L485.43 1232.46L520.55 1252.73L555.69 1273.02L590.82 1293.3L625.95 1313.58L661.08 1333.87L696.2 1354.14L731.33 1374.43L766.46 1394.71L801.6 1414.99L940.96 1495.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M975.86 1475.17L836.5 1394.71L801.37 1374.43L766.24 1354.14L731.1 1333.86L695.98 1313.58L660.85 1293.3L625.72 1273.02L590.59 1252.73L555.46 1232.45L520.34 1212.17L485.2 1191.89L450.07 1171.61L414.94 1151.32L379.81 1131.04L344.68 1110.76L309.56 1090.48L274.43 1070.2L239.29 1049.91L204.16 1029.63L169.03 1009.35L133.9 989.06L98.78 968.79L63.6501 948.5L28.5101 928.22L-6.61993 907.94L-41.7499 887.65L-76.8799 867.37L-112 847.09L-147.13 826.81L-182.27 806.53L-217.4 786.24L-252.53 765.96L-287.66 745.68L-322.78 725.4L-357.91 705.12L-393.05 684.83L-428.18 664.55L-463.31 644.27L-498.43 623.99L-533.56 603.71L-568.69 583.42L-603.82 563.14L-743.19 482.68L-603.82 563.14L-568.69 583.42L-533.56 603.71L-498.43 623.99L-463.31 644.27L-428.18 664.55L-393.05 684.83L-357.91 705.12L-322.78 725.4L-287.66 745.68L-252.53 765.96L-217.4 786.24L-182.27 806.53L-147.13 826.81L-112 847.09L-76.8799 867.37L-41.7499 887.65L-6.61993 907.94L28.5101 928.22L63.6501 948.5L98.78 968.79L133.9 989.06L169.03 1009.35L204.16 1029.63L239.29 1049.91L274.43 1070.2L309.56 1090.48L344.68 1110.76L379.81 1131.04L414.94 1151.32L450.07 1171.61L485.2 1191.89L520.34 1212.17L555.46 1232.45L590.59 1252.73L625.72 1273.02L660.85 1293.3L695.98 1313.58L731.1 1333.86L766.24 1354.14L801.37 1374.43L836.5 1394.71L975.86 1475.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1010.77 1454.89L871.4 1374.43L836.27 1354.14L801.14 1333.86L766.01 1313.58L730.89 1293.3L695.76 1273.02L660.62 1252.73L625.49 1232.45L590.36 1212.17L555.24 1191.89L520.11 1171.61L484.98 1151.32L449.84 1131.04L414.71 1110.76L379.58 1090.47L344.46 1070.2L309.33 1049.91L274.2 1029.63L239.07 1009.35L203.93 989.06L168.8 968.78L133.68 948.5L98.55 928.22L63.42 907.94L28.29 887.65L-6.84998 867.37L-41.98 847.09L-77.1 826.81L-112.23 806.53L-147.36 786.24L-182.49 765.96L-217.63 745.68L-252.76 725.39L-287.88 705.12L-323.01 684.83L-358.14 664.55L-393.27 644.27L-428.41 623.98L-463.53 603.71L-498.66 583.42L-533.79 563.14L-568.92 542.85L-708.28 462.39L-568.92 542.85L-533.79 563.14L-498.66 583.42L-463.53 603.71L-428.41 623.98L-393.27 644.27L-358.14 664.55L-323.01 684.83L-287.88 705.12L-252.76 725.39L-217.63 745.68L-182.49 765.96L-147.36 786.24L-112.23 806.53L-77.1 826.81L-41.98 847.09L-6.84998 867.37L28.29 887.65L63.42 907.94L98.55 928.22L133.68 948.5L168.8 968.78L203.93 989.06L239.07 1009.35L274.2 1029.63L309.33 1049.91L344.46 1070.2L379.58 1090.47L414.71 1110.76L449.84 1131.04L484.98 1151.32L520.11 1171.61L555.24 1191.89L590.36 1212.17L625.49 1232.45L660.62 1252.73L695.76 1273.02L730.89 1293.3L766.01 1313.58L801.14 1333.86L836.27 1354.14L871.4 1374.43L1010.77 1454.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1045.66 1434.61L906.3 1354.15L871.16 1333.87L836.03 1313.58L800.9 1293.3L765.78 1273.02L730.65 1252.74L695.52 1232.46L660.38 1212.17L625.25 1191.89L590.13 1171.61L555 1151.33L519.87 1131.05L484.74 1110.76L449.6 1090.48L414.47 1070.2L379.35 1049.92L344.22 1029.64L309.09 1009.35L273.96 989.07L238.82 968.79L203.69 948.5L168.57 928.23L133.44 907.94L98.31 887.66L63.18 867.38L28.05 847.09L-7.09003 826.81L-42.21 806.53L-77.34 786.25L-112.47 765.97L-147.6 745.68L-182.73 725.4L-217.87 705.12L-252.99 684.84L-288.12 664.56L-323.25 644.27L-358.38 623.99L-393.51 603.7L-428.63 583.43L-463.77 563.14L-498.9 542.86L-534.03 522.58L-673.39 442.12L-534.03 522.58L-498.9 542.86L-463.77 563.14L-428.63 583.43L-393.51 603.7L-358.38 623.99L-323.25 644.27L-288.12 664.56L-252.99 684.84L-217.87 705.12L-182.73 725.4L-147.6 745.68L-112.47 765.97L-77.34 786.25L-42.21 806.53L-7.09003 826.81L28.05 847.09L63.18 867.38L98.31 887.66L133.44 907.94L168.57 928.23L203.69 948.5L238.82 968.79L273.96 989.07L309.09 1009.35L344.22 1029.64L379.35 1049.92L414.47 1070.2L449.6 1090.48L484.74 1110.76L519.87 1131.05L555 1151.33L590.13 1171.61L625.25 1191.89L660.38 1212.17L695.52 1232.46L730.65 1252.74L765.78 1273.02L800.9 1293.3L836.03 1313.58L871.16 1333.87L906.3 1354.15L1045.66 1434.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1080.56 1414.33L941.2 1333.87L906.07 1313.58L870.94 1293.3L835.8 1273.02L800.68 1252.74L765.55 1232.46L730.42 1212.17L695.29 1191.89L660.16 1171.61L625.04 1151.33L589.9 1131.05L554.77 1110.76L519.64 1090.48L484.51 1070.2L449.38 1049.91L414.26 1029.64L379.12 1009.35L343.99 989.07L308.86 968.79L273.73 948.5L238.6 928.22L203.48 907.94L168.34 887.66L133.21 867.38L98.08 847.09L62.95 826.81L27.82 806.52L-7.29999 786.25L-42.43 765.97L-77.57 745.68L-112.7 725.4L-147.83 705.11L-182.96 684.83L-218.08 664.55L-253.21 644.27L-288.35 623.99L-323.48 603.7L-358.61 583.42L-393.73 563.14L-428.86 542.86L-463.99 522.58L-499.13 502.29L-638.49 421.83L-499.13 502.29L-463.99 522.58L-428.86 542.86L-393.73 563.14L-358.61 583.42L-323.48 603.7L-288.35 623.99L-253.21 644.27L-218.08 664.55L-182.96 684.83L-147.83 705.11L-112.7 725.4L-77.57 745.68L-42.43 765.97L-7.29999 786.25L27.82 806.52L62.95 826.81L98.08 847.09L133.21 867.38L168.34 887.66L203.48 907.94L238.6 928.22L273.73 948.5L308.86 968.79L343.99 989.07L379.12 1009.35L414.26 1029.64L449.38 1049.91L484.51 1070.2L519.64 1090.48L554.77 1110.76L589.9 1131.05L625.04 1151.33L660.16 1171.61L695.29 1191.89L730.42 1212.17L765.55 1232.46L800.68 1252.74L835.8 1273.02L870.94 1293.3L906.07 1313.58L941.2 1333.87L1080.56 1414.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1115.47 1394.04L976.1 1313.58L940.97 1293.3L905.84 1273.02L870.71 1252.73L835.59 1232.46L800.46 1212.17L765.32 1191.89L730.19 1171.61L695.06 1151.32L659.94 1131.05L624.81 1110.76L589.68 1090.48L554.54 1070.2L519.41 1049.91L484.28 1029.63L449.16 1009.35L414.03 989.07L378.9 968.79L343.76 948.5L308.63 928.22L273.5 907.93L238.38 887.66L203.25 867.37L168.12 847.09L132.98 826.81L97.8499 806.52L62.72 786.24L27.5999 765.96L-7.53009 745.68L-42.66 725.4L-77.79 705.11L-112.93 684.83L-148.06 664.55L-183.18 644.27L-218.31 623.99L-253.44 603.7L-288.57 583.42L-323.71 563.14L-358.83 542.86L-393.96 522.58L-429.09 502.29L-464.22 482.01L-603.59 401.55L-464.22 482.01L-429.09 502.29L-393.96 522.58L-358.83 542.86L-323.71 563.14L-288.57 583.42L-253.44 603.7L-218.31 623.99L-183.18 644.27L-148.06 664.55L-112.93 684.83L-77.79 705.11L-42.66 725.4L-7.53009 745.68L27.5999 765.96L62.72 786.24L97.8499 806.52L132.98 826.81L168.12 847.09L203.25 867.37L238.38 887.66L273.5 907.93L308.63 928.22L343.76 948.5L378.9 968.79L414.03 989.07L449.16 1009.35L484.28 1029.63L519.41 1049.91L554.54 1070.2L589.68 1090.48L624.81 1110.76L659.94 1131.05L695.06 1151.32L730.19 1171.61L765.32 1191.89L800.46 1212.17L835.59 1232.46L870.71 1252.73L905.84 1273.02L940.97 1293.3L976.1 1313.58L1115.47 1394.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1150.37 1373.76L1011.01 1293.3L975.87 1273.02L940.74 1252.73L905.61 1232.45L870.49 1212.17L835.36 1191.89L800.23 1171.61L765.09 1151.32L729.96 1131.04L694.84 1110.76L659.71 1090.48L624.58 1070.2L589.45 1049.91L554.32 1029.63L519.18 1009.34L484.06 989.07L448.93 968.78L413.8 948.5L378.67 928.22L343.54 907.93L308.4 887.65L273.28 867.37L238.15 847.09L203.02 826.81L167.89 806.52L132.76 786.24L97.6201 765.96L62.5001 745.68L27.3701 725.4L-7.75995 705.11L-42.89 684.83L-78.0199 664.55L-113.16 644.26L-148.28 623.99L-183.41 603.7L-218.54 583.42L-253.67 563.14L-288.8 542.85L-323.92 522.58L-359.05 502.29L-394.19 482.01L-429.32 461.73L-568.68 381.27L-429.32 461.73L-394.19 482.01L-359.05 502.29L-323.92 522.58L-288.8 542.85L-253.67 563.14L-218.54 583.42L-183.41 603.7L-148.28 623.99L-113.16 644.26L-78.0199 664.55L-42.89 684.83L-7.75995 705.11L27.3701 725.4L62.5001 745.68L97.6201 765.96L132.76 786.24L167.89 806.52L203.02 826.81L238.15 847.09L273.28 867.37L308.4 887.65L343.54 907.93L378.67 928.22L413.8 948.5L448.93 968.78L484.06 989.07L519.18 1009.34L554.32 1029.63L589.45 1049.91L624.58 1070.2L659.71 1090.48L694.84 1110.76L729.96 1131.04L765.09 1151.32L800.23 1171.61L835.36 1191.89L870.49 1212.17L905.61 1232.45L940.74 1252.73L975.87 1273.02L1011.01 1293.3L1150.37 1373.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1185.27 1353.48L1045.91 1273.02L1010.78 1252.73L975.65 1232.45L940.51 1212.16L905.39 1191.89L870.26 1171.6L835.13 1151.32L800 1131.04L764.87 1110.75L729.75 1090.48L694.61 1070.19L659.48 1049.91L624.35 1029.63L589.22 1009.34L554.09 989.06L518.97 968.78L483.84 948.5L448.7 928.22L413.57 907.93L378.44 887.65L343.31 867.37L308.19 847.09L273.06 826.81L237.92 806.52L202.79 786.24L167.66 765.96L132.53 745.67L97.41 725.4L62.28 705.11L27.14 684.83L-7.98999 664.55L-43.12 644.26L-78.25 623.98L-113.37 603.7L-148.5 583.42L-183.64 563.14L-218.77 542.85L-253.9 522.57L-289.02 502.29L-324.15 482.01L-359.28 461.73L-394.42 441.44L-533.78 360.98L-394.42 441.44L-359.28 461.73L-324.15 482.01L-289.02 502.29L-253.9 522.57L-218.77 542.85L-183.64 563.14L-148.5 583.42L-113.37 603.7L-78.25 623.98L-43.12 644.26L-7.98999 664.55L27.14 684.83L62.28 705.11L97.41 725.4L132.53 745.67L167.66 765.96L202.79 786.24L237.92 806.52L273.06 826.81L308.19 847.09L343.31 867.37L378.44 887.65L413.57 907.93L448.7 928.22L483.84 948.5L518.97 968.78L554.09 989.06L589.22 1009.34L624.35 1029.63L659.48 1049.91L694.61 1070.19L729.75 1090.48L764.87 1110.75L800 1131.04L835.13 1151.32L870.26 1171.6L905.39 1191.89L940.51 1212.16L975.65 1232.45L1010.78 1252.73L1045.91 1273.02L1185.27 1353.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1220.18 1333.19L1080.81 1252.73L1045.68 1232.45L1010.55 1212.16L975.42 1191.88L940.3 1171.6L905.17 1151.32L870.03 1131.04L834.9 1110.75L799.77 1090.47L764.65 1070.19L729.52 1049.91L694.39 1029.63L659.25 1009.34L624.12 989.06L588.99 968.78L553.87 948.5L518.74 928.22L483.61 907.93L448.47 887.65L413.34 867.37L378.21 847.08L343.09 826.81L307.96 806.52L272.83 786.24L237.7 765.96L202.56 745.67L167.43 725.39L132.31 705.11L97.18 684.83L62.05 664.55L26.92 644.26L-8.22 623.98L-43.35 603.7L-78.47 583.42L-113.6 563.14L-148.73 542.85L-183.86 522.57L-219 502.29L-254.12 482.01L-289.25 461.73L-324.38 441.44L-359.51 421.16L-498.87 340.7L-359.51 421.16L-324.38 441.44L-289.25 461.73L-254.12 482.01L-219 502.29L-183.86 522.57L-148.73 542.85L-113.6 563.14L-78.47 583.42L-43.35 603.7L-8.22 623.98L26.92 644.26L62.05 664.55L97.18 684.83L132.31 705.11L167.43 725.39L202.56 745.67L237.7 765.96L272.83 786.24L307.96 806.52L343.09 826.81L378.21 847.08L413.34 867.37L448.47 887.65L483.61 907.93L518.74 928.22L553.87 948.5L588.99 968.78L624.12 989.06L659.25 1009.34L694.39 1029.63L729.52 1049.91L764.65 1070.19L799.77 1090.47L834.9 1110.75L870.03 1131.04L905.17 1151.32L940.3 1171.6L975.42 1191.88L1010.55 1212.16L1045.68 1232.45L1080.81 1252.73L1220.18 1333.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1255.07 1312.92L1115.71 1232.45L1080.57 1212.17L1045.44 1191.89L1010.31 1171.6L975.19 1151.33L940.06 1131.04L904.93 1110.76L869.79 1090.48L834.66 1070.19L799.54 1049.92L764.41 1029.63L729.28 1009.35L694.15 989.07L659.01 968.78L623.88 948.5L588.76 928.22L553.63 907.94L518.5 887.66L483.37 867.37L448.23 847.09L413.1 826.81L377.98 806.53L342.85 786.25L307.72 765.96L272.59 745.68L237.46 725.4L202.32 705.11L167.2 684.84L132.07 664.55L96.9401 644.27L61.8101 623.99L26.68 603.7L-8.45996 583.42L-43.58 563.14L-78.71 542.86L-113.84 522.58L-148.97 502.29L-184.1 482.01L-219.22 461.73L-254.36 441.45L-289.49 421.17L-324.62 400.88L-463.98 320.42L-324.62 400.88L-289.49 421.17L-254.36 441.45L-219.22 461.73L-184.1 482.01L-148.97 502.29L-113.84 522.58L-78.71 542.86L-43.58 563.14L-8.45996 583.42L26.68 603.7L61.8101 623.99L96.9401 644.27L132.07 664.55L167.2 684.84L202.32 705.11L237.46 725.4L272.59 745.68L307.72 765.96L342.85 786.25L377.98 806.53L413.1 826.81L448.23 847.09L483.37 867.37L518.5 887.66L553.63 907.94L588.76 928.22L623.88 948.5L659.01 968.78L694.15 989.07L729.28 1009.35L764.41 1029.63L799.54 1049.92L834.66 1070.19L869.79 1090.48L904.93 1110.76L940.06 1131.04L975.19 1151.33L1010.31 1171.6L1045.44 1191.89L1080.57 1212.17L1115.71 1232.45L1255.07 1312.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1289.97 1292.63L1150.61 1212.17L1115.48 1191.89L1080.34 1171.6L1045.21 1151.32L1010.09 1131.04L974.96 1110.76L939.83 1090.48L904.7 1070.19L869.57 1049.91L834.45 1029.63L799.31 1009.35L764.18 989.07L729.05 968.78L693.92 948.5L658.79 928.22L623.67 907.94L588.53 887.66L553.4 867.37L518.27 847.09L483.14 826.81L448.01 806.52L412.89 786.25L377.75 765.96L342.62 745.68L307.49 725.4L272.36 705.11L237.23 684.83L202.11 664.55L166.97 644.27L131.84 623.99L96.71 603.7L61.58 583.42L26.45 563.14L-8.66998 542.86L-43.8 522.58L-78.94 502.29L-114.07 482.01L-149.2 461.73L-184.32 441.45L-219.45 421.17L-254.58 400.88L-289.72 380.6L-429.08 300.14L-289.72 380.6L-254.58 400.88L-219.45 421.17L-184.32 441.45L-149.2 461.73L-114.07 482.01L-78.94 502.29L-43.8 522.58L-8.66998 542.86L26.45 563.14L61.58 583.42L96.71 603.7L131.84 623.99L166.97 644.27L202.11 664.55L237.23 684.83L272.36 705.11L307.49 725.4L342.62 745.68L377.75 765.96L412.89 786.25L448.01 806.52L483.14 826.81L518.27 847.09L553.4 867.37L588.53 887.66L623.67 907.94L658.79 928.22L693.92 948.5L729.05 968.78L764.18 989.07L799.31 1009.35L834.45 1029.63L869.57 1049.91L904.7 1070.19L939.83 1090.48L974.96 1110.76L1010.09 1131.04L1045.21 1151.32L1080.34 1171.6L1115.48 1191.89L1150.61 1212.17L1289.97 1292.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1324.88 1272.35L1185.51 1191.89L1150.38 1171.6L1115.25 1151.32L1080.12 1131.04L1045 1110.76L1009.86 1090.48L974.73 1070.19L939.6 1049.91L904.47 1029.63L869.35 1009.35L834.22 989.07L799.09 968.78L763.95 948.5L728.82 928.22L693.69 907.93L658.57 887.66L623.44 867.37L588.31 847.09L553.17 826.81L518.04 806.52L482.91 786.24L447.79 765.96L412.66 745.68L377.53 725.4L342.39 705.11L307.26 684.83L272.13 664.55L237.01 644.27L201.88 623.99L166.75 603.7L131.61 583.42L96.4801 563.14L61.3501 542.85L26.2301 522.58L-8.89993 502.29L-44.0299 482.01L-79.1699 461.73L-114.3 441.44L-149.42 421.17L-184.55 400.88L-219.68 380.6L-254.81 360.32L-394.18 279.85L-254.81 360.32L-219.68 380.6L-184.55 400.88L-149.42 421.17L-114.3 441.44L-79.1699 461.73L-44.0299 482.01L-8.89993 502.29L26.2301 522.58L61.3501 542.85L96.4801 563.14L131.61 583.42L166.75 603.7L201.88 623.99L237.01 644.27L272.13 664.55L307.26 684.83L342.39 705.11L377.53 725.4L412.66 745.68L447.79 765.96L482.91 786.24L518.04 806.52L553.17 826.81L588.31 847.09L623.44 867.37L658.57 887.66L693.69 907.93L728.82 928.22L763.95 948.5L799.09 968.78L834.22 989.07L869.35 1009.35L904.47 1029.63L939.6 1049.91L974.73 1070.19L1009.86 1090.48L1045 1110.76L1080.12 1131.04L1115.25 1151.32L1150.38 1171.6L1185.51 1191.89L1324.88 1272.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1359.78 1252.06L1220.42 1171.6L1185.28 1151.32L1150.15 1131.04L1115.02 1110.75L1079.9 1090.48L1044.77 1070.19L1009.64 1049.91L974.5 1029.63L939.37 1009.34L904.25 989.07L869.12 968.78L833.99 948.5L798.86 928.22L763.72 907.93L728.59 887.65L693.47 867.37L658.34 847.09L623.21 826.81L588.08 806.52L552.95 786.24L517.81 765.96L482.69 745.68L447.56 725.4L412.43 705.11L377.3 684.83L342.17 664.55L307.03 644.26L271.91 623.99L236.78 603.7L201.65 583.42L166.52 563.14L131.39 542.85L96.25 522.57L61.13 502.29L26 482.01L-9.13004 461.72L-44.26 441.44L-79.39 421.16L-114.51 400.88L-149.65 380.6L-184.78 360.31L-219.91 340.03L-359.27 259.57L-219.91 340.03L-184.78 360.31L-149.65 380.6L-114.51 400.88L-79.39 421.16L-44.26 441.44L-9.13004 461.72L26 482.01L61.13 502.29L96.25 522.57L131.39 542.85L166.52 563.14L201.65 583.42L236.78 603.7L271.91 623.99L307.03 644.26L342.17 664.55L377.3 684.83L412.43 705.11L447.56 725.4L482.69 745.68L517.81 765.96L552.95 786.24L588.08 806.52L623.21 826.81L658.34 847.09L693.47 867.37L728.59 887.65L763.72 907.93L798.86 928.22L833.99 948.5L869.12 968.78L904.25 989.07L939.37 1009.34L974.5 1029.63L1009.64 1049.91L1044.77 1070.19L1079.9 1090.48L1115.02 1110.75L1150.15 1131.04L1185.28 1151.32L1220.42 1171.6L1359.78 1252.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1394.68 1231.78L1255.32 1151.32L1220.19 1131.04L1185.06 1110.75L1149.92 1090.47L1114.8 1070.19L1079.67 1049.91L1044.54 1029.63L1009.41 1009.34L974.28 989.06L939.16 968.78L904.02 948.5L868.89 928.22L833.76 907.93L798.63 887.65L763.5 867.37L728.38 847.09L693.24 826.81L658.11 806.52L622.98 786.24L587.85 765.96L552.72 745.67L517.6 725.4L482.47 705.11L447.33 684.83L412.2 664.54L377.07 644.26L341.94 623.98L306.82 603.7L271.69 583.42L236.55 563.13L201.42 542.85L166.29 522.57L131.16 502.28L96.04 482.01L60.91 461.72L25.77 441.44L-9.35999 421.16L-44.49 400.87L-79.61 380.6L-114.74 360.31L-149.87 340.03L-185.01 319.75L-324.37 239.29L-185.01 319.75L-149.87 340.03L-114.74 360.31L-79.61 380.6L-44.49 400.87L-9.35999 421.16L25.77 441.44L60.91 461.72L96.04 482.01L131.16 502.28L166.29 522.57L201.42 542.85L236.55 563.13L271.69 583.42L306.82 603.7L341.94 623.98L377.07 644.26L412.2 664.54L447.33 684.83L482.47 705.11L517.6 725.4L552.72 745.67L587.85 765.96L622.98 786.24L658.11 806.52L693.24 826.81L728.38 847.09L763.5 867.37L798.63 887.65L833.76 907.93L868.89 928.22L904.02 948.5L939.16 968.78L974.28 989.06L1009.41 1009.34L1044.54 1029.63L1079.67 1049.91L1114.8 1070.19L1149.92 1090.47L1185.06 1110.75L1220.19 1131.04L1255.32 1151.32L1394.68 1231.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1429.59 1211.5L1290.22 1131.04L1255.09 1110.75L1219.96 1090.47L1184.83 1070.19L1149.71 1049.91L1114.58 1029.63L1079.44 1009.34L1044.31 989.06L1009.18 968.78L974.06 948.5L938.93 928.22L903.8 907.93L868.66 887.65L833.53 867.37L798.4 847.08L763.28 826.81L728.15 806.52L693.02 786.24L657.88 765.95L622.75 745.67L587.62 725.39L552.5 705.11L517.37 684.83L482.24 664.54L447.1 644.26L411.97 623.98L376.84 603.69L341.72 583.42L306.59 563.13L271.46 542.85L236.33 522.57L201.19 502.28L166.06 482L130.94 461.72L95.81 441.44L60.68 421.16L25.55 400.87L-9.58997 380.59L-44.71 360.31L-79.84 340.03L-114.97 319.75L-150.1 299.46L-289.47 219L-150.1 299.46L-114.97 319.75L-79.84 340.03L-44.71 360.31L-9.58997 380.59L25.55 400.87L60.68 421.16L95.81 441.44L130.94 461.72L166.06 482L201.19 502.28L236.33 522.57L271.46 542.85L306.59 563.13L341.72 583.42L376.84 603.69L411.97 623.98L447.1 644.26L482.24 664.54L517.37 684.83L552.5 705.11L587.62 725.39L622.75 745.67L657.88 765.95L693.02 786.24L728.15 806.52L763.28 826.81L798.4 847.08L833.53 867.37L868.66 887.65L903.8 907.93L938.93 928.22L974.06 948.5L1009.18 968.78L1044.31 989.06L1079.44 1009.34L1114.58 1029.63L1149.71 1049.91L1184.83 1070.19L1219.96 1090.47L1255.09 1110.75L1290.22 1131.04L1429.59 1211.5Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1464.48 1191.22L1325.11 1110.76L1289.98 1090.48L1254.85 1070.19L1219.72 1049.91L1184.6 1029.63L1149.47 1009.35L1114.34 989.07L1079.2 968.78L1044.07 948.5L1008.95 928.22L973.82 907.94L938.69 887.66L903.56 867.37L868.42 847.09L833.29 826.8L798.17 806.53L763.04 786.24L727.91 765.96L692.78 745.68L657.64 725.39L622.51 705.11L587.39 684.83L552.26 664.55L517.13 644.27L482 623.98L446.86 603.7L411.73 583.42L376.61 563.14L341.48 542.86L306.35 522.57L271.22 502.29L236.09 482.01L200.95 461.72L165.83 441.45L130.7 421.16L95.57 400.88L60.44 380.6L25.31 360.31L-9.81 340.04L-44.95 319.75L-80.08 299.47L-115.21 279.19L-254.57 198.73L-115.21 279.19L-80.08 299.47L-44.95 319.75L-9.81 340.04L25.31 360.31L60.44 380.6L95.57 400.88L130.7 421.16L165.83 441.45L200.95 461.72L236.09 482.01L271.22 502.29L306.35 522.57L341.48 542.86L376.61 563.14L411.73 583.42L446.86 603.7L482 623.98L517.13 644.27L552.26 664.55L587.39 684.83L622.51 705.11L657.64 725.39L692.78 745.68L727.91 765.96L763.04 786.24L798.17 806.53L833.29 826.8L868.42 847.09L903.56 867.37L938.69 887.66L973.82 907.94L1008.95 928.22L1044.07 948.5L1079.2 968.78L1114.34 989.07L1149.47 1009.35L1184.6 1029.63L1219.72 1049.91L1254.85 1070.19L1289.98 1090.48L1325.11 1110.76L1464.48 1191.22Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1499.38 1170.94L1360.02 1090.48L1324.89 1070.19L1289.75 1049.91L1254.62 1029.62L1219.5 1009.35L1184.37 989.07L1149.24 968.78L1114.11 948.5L1078.97 928.21L1043.85 907.94L1008.72 887.65L973.59 867.37L938.46 847.09L903.33 826.8L868.2 806.52L833.08 786.24L797.94 765.96L762.81 745.68L727.68 725.39L692.55 705.11L657.42 684.83L622.3 664.55L587.16 644.27L552.03 623.98L516.9 603.7L481.77 583.42L446.64 563.13L411.52 542.86L376.38 522.57L341.25 502.29L306.12 482.01L270.99 461.72L235.86 441.44L200.74 421.16L165.6 400.88L130.47 380.6L95.34 360.31L60.21 340.03L25.09 319.75L-10.04 299.47L-45.17 279.19L-80.31 258.9L-219.67 178.44L-80.31 258.9L-45.17 279.19L-10.04 299.47L25.09 319.75L60.21 340.03L95.34 360.31L130.47 380.6L165.6 400.88L200.74 421.16L235.86 441.44L270.99 461.72L306.12 482.01L341.25 502.29L376.38 522.57L411.52 542.86L446.64 563.13L481.77 583.42L516.9 603.7L552.03 623.98L587.16 644.27L622.3 664.55L657.42 684.83L692.55 705.11L727.68 725.39L762.81 745.68L797.94 765.96L833.08 786.24L868.2 806.52L903.33 826.8L938.46 847.09L973.59 867.37L1008.72 887.65L1043.85 907.94L1078.97 928.21L1114.11 948.5L1149.24 968.78L1184.37 989.07L1219.5 1009.35L1254.62 1029.62L1289.75 1049.91L1324.89 1070.19L1360.02 1090.48L1499.38 1170.94Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1534.29 1150.65L1394.92 1070.19L1359.79 1049.91L1324.66 1029.62L1289.53 1009.34L1254.41 989.06L1219.27 968.78L1184.14 948.5L1149.01 928.21L1113.88 907.93L1078.76 887.65L1043.63 867.37L1008.49 847.09L973.36 826.8L938.23 806.52L903.1 786.24L867.98 765.96L832.85 745.68L797.72 725.39L762.58 705.11L727.45 684.83L692.32 664.54L657.2 644.27L622.07 623.98L586.94 603.7L551.8 583.42L516.67 563.13L481.54 542.85L446.42 522.57L411.29 502.29L376.16 482.01L341.02 461.72L305.89 441.44L270.76 421.16L235.64 400.88L200.51 380.6L165.38 360.31L130.24 340.03L95.11 319.75L59.99 299.47L24.86 279.19L-10.27 258.9L-45.4 238.62L-184.77 158.16L-45.4 238.62L-10.27 258.9L24.86 279.19L59.99 299.47L95.11 319.75L130.24 340.03L165.38 360.31L200.51 380.6L235.64 400.88L270.76 421.16L305.89 441.44L341.02 461.72L376.16 482.01L411.29 502.29L446.42 522.57L481.54 542.85L516.67 563.13L551.8 583.42L586.94 603.7L622.07 623.98L657.2 644.27L692.32 664.54L727.45 684.83L762.58 705.11L797.72 725.39L832.85 745.68L867.98 765.96L903.1 786.24L938.23 806.52L973.36 826.8L1008.49 847.09L1043.63 867.37L1078.76 887.65L1113.88 907.93L1149.01 928.21L1184.14 948.5L1219.27 968.78L1254.41 989.06L1289.53 1009.34L1324.66 1029.62L1359.79 1049.91L1394.92 1070.19L1534.29 1150.65Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1569.19 1130.37L1429.83 1049.91L1394.69 1029.62L1359.56 1009.34L1324.43 989.06L1289.31 968.78L1254.18 948.5L1219.05 928.21L1183.91 907.93L1148.78 887.65L1113.66 867.37L1078.53 847.09L1043.4 826.8L1008.27 806.52L973.13 786.24L938 765.95L902.88 745.68L867.75 725.39L832.62 705.11L797.49 684.83L762.36 664.54L727.22 644.26L692.1 623.98L656.97 603.7L621.84 583.42L586.71 563.13L551.58 542.85L516.44 522.57L481.32 502.29L446.19 482.01L411.06 461.72L375.93 441.44L340.8 421.16L305.66 400.87L270.54 380.6L235.41 360.31L200.28 340.03L165.15 319.75L130.02 299.46L94.9 279.19L59.76 258.9L24.63 238.62L-10.5 218.34L-149.86 137.87L-10.5 218.34L24.63 238.62L59.76 258.9L94.9 279.19L130.02 299.46L165.15 319.75L200.28 340.03L235.41 360.31L270.54 380.6L305.66 400.87L340.8 421.16L375.93 441.44L411.06 461.72L446.19 482.01L481.32 502.29L516.44 522.57L551.58 542.85L586.71 563.13L621.84 583.42L656.97 603.7L692.1 623.98L727.22 644.26L762.36 664.54L797.49 684.83L832.62 705.11L867.75 725.39L902.88 745.68L938 765.95L973.13 786.24L1008.27 806.52L1043.4 826.8L1078.53 847.09L1113.66 867.37L1148.78 887.65L1183.91 907.93L1219.05 928.21L1254.18 948.5L1289.31 968.78L1324.43 989.06L1359.56 1009.34L1394.69 1029.62L1429.83 1049.91L1569.19 1130.37Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1604.09 1110.09L1464.73 1029.62L1429.6 1009.34L1394.47 989.06L1359.33 968.77L1324.21 948.5L1289.08 928.21L1253.95 907.93L1218.82 887.65L1183.69 867.36L1148.57 847.09L1113.43 826.8L1078.3 806.52L1043.17 786.24L1008.04 765.95L972.91 745.67L937.79 725.39L902.65 705.11L867.52 684.83L832.39 664.54L797.26 644.26L762.13 623.98L727.01 603.7L691.87 583.42L656.74 563.13L621.61 542.85L586.48 522.57L551.35 502.28L516.23 482.01L481.1 461.72L445.96 441.44L410.83 421.16L375.7 400.87L340.57 380.59L305.45 360.31L270.32 340.03L235.18 319.75L200.05 299.46L164.92 279.18L129.8 258.9L94.67 238.62L59.54 218.34L24.4 198.05L-114.96 117.59L24.4 198.05L59.54 218.34L94.67 238.62L129.8 258.9L164.92 279.18L200.05 299.46L235.18 319.75L270.32 340.03L305.45 360.31L340.57 380.59L375.7 400.87L410.83 421.16L445.96 441.44L481.1 461.72L516.23 482.01L551.35 502.28L586.48 522.57L621.61 542.85L656.74 563.13L691.87 583.42L727.01 603.7L762.13 623.98L797.26 644.26L832.39 664.54L867.52 684.83L902.65 705.11L937.79 725.39L972.91 745.67L1008.04 765.95L1043.17 786.24L1078.3 806.52L1113.43 826.8L1148.57 847.09L1183.69 867.36L1218.82 887.65L1253.95 907.93L1289.08 928.21L1324.21 948.5L1359.33 968.77L1394.47 989.06L1429.6 1009.34L1464.73 1029.62L1604.09 1110.09Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1639 1089.8L1499.63 1009.34L1464.5 989.06L1429.37 968.77L1394.24 948.49L1359.12 928.21L1323.99 907.93L1288.85 887.65L1253.72 867.36L1218.59 847.08L1183.47 826.8L1148.34 806.52L1113.21 786.24L1078.07 765.95L1042.94 745.67L1007.81 725.39L972.69 705.11L937.56 684.83L902.43 664.54L867.29 644.26L832.16 623.98L797.03 603.69L761.91 583.42L726.78 563.13L691.65 542.85L656.51 522.57L621.38 502.28L586.25 482L551.13 461.72L516 441.44L480.87 421.16L445.73 400.87L410.6 380.59L375.47 360.31L340.35 340.03L305.22 319.75L270.09 299.46L234.96 279.18L199.82 258.89L164.7 238.62L129.57 218.34L94.44 198.05L59.31 177.77L-80.06 97.3098L59.31 177.77L94.44 198.05L129.57 218.34L164.7 238.62L199.82 258.89L234.96 279.18L270.09 299.46L305.22 319.75L340.35 340.03L375.47 360.31L410.6 380.59L445.73 400.87L480.87 421.16L516 441.44L551.13 461.72L586.25 482L621.38 502.28L656.51 522.57L691.65 542.85L726.78 563.13L761.91 583.42L797.03 603.69L832.16 623.98L867.29 644.26L902.43 664.54L937.56 684.83L972.69 705.11L1007.81 725.39L1042.94 745.67L1078.07 765.95L1113.21 786.24L1148.34 806.52L1183.47 826.8L1218.59 847.08L1253.72 867.36L1288.85 887.65L1323.99 907.93L1359.12 928.21L1394.24 948.49L1429.37 968.77L1464.5 989.06L1499.63 1009.34L1639 1089.8Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1673.89 1069.53L1534.52 989.06L1499.39 968.78L1464.26 948.5L1429.13 928.21L1394.01 907.94L1358.88 887.65L1323.74 867.37L1288.61 847.09L1253.48 826.8L1218.36 806.53L1183.23 786.24L1148.1 765.96L1112.97 745.68L1077.83 725.39L1042.7 705.11L1007.58 684.83L972.45 664.55L937.32 644.27L902.19 623.98L867.05 603.7L831.92 583.42L796.8 563.14L761.67 542.86L726.54 522.57L691.41 502.29L656.27 482.01L621.14 461.72L586.02 441.45L550.89 421.16L515.76 400.88L480.63 380.6L445.49 360.31L410.36 340.03L375.24 319.75L340.11 299.47L304.98 279.19L269.85 258.9L234.72 238.62L199.6 218.34L164.46 198.06L129.33 177.77L94.2 157.49L-45.16 77.0299L94.2 157.49L129.33 177.77L164.46 198.06L199.6 218.34L234.72 238.62L269.85 258.9L304.98 279.19L340.11 299.47L375.24 319.75L410.36 340.03L445.49 360.31L480.63 380.6L515.76 400.88L550.89 421.16L586.02 441.45L621.14 461.72L656.27 482.01L691.41 502.29L726.54 522.57L761.67 542.86L796.8 563.14L831.92 583.42L867.05 603.7L902.19 623.98L937.32 644.27L972.45 664.55L1007.58 684.83L1042.7 705.11L1077.83 725.39L1112.97 745.68L1148.1 765.96L1183.23 786.24L1218.36 806.53L1253.48 826.8L1288.61 847.09L1323.74 867.37L1358.88 887.65L1394.01 907.94L1429.13 928.21L1464.26 948.5L1499.39 968.78L1534.52 989.06L1673.89 1069.53Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1708.79 1049.24L1569.43 968.78L1534.3 948.5L1499.16 928.21L1464.03 907.93L1428.91 887.65L1393.78 867.37L1358.65 847.09L1323.52 826.8L1288.38 806.52L1253.26 786.24L1218.13 765.96L1183 745.68L1147.87 725.39L1112.74 705.11L1077.61 684.83L1042.49 664.55L1007.35 644.27L972.22 623.98L937.09 603.7L901.96 583.42L866.83 563.13L831.71 542.86L796.57 522.57L761.44 502.29L726.31 482.01L691.18 461.72L656.05 441.44L620.93 421.16L585.79 400.88L550.66 380.59L515.53 360.31L480.4 340.03L445.27 319.74L410.15 299.47L375.01 279.18L339.88 258.9L304.75 238.62L269.62 218.33L234.5 198.06L199.37 177.77L164.23 157.49L129.1 137.21L-10.26 56.7499L129.1 137.21L164.23 157.49L199.37 177.77L234.5 198.06L269.62 218.33L304.75 238.62L339.88 258.9L375.01 279.18L410.15 299.47L445.27 319.74L480.4 340.03L515.53 360.31L550.66 380.59L585.79 400.88L620.93 421.16L656.05 441.44L691.18 461.72L726.31 482.01L761.44 502.29L796.57 522.57L831.71 542.86L866.83 563.13L901.96 583.42L937.09 603.7L972.22 623.98L1007.35 644.27L1042.49 664.55L1077.61 684.83L1112.74 705.11L1147.87 725.39L1183 745.68L1218.13 765.96L1253.26 786.24L1288.38 806.52L1323.52 826.8L1358.65 847.09L1393.78 867.37L1428.91 887.65L1464.03 907.93L1499.16 928.21L1534.3 948.5L1569.43 968.78L1708.79 1049.24Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1743.7 1028.96L1604.33 948.5L1569.2 928.21L1534.07 907.93L1498.94 887.65L1463.82 867.37L1428.68 847.09L1393.55 826.8L1358.42 806.52L1323.29 786.24L1288.17 765.96L1253.04 745.68L1217.9 725.39L1182.77 705.11L1147.64 684.83L1112.51 664.54L1077.39 644.27L1042.26 623.98L1007.12 603.7L971.99 583.41L936.86 563.13L901.73 542.85L866.61 522.57L831.48 502.29L796.35 482L761.21 461.72L726.08 441.44L690.95 421.15L655.83 400.88L620.7 380.59L585.57 360.31L550.43 340.03L515.3 319.74L480.17 299.46L445.05 279.18L409.92 258.9L374.79 238.62L339.65 218.33L304.52 198.05L269.4 177.77L234.27 157.49L199.14 137.21L164.01 116.92L24.64 36.4598L164.01 116.92L199.14 137.21L234.27 157.49L269.4 177.77L304.52 198.05L339.65 218.33L374.79 238.62L409.92 258.9L445.05 279.18L480.17 299.46L515.3 319.74L550.43 340.03L585.57 360.31L620.7 380.59L655.83 400.88L690.95 421.15L726.08 441.44L761.21 461.72L796.35 482L831.48 502.29L866.61 522.57L901.73 542.85L936.86 563.13L971.99 583.41L1007.12 603.7L1042.26 623.98L1077.39 644.27L1112.51 664.54L1147.64 684.83L1182.77 705.11L1217.9 725.39L1253.04 745.68L1288.17 765.96L1323.29 786.24L1358.42 806.52L1393.55 826.8L1428.68 847.09L1463.82 867.37L1498.94 887.65L1534.07 907.93L1569.2 928.21L1604.33 948.5L1743.7 1028.96Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1778.6 1008.67L1639.24 928.21L1604.1 907.93L1568.97 887.65L1533.84 867.36L1498.72 847.09L1463.59 826.8L1428.46 806.52L1393.32 786.24L1358.19 765.95L1323.07 745.68L1287.94 725.39L1252.81 705.11L1217.68 684.82L1182.54 664.54L1147.41 644.26L1112.29 623.98L1077.16 603.7L1042.03 583.41L1006.9 563.13L971.76 542.85L936.63 522.56L901.51 502.29L866.38 482L831.25 461.72L796.12 441.44L760.99 421.15L725.85 400.87L690.73 380.59L655.6 360.31L620.47 340.03L585.34 319.74L550.21 299.46L515.07 279.18L479.95 258.9L444.82 238.62L409.69 218.33L374.56 198.05L339.43 177.77L304.31 157.49L269.17 137.21L234.04 116.92L198.91 96.6399L59.55 16.1799L198.91 96.6399L234.04 116.92L269.17 137.21L304.31 157.49L339.43 177.77L374.56 198.05L409.69 218.33L444.82 238.62L479.95 258.9L515.07 279.18L550.21 299.46L585.34 319.74L620.47 340.03L655.6 360.31L690.73 380.59L725.85 400.87L760.99 421.15L796.12 441.44L831.25 461.72L866.38 482L901.51 502.29L936.63 522.56L971.76 542.85L1006.9 563.13L1042.03 583.41L1077.16 603.7L1112.29 623.98L1147.41 644.26L1182.54 664.54L1217.68 684.82L1252.81 705.11L1287.94 725.39L1323.07 745.68L1358.19 765.95L1393.32 786.24L1428.46 806.52L1463.59 826.8L1498.72 847.09L1533.84 867.36L1568.97 887.65L1604.1 907.93L1639.24 928.21L1778.6 1008.67Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1813.5 988.39L1674.14 907.93L1639.01 887.64L1603.87 867.36L1568.74 847.08L1533.62 826.8L1498.49 806.52L1463.36 786.23L1428.23 765.95L1393.1 745.67L1357.98 725.39L1322.84 705.11L1287.71 684.82L1252.58 664.54L1217.45 644.26L1182.32 623.97L1147.2 603.7L1112.06 583.41L1076.93 563.13L1041.8 542.85L1006.67 522.56L971.54 502.28L936.42 482L901.28 461.72L866.15 441.44L831.02 421.15L795.89 400.87L760.76 380.59L725.64 360.31L690.5 340.03L655.37 319.74L620.24 299.46L585.11 279.18L549.98 258.89L514.86 238.62L479.73 218.33L444.59 198.05L409.46 177.77L374.33 157.48L339.21 137.21L304.08 116.92L268.95 96.6399L233.81 76.3599L94.45 -4.11011L233.81 76.3599L268.95 96.6399L304.08 116.92L339.21 137.21L374.33 157.48L409.46 177.77L444.59 198.05L479.73 218.33L514.86 238.62L549.98 258.89L585.11 279.18L620.24 299.46L655.37 319.74L690.5 340.03L725.64 360.31L760.76 380.59L795.89 400.87L831.02 421.15L866.15 441.44L901.28 461.72L936.42 482L971.54 502.28L1006.67 522.56L1041.8 542.85L1076.93 563.13L1112.06 583.41L1147.2 603.7L1182.32 623.97L1217.45 644.26L1252.58 664.54L1287.71 684.82L1322.84 705.11L1357.98 725.39L1393.1 745.67L1428.23 765.95L1463.36 786.23L1498.49 806.52L1533.62 826.8L1568.74 847.08L1603.87 867.36L1639.01 887.64L1674.14 907.93L1813.5 988.39Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1848.41 968.11L1709.04 887.64L1673.91 867.36L1638.78 847.08L1603.65 826.79L1568.53 806.52L1533.39 786.23L1498.26 765.95L1463.13 745.67L1428 725.38L1392.88 705.11L1357.75 684.82L1322.62 664.54L1287.48 644.26L1252.35 623.97L1217.22 603.69L1182.1 583.41L1146.97 563.13L1111.84 542.85L1076.7 522.56L1041.57 502.28L1006.44 482L971.32 461.72L936.19 441.44L901.06 421.15L865.92 400.87L830.79 380.59L795.66 360.3L760.54 340.03L725.41 319.74L690.28 299.46L655.14 279.18L620.01 258.89L584.88 238.61L549.76 218.33L514.63 198.05L479.5 177.77L444.37 157.48L409.23 137.2L374.11 116.92L338.98 96.6399L303.85 76.3599L268.72 56.0699L129.35 -24.3901L268.72 56.0699L303.85 76.3599L338.98 96.6399L374.11 116.92L409.23 137.2L444.37 157.48L479.5 177.77L514.63 198.05L549.76 218.33L584.88 238.61L620.01 258.89L655.14 279.18L690.28 299.46L725.41 319.74L760.54 340.03L795.66 360.3L830.79 380.59L865.92 400.87L901.06 421.15L936.19 441.44L971.32 461.72L1006.44 482L1041.57 502.28L1076.7 522.56L1111.84 542.85L1146.97 563.13L1182.1 583.41L1217.22 603.69L1252.35 623.97L1287.48 644.26L1322.62 664.54L1357.75 684.82L1392.88 705.11L1428 725.38L1463.13 745.67L1498.26 765.95L1533.39 786.23L1568.53 806.52L1603.65 826.79L1638.78 847.08L1673.91 867.36L1709.04 887.64L1848.41 968.11Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1883.3 947.83L1743.93 867.37L1708.8 847.08L1673.67 826.8L1638.54 806.52L1603.42 786.24L1568.29 765.96L1533.15 745.67L1498.02 725.39L1462.89 705.11L1427.77 684.83L1392.64 664.55L1357.51 644.26L1322.37 623.98L1287.24 603.7L1252.11 583.41L1216.99 563.14L1181.86 542.85L1146.73 522.57L1111.6 502.29L1076.46 482L1041.33 461.72L1006.21 441.44L971.08 421.16L935.95 400.88L900.82 380.59L865.68 360.31L830.55 340.03L795.43 319.75L760.3 299.47L725.17 279.18L690.04 258.9L654.9 238.62L619.77 218.33L584.65 198.06L549.52 177.77L514.39 157.49L479.26 137.21L444.12 116.92L409 96.6498L373.87 76.3598L338.74 56.0798L303.61 35.7998L164.25 -44.6702L303.61 35.7998L338.74 56.0798L373.87 76.3598L409 96.6498L444.12 116.92L479.26 137.21L514.39 157.49L549.52 177.77L584.65 198.06L619.77 218.33L654.9 238.62L690.04 258.9L725.17 279.18L760.3 299.47L795.43 319.75L830.55 340.03L865.68 360.31L900.82 380.59L935.95 400.88L971.08 421.16L1006.21 441.44L1041.33 461.72L1076.46 482L1111.6 502.29L1146.73 522.57L1181.86 542.85L1216.99 563.14L1252.11 583.41L1287.24 603.7L1322.37 623.98L1357.51 644.26L1392.64 664.55L1427.77 684.83L1462.89 705.11L1498.02 725.39L1533.15 745.67L1568.29 765.96L1603.42 786.24L1638.54 806.52L1673.67 826.8L1708.8 847.08L1743.93 867.37L1883.3 947.83Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1918.2 927.55L1778.84 847.08L1743.71 826.8L1708.57 806.52L1673.44 786.23L1638.32 765.96L1603.19 745.67L1568.06 725.39L1532.93 705.11L1497.79 684.82L1462.67 664.55L1427.54 644.26L1392.41 623.98L1357.28 603.7L1322.15 583.41L1287.01 563.13L1251.89 542.85L1216.76 522.57L1181.63 502.29L1146.5 482L1111.37 461.72L1076.24 441.44L1041.12 421.16L1005.98 400.88L970.85 380.59L935.72 360.31L900.59 340.03L865.46 319.74L830.34 299.47L795.2 279.18L760.07 258.9L724.94 238.62L689.81 218.33L654.68 198.05L619.56 177.77L584.42 157.49L549.29 137.21L514.16 116.92L479.03 96.6398L443.91 76.3598L408.78 56.0798L373.64 35.7998L338.51 15.5098L199.15 -64.9502L338.51 15.5098L373.64 35.7998L408.78 56.0798L443.91 76.3598L479.03 96.6398L514.16 116.92L549.29 137.21L584.42 157.49L619.56 177.77L654.68 198.05L689.81 218.33L724.94 238.62L760.07 258.9L795.2 279.18L830.34 299.47L865.46 319.74L900.59 340.03L935.72 360.31L970.85 380.59L1005.98 400.88L1041.12 421.16L1076.24 441.44L1111.37 461.72L1146.5 482L1181.63 502.29L1216.76 522.57L1251.89 542.85L1287.01 563.13L1322.15 583.41L1357.28 603.7L1392.41 623.98L1427.54 644.26L1462.67 664.55L1497.79 684.82L1532.93 705.11L1568.06 725.39L1603.19 745.67L1638.32 765.96L1673.44 786.23L1708.57 806.52L1743.71 826.8L1778.84 847.08L1918.2 927.55Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1953.1 907.26L1813.74 826.8L1778.61 806.52L1743.48 786.23L1708.35 765.95L1673.23 745.67L1638.09 725.39L1602.96 705.11L1567.83 684.82L1532.7 664.54L1497.58 644.26L1462.45 623.98L1427.31 603.7L1392.18 583.41L1357.05 563.13L1321.92 542.85L1286.8 522.57L1251.67 502.29L1216.53 482L1181.4 461.72L1146.27 441.44L1111.14 421.15L1076.02 400.88L1040.89 380.59L1005.75 360.31L970.62 340.03L935.49 319.74L900.36 299.46L865.24 279.18L830.11 258.9L794.98 238.62L759.84 218.33L724.71 198.05L689.58 177.77L654.46 157.49L619.33 137.21L584.2 116.92L549.06 96.6399L513.93 76.3599L478.81 56.0799L443.68 35.7999L408.55 15.5099L373.42 -4.7701L234.05 -85.2301L373.42 -4.7701L408.55 15.5099L443.68 35.7999L478.81 56.0799L513.93 76.3599L549.06 96.6399L584.2 116.92L619.33 137.21L654.46 157.49L689.58 177.77L724.71 198.05L759.84 218.33L794.98 238.62L830.11 258.9L865.24 279.18L900.36 299.46L935.49 319.74L970.62 340.03L1005.75 360.31L1040.89 380.59L1076.02 400.88L1111.14 421.15L1146.27 441.44L1181.4 461.72L1216.53 482L1251.67 502.29L1286.8 522.57L1321.92 542.85L1357.05 563.13L1392.18 583.41L1427.31 603.7L1462.45 623.98L1497.58 644.26L1532.7 664.54L1567.83 684.82L1602.96 705.11L1638.09 725.39L1673.23 745.67L1708.35 765.95L1743.48 786.23L1778.61 806.52L1813.74 826.8L1953.1 907.26Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1988.01 886.98L1848.64 806.52L1813.51 786.23L1778.38 765.95L1743.25 745.67L1708.13 725.39L1673 705.11L1637.87 684.82L1602.73 664.54L1567.6 644.26L1532.48 623.98L1497.35 603.7L1462.22 583.41L1427.09 563.13L1391.95 542.85L1356.82 522.56L1321.7 502.29L1286.57 482L1251.44 461.72L1216.31 441.44L1181.17 421.15L1146.04 400.87L1110.92 380.59L1075.79 360.31L1040.66 340.03L1005.53 319.74L970.39 299.46L935.26 279.18L900.14 258.9L865.01 238.62L829.88 218.33L794.75 198.05L759.62 177.76L724.48 157.48L689.36 137.21L654.23 116.92L619.1 96.6399L583.97 76.3499L548.84 56.0699L513.72 35.7899L478.58 15.5099L443.45 -4.77014L408.32 -25.0601L268.96 -105.52L408.32 -25.0601L443.45 -4.77014L478.58 15.5099L513.72 35.7899L548.84 56.0699L583.97 76.3499L619.1 96.6399L654.23 116.92L689.36 137.21L724.48 157.48L759.62 177.76L794.75 198.05L829.88 218.33L865.01 238.62L900.14 258.9L935.26 279.18L970.39 299.46L1005.53 319.74L1040.66 340.03L1075.79 360.31L1110.92 380.59L1146.04 400.87L1181.17 421.15L1216.31 441.44L1251.44 461.72L1286.57 482L1321.7 502.29L1356.82 522.56L1391.95 542.85L1427.09 563.13L1462.22 583.41L1497.35 603.7L1532.48 623.98L1567.6 644.26L1602.73 664.54L1637.87 684.82L1673 705.11L1708.13 725.39L1743.25 745.67L1778.38 765.95L1813.51 786.23L1848.64 806.52L1988.01 886.98Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2022.91 866.69L1883.55 786.23L1848.42 765.95L1813.28 745.67L1778.15 725.38L1743.03 705.11L1707.9 684.82L1672.77 664.54L1637.64 644.26L1602.51 623.97L1567.39 603.7L1532.25 583.41L1497.12 563.13L1461.99 542.85L1426.86 522.56L1391.73 502.28L1356.61 482L1321.47 461.72L1286.34 441.44L1251.21 421.15L1216.08 400.87L1180.95 380.59L1145.83 360.31L1110.69 340.03L1075.56 319.74L1040.43 299.46L1005.3 279.17L970.17 258.89L935.05 238.61L899.91 218.33L864.78 198.05L829.65 177.76L794.52 157.48L759.39 137.2L724.27 116.92L689.13 96.6398L654 76.3498L618.87 56.0698L583.74 35.7898L548.62 15.5098L513.49 -4.77016L478.36 -25.0602L443.22 -45.3402L303.86 -125.8L443.22 -45.3402L478.36 -25.0602L513.49 -4.77016L548.62 15.5098L583.74 35.7898L618.87 56.0698L654 76.3498L689.13 96.6398L724.27 116.92L759.39 137.2L794.52 157.48L829.65 177.76L864.78 198.05L899.91 218.33L935.05 238.61L970.17 258.89L1005.3 279.17L1040.43 299.46L1075.56 319.74L1110.69 340.03L1145.83 360.31L1180.95 380.59L1216.08 400.87L1251.21 421.15L1286.34 441.44L1321.47 461.72L1356.61 482L1391.73 502.28L1426.86 522.56L1461.99 542.85L1497.12 563.13L1532.25 583.41L1567.39 603.7L1602.51 623.97L1637.64 644.26L1672.77 664.54L1707.9 684.82L1743.03 705.11L1778.15 725.38L1813.28 745.67L1848.42 765.95L1883.55 786.23L2022.91 866.69Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2057.82 846.41L1918.45 765.95L1883.32 745.67L1848.19 725.38L1813.06 705.1L1777.94 684.82L1742.8 664.54L1707.67 644.26L1672.54 623.97L1637.41 603.69L1602.29 583.41L1567.16 563.13L1532.02 542.85L1496.89 522.56L1461.76 502.28L1426.63 481.99L1391.51 461.72L1356.38 441.44L1321.25 421.15L1286.11 400.87L1250.98 380.58L1215.85 360.3L1180.73 340.02L1145.6 319.74L1110.47 299.46L1075.33 279.17L1040.2 258.89L1005.07 238.61L969.95 218.33L934.82 198.05L899.69 177.76L864.55 157.48L829.42 137.2L794.29 116.91L759.17 96.6399L724.04 76.3499L688.91 56.0699L653.77 35.7899L618.64 15.4999L583.52 -4.77008L548.39 -25.0601L513.26 -45.3401L478.13 -65.6201L338.76 -146.08L478.13 -65.6201L513.26 -45.3401L548.39 -25.0601L583.52 -4.77008L618.64 15.4999L653.77 35.7899L688.91 56.0699L724.04 76.3499L759.17 96.6399L794.29 116.91L829.42 137.2L864.55 157.48L899.69 177.76L934.82 198.05L969.95 218.33L1005.07 238.61L1040.2 258.89L1075.33 279.17L1110.47 299.46L1145.6 319.74L1180.73 340.02L1215.85 360.3L1250.98 380.58L1286.11 400.87L1321.25 421.15L1356.38 441.44L1391.51 461.72L1426.63 481.99L1461.76 502.28L1496.89 522.56L1532.02 542.85L1567.16 563.13L1602.29 583.41L1637.41 603.69L1672.54 623.97L1707.67 644.26L1742.8 664.54L1777.94 684.82L1813.06 705.1L1848.19 725.38L1883.32 745.67L1918.45 765.95L2057.82 846.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2092.71 826.13L1953.34 745.67L1918.21 725.39L1883.08 705.11L1847.95 684.82L1812.83 664.55L1777.7 644.26L1742.56 623.98L1707.43 603.7L1672.3 583.41L1637.18 563.14L1602.05 542.85L1566.92 522.57L1531.78 502.28L1496.65 482L1461.52 461.72L1426.4 441.44L1391.27 421.16L1356.14 400.87L1321 380.59L1285.87 360.31L1250.74 340.02L1215.62 319.75L1180.49 299.46L1145.36 279.18L1110.23 258.9L1075.09 238.61L1039.96 218.33L1004.84 198.05L969.71 177.77L934.58 157.49L899.45 137.2L864.31 116.92L829.18 96.6399L794.06 76.3599L758.93 56.0799L723.8 35.7899L688.67 15.5099L653.53 -4.77011L618.41 -25.0501L583.28 -45.3301L548.15 -65.6201L513.02 -85.9001L373.66 -166.36L513.02 -85.9001L548.15 -65.6201L583.28 -45.3301L618.41 -25.0501L653.53 -4.77011L688.67 15.5099L723.8 35.7899L758.93 56.0799L794.06 76.3599L829.18 96.6399L864.31 116.92L899.45 137.2L934.58 157.49L969.71 177.77L1004.84 198.05L1039.96 218.33L1075.09 238.61L1110.23 258.9L1145.36 279.18L1180.49 299.46L1215.62 319.75L1250.74 340.02L1285.87 360.31L1321 380.59L1356.14 400.87L1391.27 421.16L1426.4 441.44L1461.52 461.72L1496.65 482L1531.78 502.28L1566.92 522.57L1602.05 542.85L1637.18 563.14L1672.3 583.41L1707.43 603.7L1742.56 623.98L1777.7 644.26L1812.83 664.55L1847.95 684.82L1883.08 705.11L1918.21 725.39L1953.34 745.67L2092.71 826.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2127.61 805.85L1988.25 725.39L1953.12 705.11L1917.98 684.82L1882.85 664.54L1847.73 644.26L1812.6 623.98L1777.47 603.69L1742.34 583.41L1707.2 563.13L1672.08 542.85L1636.95 522.57L1601.82 502.28L1566.69 482L1531.56 461.72L1496.42 441.43L1461.3 421.16L1426.17 400.87L1391.04 380.59L1355.91 360.31L1320.78 340.02L1285.64 319.74L1250.52 299.46L1215.39 279.18L1180.26 258.9L1145.13 238.61L1110 218.33L1074.87 198.05L1039.75 177.77L1004.61 157.49L969.48 137.2L934.35 116.92L899.22 96.6398L864.09 76.3499L828.97 56.0798L793.83 35.7899L758.7 15.5098L723.57 -4.77016L688.44 -25.0602L653.32 -45.3302L618.19 -65.6202L583.05 -85.9002L547.92 -106.18L408.56 -186.65L547.92 -106.18L583.05 -85.9002L618.19 -65.6202L653.32 -45.3302L688.44 -25.0602L723.57 -4.77016L758.7 15.5098L793.83 35.7899L828.97 56.0798L864.09 76.3499L899.22 96.6398L934.35 116.92L969.48 137.2L1004.61 157.49L1039.75 177.77L1074.87 198.05L1110 218.33L1145.13 238.61L1180.26 258.9L1215.39 279.18L1250.52 299.46L1285.64 319.74L1320.78 340.02L1355.91 360.31L1391.04 380.59L1426.17 400.87L1461.3 421.16L1496.42 441.43L1531.56 461.72L1566.69 482L1601.82 502.28L1636.95 522.57L1672.08 542.85L1707.2 563.13L1742.34 583.41L1777.47 603.69L1812.6 623.98L1847.73 644.26L1882.85 664.54L1917.98 684.82L1953.12 705.11L1988.25 725.39L2127.61 805.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2162.51 785.57L2023.15 705.1L1988.02 684.82L1952.89 664.54L1917.76 644.25L1882.64 623.98L1847.5 603.69L1812.37 583.41L1777.24 563.13L1742.11 542.84L1706.99 522.57L1671.86 502.28L1636.72 482L1601.59 461.72L1566.46 441.43L1531.33 421.15L1496.21 400.87L1461.08 380.59L1425.94 360.31L1390.81 340.02L1355.68 319.74L1320.55 299.46L1285.43 279.18L1250.3 258.9L1215.16 238.61L1180.03 218.33L1144.9 198.05L1109.77 177.76L1074.65 157.49L1039.52 137.2L1004.38 116.92L969.25 96.6398L934.12 76.3498L898.99 56.0698L863.87 35.7898L828.74 15.5098L793.61 -4.77019L758.47 -25.0602L723.34 -45.3402L688.22 -65.6202L653.09 -85.9002L617.96 -106.18L582.83 -126.47L443.46 -206.93L582.83 -126.47L617.96 -106.18L653.09 -85.9002L688.22 -65.6202L723.34 -45.3402L758.47 -25.0602L793.61 -4.77019L828.74 15.5098L863.87 35.7898L898.99 56.0698L934.12 76.3498L969.25 96.6398L1004.38 116.92L1039.52 137.2L1074.65 157.49L1109.77 177.76L1144.9 198.05L1180.03 218.33L1215.16 238.61L1250.3 258.9L1285.43 279.18L1320.55 299.46L1355.68 319.74L1390.81 340.02L1425.94 360.31L1461.08 380.59L1496.21 400.87L1531.33 421.15L1566.46 441.43L1601.59 461.72L1636.72 482L1671.86 502.28L1706.99 522.57L1742.11 542.84L1777.24 563.13L1812.37 583.41L1847.5 603.69L1882.64 623.98L1917.76 644.25L1952.89 664.54L1988.02 684.82L2023.15 705.1L2162.51 785.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2197.42 765.28L2058.05 684.82L2022.92 664.54L1987.79 644.25L1952.66 623.97L1917.54 603.69L1882.41 583.41L1847.27 563.13L1812.14 542.84L1777.01 522.56L1741.89 502.28L1706.76 482L1671.63 461.72L1636.5 441.43L1601.36 421.15L1566.23 400.87L1531.11 380.59L1495.98 360.31L1460.85 340.02L1425.72 319.74L1390.58 299.46L1355.45 279.17L1320.33 258.9L1285.2 238.61L1250.07 218.33L1214.94 198.05L1179.8 177.76L1144.67 157.48L1109.55 137.2L1074.42 116.92L1039.29 96.6398L1004.16 76.3498L969.02 56.0698L933.89 35.7898L898.77 15.5098L863.64 -4.7702L828.51 -25.0602L793.38 -45.3402L758.25 -65.6202L723.13 -85.9002L687.99 -106.18L652.86 -126.47L617.73 -146.75L478.37 -227.21L617.73 -146.75L652.86 -126.47L687.99 -106.18L723.13 -85.9002L758.25 -65.6202L793.38 -45.3402L828.51 -25.0602L863.64 -4.7702L898.77 15.5098L933.89 35.7898L969.02 56.0698L1004.16 76.3498L1039.29 96.6398L1074.42 116.92L1109.55 137.2L1144.67 157.48L1179.8 177.76L1214.94 198.05L1250.07 218.33L1285.2 238.61L1320.33 258.9L1355.45 279.17L1390.58 299.46L1425.72 319.74L1460.85 340.02L1495.98 360.31L1531.11 380.59L1566.23 400.87L1601.36 421.15L1636.5 441.43L1671.63 461.72L1706.76 482L1741.89 502.28L1777.01 522.56L1812.14 542.84L1847.27 563.13L1882.41 583.41L1917.54 603.69L1952.66 623.97L1987.79 644.25L2022.92 664.54L2058.05 684.82L2197.42 765.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2232.32 745L2092.96 664.54L2057.83 644.25L2022.69 623.97L1987.56 603.69L1952.44 583.41L1917.31 563.13L1882.18 542.84L1847.05 522.56L1811.91 502.28L1776.79 482L1741.66 461.72L1706.53 441.43L1671.4 421.15L1636.27 400.87L1601.14 380.58L1566.02 360.31L1530.88 340.02L1495.75 319.74L1460.62 299.46L1425.49 279.17L1390.36 258.89L1355.24 238.61L1320.1 218.33L1284.97 198.05L1249.84 177.76L1214.71 157.48L1179.58 137.2L1144.46 116.92L1109.32 96.64L1074.19 76.35L1039.06 56.07L1003.93 35.79L968.8 15.5L933.68 -4.77L898.54 -25.06L863.41 -45.34L828.28 -65.62L793.15 -85.91L758.03 -106.18L722.9 -126.47L687.76 -146.75L652.63 -167.03L513.27 -247.5L652.63 -167.03L687.76 -146.75L722.9 -126.47L758.03 -106.18L793.15 -85.91L828.28 -65.62L863.41 -45.34L898.54 -25.06L933.68 -4.77L968.8 15.5L1003.93 35.79L1039.06 56.07L1074.19 76.35L1109.32 96.64L1144.46 116.92L1179.58 137.2L1214.71 157.48L1249.84 177.76L1284.97 198.05L1320.1 218.33L1355.24 238.61L1390.36 258.89L1425.49 279.17L1460.62 299.46L1495.75 319.74L1530.88 340.02L1566.02 360.31L1601.14 380.58L1636.27 400.87L1671.4 421.15L1706.53 441.43L1741.66 461.72L1776.79 482L1811.91 502.28L1847.05 522.56L1882.18 542.84L1917.31 563.13L1952.44 583.41L1987.56 603.69L2022.69 623.97L2057.83 644.25L2092.96 664.54L2232.32 745Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2267.23 724.72L2127.86 644.25L2092.73 623.97L2057.6 603.69L2022.47 583.4L1987.35 563.13L1952.21 542.84L1917.08 522.56L1881.95 502.28L1846.82 481.99L1811.7 461.72L1776.57 441.43L1741.43 421.15L1706.3 400.87L1671.17 380.58L1636.04 360.3L1600.92 340.02L1565.79 319.74L1530.65 299.46L1495.52 279.17L1460.39 258.89L1425.26 238.61L1390.14 218.33L1355.01 198.05L1319.88 177.76L1284.74 157.48L1249.61 137.2L1214.48 116.91L1179.36 96.64L1144.23 76.35L1109.1 56.07L1073.96 35.79L1038.83 15.5L1003.7 -4.78003L968.58 -25.06L933.45 -45.34L898.32 -65.62L863.18 -85.91L828.05 -106.19L792.93 -126.47L757.8 -146.75L722.67 -167.04L687.54 -187.32L548.17 -267.78L687.54 -187.32L722.67 -167.04L757.8 -146.75L792.93 -126.47L828.05 -106.19L863.18 -85.91L898.32 -65.62L933.45 -45.34L968.58 -25.06L1003.7 -4.78003L1038.83 15.5L1073.96 35.79L1109.1 56.07L1144.23 76.35L1179.36 96.64L1214.48 116.91L1249.61 137.2L1284.74 157.48L1319.88 177.76L1355.01 198.05L1390.14 218.33L1425.26 238.61L1460.39 258.89L1495.52 279.17L1530.65 299.46L1565.79 319.74L1600.92 340.02L1636.04 360.3L1671.17 380.58L1706.3 400.87L1741.43 421.15L1776.57 441.43L1811.7 461.72L1846.82 481.99L1881.95 502.28L1917.08 522.56L1952.21 542.84L1987.35 563.13L2022.47 583.4L2057.6 603.69L2092.73 623.97L2127.86 644.25L2267.23 724.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2302.12 704.44L2162.75 623.98L2127.62 603.69L2092.49 583.41L2057.36 563.13L2022.24 542.85L1987.11 522.57L1951.97 502.28L1916.84 482L1881.71 461.72L1846.59 441.44L1811.46 421.16L1776.33 400.87L1741.19 380.59L1706.06 360.31L1670.93 340.02L1635.81 319.75L1600.68 299.46L1565.55 279.18L1530.41 258.9L1495.28 238.61L1460.15 218.33L1425.03 198.05L1389.9 177.77L1354.77 157.49L1319.64 137.2L1284.5 116.92L1249.37 96.64L1214.25 76.3599L1179.12 56.08L1143.99 35.7899L1108.86 15.5099L1073.72 -4.77005L1038.59 -25.0601L1003.47 -45.3301L968.34 -65.6201L933.21 -85.9001L898.08 -106.19L862.94 -126.47L827.82 -146.75L792.69 -167.03L757.56 -187.31L722.43 -207.6L583.06 -288.06L722.43 -207.6L757.56 -187.31L792.69 -167.03L827.82 -146.75L862.94 -126.47L898.08 -106.19L933.21 -85.9001L968.34 -65.6201L1003.47 -45.3301L1038.59 -25.0601L1073.72 -4.77005L1108.86 15.5099L1143.99 35.7899L1179.12 56.08L1214.25 76.3599L1249.37 96.64L1284.5 116.92L1319.64 137.2L1354.77 157.49L1389.9 177.77L1425.03 198.05L1460.15 218.33L1495.28 238.61L1530.41 258.9L1565.55 279.18L1600.68 299.46L1635.81 319.75L1670.93 340.02L1706.06 360.31L1741.19 380.59L1776.33 400.87L1811.46 421.16L1846.59 441.44L1881.71 461.72L1916.84 482L1951.97 502.28L1987.11 522.57L2022.24 542.85L2057.36 563.13L2092.49 583.41L2127.62 603.69L2162.75 623.98L2302.12 704.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2337.02 684.15L2197.66 603.69L2162.52 583.41L2127.39 563.13L2092.26 542.84L2057.14 522.57L2022.01 502.28L1986.88 482L1951.75 461.72L1916.61 441.43L1881.49 421.16L1846.36 400.87L1811.23 380.59L1776.1 360.31L1740.97 340.02L1705.83 319.74L1670.71 299.46L1635.58 279.18L1600.45 258.9L1565.32 238.61L1530.19 218.33L1495.05 198.05L1459.93 177.77L1424.8 157.49L1389.67 137.2L1354.54 116.92L1319.41 96.6299L1284.27 76.3499L1249.15 56.0799L1214.02 35.7899L1178.89 15.5099L1143.76 -4.78009L1108.63 -25.0601L1073.5 -45.3401L1038.38 -65.6201L1003.24 -85.9001L968.11 -106.19L932.98 -126.47L897.85 -146.75L862.73 -167.03L827.6 -187.31L792.46 -207.6L757.33 -227.88L617.97 -308.34L757.33 -227.88L792.46 -207.6L827.6 -187.31L862.73 -167.03L897.85 -146.75L932.98 -126.47L968.11 -106.19L1003.24 -85.9001L1038.38 -65.6201L1073.5 -45.3401L1108.63 -25.0601L1143.76 -4.78009L1178.89 15.5099L1214.02 35.7899L1249.15 56.0799L1284.27 76.3499L1319.41 96.6299L1354.54 116.92L1389.67 137.2L1424.8 157.49L1459.93 177.77L1495.05 198.05L1530.19 218.33L1565.32 238.61L1600.45 258.9L1635.58 279.18L1670.71 299.46L1705.83 319.74L1740.97 340.02L1776.1 360.31L1811.23 380.59L1846.36 400.87L1881.49 421.16L1916.61 441.43L1951.75 461.72L1986.88 482L2022.01 502.28L2057.14 522.57L2092.26 542.84L2127.39 563.13L2162.52 583.41L2197.66 603.69L2337.02 684.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2371.92 663.87L2232.56 583.41L2197.43 563.13L2162.3 542.84L2127.16 522.56L2092.04 502.28L2056.91 482L2021.78 461.72L1986.65 441.43L1951.52 421.15L1916.4 400.87L1881.27 380.59L1846.13 360.31L1811 340.02L1775.87 319.74L1740.74 299.46L1705.62 279.18L1670.49 258.9L1635.35 238.61L1600.22 218.33L1565.09 198.04L1529.96 177.76L1494.84 157.48L1459.71 137.2L1424.57 116.92L1389.44 96.6299L1354.31 76.3499L1319.18 56.0699L1284.06 35.7899L1248.93 15.5099L1213.79 -4.78012L1178.66 -25.0601L1143.53 -45.3401L1108.4 -65.6301L1073.28 -85.9001L1038.15 -106.19L1003.02 -126.47L967.88 -146.75L932.75 -167.04L897.63 -187.31L862.5 -207.6L827.37 -227.88L792.24 -248.16L652.87 -328.62L792.24 -248.16L827.37 -227.88L862.5 -207.6L897.63 -187.31L932.75 -167.04L967.88 -146.75L1003.02 -126.47L1038.15 -106.19L1073.28 -85.9001L1108.4 -65.6301L1143.53 -45.3401L1178.66 -25.0601L1213.79 -4.78012L1248.93 15.5099L1284.06 35.7899L1319.18 56.0699L1354.31 76.3499L1389.44 96.6299L1424.57 116.92L1459.71 137.2L1494.84 157.48L1529.96 177.76L1565.09 198.04L1600.22 218.33L1635.35 238.61L1670.49 258.9L1705.62 279.18L1740.74 299.46L1775.87 319.74L1811 340.02L1846.13 360.31L1881.27 380.59L1916.4 400.87L1951.52 421.15L1986.65 441.43L2021.78 461.72L2056.91 482L2092.04 502.28L2127.16 522.56L2162.3 542.84L2197.43 563.13L2232.56 583.41L2371.92 663.87Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2602.96 1349.46L2463.6 1268.99L2428.46 1248.71L2393.33 1228.43L2358.2 1208.14L2323.08 1187.87L2287.95 1167.58L2252.82 1147.3L2217.69 1127.02L2182.55 1106.73L2147.43 1086.46L2112.3 1066.17L2077.17 1045.89L2042.04 1025.61L2006.91 1005.32L1971.77 985.04L1936.65 964.76L1901.52 944.48L1866.39 924.2L1831.26 903.91L1796.13 883.63L1760.99 863.35L1725.87 843.07L1690.74 822.79L1655.61 802.5L1620.48 782.22L1585.35 761.94L1550.21 741.65L1515.09 721.38L1479.96 701.09L1444.83 680.81L1409.7 660.53L1374.57 640.24L1339.44 619.96L1304.32 599.68L1269.18 579.4L1234.05 559.12L1198.92 538.83L1163.79 518.55L1128.67 498.27L1093.54 477.99L1058.4 457.71L1023.27 437.42L883.91 356.96L1023.27 437.42L1058.4 457.71L1093.54 477.99L1128.67 498.27L1163.79 518.55L1198.92 538.83L1234.05 559.12L1269.18 579.4L1304.32 599.68L1339.44 619.96L1374.57 640.24L1409.7 660.53L1444.83 680.81L1479.96 701.09L1515.09 721.38L1550.21 741.65L1585.35 761.94L1620.48 782.22L1655.61 802.5L1690.74 822.79L1725.87 843.07L1760.99 863.35L1796.13 883.63L1831.26 903.91L1866.39 924.2L1901.52 944.48L1936.65 964.76L1971.77 985.04L2006.91 1005.32L2042.04 1025.61L2077.17 1045.89L2112.3 1066.17L2147.43 1086.46L2182.55 1106.73L2217.69 1127.02L2252.82 1147.3L2287.95 1167.58L2323.08 1187.87L2358.2 1208.14L2393.33 1228.43L2428.46 1248.71L2463.6 1268.99L2602.96 1349.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2637.86 1329.17L2498.5 1248.71L2463.37 1228.43L2428.24 1208.14L2393.1 1187.86L2357.98 1167.58L2322.85 1147.3L2287.72 1127.02L2252.59 1106.73L2217.46 1086.45L2182.34 1066.17L2147.21 1045.89L2112.07 1025.61L2076.94 1005.32L2041.81 985.04L2006.68 964.76L1971.56 944.48L1936.43 924.2L1901.29 903.91L1866.16 883.63L1831.03 863.35L1795.9 843.06L1760.78 822.79L1725.65 802.5L1690.51 782.22L1655.38 761.94L1620.25 741.65L1585.12 721.37L1550 701.09L1514.87 680.81L1479.73 660.53L1444.6 640.24L1409.47 619.96L1374.34 599.68L1339.22 579.4L1304.09 559.12L1268.95 538.83L1233.82 518.55L1198.69 498.27L1163.57 477.99L1128.44 457.71L1093.31 437.42L1058.18 417.14L918.81 336.68L1058.18 417.14L1093.31 437.42L1128.44 457.71L1163.57 477.99L1198.69 498.27L1233.82 518.55L1268.95 538.83L1304.09 559.12L1339.22 579.4L1374.34 599.68L1409.47 619.96L1444.6 640.24L1479.73 660.53L1514.87 680.81L1550 701.09L1585.12 721.37L1620.25 741.65L1655.38 761.94L1690.51 782.22L1725.65 802.5L1760.78 822.79L1795.9 843.06L1831.03 863.35L1866.16 883.63L1901.29 903.91L1936.43 924.2L1971.56 944.48L2006.68 964.76L2041.81 985.04L2076.94 1005.32L2112.07 1025.61L2147.21 1045.89L2182.34 1066.17L2217.46 1086.45L2252.59 1106.73L2287.72 1127.02L2322.85 1147.3L2357.98 1167.58L2393.1 1187.86L2428.24 1208.14L2463.37 1228.43L2498.5 1248.71L2637.86 1329.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2672.77 1308.89L2533.4 1228.43L2498.27 1208.14L2463.14 1187.86L2428.01 1167.58L2392.89 1147.3L2357.76 1127.02L2322.62 1106.73L2287.49 1086.45L2252.36 1066.17L2217.24 1045.89L2182.11 1025.61L2146.98 1005.32L2111.84 985.04L2076.71 964.76L2041.58 944.47L2006.46 924.2L1971.33 903.91L1936.2 883.63L1901.07 863.35L1865.93 843.06L1830.8 822.78L1795.68 802.5L1760.55 782.22L1725.42 761.94L1690.29 741.65L1655.15 721.37L1620.02 701.09L1584.9 680.81L1549.77 660.53L1514.64 640.24L1479.51 619.96L1444.37 599.68L1409.24 579.39L1374.12 559.12L1338.99 538.83L1303.86 518.55L1268.73 498.27L1233.59 477.98L1198.47 457.71L1163.34 437.42L1128.21 417.14L1093.08 396.85L953.72 316.39L1093.08 396.85L1128.21 417.14L1163.34 437.42L1198.47 457.71L1233.59 477.98L1268.73 498.27L1303.86 518.55L1338.99 538.83L1374.12 559.12L1409.24 579.39L1444.37 599.68L1479.51 619.96L1514.64 640.24L1549.77 660.53L1584.9 680.81L1620.02 701.09L1655.15 721.37L1690.29 741.65L1725.42 761.94L1760.55 782.22L1795.68 802.5L1830.8 822.78L1865.93 843.06L1901.07 863.35L1936.2 883.63L1971.33 903.91L2006.46 924.2L2041.58 944.47L2076.71 964.76L2111.84 985.04L2146.98 1005.32L2182.11 1025.61L2217.24 1045.89L2252.36 1066.17L2287.49 1086.45L2322.62 1106.73L2357.76 1127.02L2392.89 1147.3L2428.01 1167.58L2463.14 1187.86L2498.27 1208.14L2533.4 1228.43L2672.77 1308.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2707.66 1288.61L2568.3 1208.15L2533.16 1187.87L2498.03 1167.58L2462.9 1147.3L2427.78 1127.02L2392.65 1106.74L2357.52 1086.46L2322.38 1066.17L2287.25 1045.89L2252.13 1025.61L2217 1005.33L2181.87 985.05L2146.74 964.76L2111.6 944.48L2076.47 924.2L2041.35 903.92L2006.22 883.64L1971.09 863.35L1935.96 843.07L1900.82 822.79L1865.69 802.5L1830.57 782.23L1795.44 761.94L1760.31 741.66L1725.18 721.38L1690.05 701.09L1654.91 680.81L1619.79 660.53L1584.66 640.25L1549.53 619.97L1514.4 599.68L1479.27 579.4L1444.13 559.12L1409.01 538.84L1373.88 518.56L1338.75 498.27L1303.62 477.99L1268.49 457.7L1233.37 437.43L1198.23 417.14L1163.1 396.86L1127.97 376.58L988.61 296.12L1127.97 376.58L1163.1 396.86L1198.23 417.14L1233.37 437.43L1268.49 457.7L1303.62 477.99L1338.75 498.27L1373.88 518.56L1409.01 538.84L1444.13 559.12L1479.27 579.4L1514.4 599.68L1549.53 619.97L1584.66 640.25L1619.79 660.53L1654.91 680.81L1690.05 701.09L1725.18 721.38L1760.31 741.66L1795.44 761.94L1830.57 782.23L1865.69 802.5L1900.82 822.79L1935.96 843.07L1971.09 863.35L2006.22 883.64L2041.35 903.92L2076.47 924.2L2111.6 944.48L2146.74 964.76L2181.87 985.05L2217 1005.33L2252.13 1025.61L2287.25 1045.89L2322.38 1066.17L2357.52 1086.46L2392.65 1106.74L2427.78 1127.02L2462.9 1147.3L2498.03 1167.58L2533.16 1187.87L2568.3 1208.15L2707.66 1288.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2742.56 1268.33L2603.2 1187.87L2568.07 1167.58L2532.94 1147.3L2497.8 1127.02L2462.68 1106.74L2427.55 1086.46L2392.42 1066.17L2357.29 1045.89L2322.16 1025.61L2287.04 1005.33L2251.9 985.05L2216.77 964.76L2181.64 944.48L2146.51 924.2L2111.38 903.91L2076.26 883.64L2041.12 863.35L2005.99 843.07L1970.86 822.79L1935.73 802.5L1900.6 782.22L1865.48 761.94L1830.34 741.66L1795.21 721.38L1760.08 701.09L1724.95 680.81L1689.82 660.52L1654.7 640.25L1619.57 619.97L1584.43 599.68L1549.3 579.4L1514.17 559.11L1479.04 538.83L1443.92 518.55L1408.79 498.27L1373.65 477.99L1338.52 457.7L1303.39 437.42L1268.27 417.14L1233.14 396.86L1198.01 376.58L1162.87 356.29L1023.51 275.83L1162.87 356.29L1198.01 376.58L1233.14 396.86L1268.27 417.14L1303.39 437.42L1338.52 457.7L1373.65 477.99L1408.79 498.27L1443.92 518.55L1479.04 538.83L1514.17 559.11L1549.3 579.4L1584.43 599.68L1619.57 619.97L1654.7 640.25L1689.82 660.52L1724.95 680.81L1760.08 701.09L1795.21 721.38L1830.34 741.66L1865.48 761.94L1900.6 782.22L1935.73 802.5L1970.86 822.79L2005.99 843.07L2041.12 863.35L2076.26 883.64L2111.38 903.91L2146.51 924.2L2181.64 944.48L2216.77 964.76L2251.9 985.05L2287.04 1005.33L2322.16 1025.61L2357.29 1045.89L2392.42 1066.17L2427.55 1086.46L2462.68 1106.74L2497.8 1127.02L2532.94 1147.3L2568.07 1167.58L2603.2 1187.87L2742.56 1268.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2777.47 1248.04L2638.1 1167.58L2602.97 1147.3L2567.84 1127.02L2532.71 1106.73L2497.59 1086.46L2462.46 1066.17L2427.32 1045.89L2392.19 1025.61L2357.06 1005.32L2321.94 985.05L2286.81 964.76L2251.68 944.48L2216.54 924.2L2181.41 903.91L2146.28 883.63L2111.16 863.35L2076.03 843.07L2040.9 822.79L2005.76 802.5L1970.63 782.22L1935.5 761.93L1900.38 741.66L1865.25 721.37L1830.12 701.09L1794.98 680.81L1759.85 660.52L1724.72 640.24L1689.6 619.96L1654.47 599.68L1619.34 579.4L1584.2 559.11L1549.07 538.83L1513.94 518.55L1478.82 498.27L1443.69 477.99L1408.56 457.7L1373.43 437.42L1338.29 417.14L1303.17 396.86L1268.04 376.58L1232.91 356.29L1197.78 336.01L1058.41 255.55L1197.78 336.01L1232.91 356.29L1268.04 376.58L1303.17 396.86L1338.29 417.14L1373.43 437.42L1408.56 457.7L1443.69 477.99L1478.82 498.27L1513.94 518.55L1549.07 538.83L1584.2 559.11L1619.34 579.4L1654.47 599.68L1689.6 619.96L1724.72 640.24L1759.85 660.52L1794.98 680.81L1830.12 701.09L1865.25 721.37L1900.38 741.66L1935.5 761.93L1970.63 782.22L2005.76 802.5L2040.9 822.79L2076.03 843.07L2111.16 863.35L2146.28 883.63L2181.41 903.91L2216.54 924.2L2251.68 944.48L2286.81 964.76L2321.94 985.05L2357.06 1005.32L2392.19 1025.61L2427.32 1045.89L2462.46 1066.17L2497.59 1086.46L2532.71 1106.73L2567.84 1127.02L2602.97 1147.3L2638.1 1167.58L2777.47 1248.04Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_4455">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* groendak */
export const step27Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_7938)">
      <path
        d="M968.53 360.91L730.1 499.22L392.26 304.16L630.69 165.86L968.53 360.91Z"
        fill="#00AB84"
      />
      <path
        d="M968.53 360.91L967.78 626.2L729.34 764.5L730.1 499.22L968.53 360.91Z"
        fill="#D7D3CC"
      />
      <path
        d="M730.1 499.22L729.34 764.5L391.51 569.45L392.26 304.16L730.1 499.22Z"
        fill="#E8E6E0"
      />
      <path
        d="M409.89 507.43L409.05 950.39L731.72 763.28L729.24 354.09L409.89 507.43Z"
        fill="#D7D3CC"
      />
      <path
        d="M113.96 780.02L409.05 950.39L409.89 507.43L111.47 337.05L113.96 780.02Z"
        fill="#E8E6E0"
      />
      <path
        d="M581.88 302.98L729.24 354.09L409.89 507.43L581.88 302.98Z"
        fill="#D7D3CC"
      />
      <path
        d="M497.36 899.36L583.04 848.96L583.42 609.77L497.58 660.77L497.36 899.36Z"
        fill="#E8E6E0"
      />
      <mask
        id="mask0_68_7938"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="625"
        y="554"
        width="60"
        height="163"
      >
        <path
          d="M625.54 716.23L625.18 588.18L684.17 554.12L684.54 682.17L625.54 716.23Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_7938)">
        <path
          d="M684.17 554.12L625.18 588.18L625.54 716.23L684.54 682.17L684.17 554.12Z"
          fill="#E8E6E0"
        />
      </g>
      <path
        d="M343.97 775.03L344.34 646.98L285.34 612.92L284.98 740.97L343.97 775.03Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask1_68_7938"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="284"
        y="612"
        width="61"
        height="164"
      >
        <path
          d="M343.97 775.03L344.34 646.98L285.34 612.92L284.98 740.97L343.97 775.03Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_68_7938)">
        <path
          d="M285.34 612.92L344.34 646.98L343.97 775.03L284.98 740.97L285.34 612.92Z"
          fill="#B3B4B3"
        />
      </g>
      <path
        d="M227.21 706.99L227.58 578.94L168.58 544.88L168.22 672.93L227.21 706.99Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask2_68_7938"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="168"
        y="544"
        width="60"
        height="163"
      >
        <path
          d="M227.21 706.99L227.58 578.94L168.58 544.88L168.22 672.93L227.21 706.99Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_68_7938)">
        <path
          d="M168.58 544.88L227.58 578.94L227.21 706.99L168.22 672.93L168.58 544.88Z"
          fill="#B3B4B3"
        />
      </g>
      <path
        d="M409.05 950.39L409.89 506.5"
        stroke="#B3B4B3"
        stroke-miterlimit="10"
      />
      <path
        d="M873.07 462.5L766.72 523.9L767.38 646.56L873.72 585.16L873.07 462.5Z"
        fill="#E8E6E0"
      />
      <path
        d="M111.47 334.05L409.89 504.43L581.88 299.98L286.8 129.61L111.47 334.05Z"
        fill="#00A3DE"
      />
      <path
        d="M286.8 129.61L581.88 299.98L729.24 351.09L405.72 166.5L286.8 129.61Z"
        fill="#00545E"
      />
      <path
        d="M111.47 334.05V342.1L409.72 512.5L582.8 306.66L729.24 357.38V351.09L581.88 299.98L409.72 504.5L111.47 334.05Z"
        fill="#00545E"
      />
      <path
        d="M765.94 361.34C771.54 362.28 775.34 368.99 773.28 374.28Z"
        fill="#007B5F"
      />
      <path
        d="M779.23 356.43C775.99 359.76 775.88 363.26 776.76 366.8Z"
        fill="#007B5F"
      />
      <path
        d="M785.94 363.55C783.17 366.65 781.57 370.76 781.51 374.91Z"
        fill="#007B5F"
      />
      <path
        d="M744.84 423.59C749.92 426.31 753.66 431.42 754.71 437.09Z"
        fill="#007B5F"
      />
      <path
        d="M755.16 422.49C759.17 425.08 760.12 428.72 758.83 432.8Z"
        fill="#007B5F"
      />
      <path
        d="M766.81 424.97C764.33 426.99 762.81 430.14 762.78 433.33Z"
        fill="#007B5F"
      />
      <path
        d="M595.02 242.25C598.7 242.41 601.71 246.36 600.89 249.95Z"
        fill="#007B5F"
      />
      <path
        d="M604.67 240.8C605.53 243.41 605.58 246.27 604.81 248.91Z"
        fill="#007B5F"
      />
      <path
        d="M616.6 242.27C613.45 243.36 611.21 246.64 611.33 249.96Z"
        fill="#007B5F"
      />
      <path
        d="M532.73 211.71C536 213.59 538.35 217.01 538.91 220.74Z"
        fill="#007B5F"
      />
      <path
        d="M542.54 212.11C540.5 215 540.5 217 542.54 219.68Z"
        fill="#007B5F"
      />
      <path
        d="M855.18 375.45C858.28 378.69 859.58 383.56 858.51 387.92Z"
        fill="#007B5F"
      />
      <path
        d="M865.99 380.86C863.61 382.82 862.17 385.89 862.2 388.98Z"
        fill="#007B5F"
      />
      <path
        d="M826.94 294.34C832.54 295.28 836.34 301.99 834.28 307.28Z"
        fill="#007B5F"
      />
      <path
        d="M840.23 289.43C836.99 292.76 836.88 296.26 837.76 299.8Z"
        fill="#007B5F"
      />
      <path
        d="M846.94 296.55C844.17 299.65 842.57 303.76 842.51 307.91Z"
        fill="#007B5F"
      />
      <path
        d="M696.84 293.59C701.92 296.31 705.66 301.42 706.71 307.09Z"
        fill="#007B5F"
      />
      <path
        d="M707.16 292.49C711.17 295.08 712.12 298.72 710.83 302.8Z"
        fill="#007B5F"
      />
      <path
        d="M718.81 294.97C716.33 296.99 714.81 300.14 714.78 303.33Z"
        fill="#007B5F"
      />
      <path
        d="M751.02 248.25C754.7 248.41 757.71 252.36 756.89 255.95Z"
        fill="#007B5F"
      />
      <path
        d="M760.67 246.8C761.53 249.41 761.58 252.27 760.81 254.91Z"
        fill="#007B5F"
      />
      <path
        d="M772.6 248.27C769.45 249.36 767.21 252.64 767.33 255.96Z"
        fill="#007B5F"
      />
      <path
        d="M688.73 217.71C692 219.59 694.35 223.01 694.91 226.74Z"
        fill="#007B5F"
      />
      <path
        d="M698.54 218.11C696.5 221 696.5 223 698.54 225.68Z"
        fill="#007B5F"
      />
      <path
        opacity="0.3"
        d="M2244.96 133.46L2105.6 52.99L2070.46 32.71L2035.33 12.43L2000.2 -7.85999L1965.08 -28.13L1929.95 -48.42L1894.82 -68.7L1859.69 -88.98L1824.55 -109.27L1789.43 -129.54L1754.3 -149.83L1719.17 -170.11L1684.04 -190.39L1648.91 -210.68L1613.77 -230.96L1578.65 -251.24L1543.52 -271.52L1508.39 -291.8L1473.26 -312.09L1438.13 -332.37L1402.99 -352.65L1367.87 -372.93L1332.74 -393.21L1297.61 -413.5L1262.48 -433.78L1227.35 -454.06L1192.21 -474.35L1157.09 -494.62L1121.96 -514.91L1086.83 -535.19L1051.7 -555.47L1016.57 -575.76L981.44 -596.04L946.32 -616.32L911.18 -636.6L876.05 -656.88L840.92 -677.17L805.79 -697.45L770.67 -717.73L735.54 -738.01L700.4 -758.29L665.27 -778.58L525.91 -859.04L665.27 -778.58L700.4 -758.29L735.54 -738.01L770.67 -717.73L805.79 -697.45L840.92 -677.17L876.05 -656.88L911.18 -636.6L946.32 -616.32L981.44 -596.04L1016.57 -575.76L1051.7 -555.47L1086.83 -535.19L1121.96 -514.91L1157.09 -494.62L1192.21 -474.35L1227.35 -454.06L1262.48 -433.78L1297.61 -413.5L1332.74 -393.21L1367.87 -372.93L1402.99 -352.65L1438.13 -332.37L1473.26 -312.09L1508.39 -291.8L1543.52 -271.52L1578.65 -251.24L1613.77 -230.96L1648.91 -210.68L1684.04 -190.39L1719.17 -170.11L1754.3 -149.83L1789.43 -129.54L1824.55 -109.27L1859.69 -88.98L1894.82 -68.7L1929.95 -48.42L1965.08 -28.13L2000.2 -7.85999L2035.33 12.43L2070.46 32.71L2105.6 52.99L2244.96 133.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2279.86 113.17L2140.5 32.71L2105.37 12.43L2070.24 -7.85999L2035.1 -28.14L1999.98 -48.42L1964.85 -68.7L1929.72 -88.98L1894.59 -109.27L1859.46 -129.55L1824.34 -149.83L1789.2 -170.11L1754.07 -190.39L1718.94 -210.68L1683.81 -230.96L1648.68 -251.24L1613.56 -271.52L1578.43 -291.8L1543.29 -312.09L1508.16 -332.37L1473.03 -352.65L1437.9 -372.94L1402.78 -393.21L1367.65 -413.5L1332.51 -433.78L1297.38 -454.06L1262.25 -474.35L1227.12 -494.63L1192 -514.91L1156.87 -535.19L1121.73 -555.47L1086.6 -575.76L1051.47 -596.04L1016.34 -616.32L981.22 -636.6L946.09 -656.88L910.95 -677.17L875.82 -697.45L840.69 -717.73L805.57 -738.01L770.44 -758.29L735.31 -778.58L700.18 -798.86L560.81 -879.32L700.18 -798.86L735.31 -778.58L770.44 -758.29L805.57 -738.01L840.69 -717.73L875.82 -697.45L910.95 -677.17L946.09 -656.88L981.22 -636.6L1016.34 -616.32L1051.47 -596.04L1086.6 -575.76L1121.73 -555.47L1156.87 -535.19L1192 -514.91L1227.12 -494.63L1262.25 -474.35L1297.38 -454.06L1332.51 -433.78L1367.65 -413.5L1402.78 -393.21L1437.9 -372.94L1473.03 -352.65L1508.16 -332.37L1543.29 -312.09L1578.43 -291.8L1613.56 -271.52L1648.68 -251.24L1683.81 -230.96L1718.94 -210.68L1754.07 -190.39L1789.2 -170.11L1824.34 -149.83L1859.46 -129.55L1894.59 -109.27L1929.72 -88.98L1964.85 -68.7L1999.98 -48.42L2035.1 -28.14L2070.24 -7.85999L2105.37 12.43L2140.5 32.71L2279.86 113.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2314.77 92.89L2175.4 12.43L2140.27 -7.85999L2105.14 -28.14L2070.01 -48.42L2034.89 -68.7L1999.76 -88.98L1964.62 -109.27L1929.49 -129.55L1894.36 -149.83L1859.24 -170.11L1824.11 -190.39L1788.98 -210.68L1753.84 -230.96L1718.71 -251.24L1683.58 -271.53L1648.46 -291.8L1613.33 -312.09L1578.2 -332.37L1543.07 -352.65L1507.93 -372.94L1472.8 -393.22L1437.68 -413.5L1402.55 -433.78L1367.42 -454.06L1332.29 -474.35L1297.15 -494.63L1262.02 -514.91L1226.9 -535.19L1191.77 -555.47L1156.64 -575.76L1121.51 -596.04L1086.37 -616.32L1051.24 -636.61L1016.12 -656.88L980.99 -677.17L945.86 -697.45L910.73 -717.73L875.59 -738.02L840.47 -758.29L805.34 -778.58L770.21 -798.86L735.08 -819.15L595.72 -899.61L735.08 -819.15L770.21 -798.86L805.34 -778.58L840.47 -758.29L875.59 -738.02L910.73 -717.73L945.86 -697.45L980.99 -677.17L1016.12 -656.88L1051.24 -636.61L1086.37 -616.32L1121.51 -596.04L1156.64 -575.76L1191.77 -555.47L1226.9 -535.19L1262.02 -514.91L1297.15 -494.63L1332.29 -474.35L1367.42 -454.06L1402.55 -433.78L1437.68 -413.5L1472.8 -393.22L1507.93 -372.94L1543.07 -352.65L1578.2 -332.37L1613.33 -312.09L1648.46 -291.8L1683.58 -271.53L1718.71 -251.24L1753.84 -230.96L1788.98 -210.68L1824.11 -190.39L1859.24 -170.11L1894.36 -149.83L1929.49 -129.55L1964.62 -109.27L1999.76 -88.98L2034.89 -68.7L2070.01 -48.42L2105.14 -28.14L2140.27 -7.85999L2175.4 12.43L2314.77 92.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2349.66 72.61L2210.3 -7.84998L2175.16 -28.13L2140.03 -48.42L2104.9 -68.7L2069.78 -88.98L2034.65 -109.26L1999.52 -129.54L1964.38 -149.83L1929.25 -170.11L1894.13 -190.39L1859 -210.67L1823.87 -230.95L1788.74 -251.24L1753.6 -271.52L1718.47 -291.8L1683.35 -312.08L1648.22 -332.36L1613.09 -352.65L1577.96 -372.93L1542.82 -393.21L1507.69 -413.5L1472.57 -433.77L1437.44 -454.06L1402.31 -474.34L1367.18 -494.62L1332.05 -514.91L1296.91 -535.19L1261.79 -555.47L1226.66 -575.75L1191.53 -596.03L1156.4 -616.32L1121.27 -636.6L1086.13 -656.88L1051.01 -677.16L1015.88 -697.44L980.75 -717.73L945.62 -738.01L910.49 -758.3L875.37 -778.57L840.23 -798.86L805.1 -819.14L769.97 -839.42L630.61 -919.88L769.97 -839.42L805.1 -819.14L840.23 -798.86L875.37 -778.57L910.49 -758.3L945.62 -738.01L980.75 -717.73L1015.88 -697.44L1051.01 -677.16L1086.13 -656.88L1121.27 -636.6L1156.4 -616.32L1191.53 -596.03L1226.66 -575.75L1261.79 -555.47L1296.91 -535.19L1332.05 -514.91L1367.18 -494.62L1402.31 -474.34L1437.44 -454.06L1472.57 -433.77L1507.69 -413.5L1542.82 -393.21L1577.96 -372.93L1613.09 -352.65L1648.22 -332.36L1683.35 -312.08L1718.47 -291.8L1753.6 -271.52L1788.74 -251.24L1823.87 -230.95L1859 -210.67L1894.13 -190.39L1929.25 -170.11L1964.38 -149.83L1999.52 -129.54L2034.65 -109.26L2069.78 -88.98L2104.9 -68.7L2140.03 -48.42L2175.16 -28.13L2210.3 -7.84998L2349.66 72.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2384.56 52.33L2245.2 -28.13L2210.07 -48.42L2174.94 -68.7L2139.8 -88.98L2104.68 -109.26L2069.55 -129.54L2034.42 -149.83L1999.29 -170.11L1964.16 -190.39L1929.04 -210.67L1893.9 -230.95L1858.77 -251.24L1823.64 -271.52L1788.51 -291.8L1753.38 -312.09L1718.26 -332.36L1683.12 -352.65L1647.99 -372.93L1612.86 -393.21L1577.73 -413.5L1542.6 -433.78L1507.48 -454.06L1472.34 -474.34L1437.21 -494.62L1402.08 -514.91L1366.95 -535.19L1331.82 -555.48L1296.7 -575.75L1261.57 -596.03L1226.43 -616.32L1191.3 -636.6L1156.17 -656.89L1121.04 -677.17L1085.92 -697.45L1050.79 -717.73L1015.65 -738.01L980.52 -758.3L945.39 -778.58L910.27 -798.86L875.14 -819.14L840.01 -839.42L804.87 -859.71L665.51 -940.17L804.87 -859.71L840.01 -839.42L875.14 -819.14L910.27 -798.86L945.39 -778.58L980.52 -758.3L1015.65 -738.01L1050.79 -717.73L1085.92 -697.45L1121.04 -677.17L1156.17 -656.89L1191.3 -636.6L1226.43 -616.32L1261.57 -596.03L1296.7 -575.75L1331.82 -555.48L1366.95 -535.19L1402.08 -514.91L1437.21 -494.62L1472.34 -474.34L1507.48 -454.06L1542.6 -433.78L1577.73 -413.5L1612.86 -393.21L1647.99 -372.93L1683.12 -352.65L1718.26 -332.36L1753.38 -312.09L1788.51 -291.8L1823.64 -271.52L1858.77 -251.24L1893.9 -230.95L1929.04 -210.67L1964.16 -190.39L1999.29 -170.11L2034.42 -149.83L2069.55 -129.54L2104.68 -109.26L2139.8 -88.98L2174.94 -68.7L2210.07 -48.42L2245.2 -28.13L2384.56 52.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2419.47 32.04L2280.1 -48.42L2244.97 -68.7L2209.84 -88.98L2174.71 -109.27L2139.59 -129.54L2104.46 -149.83L2069.32 -170.11L2034.19 -190.39L1999.06 -210.68L1963.94 -230.95L1928.81 -251.24L1893.68 -271.52L1858.54 -291.8L1823.41 -312.09L1788.28 -332.37L1753.16 -352.65L1718.03 -372.93L1682.9 -393.21L1647.76 -413.5L1612.63 -433.78L1577.5 -454.07L1542.38 -474.34L1507.25 -494.63L1472.12 -514.91L1436.98 -535.19L1401.85 -555.48L1366.72 -575.76L1331.6 -596.04L1296.47 -616.32L1261.34 -636.6L1226.2 -656.89L1191.07 -677.17L1155.94 -697.45L1120.82 -717.73L1085.69 -738.01L1050.56 -758.3L1015.43 -778.58L980.29 -798.86L945.17 -819.14L910.04 -839.42L874.91 -859.71L839.78 -879.99L700.41 -960.45L839.78 -879.99L874.91 -859.71L910.04 -839.42L945.17 -819.14L980.29 -798.86L1015.43 -778.58L1050.56 -758.3L1085.69 -738.01L1120.82 -717.73L1155.94 -697.45L1191.07 -677.17L1226.2 -656.89L1261.34 -636.6L1296.47 -616.32L1331.6 -596.04L1366.72 -575.76L1401.85 -555.48L1436.98 -535.19L1472.12 -514.91L1507.25 -494.63L1542.38 -474.34L1577.5 -454.07L1612.63 -433.78L1647.76 -413.5L1682.9 -393.21L1718.03 -372.93L1753.16 -352.65L1788.28 -332.37L1823.41 -312.09L1858.54 -291.8L1893.68 -271.52L1928.81 -251.24L1963.94 -230.95L1999.06 -210.68L2034.19 -190.39L2069.32 -170.11L2104.46 -149.83L2139.59 -129.54L2174.71 -109.27L2209.84 -88.98L2244.97 -68.7L2280.1 -48.42L2419.47 32.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2454.37 11.76L2315.01 -68.7L2279.87 -88.98L2244.74 -109.27L2209.61 -129.55L2174.49 -149.83L2139.36 -170.11L2104.23 -190.39L2069.09 -210.68L2033.96 -230.96L1998.84 -251.24L1963.71 -271.52L1928.58 -291.8L1893.45 -312.09L1858.32 -332.37L1823.18 -352.66L1788.06 -372.93L1752.93 -393.22L1717.8 -413.5L1682.67 -433.78L1647.54 -454.07L1612.4 -474.35L1577.28 -494.63L1542.15 -514.91L1507.02 -535.19L1471.89 -555.48L1436.76 -575.76L1401.62 -596.04L1366.5 -616.32L1331.37 -636.6L1296.24 -656.89L1261.11 -677.17L1225.98 -697.45L1190.84 -717.74L1155.72 -738.01L1120.59 -758.3L1085.46 -778.58L1050.33 -798.86L1015.2 -819.15L980.08 -839.42L944.95 -859.71L909.81 -879.99L874.68 -900.27L735.32 -980.73L874.68 -900.27L909.81 -879.99L944.95 -859.71L980.08 -839.42L1015.2 -819.15L1050.33 -798.86L1085.46 -778.58L1120.59 -758.3L1155.72 -738.01L1190.84 -717.74L1225.98 -697.45L1261.11 -677.17L1296.24 -656.89L1331.37 -636.6L1366.5 -616.32L1401.62 -596.04L1436.76 -575.76L1471.89 -555.48L1507.02 -535.19L1542.15 -514.91L1577.28 -494.63L1612.4 -474.35L1647.54 -454.07L1682.67 -433.78L1717.8 -413.5L1752.93 -393.22L1788.06 -372.93L1823.18 -352.66L1858.32 -332.37L1893.45 -312.09L1928.58 -291.8L1963.71 -271.52L1998.84 -251.24L2033.96 -230.96L2069.09 -210.68L2104.23 -190.39L2139.36 -170.11L2174.49 -149.83L2209.61 -129.55L2244.74 -109.27L2279.87 -88.98L2315.01 -68.7L2454.37 11.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M854.96 1528.46L715.6 1447.99L680.46 1427.71L645.33 1407.43L610.2 1387.14L575.08 1366.87L539.95 1346.58L504.82 1326.3L469.69 1306.02L434.55 1285.73L399.43 1265.46L364.3 1245.17L329.17 1224.89L294.04 1204.61L258.91 1184.32L223.77 1164.04L188.65 1143.76L153.52 1123.48L118.39 1103.2L83.2599 1082.91L48.1299 1062.63L12.9899 1042.35L-22.1301 1022.07L-57.2601 1001.79L-92.3901 981.5L-127.52 961.22L-162.65 940.94L-197.79 920.65L-232.91 900.38L-268.04 880.09L-303.17 859.81L-338.3 839.53L-373.43 819.24L-408.56 798.96L-443.68 778.68L-478.82 758.4L-513.95 738.12L-549.08 717.83L-584.21 697.55L-619.33 677.27L-654.46 656.99L-689.6 636.71L-724.73 616.42L-864.09 535.96L-724.73 616.42L-689.6 636.71L-654.46 656.99L-619.33 677.27L-584.21 697.55L-549.08 717.83L-513.95 738.12L-478.82 758.4L-443.68 778.68L-408.56 798.96L-373.43 819.24L-338.3 839.53L-303.17 859.81L-268.04 880.09L-232.91 900.38L-197.79 920.65L-162.65 940.94L-127.52 961.22L-92.3901 981.5L-57.2601 1001.79L-22.1301 1022.07L12.9899 1042.35L48.1299 1062.63L83.2599 1082.91L118.39 1103.2L153.52 1123.48L188.65 1143.76L223.77 1164.04L258.91 1184.32L294.04 1204.61L329.17 1224.89L364.3 1245.17L399.43 1265.46L434.55 1285.73L469.69 1306.02L504.82 1326.3L539.95 1346.58L575.08 1366.87L610.2 1387.14L645.33 1407.43L680.46 1427.71L715.6 1447.99L854.96 1528.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M889.86 1508.17L750.5 1427.71L715.37 1407.43L680.24 1387.14L645.1 1366.86L609.98 1346.58L574.85 1326.3L539.72 1306.02L504.59 1285.73L469.46 1265.45L434.34 1245.17L399.2 1224.89L364.07 1204.61L328.94 1184.32L293.81 1164.04L258.68 1143.76L223.56 1123.48L188.43 1103.2L153.29 1082.91L118.16 1062.63L83.03 1042.35L47.9001 1022.06L12.7801 1001.79L-22.3499 981.5L-57.4899 961.22L-92.6199 940.94L-127.75 920.65L-162.88 900.37L-198 880.09L-233.13 859.81L-268.27 839.53L-303.4 819.24L-338.53 798.96L-373.66 778.68L-408.78 758.4L-443.91 738.12L-479.05 717.83L-514.18 697.55L-549.31 677.27L-584.43 656.99L-619.56 636.71L-654.69 616.42L-689.82 596.14L-829.19 515.68L-689.82 596.14L-654.69 616.42L-619.56 636.71L-584.43 656.99L-549.31 677.27L-514.18 697.55L-479.05 717.83L-443.91 738.12L-408.78 758.4L-373.66 778.68L-338.53 798.96L-303.4 819.24L-268.27 839.53L-233.13 859.81L-198 880.09L-162.88 900.37L-127.75 920.65L-92.6199 940.94L-57.4899 961.22L-22.3499 981.5L12.7801 1001.79L47.9001 1022.06L83.03 1042.35L118.16 1062.63L153.29 1082.91L188.43 1103.2L223.56 1123.48L258.68 1143.76L293.81 1164.04L328.94 1184.32L364.07 1204.61L399.2 1224.89L434.34 1245.17L469.46 1265.45L504.59 1285.73L539.72 1306.02L574.85 1326.3L609.98 1346.58L645.1 1366.86L680.24 1387.14L715.37 1407.43L750.5 1427.71L889.86 1508.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M924.77 1487.89L785.4 1407.43L750.27 1387.14L715.14 1366.86L680.01 1346.58L644.89 1326.3L609.76 1306.02L574.62 1285.73L539.49 1265.45L504.36 1245.17L469.24 1224.89L434.11 1204.61L398.98 1184.32L363.84 1164.04L328.71 1143.76L293.58 1123.47L258.46 1103.2L223.33 1082.91L188.2 1062.63L153.07 1042.35L117.93 1022.06L82.8 1001.78L47.68 981.5L12.55 961.22L-22.58 940.94L-57.71 920.65L-92.85 900.37L-127.98 880.09L-163.1 859.81L-198.23 839.53L-233.36 819.24L-268.49 798.96L-303.63 778.68L-338.76 758.39L-373.88 738.12L-409.01 717.83L-444.14 697.55L-479.27 677.27L-514.41 656.98L-549.53 636.71L-584.66 616.42L-619.79 596.14L-654.92 575.85L-794.28 495.39L-654.92 575.85L-619.79 596.14L-584.66 616.42L-549.53 636.71L-514.41 656.98L-479.27 677.27L-444.14 697.55L-409.01 717.83L-373.88 738.12L-338.76 758.39L-303.63 778.68L-268.49 798.96L-233.36 819.24L-198.23 839.53L-163.1 859.81L-127.98 880.09L-92.85 900.37L-57.71 920.65L-22.58 940.94L12.55 961.22L47.68 981.5L82.8 1001.78L117.93 1022.06L153.07 1042.35L188.2 1062.63L223.33 1082.91L258.46 1103.2L293.58 1123.47L328.71 1143.76L363.84 1164.04L398.98 1184.32L434.11 1204.61L469.24 1224.89L504.36 1245.17L539.49 1265.45L574.62 1285.73L609.76 1306.02L644.89 1326.3L680.01 1346.58L715.14 1366.86L750.27 1387.14L785.4 1407.43L924.77 1487.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M959.66 1467.61L820.3 1387.15L785.16 1366.87L750.03 1346.58L714.9 1326.3L679.78 1306.02L644.65 1285.74L609.52 1265.46L574.38 1245.17L539.25 1224.89L504.13 1204.61L469 1184.33L433.87 1164.05L398.74 1143.76L363.6 1123.48L328.47 1103.2L293.35 1082.92L258.22 1062.64L223.09 1042.35L187.96 1022.07L152.82 1001.79L117.69 981.5L82.57 961.23L47.44 940.94L12.31 920.66L-22.82 900.38L-57.95 880.09L-93.09 859.81L-128.21 839.53L-163.34 819.25L-198.47 798.97L-233.6 778.68L-268.73 758.4L-303.87 738.12L-338.99 717.84L-374.12 697.56L-409.25 677.27L-444.38 656.99L-479.51 636.7L-514.63 616.43L-549.77 596.14L-584.9 575.86L-620.03 555.58L-759.39 475.12L-620.03 555.58L-584.9 575.86L-549.77 596.14L-514.63 616.43L-479.51 636.7L-444.38 656.99L-409.25 677.27L-374.12 697.56L-338.99 717.84L-303.87 738.12L-268.73 758.4L-233.6 778.68L-198.47 798.97L-163.34 819.25L-128.21 839.53L-93.09 859.81L-57.95 880.09L-22.82 900.38L12.31 920.66L47.44 940.94L82.57 961.23L117.69 981.5L152.82 1001.79L187.96 1022.07L223.09 1042.35L258.22 1062.64L293.35 1082.92L328.47 1103.2L363.6 1123.48L398.74 1143.76L433.87 1164.05L469 1184.33L504.13 1204.61L539.25 1224.89L574.38 1245.17L609.52 1265.46L644.65 1285.74L679.78 1306.02L714.9 1326.3L750.03 1346.58L785.16 1366.87L820.3 1387.15L959.66 1467.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M994.56 1447.33L855.2 1366.87L820.07 1346.58L784.94 1326.3L749.8 1306.02L714.68 1285.74L679.55 1265.46L644.42 1245.17L609.29 1224.89L574.16 1204.61L539.04 1184.33L503.9 1164.05L468.77 1143.76L433.64 1123.48L398.51 1103.2L363.38 1082.91L328.26 1062.64L293.12 1042.35L257.99 1022.07L222.86 1001.79L187.73 981.5L152.6 961.22L117.48 940.94L82.34 920.66L47.21 900.38L12.08 880.09L-23.05 859.81L-58.18 839.52L-93.3 819.25L-128.43 798.97L-163.57 778.68L-198.7 758.4L-233.83 738.11L-268.96 717.83L-304.08 697.55L-339.21 677.27L-374.35 656.99L-409.48 636.7L-444.61 616.42L-479.73 596.14L-514.86 575.86L-549.99 555.58L-585.13 535.29L-724.49 454.83L-585.13 535.29L-549.99 555.58L-514.86 575.86L-479.73 596.14L-444.61 616.42L-409.48 636.7L-374.35 656.99L-339.21 677.27L-304.08 697.55L-268.96 717.83L-233.83 738.11L-198.7 758.4L-163.57 778.68L-128.43 798.97L-93.3 819.25L-58.18 839.52L-23.05 859.81L12.08 880.09L47.21 900.38L82.34 920.66L117.48 940.94L152.6 961.22L187.73 981.5L222.86 1001.79L257.99 1022.07L293.12 1042.35L328.26 1062.64L363.38 1082.91L398.51 1103.2L433.64 1123.48L468.77 1143.76L503.9 1164.05L539.04 1184.33L574.16 1204.61L609.29 1224.89L644.42 1245.17L679.55 1265.46L714.68 1285.74L749.8 1306.02L784.94 1326.3L820.07 1346.58L855.2 1366.87L994.56 1447.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1029.47 1427.04L890.1 1346.58L854.97 1326.3L819.84 1306.02L784.71 1285.73L749.59 1265.46L714.46 1245.17L679.32 1224.89L644.19 1204.61L609.06 1184.32L573.94 1164.05L538.81 1143.76L503.68 1123.48L468.54 1103.2L433.41 1082.91L398.28 1062.63L363.16 1042.35L328.03 1022.07L292.9 1001.79L257.76 981.5L222.63 961.22L187.5 940.93L152.38 920.66L117.25 900.37L82.1199 880.09L46.9799 859.81L11.8499 839.52L-23.2801 819.24L-58.4001 798.96L-93.53 778.68L-128.66 758.4L-163.79 738.11L-198.93 717.83L-234.06 697.55L-269.18 677.27L-304.31 656.99L-339.44 636.7L-374.57 616.42L-409.71 596.14L-444.83 575.86L-479.96 555.58L-515.09 535.29L-550.22 515.01L-689.59 434.55L-550.22 515.01L-515.09 535.29L-479.96 555.58L-444.83 575.86L-409.71 596.14L-374.57 616.42L-339.44 636.7L-304.31 656.99L-269.18 677.27L-234.06 697.55L-198.93 717.83L-163.79 738.11L-128.66 758.4L-93.53 778.68L-58.4001 798.96L-23.2801 819.24L11.8499 839.52L46.9799 859.81L82.1199 880.09L117.25 900.37L152.38 920.66L187.5 940.93L222.63 961.22L257.76 981.5L292.9 1001.79L328.03 1022.07L363.16 1042.35L398.28 1062.63L433.41 1082.91L468.54 1103.2L503.68 1123.48L538.81 1143.76L573.94 1164.05L609.06 1184.32L644.19 1204.61L679.32 1224.89L714.46 1245.17L749.59 1265.46L784.71 1285.73L819.84 1306.02L854.97 1326.3L890.1 1346.58L1029.47 1427.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1064.37 1406.76L925.01 1326.3L889.87 1306.02L854.74 1285.73L819.61 1265.45L784.49 1245.17L749.36 1224.89L714.23 1204.61L679.09 1184.32L643.96 1164.04L608.84 1143.76L573.71 1123.48L538.58 1103.2L503.45 1082.91L468.32 1062.63L433.18 1042.34L398.06 1022.07L362.93 1001.78L327.8 981.5L292.67 961.22L257.54 940.93L222.4 920.65L187.28 900.37L152.15 880.09L117.02 859.81L81.8901 839.52L46.7601 819.24L11.6201 798.96L-23.4999 778.68L-58.6299 758.4L-93.7599 738.11L-128.89 717.83L-164.02 697.55L-199.16 677.26L-234.28 656.99L-269.41 636.7L-304.54 616.42L-339.67 596.14L-374.8 575.85L-409.92 555.58L-445.05 535.29L-480.19 515.01L-515.32 494.73L-654.68 414.27L-515.32 494.73L-480.19 515.01L-445.05 535.29L-409.92 555.58L-374.8 575.85L-339.67 596.14L-304.54 616.42L-269.41 636.7L-234.28 656.99L-199.16 677.26L-164.02 697.55L-128.89 717.83L-93.7599 738.11L-58.6299 758.4L-23.4999 778.68L11.6201 798.96L46.7601 819.24L81.8901 839.52L117.02 859.81L152.15 880.09L187.28 900.37L222.4 920.65L257.54 940.93L292.67 961.22L327.8 981.5L362.93 1001.78L398.06 1022.07L433.18 1042.34L468.32 1062.63L503.45 1082.91L538.58 1103.2L573.71 1123.48L608.84 1143.76L643.96 1164.04L679.09 1184.32L714.23 1204.61L749.36 1224.89L784.49 1245.17L819.61 1265.45L854.74 1285.73L889.87 1306.02L925.01 1326.3L1064.37 1406.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1099.27 1386.48L959.91 1306.02L924.78 1285.73L889.65 1265.45L854.51 1245.16L819.39 1224.89L784.26 1204.6L749.13 1184.32L714 1164.04L678.87 1143.75L643.75 1123.48L608.61 1103.19L573.48 1082.91L538.35 1062.63L503.22 1042.34L468.09 1022.06L432.97 1001.78L397.84 981.5L362.7 961.22L327.57 940.93L292.44 920.65L257.31 900.37L222.19 880.09L187.06 859.81L151.92 839.52L116.79 819.24L81.66 798.96L46.53 778.67L11.41 758.4L-23.72 738.11L-58.86 717.83L-93.99 697.55L-129.12 677.26L-164.25 656.98L-199.37 636.7L-234.5 616.42L-269.64 596.14L-304.77 575.85L-339.9 555.57L-375.02 535.29L-410.15 515.01L-445.28 494.73L-480.42 474.44L-619.78 393.98L-480.42 474.44L-445.28 494.73L-410.15 515.01L-375.02 535.29L-339.9 555.57L-304.77 575.85L-269.64 596.14L-234.5 616.42L-199.37 636.7L-164.25 656.98L-129.12 677.26L-93.99 697.55L-58.86 717.83L-23.72 738.11L11.41 758.4L46.53 778.67L81.66 798.96L116.79 819.24L151.92 839.52L187.06 859.81L222.19 880.09L257.31 900.37L292.44 920.65L327.57 940.93L362.7 961.22L397.84 981.5L432.97 1001.78L468.09 1022.06L503.22 1042.34L538.35 1062.63L573.48 1082.91L608.61 1103.19L643.75 1123.48L678.87 1143.75L714 1164.04L749.13 1184.32L784.26 1204.6L819.39 1224.89L854.51 1245.16L889.65 1265.45L924.78 1285.73L959.91 1306.02L1099.27 1386.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1134.18 1366.19L994.81 1285.73L959.68 1265.45L924.55 1245.16L889.42 1224.88L854.3 1204.6L819.17 1184.32L784.03 1164.04L748.9 1143.75L713.77 1123.47L678.65 1103.19L643.52 1082.91L608.39 1062.63L573.25 1042.34L538.12 1022.06L502.99 1001.78L467.87 981.5L432.74 961.22L397.61 940.93L362.47 920.65L327.34 900.37L292.21 880.08L257.09 859.81L221.96 839.52L186.83 819.24L151.7 798.96L116.56 778.67L81.4299 758.39L46.3099 738.11L11.1799 717.83L-23.9501 697.55L-59.0801 677.26L-94.2201 656.98L-129.35 636.7L-164.47 616.42L-199.6 596.14L-234.73 575.85L-269.86 555.57L-305 535.29L-340.12 515.01L-375.25 494.73L-410.38 474.44L-445.51 454.16L-584.87 373.7L-445.51 454.16L-410.38 474.44L-375.25 494.73L-340.12 515.01L-305 535.29L-269.86 555.57L-234.73 575.85L-199.6 596.14L-164.47 616.42L-129.35 636.7L-94.2201 656.98L-59.0801 677.26L-23.9501 697.55L11.1799 717.83L46.3099 738.11L81.4299 758.39L116.56 778.67L151.7 798.96L186.83 819.24L221.96 839.52L257.09 859.81L292.21 880.08L327.34 900.37L362.47 920.65L397.61 940.93L432.74 961.22L467.87 981.5L502.99 1001.78L538.12 1022.06L573.25 1042.34L608.39 1062.63L643.52 1082.91L678.65 1103.19L713.77 1123.47L748.9 1143.75L784.03 1164.04L819.17 1184.32L854.3 1204.6L889.42 1224.88L924.55 1245.16L959.68 1265.45L994.81 1285.73L1134.18 1366.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1169.07 1345.92L1029.71 1265.45L994.57 1245.17L959.44 1224.89L924.31 1204.6L889.19 1184.33L854.06 1164.04L818.93 1143.76L783.79 1123.48L748.66 1103.19L713.54 1082.92L678.41 1062.63L643.28 1042.35L608.15 1022.07L573.01 1001.78L537.88 981.5L502.76 961.22L467.63 940.94L432.5 920.66L397.37 900.37L362.23 880.09L327.1 859.81L291.98 839.53L256.85 819.25L221.72 798.96L186.59 778.68L151.46 758.4L116.32 738.11L81.2 717.84L46.07 697.55L10.94 677.27L-24.19 656.99L-59.32 636.7L-94.46 616.42L-129.58 596.14L-164.71 575.86L-199.84 555.58L-234.97 535.29L-270.1 515.01L-305.22 494.73L-340.36 474.45L-375.49 454.17L-410.62 433.88L-549.98 353.42L-410.62 433.88L-375.49 454.17L-340.36 474.45L-305.22 494.73L-270.1 515.01L-234.97 535.29L-199.84 555.58L-164.71 575.86L-129.58 596.14L-94.46 616.42L-59.32 636.7L-24.19 656.99L10.94 677.27L46.07 697.55L81.2 717.84L116.32 738.11L151.46 758.4L186.59 778.68L221.72 798.96L256.85 819.25L291.98 839.53L327.1 859.81L362.23 880.09L397.37 900.37L432.5 920.66L467.63 940.94L502.76 961.22L537.88 981.5L573.01 1001.78L608.15 1022.07L643.28 1042.35L678.41 1062.63L713.54 1082.92L748.66 1103.19L783.79 1123.48L818.93 1143.76L854.06 1164.04L889.19 1184.33L924.31 1204.6L959.44 1224.89L994.57 1245.17L1029.71 1265.45L1169.07 1345.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1203.97 1325.63L1064.61 1245.17L1029.48 1224.89L994.34 1204.6L959.21 1184.32L924.09 1164.04L888.96 1143.76L853.83 1123.48L818.7 1103.19L783.57 1082.91L748.45 1062.63L713.31 1042.35L678.18 1022.07L643.05 1001.78L607.92 981.5L572.79 961.22L537.67 940.94L502.53 920.66L467.4 900.37L432.27 880.09L397.14 859.81L362.01 839.52L326.89 819.25L291.75 798.96L256.62 778.68L221.49 758.4L186.36 738.11L151.23 717.83L116.11 697.55L80.97 677.27L45.84 656.99L10.71 636.7L-24.4201 616.42L-59.55 596.14L-94.67 575.86L-129.8 555.58L-164.94 535.29L-200.07 515.01L-235.2 494.73L-270.32 474.45L-305.45 454.17L-340.58 433.88L-375.72 413.6L-515.08 333.14L-375.72 413.6L-340.58 433.88L-305.45 454.17L-270.32 474.45L-235.2 494.73L-200.07 515.01L-164.94 535.29L-129.8 555.58L-94.67 575.86L-59.55 596.14L-24.4201 616.42L10.71 636.7L45.84 656.99L80.97 677.27L116.11 697.55L151.23 717.83L186.36 738.11L221.49 758.4L256.62 778.68L291.75 798.96L326.89 819.25L362.01 839.52L397.14 859.81L432.27 880.09L467.4 900.37L502.53 920.66L537.67 940.94L572.79 961.22L607.92 981.5L643.05 1001.78L678.18 1022.07L713.31 1042.35L748.45 1062.63L783.57 1082.91L818.7 1103.19L853.83 1123.48L888.96 1143.76L924.09 1164.04L959.21 1184.32L994.34 1204.6L1029.48 1224.89L1064.61 1245.17L1203.97 1325.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1238.88 1305.35L1099.51 1224.89L1064.38 1204.6L1029.25 1184.32L994.12 1164.04L959 1143.76L923.86 1123.48L888.73 1103.19L853.6 1082.91L818.47 1062.63L783.35 1042.35L748.22 1022.07L713.09 1001.78L677.95 981.5L642.82 961.22L607.69 940.93L572.57 920.66L537.44 900.37L502.31 880.09L467.17 859.81L432.04 839.52L396.91 819.24L361.79 798.96L326.66 778.68L291.53 758.4L256.39 738.11L221.26 717.83L186.13 697.55L151.01 677.27L115.88 656.99L80.7501 636.7L45.61 616.42L10.4801 596.14L-24.6499 575.85L-59.7699 555.58L-94.8999 535.29L-130.03 515.01L-165.16 494.73L-200.3 474.44L-235.42 454.17L-270.55 433.88L-305.68 413.6L-340.81 393.32L-480.18 312.85L-340.81 393.32L-305.68 413.6L-270.55 433.88L-235.42 454.17L-200.3 474.44L-165.16 494.73L-130.03 515.01L-94.8999 535.29L-59.7699 555.58L-24.6499 575.85L10.4801 596.14L45.61 616.42L80.7501 636.7L115.88 656.99L151.01 677.27L186.13 697.55L221.26 717.83L256.39 738.11L291.53 758.4L326.66 778.68L361.79 798.96L396.91 819.24L432.04 839.52L467.17 859.81L502.31 880.09L537.44 900.37L572.57 920.66L607.69 940.93L642.82 961.22L677.95 981.5L713.09 1001.78L748.22 1022.07L783.35 1042.35L818.47 1062.63L853.6 1082.91L888.73 1103.19L923.86 1123.48L959 1143.76L994.12 1164.04L1029.25 1184.32L1064.38 1204.6L1099.51 1224.89L1238.88 1305.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1273.78 1285.06L1134.42 1204.6L1099.28 1184.32L1064.15 1164.04L1029.02 1143.75L993.9 1123.48L958.77 1103.19L923.64 1082.91L888.5 1062.63L853.37 1042.34L818.25 1022.07L783.12 1001.78L747.99 981.5L712.86 961.22L677.72 940.93L642.59 920.65L607.47 900.37L572.34 880.09L537.21 859.81L502.08 839.52L466.95 819.24L431.81 798.96L396.69 778.68L361.56 758.4L326.43 738.11L291.3 717.83L256.17 697.55L221.03 677.26L185.91 656.99L150.78 636.7L115.65 616.42L80.52 596.14L45.39 575.85L10.25 555.57L-24.87 535.29L-60 515.01L-95.13 494.72L-130.26 474.44L-165.39 454.16L-200.51 433.88L-235.65 413.6L-270.78 393.31L-305.91 373.03L-445.27 292.57L-305.91 373.03L-270.78 393.31L-235.65 413.6L-200.51 433.88L-165.39 454.16L-130.26 474.44L-95.13 494.72L-60 515.01L-24.87 535.29L10.25 555.57L45.39 575.85L80.52 596.14L115.65 616.42L150.78 636.7L185.91 656.99L221.03 677.26L256.17 697.55L291.3 717.83L326.43 738.11L361.56 758.4L396.69 778.68L431.81 798.96L466.95 819.24L502.08 839.52L537.21 859.81L572.34 880.09L607.47 900.37L642.59 920.65L677.72 940.93L712.86 961.22L747.99 981.5L783.12 1001.78L818.25 1022.07L853.37 1042.34L888.5 1062.63L923.64 1082.91L958.77 1103.19L993.9 1123.48L1029.02 1143.75L1064.15 1164.04L1099.28 1184.32L1134.42 1204.6L1273.78 1285.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1308.68 1264.78L1169.32 1184.32L1134.19 1164.04L1099.06 1143.75L1063.92 1123.47L1028.8 1103.19L993.67 1082.91L958.54 1062.63L923.41 1042.34L888.28 1022.06L853.16 1001.78L818.02 981.5L782.89 961.22L747.76 940.93L712.63 920.65L677.5 900.37L642.38 880.09L607.24 859.81L572.11 839.52L536.98 819.24L501.85 798.96L466.72 778.67L431.6 758.4L396.47 738.11L361.33 717.83L326.2 697.54L291.07 677.26L255.94 656.98L220.82 636.7L185.69 616.42L150.55 596.13L115.42 575.85L80.2899 555.57L45.1599 535.28L10.0399 515.01L-25.0901 494.72L-60.2301 474.44L-95.3601 454.16L-130.49 433.87L-165.61 413.6L-200.74 393.31L-235.87 373.03L-271.01 352.75L-410.37 272.29L-271.01 352.75L-235.87 373.03L-200.74 393.31L-165.61 413.6L-130.49 433.87L-95.3601 454.16L-60.2301 474.44L-25.0901 494.72L10.0399 515.01L45.1599 535.28L80.2899 555.57L115.42 575.85L150.55 596.13L185.69 616.42L220.82 636.7L255.94 656.98L291.07 677.26L326.2 697.54L361.33 717.83L396.47 738.11L431.6 758.4L466.72 778.67L501.85 798.96L536.98 819.24L572.11 839.52L607.24 859.81L642.38 880.09L677.5 900.37L712.63 920.65L747.76 940.93L782.89 961.22L818.02 981.5L853.16 1001.78L888.28 1022.06L923.41 1042.34L958.54 1062.63L993.67 1082.91L1028.8 1103.19L1063.92 1123.47L1099.06 1143.75L1134.19 1164.04L1169.32 1184.32L1308.68 1264.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1343.59 1244.5L1204.22 1164.04L1169.09 1143.75L1133.96 1123.47L1098.83 1103.19L1063.71 1082.91L1028.58 1062.63L993.44 1042.34L958.31 1022.06L923.18 1001.78L888.06 981.5L852.93 961.22L817.8 940.93L782.66 920.65L747.53 900.37L712.4 880.08L677.28 859.81L642.15 839.52L607.02 819.24L571.88 798.95L536.75 778.67L501.62 758.39L466.5 738.11L431.37 717.83L396.24 697.54L361.1 677.26L325.97 656.98L290.84 636.69L255.72 616.42L220.59 596.13L185.46 575.85L150.33 555.57L115.19 535.28L80.06 515L44.94 494.72L9.81003 474.44L-25.32 454.16L-60.45 433.87L-95.59 413.59L-130.71 393.31L-165.84 373.03L-200.97 352.75L-236.1 332.46L-375.47 252L-236.1 332.46L-200.97 352.75L-165.84 373.03L-130.71 393.31L-95.59 413.59L-60.45 433.87L-25.32 454.16L9.81003 474.44L44.94 494.72L80.06 515L115.19 535.28L150.33 555.57L185.46 575.85L220.59 596.13L255.72 616.42L290.84 636.69L325.97 656.98L361.1 677.26L396.24 697.54L431.37 717.83L466.5 738.11L501.62 758.39L536.75 778.67L571.88 798.95L607.02 819.24L642.15 839.52L677.28 859.81L712.4 880.08L747.53 900.37L782.66 920.65L817.8 940.93L852.93 961.22L888.06 981.5L923.18 1001.78L958.31 1022.06L993.44 1042.34L1028.58 1062.63L1063.71 1082.91L1098.83 1103.19L1133.96 1123.47L1169.09 1143.75L1204.22 1164.04L1343.59 1244.5Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1378.48 1224.22L1239.11 1143.76L1203.98 1123.48L1168.85 1103.19L1133.72 1082.91L1098.6 1062.63L1063.47 1042.35L1028.34 1022.07L993.2 1001.78L958.07 981.5L922.95 961.22L887.82 940.94L852.69 920.66L817.56 900.37L782.42 880.09L747.29 859.8L712.17 839.53L677.04 819.24L641.91 798.96L606.78 778.68L571.64 758.39L536.51 738.11L501.39 717.83L466.26 697.55L431.13 677.27L396 656.98L360.86 636.7L325.73 616.42L290.61 596.14L255.48 575.86L220.35 555.57L185.22 535.29L150.09 515.01L114.95 494.72L79.83 474.45L44.7 454.16L9.56995 433.88L-25.5601 413.6L-60.6901 393.31L-95.8101 373.04L-130.95 352.75L-166.08 332.47L-201.21 312.19L-340.57 231.73L-201.21 312.19L-166.08 332.47L-130.95 352.75L-95.8101 373.04L-60.6901 393.31L-25.5601 413.6L9.56995 433.88L44.7 454.16L79.83 474.45L114.95 494.72L150.09 515.01L185.22 535.29L220.35 555.57L255.48 575.86L290.61 596.14L325.73 616.42L360.86 636.7L396 656.98L431.13 677.27L466.26 697.55L501.39 717.83L536.51 738.11L571.64 758.39L606.78 778.68L641.91 798.96L677.04 819.24L712.17 839.53L747.29 859.8L782.42 880.09L817.56 900.37L852.69 920.66L887.82 940.94L922.95 961.22L958.07 981.5L993.2 1001.78L1028.34 1022.07L1063.47 1042.35L1098.6 1062.63L1133.72 1082.91L1168.85 1103.19L1203.98 1123.48L1239.11 1143.76L1378.48 1224.22Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1413.38 1203.94L1274.02 1123.48L1238.89 1103.19L1203.75 1082.91L1168.62 1062.62L1133.5 1042.35L1098.37 1022.07L1063.24 1001.78L1028.11 981.5L992.97 961.21L957.85 940.94L922.72 920.65L887.59 900.37L852.46 880.09L817.33 859.8L782.2 839.52L747.08 819.24L711.94 798.96L676.81 778.68L641.68 758.39L606.55 738.11L571.42 717.83L536.3 697.55L501.16 677.27L466.03 656.98L430.9 636.7L395.77 616.42L360.64 596.13L325.52 575.86L290.38 555.57L255.25 535.29L220.12 515.01L184.99 494.72L149.86 474.44L114.74 454.16L79.6001 433.88L44.4701 413.6L9.34009 393.31L-25.7899 373.03L-60.9099 352.75L-96.0399 332.47L-131.17 312.19L-166.31 291.9L-305.67 211.44L-166.31 291.9L-131.17 312.19L-96.0399 332.47L-60.9099 352.75L-25.7899 373.03L9.34009 393.31L44.4701 413.6L79.6001 433.88L114.74 454.16L149.86 474.44L184.99 494.72L220.12 515.01L255.25 535.29L290.38 555.57L325.52 575.86L360.64 596.13L395.77 616.42L430.9 636.7L466.03 656.98L501.16 677.27L536.3 697.55L571.42 717.83L606.55 738.11L641.68 758.39L676.81 778.68L711.94 798.96L747.08 819.24L782.2 839.52L817.33 859.8L852.46 880.09L887.59 900.37L922.72 920.65L957.85 940.94L992.97 961.21L1028.11 981.5L1063.24 1001.78L1098.37 1022.07L1133.5 1042.35L1168.62 1062.62L1203.75 1082.91L1238.89 1103.19L1274.02 1123.48L1413.38 1203.94Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1448.29 1183.65L1308.92 1103.19L1273.79 1082.91L1238.66 1062.62L1203.53 1042.34L1168.41 1022.06L1133.27 1001.78L1098.14 981.5L1063.01 961.21L1027.88 940.93L992.76 920.65L957.63 900.37L922.49 880.09L887.36 859.8L852.23 839.52L817.1 819.24L781.98 798.96L746.85 778.68L711.72 758.39L676.58 738.11L641.45 717.83L606.32 697.54L571.2 677.27L536.07 656.98L500.94 636.7L465.8 616.42L430.67 596.13L395.54 575.85L360.42 555.57L325.29 535.29L290.16 515.01L255.02 494.72L219.89 474.44L184.76 454.16L149.64 433.88L114.51 413.6L79.38 393.31L44.24 373.03L9.10995 352.75L-26.01 332.47L-61.14 312.19L-96.27 291.9L-131.4 271.62L-270.77 191.16L-131.4 271.62L-96.27 291.9L-61.14 312.19L-26.01 332.47L9.10995 352.75L44.24 373.03L79.38 393.31L114.51 413.6L149.64 433.88L184.76 454.16L219.89 474.44L255.02 494.72L290.16 515.01L325.29 535.29L360.42 555.57L395.54 575.85L430.67 596.13L465.8 616.42L500.94 636.7L536.07 656.98L571.2 677.27L606.32 697.54L641.45 717.83L676.58 738.11L711.72 758.39L746.85 778.68L781.98 798.96L817.1 819.24L852.23 839.52L887.36 859.8L922.49 880.09L957.63 900.37L992.76 920.65L1027.88 940.93L1063.01 961.21L1098.14 981.5L1133.27 1001.78L1168.41 1022.06L1203.53 1042.34L1238.66 1062.62L1273.79 1082.91L1308.92 1103.19L1448.29 1183.65Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1483.19 1163.37L1343.83 1082.91L1308.69 1062.62L1273.56 1042.34L1238.43 1022.06L1203.31 1001.78L1168.18 981.5L1133.05 961.21L1097.91 940.93L1062.78 920.65L1027.66 900.37L992.53 880.09L957.4 859.8L922.27 839.52L887.13 819.24L852 798.95L816.88 778.68L781.75 758.39L746.62 738.11L711.49 717.83L676.36 697.54L641.22 677.26L606.1 656.98L570.97 636.7L535.84 616.42L500.71 596.13L465.58 575.85L430.44 555.57L395.32 535.29L360.19 515.01L325.06 494.72L289.93 474.44L254.8 454.16L219.66 433.87L184.54 413.6L149.41 393.31L114.28 373.03L79.1501 352.75L44.0201 332.46L8.90013 312.19L-26.2399 291.9L-61.3699 271.62L-96.4999 251.34L-235.86 170.87L-96.4999 251.34L-61.3699 271.62L-26.2399 291.9L8.90013 312.19L44.0201 332.46L79.1501 352.75L114.28 373.03L149.41 393.31L184.54 413.6L219.66 433.87L254.8 454.16L289.93 474.44L325.06 494.72L360.19 515.01L395.32 535.29L430.44 555.57L465.58 575.85L500.71 596.13L535.84 616.42L570.97 636.7L606.1 656.98L641.22 677.26L676.36 697.54L711.49 717.83L746.62 738.11L781.75 758.39L816.88 778.68L852 798.95L887.13 819.24L922.27 839.52L957.4 859.8L992.53 880.09L1027.66 900.37L1062.78 920.65L1097.91 940.93L1133.05 961.21L1168.18 981.5L1203.31 1001.78L1238.43 1022.06L1273.56 1042.34L1308.69 1062.62L1343.83 1082.91L1483.19 1163.37Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1518.09 1143.09L1378.73 1062.62L1343.6 1042.34L1308.47 1022.06L1273.33 1001.77L1238.21 981.5L1203.08 961.21L1167.95 940.93L1132.82 920.65L1097.69 900.36L1062.57 880.09L1027.43 859.8L992.3 839.52L957.17 819.24L922.04 798.95L886.91 778.67L851.79 758.39L816.65 738.11L781.52 717.83L746.39 697.54L711.26 677.26L676.13 656.98L641.01 636.7L605.87 616.42L570.74 596.13L535.61 575.85L500.48 555.57L465.35 535.28L430.23 515.01L395.1 494.72L359.96 474.44L324.83 454.16L289.7 433.87L254.57 413.59L219.45 393.31L184.32 373.03L149.18 352.75L114.05 332.46L78.92 312.18L43.8 291.9L8.67004 271.62L-26.46 251.34L-61.5999 231.05L-200.96 150.59L-61.5999 231.05L-26.46 251.34L8.67004 271.62L43.8 291.9L78.92 312.18L114.05 332.46L149.18 352.75L184.32 373.03L219.45 393.31L254.57 413.59L289.7 433.87L324.83 454.16L359.96 474.44L395.1 494.72L430.23 515.01L465.35 535.28L500.48 555.57L535.61 575.85L570.74 596.13L605.87 616.42L641.01 636.7L676.13 656.98L711.26 677.26L746.39 697.54L781.52 717.83L816.65 738.11L851.79 758.39L886.91 778.67L922.04 798.95L957.17 819.24L992.3 839.52L1027.43 859.8L1062.57 880.09L1097.69 900.36L1132.82 920.65L1167.95 940.93L1203.08 961.21L1238.21 981.5L1273.33 1001.77L1308.47 1022.06L1343.6 1042.34L1378.73 1062.62L1518.09 1143.09Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1553 1122.8L1413.63 1042.34L1378.5 1022.06L1343.37 1001.77L1308.24 981.49L1273.12 961.21L1237.99 940.93L1202.85 920.65L1167.72 900.36L1132.59 880.08L1097.47 859.8L1062.34 839.52L1027.21 819.24L992.07 798.95L956.94 778.67L921.81 758.39L886.69 738.11L851.56 717.83L816.43 697.54L781.29 677.26L746.16 656.98L711.03 636.69L675.91 616.42L640.78 596.13L605.65 575.85L570.51 555.57L535.38 535.28L500.25 515L465.13 494.72L430 474.44L394.87 454.16L359.73 433.87L324.6 413.59L289.47 393.31L254.35 373.03L219.22 352.75L184.09 332.46L148.96 312.18L113.82 291.89L78.6999 271.62L43.5699 251.34L8.43994 231.05L-26.6901 210.77L-166.06 130.31L-26.6901 210.77L8.43994 231.05L43.5699 251.34L78.6999 271.62L113.82 291.89L148.96 312.18L184.09 332.46L219.22 352.75L254.35 373.03L289.47 393.31L324.6 413.59L359.73 433.87L394.87 454.16L430 474.44L465.13 494.72L500.25 515L535.38 535.28L570.51 555.57L605.65 575.85L640.78 596.13L675.91 616.42L711.03 636.69L746.16 656.98L781.29 677.26L816.43 697.54L851.56 717.83L886.69 738.11L921.81 758.39L956.94 778.67L992.07 798.95L1027.21 819.24L1062.34 839.52L1097.47 859.8L1132.59 880.08L1167.72 900.36L1202.85 920.65L1237.99 940.93L1273.12 961.21L1308.24 981.49L1343.37 1001.77L1378.5 1022.06L1413.63 1042.34L1553 1122.8Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1587.89 1102.53L1448.52 1022.06L1413.39 1001.78L1378.26 981.5L1343.13 961.21L1308.01 940.94L1272.88 920.65L1237.74 900.37L1202.61 880.09L1167.48 859.8L1132.36 839.53L1097.23 819.24L1062.1 798.96L1026.97 778.68L991.83 758.39L956.7 738.11L921.58 717.83L886.45 697.55L851.32 677.27L816.19 656.98L781.05 636.7L745.92 616.42L710.8 596.14L675.67 575.86L640.54 555.57L605.41 535.29L570.27 515.01L535.14 494.72L500.02 474.45L464.89 454.16L429.76 433.88L394.63 413.6L359.49 393.31L324.36 373.03L289.24 352.75L254.11 332.47L218.98 312.19L183.85 291.9L148.72 271.62L113.6 251.34L78.4598 231.06L43.3298 210.77L8.19984 190.49L-131.16 110.03L8.19984 190.49L43.3298 210.77L78.4598 231.06L113.6 251.34L148.72 271.62L183.85 291.9L218.98 312.19L254.11 332.47L289.24 352.75L324.36 373.03L359.49 393.31L394.63 413.6L429.76 433.88L464.89 454.16L500.02 474.45L535.14 494.72L570.27 515.01L605.41 535.29L640.54 555.57L675.67 575.86L710.8 596.14L745.92 616.42L781.05 636.7L816.19 656.98L851.32 677.27L886.45 697.55L921.58 717.83L956.7 738.11L991.83 758.39L1026.97 778.68L1062.1 798.96L1097.23 819.24L1132.36 839.53L1167.48 859.8L1202.61 880.09L1237.74 900.37L1272.88 920.65L1308.01 940.94L1343.13 961.21L1378.26 981.5L1413.39 1001.78L1448.52 1022.06L1587.89 1102.53Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1622.79 1082.24L1483.43 1001.78L1448.3 981.5L1413.16 961.21L1378.03 940.93L1342.91 920.65L1307.78 900.37L1272.65 880.09L1237.52 859.8L1202.38 839.52L1167.26 819.24L1132.13 798.96L1097 778.68L1061.87 758.39L1026.74 738.11L991.61 717.83L956.49 697.55L921.35 677.27L886.22 656.98L851.09 636.7L815.96 616.42L780.83 596.13L745.71 575.86L710.57 555.57L675.44 535.29L640.31 515.01L605.18 494.72L570.05 474.44L534.93 454.16L499.79 433.88L464.66 413.59L429.53 393.31L394.4 373.03L359.27 352.74L324.15 332.47L289.01 312.18L253.88 291.9L218.75 271.62L183.62 251.33L148.5 231.06L113.37 210.77L78.23 190.49L43.1 170.21L-96.26 89.75L43.1 170.21L78.23 190.49L113.37 210.77L148.5 231.06L183.62 251.33L218.75 271.62L253.88 291.9L289.01 312.18L324.15 332.47L359.27 352.74L394.4 373.03L429.53 393.31L464.66 413.59L499.79 433.88L534.93 454.16L570.05 474.44L605.18 494.72L640.31 515.01L675.44 535.29L710.57 555.57L745.71 575.86L780.83 596.13L815.96 616.42L851.09 636.7L886.22 656.98L921.35 677.27L956.49 697.55L991.61 717.83L1026.74 738.11L1061.87 758.39L1097 778.68L1132.13 798.96L1167.26 819.24L1202.38 839.52L1237.52 859.8L1272.65 880.09L1307.78 900.37L1342.91 920.65L1378.03 940.93L1413.16 961.21L1448.3 981.5L1483.43 1001.78L1622.79 1082.24Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1657.7 1061.96L1518.33 981.5L1483.2 961.21L1448.07 940.93L1412.94 920.65L1377.82 900.37L1342.68 880.09L1307.55 859.8L1272.42 839.52L1237.29 819.24L1202.17 798.96L1167.04 778.68L1131.9 758.39L1096.77 738.11L1061.64 717.83L1026.51 697.54L991.39 677.27L956.26 656.98L921.12 636.7L885.99 616.41L850.86 596.13L815.73 575.85L780.61 555.57L745.48 535.29L710.35 515L675.21 494.72L640.08 474.44L604.95 454.15L569.83 433.88L534.7 413.59L499.57 393.31L464.43 373.03L429.3 352.74L394.17 332.46L359.05 312.18L323.92 291.9L288.79 271.62L253.65 251.33L218.52 231.05L183.4 210.77L148.27 190.49L113.14 170.21L78.0101 149.92L-61.3599 69.46L78.0101 149.92L113.14 170.21L148.27 190.49L183.4 210.77L218.52 231.05L253.65 251.33L288.79 271.62L323.92 291.9L359.05 312.18L394.17 332.46L429.3 352.74L464.43 373.03L499.57 393.31L534.7 413.59L569.83 433.88L604.95 454.15L640.08 474.44L675.21 494.72L710.35 515L745.48 535.29L780.61 555.57L815.73 575.85L850.86 596.13L885.99 616.41L921.12 636.7L956.26 656.98L991.39 677.27L1026.51 697.54L1061.64 717.83L1096.77 738.11L1131.9 758.39L1167.04 778.68L1202.17 798.96L1237.29 819.24L1272.42 839.52L1307.55 859.8L1342.68 880.09L1377.82 900.37L1412.94 920.65L1448.07 940.93L1483.2 961.21L1518.33 981.5L1657.7 1061.96Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1692.6 1041.67L1553.24 961.21L1518.1 940.93L1482.97 920.65L1447.84 900.36L1412.72 880.09L1377.59 859.8L1342.46 839.52L1307.32 819.24L1272.19 798.95L1237.07 778.68L1201.94 758.39L1166.81 738.11L1131.68 717.82L1096.54 697.54L1061.41 677.26L1026.29 656.98L991.16 636.7L956.03 616.41L920.9 596.13L885.76 575.85L850.63 555.56L815.51 535.29L780.38 515L745.25 494.72L710.12 474.44L674.99 454.15L639.85 433.87L604.73 413.59L569.6 393.31L534.47 373.03L499.34 352.74L464.21 332.46L429.07 312.18L393.95 291.9L358.82 271.62L323.69 251.33L288.56 231.05L253.43 210.77L218.31 190.49L183.17 170.21L148.04 149.92L112.91 129.64L-26.45 49.1799L112.91 129.64L148.04 149.92L183.17 170.21L218.31 190.49L253.43 210.77L288.56 231.05L323.69 251.33L358.82 271.62L393.95 291.9L429.07 312.18L464.21 332.46L499.34 352.74L534.47 373.03L569.6 393.31L604.73 413.59L639.85 433.87L674.99 454.15L710.12 474.44L745.25 494.72L780.38 515L815.51 535.29L850.63 555.56L885.76 575.85L920.9 596.13L956.03 616.41L991.16 636.7L1026.29 656.98L1061.41 677.26L1096.54 697.54L1131.68 717.82L1166.81 738.11L1201.94 758.39L1237.07 778.68L1272.19 798.95L1307.32 819.24L1342.46 839.52L1377.59 859.8L1412.72 880.09L1447.84 900.36L1482.97 920.65L1518.1 940.93L1553.24 961.21L1692.6 1041.67Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1727.5 1021.39L1588.14 940.93L1553.01 920.64L1517.87 900.36L1482.74 880.08L1447.62 859.8L1412.49 839.52L1377.36 819.23L1342.23 798.95L1307.1 778.67L1271.98 758.39L1236.84 738.11L1201.71 717.82L1166.58 697.54L1131.45 677.26L1096.32 656.97L1061.2 636.7L1026.06 616.41L990.93 596.13L955.8 575.85L920.67 555.56L885.54 535.28L850.42 515L815.28 494.72L780.15 474.44L745.02 454.15L709.89 433.87L674.76 413.59L639.64 393.31L604.5 373.03L569.37 352.74L534.24 332.46L499.11 312.18L463.98 291.89L428.86 271.62L393.73 251.33L358.59 231.05L323.46 210.77L288.33 190.48L253.21 170.21L218.08 149.92L182.95 129.64L147.81 109.36L8.44995 28.89L147.81 109.36L182.95 129.64L218.08 149.92L253.21 170.21L288.33 190.48L323.46 210.77L358.59 231.05L393.73 251.33L428.86 271.62L463.98 291.89L499.11 312.18L534.24 332.46L569.37 352.74L604.5 373.03L639.64 393.31L674.76 413.59L709.89 433.87L745.02 454.15L780.15 474.44L815.28 494.72L850.42 515L885.54 535.28L920.67 555.56L955.8 575.85L990.93 596.13L1026.06 616.41L1061.2 636.7L1096.32 656.97L1131.45 677.26L1166.58 697.54L1201.71 717.82L1236.84 738.11L1271.98 758.39L1307.1 778.67L1342.23 798.95L1377.36 819.23L1412.49 839.52L1447.62 859.8L1482.74 880.08L1517.87 900.36L1553.01 920.64L1588.14 940.93L1727.5 1021.39Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1762.41 1001.11L1623.04 920.64L1587.91 900.36L1552.78 880.08L1517.65 859.79L1482.53 839.52L1447.39 819.23L1412.26 798.95L1377.13 778.67L1342 758.38L1306.88 738.11L1271.75 717.82L1236.62 697.54L1201.48 677.26L1166.35 656.97L1131.22 636.69L1096.1 616.41L1060.97 596.13L1025.84 575.85L990.7 555.56L955.57 535.28L920.44 515L885.32 494.72L850.19 474.44L815.06 454.15L779.92 433.87L744.79 413.59L709.66 393.3L674.54 373.03L639.41 352.74L604.28 332.46L569.14 312.18L534.01 291.89L498.88 271.61L463.76 251.33L428.63 231.05L393.5 210.77L358.37 190.48L323.23 170.2L288.11 149.92L252.98 129.64L217.85 109.36L182.72 89.07L43.3501 8.60999L182.72 89.07L217.85 109.36L252.98 129.64L288.11 149.92L323.23 170.2L358.37 190.48L393.5 210.77L428.63 231.05L463.76 251.33L498.88 271.61L534.01 291.89L569.14 312.18L604.28 332.46L639.41 352.74L674.54 373.03L709.66 393.3L744.79 413.59L779.92 433.87L815.06 454.15L850.19 474.44L885.32 494.72L920.44 515L955.57 535.28L990.7 555.56L1025.84 575.85L1060.97 596.13L1096.1 616.41L1131.22 636.69L1166.35 656.97L1201.48 677.26L1236.62 697.54L1271.75 717.82L1306.88 738.11L1342 758.38L1377.13 778.67L1412.26 798.95L1447.39 819.23L1482.53 839.52L1517.65 859.79L1552.78 880.08L1587.91 900.36L1623.04 920.64L1762.41 1001.11Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1797.3 980.83L1657.93 900.37L1622.8 880.08L1587.67 859.8L1552.54 839.52L1517.42 819.24L1482.29 798.96L1447.15 778.67L1412.02 758.39L1376.89 738.11L1341.77 717.83L1306.64 697.55L1271.51 677.26L1236.37 656.98L1201.24 636.7L1166.11 616.41L1130.99 596.14L1095.86 575.85L1060.73 555.57L1025.6 535.29L990.46 515L955.33 494.72L920.21 474.44L885.08 454.16L849.95 433.88L814.82 413.59L779.68 393.31L744.55 373.03L709.43 352.75L674.3 332.47L639.17 312.18L604.04 291.9L568.9 271.62L533.77 251.33L498.65 231.06L463.52 210.77L428.39 190.49L393.26 170.21L358.12 149.92L323 129.65L287.87 109.36L252.74 89.08L217.61 68.8L78.25 -11.67L217.61 68.8L252.74 89.08L287.87 109.36L323 129.65L358.12 149.92L393.26 170.21L428.39 190.49L463.52 210.77L498.65 231.06L533.77 251.33L568.9 271.62L604.04 291.9L639.17 312.18L674.3 332.47L709.43 352.75L744.55 373.03L779.68 393.31L814.82 413.59L849.95 433.88L885.08 454.16L920.21 474.44L955.33 494.72L990.46 515L1025.6 535.29L1060.73 555.57L1095.86 575.85L1130.99 596.14L1166.11 616.41L1201.24 636.7L1236.37 656.98L1271.51 677.26L1306.64 697.55L1341.77 717.83L1376.89 738.11L1412.02 758.39L1447.15 778.67L1482.29 798.96L1517.42 819.24L1552.54 839.52L1587.67 859.8L1622.8 880.08L1657.93 900.37L1797.3 980.83Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1832.2 960.55L1692.84 880.08L1657.71 859.8L1622.57 839.52L1587.44 819.23L1552.32 798.96L1517.19 778.67L1482.06 758.39L1446.93 738.11L1411.79 717.82L1376.67 697.55L1341.54 677.26L1306.41 656.98L1271.28 636.7L1236.15 616.41L1201.01 596.13L1165.89 575.85L1130.76 555.57L1095.63 535.29L1060.5 515L1025.37 494.72L990.24 474.44L955.12 454.16L919.98 433.88L884.85 413.59L849.72 393.31L814.59 373.03L779.46 352.74L744.34 332.47L709.2 312.18L674.07 291.9L638.94 271.62L603.81 251.33L568.68 231.05L533.56 210.77L498.42 190.49L463.29 170.21L428.16 149.92L393.03 129.64L357.91 109.36L322.78 89.08L287.64 68.8L252.51 48.51L113.15 -31.95L252.51 48.51L287.64 68.8L322.78 89.08L357.91 109.36L393.03 129.64L428.16 149.92L463.29 170.21L498.42 190.49L533.56 210.77L568.68 231.05L603.81 251.33L638.94 271.62L674.07 291.9L709.2 312.18L744.34 332.47L779.46 352.74L814.59 373.03L849.72 393.31L884.85 413.59L919.98 433.88L955.12 454.16L990.24 474.44L1025.37 494.72L1060.5 515L1095.63 535.29L1130.76 555.57L1165.89 575.85L1201.01 596.13L1236.15 616.41L1271.28 636.7L1306.41 656.98L1341.54 677.26L1376.67 697.55L1411.79 717.82L1446.93 738.11L1482.06 758.39L1517.19 778.67L1552.32 798.96L1587.44 819.23L1622.57 839.52L1657.71 859.8L1692.84 880.08L1832.2 960.55Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1867.1 940.26L1727.74 859.8L1692.61 839.52L1657.48 819.23L1622.35 798.95L1587.23 778.67L1552.09 758.39L1516.96 738.11L1481.83 717.82L1446.7 697.54L1411.58 677.26L1376.45 656.98L1341.31 636.7L1306.18 616.41L1271.05 596.13L1235.92 575.85L1200.8 555.57L1165.67 535.29L1130.53 515L1095.4 494.72L1060.27 474.44L1025.14 454.15L990.02 433.88L954.89 413.59L919.75 393.31L884.62 373.03L849.49 352.74L814.36 332.46L779.24 312.18L744.11 291.9L708.98 271.62L673.84 251.33L638.71 231.05L603.58 210.77L568.46 190.49L533.33 170.21L498.2 149.92L463.06 129.64L427.93 109.36L392.81 89.08L357.68 68.8L322.55 48.51L287.42 28.23L148.05 -52.23L287.42 28.23L322.55 48.51L357.68 68.8L392.81 89.08L427.93 109.36L463.06 129.64L498.2 149.92L533.33 170.21L568.46 190.49L603.58 210.77L638.71 231.05L673.84 251.33L708.98 271.62L744.11 291.9L779.24 312.18L814.36 332.46L849.49 352.74L884.62 373.03L919.75 393.31L954.89 413.59L990.02 433.88L1025.14 454.15L1060.27 474.44L1095.4 494.72L1130.53 515L1165.67 535.29L1200.8 555.57L1235.92 575.85L1271.05 596.13L1306.18 616.41L1341.31 636.7L1376.45 656.98L1411.58 677.26L1446.7 697.54L1481.83 717.82L1516.96 738.11L1552.09 758.39L1587.23 778.67L1622.35 798.95L1657.48 819.23L1692.61 839.52L1727.74 859.8L1867.1 940.26Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1902.01 919.98L1762.64 839.52L1727.51 819.23L1692.38 798.95L1657.25 778.67L1622.13 758.39L1587 738.11L1551.87 717.82L1516.73 697.54L1481.6 677.26L1446.48 656.98L1411.35 636.7L1376.22 616.41L1341.09 596.13L1305.95 575.85L1270.82 555.56L1235.7 535.29L1200.57 515L1165.44 494.72L1130.31 474.44L1095.17 454.15L1060.04 433.87L1024.92 413.59L989.79 393.31L954.66 373.03L919.53 352.74L884.39 332.46L849.26 312.18L814.14 291.9L779.01 271.62L743.88 251.33L708.75 231.05L673.62 210.76L638.48 190.48L603.36 170.21L568.23 149.92L533.1 129.64L497.97 109.35L462.84 89.07L427.72 68.79L392.58 48.51L357.45 28.23L322.32 7.93998L182.96 -72.52L322.32 7.93998L357.45 28.23L392.58 48.51L427.72 68.79L462.84 89.07L497.97 109.35L533.1 129.64L568.23 149.92L603.36 170.21L638.48 190.48L673.62 210.76L708.75 231.05L743.88 251.33L779.01 271.62L814.14 291.9L849.26 312.18L884.39 332.46L919.53 352.74L954.66 373.03L989.79 393.31L1024.92 413.59L1060.04 433.87L1095.17 454.15L1130.31 474.44L1165.44 494.72L1200.57 515L1235.7 535.29L1270.82 555.56L1305.95 575.85L1341.09 596.13L1376.22 616.41L1411.35 636.7L1446.48 656.98L1481.6 677.26L1516.73 697.54L1551.87 717.82L1587 738.11L1622.13 758.39L1657.25 778.67L1692.38 798.95L1727.51 819.23L1762.64 839.52L1902.01 919.98Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1936.91 899.69L1797.55 819.23L1762.42 798.95L1727.28 778.67L1692.15 758.38L1657.03 738.11L1621.9 717.82L1586.77 697.54L1551.64 677.26L1516.51 656.97L1481.39 636.7L1446.25 616.41L1411.12 596.13L1375.99 575.85L1340.86 555.56L1305.73 535.28L1270.61 515L1235.47 494.72L1200.34 474.44L1165.21 454.15L1130.08 433.87L1094.95 413.59L1059.83 393.31L1024.69 373.03L989.56 352.74L954.43 332.46L919.3 312.17L884.17 291.89L849.05 271.61L813.91 251.33L778.78 231.05L743.65 210.76L708.52 190.48L673.39 170.2L638.27 149.92L603.13 129.64L568 109.35L532.87 89.0699L497.74 68.7899L462.62 48.5099L427.49 28.2299L392.36 7.93996L357.22 -12.34L217.86 -92.8L357.22 -12.34L392.36 7.93996L427.49 28.2299L462.62 48.5099L497.74 68.7899L532.87 89.0699L568 109.35L603.13 129.64L638.27 149.92L673.39 170.2L708.52 190.48L743.65 210.76L778.78 231.05L813.91 251.33L849.05 271.61L884.17 291.89L919.3 312.17L954.43 332.46L989.56 352.74L1024.69 373.03L1059.83 393.31L1094.95 413.59L1130.08 433.87L1165.21 454.15L1200.34 474.44L1235.47 494.72L1270.61 515L1305.73 535.28L1340.86 555.56L1375.99 575.85L1411.12 596.13L1446.25 616.41L1481.39 636.7L1516.51 656.97L1551.64 677.26L1586.77 697.54L1621.9 717.82L1657.03 738.11L1692.15 758.38L1727.28 778.67L1762.42 798.95L1797.55 819.23L1936.91 899.69Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1971.82 879.41L1832.45 798.95L1797.32 778.67L1762.19 758.38L1727.06 738.1L1691.94 717.82L1656.8 697.54L1621.67 677.26L1586.54 656.97L1551.41 636.69L1516.29 616.41L1481.16 596.13L1446.02 575.85L1410.89 555.56L1375.76 535.28L1340.63 514.99L1305.51 494.72L1270.38 474.44L1235.25 454.15L1200.11 433.87L1164.98 413.58L1129.85 393.3L1094.73 373.02L1059.6 352.74L1024.47 332.46L989.33 312.17L954.2 291.89L919.07 271.61L883.95 251.33L848.82 231.05L813.69 210.76L778.55 190.48L743.42 170.2L708.29 149.91L673.17 129.64L638.04 109.35L602.91 89.07L567.77 68.79L532.64 48.5L497.52 28.23L462.39 7.94005L427.26 -12.34L392.13 -32.6199L252.76 -113.08L392.13 -32.6199L427.26 -12.34L462.39 7.94005L497.52 28.23L532.64 48.5L567.77 68.79L602.91 89.07L638.04 109.35L673.17 129.64L708.29 149.91L743.42 170.2L778.55 190.48L813.69 210.76L848.82 231.05L883.95 251.33L919.07 271.61L954.2 291.89L989.33 312.17L1024.47 332.46L1059.6 352.74L1094.73 373.02L1129.85 393.3L1164.98 413.58L1200.11 433.87L1235.25 454.15L1270.38 474.44L1305.51 494.72L1340.63 514.99L1375.76 535.28L1410.89 555.56L1446.02 575.85L1481.16 596.13L1516.29 616.41L1551.41 636.69L1586.54 656.97L1621.67 677.26L1656.8 697.54L1691.94 717.82L1727.06 738.1L1762.19 758.38L1797.32 778.67L1832.45 798.95L1971.82 879.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2006.71 859.13L1867.34 778.67L1832.21 758.39L1797.08 738.11L1761.95 717.82L1726.83 697.55L1691.7 677.26L1656.56 656.98L1621.43 636.7L1586.3 616.41L1551.18 596.14L1516.05 575.85L1480.92 555.57L1445.78 535.28L1410.65 515L1375.52 494.72L1340.4 474.44L1305.27 454.16L1270.14 433.87L1235 413.59L1199.87 393.31L1164.74 373.02L1129.62 352.75L1094.49 332.46L1059.36 312.18L1024.23 291.9L989.09 271.61L953.96 251.33L918.84 231.05L883.71 210.77L848.58 190.49L813.45 170.2L778.31 149.92L743.18 129.64L708.06 109.36L672.93 89.08L637.8 68.79L602.67 48.51L567.53 28.23L532.41 7.95001L497.28 -12.33L462.15 -32.62L427.02 -52.9L287.66 -133.36L427.02 -52.9L462.15 -32.62L497.28 -12.33L532.41 7.95001L567.53 28.23L602.67 48.51L637.8 68.79L672.93 89.08L708.06 109.36L743.18 129.64L778.31 149.92L813.45 170.2L848.58 190.49L883.71 210.77L918.84 231.05L953.96 251.33L989.09 271.61L1024.23 291.9L1059.36 312.18L1094.49 332.46L1129.62 352.75L1164.74 373.02L1199.87 393.31L1235 413.59L1270.14 433.87L1305.27 454.16L1340.4 474.44L1375.52 494.72L1410.65 515L1445.78 535.28L1480.92 555.57L1516.05 575.85L1551.18 596.14L1586.3 616.41L1621.43 636.7L1656.56 656.98L1691.7 677.26L1726.83 697.55L1761.95 717.82L1797.08 738.11L1832.21 758.39L1867.34 778.67L2006.71 859.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2041.61 838.85L1902.25 758.39L1867.12 738.11L1831.98 717.82L1796.85 697.54L1761.73 677.26L1726.6 656.98L1691.47 636.69L1656.34 616.41L1621.2 596.13L1586.08 575.85L1550.95 555.57L1515.82 535.28L1480.69 515L1445.56 494.72L1410.42 474.43L1375.3 454.16L1340.17 433.87L1305.04 413.59L1269.91 393.31L1234.78 373.02L1199.64 352.74L1164.52 332.46L1129.39 312.18L1094.26 291.9L1059.13 271.61L1024 251.33L988.87 231.05L953.75 210.77L918.61 190.49L883.48 170.2L848.35 149.92L813.22 129.64L778.09 109.35L742.97 89.08L707.83 68.79L672.7 48.51L637.57 28.23L602.44 7.93997L567.32 -12.33L532.19 -32.62L497.05 -52.9L461.92 -73.18L322.56 -153.65L461.92 -73.18L497.05 -52.9L532.19 -32.62L567.32 -12.33L602.44 7.93997L637.57 28.23L672.7 48.51L707.83 68.79L742.97 89.08L778.09 109.35L813.22 129.64L848.35 149.92L883.48 170.2L918.61 190.49L953.75 210.77L988.87 231.05L1024 251.33L1059.13 271.61L1094.26 291.9L1129.39 312.18L1164.52 332.46L1199.64 352.74L1234.78 373.02L1269.91 393.31L1305.04 413.59L1340.17 433.87L1375.3 454.16L1410.42 474.43L1445.56 494.72L1480.69 515L1515.82 535.28L1550.95 555.57L1586.08 575.85L1621.2 596.13L1656.34 616.41L1691.47 636.69L1726.6 656.98L1761.73 677.26L1796.85 697.54L1831.98 717.82L1867.12 738.11L1902.25 758.39L2041.61 838.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2076.51 818.57L1937.15 738.1L1902.02 717.82L1866.89 697.54L1831.76 677.25L1796.64 656.98L1761.5 636.69L1726.37 616.41L1691.24 596.13L1656.11 575.84L1620.99 555.57L1585.86 535.28L1550.72 515L1515.59 494.72L1480.46 474.43L1445.33 454.15L1410.21 433.87L1375.08 413.59L1339.94 393.31L1304.81 373.02L1269.68 352.74L1234.55 332.46L1199.43 312.18L1164.3 291.9L1129.16 271.61L1094.03 251.33L1058.9 231.05L1023.77 210.76L988.65 190.49L953.52 170.2L918.38 149.92L883.25 129.64L848.12 109.35L812.99 89.0701L777.87 68.7901L742.74 48.5101L707.61 28.2301L672.47 7.94006L637.34 -12.3399L602.22 -32.6199L567.09 -52.8999L531.96 -73.1799L496.83 -93.4699L357.46 -173.93L496.83 -93.4699L531.96 -73.1799L567.09 -52.8999L602.22 -32.6199L637.34 -12.3399L672.47 7.94006L707.61 28.2301L742.74 48.5101L777.87 68.7901L812.99 89.0701L848.12 109.35L883.25 129.64L918.38 149.92L953.52 170.2L988.65 190.49L1023.77 210.76L1058.9 231.05L1094.03 251.33L1129.16 271.61L1164.3 291.9L1199.43 312.18L1234.55 332.46L1269.68 352.74L1304.81 373.02L1339.94 393.31L1375.08 413.59L1410.21 433.87L1445.33 454.15L1480.46 474.43L1515.59 494.72L1550.72 515L1585.86 535.28L1620.99 555.57L1656.11 575.84L1691.24 596.13L1726.37 616.41L1761.5 636.69L1796.64 656.98L1831.76 677.25L1866.89 697.54L1902.02 717.82L1937.15 738.1L2076.51 818.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2111.42 798.28L1972.05 717.82L1936.92 697.54L1901.79 677.25L1866.66 656.97L1831.54 636.69L1796.41 616.41L1761.27 596.13L1726.14 575.84L1691.01 555.56L1655.89 535.28L1620.76 515L1585.63 494.72L1550.5 474.43L1515.36 454.15L1480.23 433.87L1445.11 413.59L1409.98 393.31L1374.85 373.02L1339.72 352.74L1304.58 332.46L1269.45 312.17L1234.33 291.9L1199.2 271.61L1164.07 251.33L1128.94 231.05L1093.8 210.76L1058.67 190.48L1023.55 170.2L988.42 149.92L953.29 129.64L918.16 109.35L883.02 89.07L847.89 68.79L812.77 48.51L777.64 28.23L742.51 7.94005L707.38 -12.34L672.25 -32.6199L637.13 -52.9L601.99 -73.18L566.86 -93.47L531.73 -113.75L392.37 -194.21L531.73 -113.75L566.86 -93.47L601.99 -73.18L637.13 -52.9L672.25 -32.6199L707.38 -12.34L742.51 7.94005L777.64 28.23L812.77 48.51L847.89 68.79L883.02 89.07L918.16 109.35L953.29 129.64L988.42 149.92L1023.55 170.2L1058.67 190.48L1093.8 210.76L1128.94 231.05L1164.07 251.33L1199.2 271.61L1234.33 291.9L1269.45 312.17L1304.58 332.46L1339.72 352.74L1374.85 373.02L1409.98 393.31L1445.11 413.59L1480.23 433.87L1515.36 454.15L1550.5 474.43L1585.63 494.72L1620.76 515L1655.89 535.28L1691.01 555.56L1726.14 575.84L1761.27 596.13L1796.41 616.41L1831.54 636.69L1866.66 656.97L1901.79 677.25L1936.92 697.54L1972.05 717.82L2111.42 798.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2146.32 778L2006.96 697.54L1971.83 677.25L1936.69 656.97L1901.56 636.69L1866.44 616.41L1831.31 596.13L1796.18 575.84L1761.05 555.56L1725.91 535.28L1690.79 515L1655.66 494.72L1620.53 474.43L1585.4 454.15L1550.27 433.87L1515.14 413.58L1480.02 393.31L1444.88 373.02L1409.75 352.74L1374.62 332.46L1339.49 312.17L1304.36 291.89L1269.24 271.61L1234.1 251.33L1198.97 231.05L1163.84 210.76L1128.71 190.48L1093.58 170.2L1058.46 149.92L1023.32 129.64L988.19 109.35L953.06 89.07L917.93 68.79L882.8 48.5L847.68 28.23L812.54 7.94L777.41 -12.34L742.28 -32.62L707.15 -52.91L672.03 -73.18L636.9 -93.47L601.76 -113.75L566.63 -134.03L427.27 -214.5L566.63 -134.03L601.76 -113.75L636.9 -93.47L672.03 -73.18L707.15 -52.91L742.28 -32.62L777.41 -12.34L812.54 7.94L847.68 28.23L882.8 48.5L917.93 68.79L953.06 89.07L988.19 109.35L1023.32 129.64L1058.46 149.92L1093.58 170.2L1128.71 190.48L1163.84 210.76L1198.97 231.05L1234.1 251.33L1269.24 271.61L1304.36 291.89L1339.49 312.17L1374.62 332.46L1409.75 352.74L1444.88 373.02L1480.02 393.31L1515.14 413.58L1550.27 433.87L1585.4 454.15L1620.53 474.43L1655.66 494.72L1690.79 515L1725.91 535.28L1761.05 555.56L1796.18 575.84L1831.31 596.13L1866.44 616.41L1901.56 636.69L1936.69 656.97L1971.83 677.25L2006.96 697.54L2146.32 778Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2181.23 757.72L2041.86 677.25L2006.73 656.97L1971.6 636.69L1936.47 616.4L1901.35 596.13L1866.21 575.84L1831.08 555.56L1795.95 535.28L1760.82 514.99L1725.7 494.72L1690.57 474.43L1655.43 454.15L1620.3 433.87L1585.17 413.58L1550.04 393.3L1514.92 373.02L1479.79 352.74L1444.65 332.46L1409.52 312.17L1374.39 291.89L1339.26 271.61L1304.14 251.33L1269.01 231.05L1233.88 210.76L1198.74 190.48L1163.61 170.2L1128.48 149.91L1093.36 129.64L1058.23 109.35L1023.1 89.07L987.96 68.79L952.83 48.5L917.7 28.22L882.58 7.93997L847.45 -12.34L812.32 -32.62L777.18 -52.91L742.05 -73.19L706.93 -93.47L671.8 -113.75L636.67 -134.04L601.54 -154.32L462.17 -234.78L601.54 -154.32L636.67 -134.04L671.8 -113.75L706.93 -93.47L742.05 -73.19L777.18 -52.91L812.32 -32.62L847.45 -12.34L882.58 7.93997L917.7 28.22L952.83 48.5L987.96 68.79L1023.1 89.07L1058.23 109.35L1093.36 129.64L1128.48 149.91L1163.61 170.2L1198.74 190.48L1233.88 210.76L1269.01 231.05L1304.14 251.33L1339.26 271.61L1374.39 291.89L1409.52 312.17L1444.65 332.46L1479.79 352.74L1514.92 373.02L1550.04 393.3L1585.17 413.58L1620.3 433.87L1655.43 454.15L1690.57 474.43L1725.7 494.72L1760.82 514.99L1795.95 535.28L1831.08 555.56L1866.21 575.84L1901.35 596.13L1936.47 616.4L1971.6 636.69L2006.73 656.97L2041.86 677.25L2181.23 757.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2216.12 737.44L2076.75 656.98L2041.62 636.69L2006.49 616.41L1971.36 596.13L1936.24 575.85L1901.11 555.57L1865.97 535.28L1830.84 515L1795.71 494.72L1760.59 474.44L1725.46 454.16L1690.33 433.87L1655.19 413.59L1620.06 393.31L1584.93 373.02L1549.81 352.75L1514.68 332.46L1479.55 312.18L1444.41 291.9L1409.28 271.61L1374.15 251.33L1339.03 231.05L1303.9 210.77L1268.77 190.49L1233.64 170.2L1198.5 149.92L1163.37 129.64L1128.25 109.36L1093.12 89.08L1057.99 68.7899L1022.86 48.5099L987.72 28.2299L952.59 7.93994L917.47 -12.3301L882.34 -32.6201L847.21 -52.9001L812.08 -73.1901L776.94 -93.4701L741.82 -113.75L706.69 -134.03L671.56 -154.31L636.43 -174.6L497.06 -255.06L636.43 -174.6L671.56 -154.31L706.69 -134.03L741.82 -113.75L776.94 -93.4701L812.08 -73.1901L847.21 -52.9001L882.34 -32.6201L917.47 -12.3301L952.59 7.93994L987.72 28.2299L1022.86 48.5099L1057.99 68.7899L1093.12 89.08L1128.25 109.36L1163.37 129.64L1198.5 149.92L1233.64 170.2L1268.77 190.49L1303.9 210.77L1339.03 231.05L1374.15 251.33L1409.28 271.61L1444.41 291.9L1479.55 312.18L1514.68 332.46L1549.81 352.75L1584.93 373.02L1620.06 393.31L1655.19 413.59L1690.33 433.87L1725.46 454.16L1760.59 474.44L1795.71 494.72L1830.84 515L1865.97 535.28L1901.11 555.57L1936.24 575.85L1971.36 596.13L2006.49 616.41L2041.62 636.69L2076.75 656.98L2216.12 737.44Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2251.02 717.15L2111.66 636.69L2076.52 616.41L2041.39 596.13L2006.26 575.84L1971.14 555.57L1936.01 535.28L1900.88 515L1865.75 494.72L1830.61 474.43L1795.49 454.16L1760.36 433.87L1725.23 413.59L1690.1 393.31L1654.97 373.02L1619.83 352.74L1584.71 332.46L1549.58 312.18L1514.45 291.9L1479.32 271.61L1444.19 251.33L1409.05 231.05L1373.93 210.77L1338.8 190.49L1303.67 170.2L1268.54 149.92L1233.41 129.63L1198.27 109.35L1163.15 89.08L1128.02 68.79L1092.89 48.51L1057.76 28.22L1022.63 7.94003L987.5 -12.34L952.38 -32.62L917.24 -52.9L882.11 -73.19L846.98 -93.47L811.85 -113.75L776.73 -134.03L741.6 -154.31L706.46 -174.6L671.33 -194.88L531.97 -275.34L671.33 -194.88L706.46 -174.6L741.6 -154.31L776.73 -134.03L811.85 -113.75L846.98 -93.47L882.11 -73.19L917.24 -52.9L952.38 -32.62L987.5 -12.34L1022.63 7.94003L1057.76 28.22L1092.89 48.51L1128.02 68.79L1163.15 89.08L1198.27 109.35L1233.41 129.63L1268.54 149.92L1303.67 170.2L1338.8 190.49L1373.93 210.77L1409.05 231.05L1444.19 251.33L1479.32 271.61L1514.45 291.9L1549.58 312.18L1584.71 332.46L1619.83 352.74L1654.97 373.02L1690.1 393.31L1725.23 413.59L1760.36 433.87L1795.49 454.16L1830.61 474.43L1865.75 494.72L1900.88 515L1936.01 535.28L1971.14 555.57L2006.26 575.84L2041.39 596.13L2076.52 616.41L2111.66 636.69L2251.02 717.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2285.92 696.87L2146.56 616.41L2111.43 596.13L2076.3 575.84L2041.16 555.56L2006.04 535.28L1970.91 515L1935.78 494.72L1900.65 474.43L1865.52 454.15L1830.4 433.87L1795.27 413.59L1760.13 393.31L1725 373.02L1689.87 352.74L1654.74 332.46L1619.62 312.18L1584.49 291.9L1549.35 271.61L1514.22 251.33L1479.09 231.04L1443.96 210.76L1408.84 190.48L1373.71 170.2L1338.57 149.92L1303.44 129.63L1268.31 109.35L1233.18 89.07L1198.06 68.79L1162.93 48.51L1127.79 28.22L1092.66 7.94L1057.53 -12.34L1022.4 -32.63L987.28 -52.9L952.15 -73.19L917.02 -93.47L881.88 -113.75L846.75 -134.04L811.63 -154.31L776.5 -174.6L741.37 -194.88L706.24 -215.16L566.87 -295.62L706.24 -215.16L741.37 -194.88L776.5 -174.6L811.63 -154.31L846.75 -134.04L881.88 -113.75L917.02 -93.47L952.15 -73.19L987.28 -52.9L1022.4 -32.63L1057.53 -12.34L1092.66 7.94L1127.79 28.22L1162.93 48.51L1198.06 68.79L1233.18 89.07L1268.31 109.35L1303.44 129.63L1338.57 149.92L1373.71 170.2L1408.84 190.48L1443.96 210.76L1479.09 231.04L1514.22 251.33L1549.35 271.61L1584.49 291.9L1619.62 312.18L1654.74 332.46L1689.87 352.74L1725 373.02L1760.13 393.31L1795.27 413.59L1830.4 433.87L1865.52 454.15L1900.65 474.43L1935.78 494.72L1970.91 515L2006.04 535.28L2041.16 555.56L2076.3 575.84L2111.43 596.13L2146.56 616.41L2285.92 696.87Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_7938">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* wordt je woning te warm */
export const step31Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_8596)">
      <path
        d="M479.15 500.42L478.18 1014.98L853 797.62L850.12 322.29L479.15 500.42Z"
        fill="#CD163F"
      />
      <path
        d="M135.39 817.07L478.18 1014.98L479.15 500.42L132.5 302.51L135.39 817.07Z"
        fill="#F8B8C4"
      />
      <path
        d="M132.5 302.51L479.15 500.42L678.95 262.93L336.17 65.02L132.5 302.51Z"
        fill="#940731"
      />
      <path
        d="M678.95 262.93L850.12 322.29L479.15 500.42L678.95 262.93Z"
        fill="#CD163F"
      />
      <path
        d="M336.17 65.02L678.95 262.93L850.12 322.29L477.75 108L336.17 65.02Z"
        fill="#85081F"
      />
      <path
        d="M580.76 955.7L680.29 897.15L680.73 619.3L581.02 678.54L580.76 955.7Z"
        fill="#F8B8C4"
      />
      <mask
        id="mask0_68_8596"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="729"
        y="554"
        width="70"
        height="189"
      >
        <path
          d="M729.66 742.97L729.24 594.23L797.77 554.66L798.19 703.4L729.66 742.97Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_8596)">
        <path
          d="M797.77 554.66L729.24 594.23L729.66 742.97L798.19 703.4L797.77 554.66Z"
          fill="#F8B8C4"
        />
      </g>
      <path
        d="M402.59 811.27L403.01 662.53L334.48 622.97L334.06 771.71L402.59 811.27Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask1_68_8596"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="334"
        y="622"
        width="70"
        height="190"
      >
        <path
          d="M402.59 811.27L403.01 662.53L334.48 622.97L334.06 771.71L402.59 811.27Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_68_8596)">
        <path
          d="M334.48 622.97L403.01 662.53L402.59 811.27L334.06 771.71L334.48 622.97Z"
          fill="#CD163F"
        />
      </g>
      <path
        d="M266.95 732.24L267.38 583.49L198.85 543.93L198.42 692.67L266.95 732.24Z"
        fill="#B3B4B3"
      />
      <mask
        id="mask2_68_8596"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="198"
        y="543"
        width="70"
        height="190"
      >
        <path
          d="M266.95 732.24L267.38 583.49L198.85 543.93L198.42 692.67L266.95 732.24Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_68_8596)">
        <path
          d="M198.85 543.93L267.38 583.49L266.95 732.24L198.42 692.67L198.85 543.93Z"
          fill="#CD163F"
        />
      </g>
      <path
        d="M132.5 302.51L479.15 500.42L678.95 262.93"
        stroke="#940731"
        stroke-miterlimit="10"
      />
      <path
        d="M478.18 1014.98L479.15 500.42"
        stroke="#940731"
        stroke-miterlimit="10"
      />
      <path
        d="M891 484.12V132.5C891 101.3 865.7 76 834.5 76C803.3 76 778 101.3 778 132.5V484.12C744.23 503.66 721.5 540.17 721.5 582C721.5 644.41 772.09 695 834.5 695C896.91 695 947.5 644.41 947.5 582C947.5 540.17 924.78 503.66 891 484.12Z"
        fill="#71CFEC"
      />
      <path
        d="M834.5 665C788.73 665 751.5 627.77 751.5 582C751.5 552.46 767.41 524.91 793.02 510.09L808 501.43V132.5C808 117.89 819.89 106 834.5 106C849.11 106 861 117.89 861 132.5V501.42L875.98 510.09C901.59 524.91 917.5 552.46 917.5 582C917.5 627.77 880.27 665 834.5 665Z"
        fill="#BBE5F0"
      />
      <path
        d="M834.75 623.43C858.183 623.43 877.18 604.434 877.18 581C877.18 557.567 858.183 538.57 834.75 538.57C811.316 538.57 792.32 557.567 792.32 581C792.32 604.434 811.316 623.43 834.75 623.43Z"
        fill="#CD163F"
      />
      <path
        d="M834.75 581V141.66"
        stroke="#CD163F"
        stroke-width="26"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M847.12 477.74H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 437.86H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 397.99H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 358.11H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 318.24H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 278.36H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 238.49H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 198.61H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M847.12 158.74H874.18"
        stroke="#71CFEC"
        stroke-width="16"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.3"
        d="M1867.96 256.46L1728.6 175.99L1693.46 155.71L1658.33 135.43L1623.2 115.14L1588.08 94.8701L1552.95 74.5801L1517.82 54.3L1482.69 34.0201L1447.55 13.73L1412.43 -6.53992L1377.3 -26.83L1342.17 -47.1099L1307.04 -67.39L1271.91 -87.6799L1236.77 -107.96L1201.65 -128.24L1166.52 -148.52L1131.39 -168.8L1096.26 -189.09L1061.13 -209.37L1025.99 -229.65L990.87 -249.93L955.74 -270.21L920.61 -290.5L885.48 -310.78L850.35 -331.06L815.21 -351.35L780.09 -371.62L744.96 -391.91L709.83 -412.19L674.7 -432.47L639.57 -452.76L604.44 -473.04L569.32 -493.32L534.18 -513.6L499.05 -533.88L463.92 -554.17L428.79 -574.45L393.67 -594.73L358.54 -615.01L323.4 -635.29L288.27 -655.58L148.91 -736.04L288.27 -655.58L323.4 -635.29L358.54 -615.01L393.67 -594.73L428.79 -574.45L463.92 -554.17L499.05 -533.88L534.18 -513.6L569.32 -493.32L604.44 -473.04L639.57 -452.76L674.7 -432.47L709.83 -412.19L744.96 -391.91L780.09 -371.62L815.21 -351.35L850.35 -331.06L885.48 -310.78L920.61 -290.5L955.74 -270.21L990.87 -249.93L1025.99 -229.65L1061.13 -209.37L1096.26 -189.09L1131.39 -168.8L1166.52 -148.52L1201.65 -128.24L1236.77 -107.96L1271.91 -87.6799L1307.04 -67.39L1342.17 -47.1099L1377.3 -26.83L1412.43 -6.53992L1447.55 13.73L1482.69 34.0201L1517.82 54.3L1552.95 74.5801L1588.08 94.8701L1623.2 115.14L1658.33 135.43L1693.46 155.71L1728.6 175.99L1867.96 256.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1902.86 236.17L1763.5 155.71L1728.37 135.43L1693.24 115.14L1658.1 94.86L1622.98 74.5801L1587.85 54.3L1552.72 34.0201L1517.59 13.73L1482.46 -6.54993L1447.34 -26.83L1412.2 -47.1099L1377.07 -67.39L1341.94 -87.6799L1306.81 -107.96L1271.68 -128.24L1236.56 -148.52L1201.43 -168.8L1166.29 -189.09L1131.16 -209.37L1096.03 -229.65L1060.9 -249.94L1025.78 -270.21L990.65 -290.5L955.51 -310.78L920.38 -331.06L885.25 -351.35L850.12 -371.63L815 -391.91L779.87 -412.19L744.73 -432.47L709.6 -452.76L674.47 -473.04L639.34 -493.32L604.22 -513.6L569.09 -533.88L533.95 -554.17L498.82 -574.45L463.69 -594.73L428.57 -615.01L393.44 -635.29L358.31 -655.58L323.18 -675.86L183.81 -756.32L323.18 -675.86L358.31 -655.58L393.44 -635.29L428.57 -615.01L463.69 -594.73L498.82 -574.45L533.95 -554.17L569.09 -533.88L604.22 -513.6L639.34 -493.32L674.47 -473.04L709.6 -452.76L744.73 -432.47L779.87 -412.19L815 -391.91L850.12 -371.63L885.25 -351.35L920.38 -331.06L955.51 -310.78L990.65 -290.5L1025.78 -270.21L1060.9 -249.94L1096.03 -229.65L1131.16 -209.37L1166.29 -189.09L1201.43 -168.8L1236.56 -148.52L1271.68 -128.24L1306.81 -107.96L1341.94 -87.6799L1377.07 -67.39L1412.2 -47.1099L1447.34 -26.83L1482.46 -6.54993L1517.59 13.73L1552.72 34.0201L1587.85 54.3L1622.98 74.5801L1658.1 94.86L1693.24 115.14L1728.37 135.43L1763.5 155.71L1902.86 236.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1937.77 215.89L1798.4 135.43L1763.27 115.14L1728.14 94.8601L1693.01 74.5801L1657.89 54.3001L1622.76 34.0201L1587.62 13.7301L1552.49 -6.54987L1517.36 -26.8299L1482.24 -47.1099L1447.11 -67.3899L1411.98 -87.6799L1376.84 -107.96L1341.71 -128.24L1306.58 -148.53L1271.46 -168.8L1236.33 -189.09L1201.2 -209.37L1166.07 -229.65L1130.93 -249.94L1095.8 -270.22L1060.68 -290.5L1025.55 -310.78L990.42 -331.06L955.29 -351.35L920.15 -371.63L885.02 -391.91L849.9 -412.19L814.77 -432.47L779.64 -452.76L744.51 -473.04L709.37 -493.32L674.24 -513.61L639.12 -533.88L603.99 -554.17L568.86 -574.45L533.73 -594.73L498.59 -615.02L463.47 -635.29L428.34 -655.58L393.21 -675.86L358.08 -696.15L218.72 -776.61L358.08 -696.15L393.21 -675.86L428.34 -655.58L463.47 -635.29L498.59 -615.02L533.73 -594.73L568.86 -574.45L603.99 -554.17L639.12 -533.88L674.24 -513.61L709.37 -493.32L744.51 -473.04L779.64 -452.76L814.77 -432.47L849.9 -412.19L885.02 -391.91L920.15 -371.63L955.29 -351.35L990.42 -331.06L1025.55 -310.78L1060.68 -290.5L1095.8 -270.22L1130.93 -249.94L1166.07 -229.65L1201.2 -209.37L1236.33 -189.09L1271.46 -168.8L1306.58 -148.53L1341.71 -128.24L1376.84 -107.96L1411.98 -87.6799L1447.11 -67.3899L1482.24 -47.1099L1517.36 -26.8299L1552.49 -6.54987L1587.62 13.7301L1622.76 34.0201L1657.89 54.3001L1693.01 74.5801L1728.14 94.8601L1763.27 115.14L1798.4 135.43L1937.77 215.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1972.66 195.61L1833.3 115.15L1798.16 94.8701L1763.03 74.5801L1727.9 54.3001L1692.78 34.0201L1657.65 13.7401L1622.52 -6.53986L1587.38 -26.8299L1552.25 -47.1099L1517.13 -67.3899L1482 -87.6699L1446.87 -107.95L1411.74 -128.24L1376.6 -148.52L1341.47 -168.8L1306.35 -189.08L1271.22 -209.36L1236.09 -229.65L1200.96 -249.93L1165.82 -270.21L1130.69 -290.5L1095.57 -310.77L1060.44 -331.06L1025.31 -351.34L990.18 -371.62L955.05 -391.91L919.91 -412.19L884.79 -432.47L849.66 -452.75L814.53 -473.03L779.4 -493.32L744.27 -513.6L709.13 -533.88L674.01 -554.16L638.88 -574.44L603.75 -594.73L568.62 -615.01L533.49 -635.3L498.37 -655.57L463.23 -675.86L428.1 -696.14L392.97 -716.42L253.61 -796.88L392.97 -716.42L428.1 -696.14L463.23 -675.86L498.37 -655.57L533.49 -635.3L568.62 -615.01L603.75 -594.73L638.88 -574.44L674.01 -554.16L709.13 -533.88L744.27 -513.6L779.4 -493.32L814.53 -473.03L849.66 -452.75L884.79 -432.47L919.91 -412.19L955.05 -391.91L990.18 -371.62L1025.31 -351.34L1060.44 -331.06L1095.57 -310.77L1130.69 -290.5L1165.82 -270.21L1200.96 -249.93L1236.09 -229.65L1271.22 -209.36L1306.35 -189.08L1341.47 -168.8L1376.6 -148.52L1411.74 -128.24L1446.87 -107.95L1482 -87.6699L1517.13 -67.3899L1552.25 -47.1099L1587.38 -26.8299L1622.52 -6.53986L1657.65 13.7401L1692.78 34.0201L1727.9 54.3001L1763.03 74.5801L1798.16 94.8701L1833.3 115.15L1972.66 195.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2007.56 175.33L1868.2 94.8701L1833.07 74.5801L1797.94 54.3L1762.8 34.0201L1727.68 13.7401L1692.55 -6.53992L1657.42 -26.83L1622.29 -47.1099L1587.16 -67.39L1552.04 -87.6699L1516.9 -107.95L1481.77 -128.24L1446.64 -148.52L1411.51 -168.8L1376.38 -189.09L1341.26 -209.36L1306.12 -229.65L1270.99 -249.93L1235.86 -270.21L1200.73 -290.5L1165.6 -310.78L1130.48 -331.06L1095.34 -351.34L1060.21 -371.62L1025.08 -391.91L989.95 -412.19L954.82 -432.48L919.7 -452.75L884.57 -473.03L849.43 -493.32L814.3 -513.6L779.17 -533.89L744.04 -554.17L708.92 -574.45L673.79 -594.73L638.65 -615.01L603.52 -635.3L568.39 -655.58L533.27 -675.86L498.14 -696.14L463.01 -716.42L427.87 -736.71L288.51 -817.17L427.87 -736.71L463.01 -716.42L498.14 -696.14L533.27 -675.86L568.39 -655.58L603.52 -635.3L638.65 -615.01L673.79 -594.73L708.92 -574.45L744.04 -554.17L779.17 -533.89L814.3 -513.6L849.43 -493.32L884.57 -473.03L919.7 -452.75L954.82 -432.48L989.95 -412.19L1025.08 -391.91L1060.21 -371.62L1095.34 -351.34L1130.48 -331.06L1165.6 -310.78L1200.73 -290.5L1235.86 -270.21L1270.99 -249.93L1306.12 -229.65L1341.26 -209.36L1376.38 -189.09L1411.51 -168.8L1446.64 -148.52L1481.77 -128.24L1516.9 -107.95L1552.04 -87.6699L1587.16 -67.39L1622.29 -47.1099L1657.42 -26.83L1692.55 -6.53992L1727.68 13.7401L1762.8 34.0201L1797.94 54.3L1833.07 74.5801L1868.2 94.8701L2007.56 175.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2042.47 155.04L1903.1 74.5801L1867.97 54.3L1832.84 34.0201L1797.71 13.73L1762.59 -6.53992L1727.46 -26.83L1692.32 -47.1099L1657.19 -67.39L1622.06 -87.6799L1586.94 -107.95L1551.81 -128.24L1516.68 -148.52L1481.54 -168.8L1446.41 -189.09L1411.28 -209.37L1376.16 -229.65L1341.03 -249.93L1305.9 -270.21L1270.76 -290.5L1235.63 -310.78L1200.5 -331.07L1165.38 -351.34L1130.25 -371.63L1095.12 -391.91L1059.98 -412.19L1024.85 -432.48L989.72 -452.76L954.6 -473.04L919.47 -493.32L884.34 -513.6L849.21 -533.89L814.07 -554.17L778.94 -574.45L743.82 -594.73L708.69 -615.01L673.56 -635.3L638.43 -655.58L603.29 -675.86L568.17 -696.14L533.04 -716.42L497.91 -736.71L462.78 -756.99L323.41 -837.45L462.78 -756.99L497.91 -736.71L533.04 -716.42L568.17 -696.14L603.29 -675.86L638.43 -655.58L673.56 -635.3L708.69 -615.01L743.82 -594.73L778.94 -574.45L814.07 -554.17L849.21 -533.89L884.34 -513.6L919.47 -493.32L954.6 -473.04L989.72 -452.76L1024.85 -432.48L1059.98 -412.19L1095.12 -391.91L1130.25 -371.63L1165.38 -351.34L1200.5 -331.07L1235.63 -310.78L1270.76 -290.5L1305.9 -270.21L1341.03 -249.93L1376.16 -229.65L1411.28 -209.37L1446.41 -189.09L1481.54 -168.8L1516.68 -148.52L1551.81 -128.24L1586.94 -107.95L1622.06 -87.6799L1657.19 -67.39L1692.32 -47.1099L1727.46 -26.83L1762.59 -6.53992L1797.71 13.73L1832.84 34.0201L1867.97 54.3L1903.1 74.5801L2042.47 155.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2077.37 134.76L1938.01 54.3L1902.87 34.02L1867.74 13.73L1832.61 -6.54999L1797.49 -26.83L1762.36 -47.11L1727.23 -67.39L1692.09 -87.68L1656.96 -107.96L1621.84 -128.24L1586.71 -148.52L1551.58 -168.8L1516.45 -189.09L1481.32 -209.37L1446.18 -229.66L1411.06 -249.93L1375.93 -270.22L1340.8 -290.5L1305.67 -310.78L1270.54 -331.07L1235.4 -351.35L1200.28 -371.63L1165.15 -391.91L1130.02 -412.19L1094.89 -432.48L1059.76 -452.76L1024.62 -473.04L989.5 -493.32L954.37 -513.6L919.24 -533.89L884.11 -554.17L848.98 -574.45L813.84 -594.74L778.72 -615.01L743.59 -635.3L708.46 -655.58L673.33 -675.86L638.2 -696.15L603.08 -716.42L567.95 -736.71L532.81 -756.99L497.68 -777.27L358.32 -857.73L497.68 -777.27L532.81 -756.99L567.95 -736.71L603.08 -716.42L638.2 -696.15L673.33 -675.86L708.46 -655.58L743.59 -635.3L778.72 -615.01L813.84 -594.74L848.98 -574.45L884.11 -554.17L919.24 -533.89L954.37 -513.6L989.5 -493.32L1024.62 -473.04L1059.76 -452.76L1094.89 -432.48L1130.02 -412.19L1165.15 -391.91L1200.28 -371.63L1235.4 -351.35L1270.54 -331.07L1305.67 -310.78L1340.8 -290.5L1375.93 -270.22L1411.06 -249.93L1446.18 -229.66L1481.32 -209.37L1516.45 -189.09L1551.58 -168.8L1586.71 -148.52L1621.84 -128.24L1656.96 -107.96L1692.09 -87.68L1727.23 -67.39L1762.36 -47.11L1797.49 -26.83L1832.61 -6.54999L1867.74 13.73L1902.87 34.02L1938.01 54.3L2077.37 134.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2112.27 114.48L1972.91 34.02L1937.78 13.73L1902.65 -6.54999L1867.51 -26.84L1832.39 -47.11L1797.26 -67.4L1762.13 -87.68L1727 -107.96L1691.87 -128.25L1656.75 -148.52L1621.61 -168.81L1586.48 -189.09L1551.35 -209.37L1516.22 -229.66L1481.09 -249.94L1445.97 -270.22L1410.84 -290.5L1375.7 -310.78L1340.57 -331.07L1305.44 -351.35L1270.31 -371.63L1235.19 -391.91L1200.06 -412.19L1164.92 -432.48L1129.79 -452.76L1094.66 -473.04L1059.53 -493.33L1024.41 -513.6L989.28 -533.89L954.14 -554.17L919.01 -574.45L883.88 -594.74L848.75 -615.02L813.63 -635.3L778.5 -655.58L743.36 -675.86L708.23 -696.15L673.1 -716.43L637.98 -736.71L602.85 -756.99L567.72 -777.27L532.58 -797.56L393.22 -878.02L532.58 -797.56L567.72 -777.27L602.85 -756.99L637.98 -736.71L673.1 -716.43L708.23 -696.15L743.36 -675.86L778.5 -655.58L813.63 -635.3L848.75 -615.02L883.88 -594.74L919.01 -574.45L954.14 -554.17L989.28 -533.89L1024.41 -513.6L1059.53 -493.33L1094.66 -473.04L1129.79 -452.76L1164.92 -432.48L1200.06 -412.19L1235.19 -391.91L1270.31 -371.63L1305.44 -351.35L1340.57 -331.07L1375.7 -310.78L1410.84 -290.5L1445.97 -270.22L1481.09 -249.94L1516.22 -229.66L1551.35 -209.37L1586.48 -189.09L1621.61 -168.81L1656.75 -148.52L1691.87 -128.25L1727 -107.96L1762.13 -87.68L1797.26 -67.4L1832.39 -47.11L1867.51 -26.84L1902.65 -6.54999L1937.78 13.73L1972.91 34.02L2112.27 114.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2147.18 94.1901L2007.81 13.73L1972.68 -6.54993L1937.55 -26.84L1902.42 -47.1199L1867.3 -67.4L1832.17 -87.6799L1797.03 -107.96L1761.9 -128.25L1726.77 -148.53L1691.65 -168.81L1656.52 -189.09L1621.39 -209.37L1586.25 -229.66L1551.12 -249.94L1515.99 -270.22L1480.87 -290.5L1445.74 -310.78L1410.61 -331.07L1375.47 -351.35L1340.34 -371.63L1305.21 -391.92L1270.09 -412.19L1234.96 -432.48L1199.83 -452.76L1164.7 -473.04L1129.56 -493.33L1094.43 -513.61L1059.31 -533.89L1024.18 -554.17L989.05 -574.45L953.92 -594.74L918.78 -615.02L883.65 -635.3L848.53 -655.58L813.4 -675.86L778.27 -696.15L743.14 -716.43L708 -736.71L672.88 -756.99L637.75 -777.27L602.62 -797.56L567.49 -817.84L428.13 -898.3L567.49 -817.84L602.62 -797.56L637.75 -777.27L672.88 -756.99L708 -736.71L743.14 -716.43L778.27 -696.15L813.4 -675.86L848.53 -655.58L883.65 -635.3L918.78 -615.02L953.92 -594.74L989.05 -574.45L1024.18 -554.17L1059.31 -533.89L1094.43 -513.61L1129.56 -493.33L1164.7 -473.04L1199.83 -452.76L1234.96 -432.48L1270.09 -412.19L1305.21 -391.92L1340.34 -371.63L1375.47 -351.35L1410.61 -331.07L1445.74 -310.78L1480.87 -290.5L1515.99 -270.22L1551.12 -249.94L1586.25 -229.66L1621.39 -209.37L1656.52 -189.09L1691.65 -168.81L1726.77 -148.53L1761.9 -128.25L1797.03 -107.96L1832.17 -87.6799L1867.3 -67.4L1902.42 -47.1199L1937.55 -26.84L1972.68 -6.54993L2007.81 13.73L2147.18 94.1901Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2182.07 73.92L2042.71 -6.54993L2007.57 -26.83L1972.44 -47.1099L1937.31 -67.4L1902.19 -87.6699L1867.06 -107.96L1831.93 -128.24L1796.79 -148.52L1761.66 -168.81L1726.54 -189.08L1691.41 -209.37L1656.28 -229.65L1621.15 -249.93L1586.01 -270.22L1550.88 -290.5L1515.76 -310.78L1480.63 -331.06L1445.5 -351.34L1410.37 -371.63L1375.23 -391.91L1340.1 -412.19L1304.98 -432.47L1269.85 -452.75L1234.72 -473.04L1199.59 -493.32L1164.46 -513.6L1129.32 -533.89L1094.2 -554.16L1059.07 -574.45L1023.94 -594.73L988.81 -615.01L953.68 -635.3L918.54 -655.58L883.42 -675.86L848.29 -696.14L813.16 -716.42L778.03 -736.71L742.9 -756.99L707.78 -777.27L672.64 -797.55L637.51 -817.83L602.38 -838.12L463.02 -918.58L602.38 -838.12L637.51 -817.83L672.64 -797.55L707.78 -777.27L742.9 -756.99L778.03 -736.71L813.16 -716.42L848.29 -696.14L883.42 -675.86L918.54 -655.58L953.68 -635.3L988.81 -615.01L1023.94 -594.73L1059.07 -574.45L1094.2 -554.16L1129.32 -533.89L1164.46 -513.6L1199.59 -493.32L1234.72 -473.04L1269.85 -452.75L1304.98 -432.47L1340.1 -412.19L1375.23 -391.91L1410.37 -371.63L1445.5 -351.34L1480.63 -331.06L1515.76 -310.78L1550.88 -290.5L1586.01 -270.22L1621.15 -249.93L1656.28 -229.65L1691.41 -209.37L1726.54 -189.08L1761.66 -168.81L1796.79 -148.52L1831.93 -128.24L1867.06 -107.96L1902.19 -87.6699L1937.31 -67.4L1972.44 -47.1099L2007.57 -26.83L2042.71 -6.54993L2182.07 73.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2216.97 53.6301L2077.61 -26.8299L2042.48 -47.1099L2007.34 -67.3999L1972.21 -87.6799L1937.09 -107.96L1901.96 -128.24L1866.83 -148.52L1831.7 -168.81L1796.57 -189.09L1761.45 -209.37L1726.31 -229.65L1691.18 -249.93L1656.05 -270.22L1620.92 -290.5L1585.79 -310.78L1550.67 -331.06L1515.53 -351.34L1480.4 -371.63L1445.27 -391.91L1410.14 -412.19L1375.01 -432.48L1339.89 -452.75L1304.75 -473.04L1269.62 -493.32L1234.49 -513.6L1199.36 -533.89L1164.23 -554.17L1129.11 -574.45L1093.97 -594.73L1058.84 -615.01L1023.71 -635.3L988.58 -655.58L953.45 -675.86L918.33 -696.14L883.2 -716.42L848.06 -736.71L812.93 -756.99L777.8 -777.27L742.68 -797.55L707.55 -817.83L672.42 -838.12L637.28 -858.4L497.92 -938.86L637.28 -858.4L672.42 -838.12L707.55 -817.83L742.68 -797.55L777.8 -777.27L812.93 -756.99L848.06 -736.71L883.2 -716.42L918.33 -696.14L953.45 -675.86L988.58 -655.58L1023.71 -635.3L1058.84 -615.01L1093.97 -594.73L1129.11 -574.45L1164.23 -554.17L1199.36 -533.89L1234.49 -513.6L1269.62 -493.32L1304.75 -473.04L1339.89 -452.75L1375.01 -432.48L1410.14 -412.19L1445.27 -391.91L1480.4 -371.63L1515.53 -351.34L1550.67 -331.06L1585.79 -310.78L1620.92 -290.5L1656.05 -270.22L1691.18 -249.93L1726.31 -229.65L1761.45 -209.37L1796.57 -189.09L1831.7 -168.81L1866.83 -148.52L1901.96 -128.24L1937.09 -107.96L1972.21 -87.6799L2007.34 -67.3999L2042.48 -47.1099L2077.61 -26.8299L2216.97 53.6301Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2251.88 33.3501L2112.51 -47.1099L2077.38 -67.3999L2042.25 -87.6799L2007.12 -107.96L1972 -128.24L1936.86 -148.52L1901.73 -168.81L1866.6 -189.09L1831.47 -209.37L1796.35 -229.65L1761.22 -249.93L1726.09 -270.22L1690.95 -290.5L1655.82 -310.78L1620.69 -331.07L1585.57 -351.34L1550.44 -371.63L1515.31 -391.91L1480.17 -412.19L1445.04 -432.48L1409.91 -452.76L1374.79 -473.04L1339.66 -493.32L1304.53 -513.6L1269.39 -533.89L1234.26 -554.17L1199.13 -574.45L1164.01 -594.73L1128.88 -615.01L1093.75 -635.3L1058.61 -655.58L1023.48 -675.86L988.35 -696.15L953.23 -716.42L918.1 -736.71L882.97 -756.99L847.84 -777.27L812.7 -797.56L777.58 -817.83L742.45 -838.12L707.32 -858.4L672.19 -878.68L532.82 -959.15L672.19 -878.68L707.32 -858.4L742.45 -838.12L777.58 -817.83L812.7 -797.56L847.84 -777.27L882.97 -756.99L918.1 -736.71L953.23 -716.42L988.35 -696.15L1023.48 -675.86L1058.61 -655.58L1093.75 -635.3L1128.88 -615.01L1164.01 -594.73L1199.13 -574.45L1234.26 -554.17L1269.39 -533.89L1304.53 -513.6L1339.66 -493.32L1374.79 -473.04L1409.91 -452.76L1445.04 -432.48L1480.17 -412.19L1515.31 -391.91L1550.44 -371.63L1585.57 -351.34L1620.69 -331.07L1655.82 -310.78L1690.95 -290.5L1726.09 -270.22L1761.22 -249.93L1796.35 -229.65L1831.47 -209.37L1866.6 -189.09L1901.73 -168.81L1936.86 -148.52L1972 -128.24L2007.12 -107.96L2042.25 -87.6799L2077.38 -67.3999L2112.51 -47.1099L2251.88 33.3501Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2286.78 13.0601L2147.42 -67.4L2112.28 -87.6799L2077.15 -107.96L2042.02 -128.25L2006.9 -148.52L1971.77 -168.81L1936.64 -189.09L1901.5 -209.37L1866.37 -229.66L1831.25 -249.93L1796.12 -270.22L1760.99 -290.5L1725.86 -310.78L1690.72 -331.07L1655.59 -351.35L1620.47 -371.63L1585.34 -391.91L1550.21 -412.19L1515.08 -432.48L1479.95 -452.76L1444.81 -473.04L1409.69 -493.32L1374.56 -513.6L1339.43 -533.89L1304.3 -554.17L1269.17 -574.45L1234.03 -594.74L1198.91 -615.01L1163.78 -635.3L1128.65 -655.58L1093.52 -675.86L1058.39 -696.15L1023.25 -716.43L988.13 -736.71L953 -756.99L917.87 -777.28L882.74 -797.56L847.61 -817.84L812.49 -838.12L777.35 -858.4L742.22 -878.69L707.09 -898.97L567.73 -979.43L707.09 -898.97L742.22 -878.69L777.35 -858.4L812.49 -838.12L847.61 -817.84L882.74 -797.56L917.87 -777.28L953 -756.99L988.13 -736.71L1023.25 -716.43L1058.39 -696.15L1093.52 -675.86L1128.65 -655.58L1163.78 -635.3L1198.91 -615.01L1234.03 -594.74L1269.17 -574.45L1304.3 -554.17L1339.43 -533.89L1374.56 -513.6L1409.69 -493.32L1444.81 -473.04L1479.95 -452.76L1515.08 -432.48L1550.21 -412.19L1585.34 -391.91L1620.47 -371.63L1655.59 -351.35L1690.72 -331.07L1725.86 -310.78L1760.99 -290.5L1796.12 -270.22L1831.25 -249.93L1866.37 -229.66L1901.5 -209.37L1936.64 -189.09L1971.77 -168.81L2006.9 -148.52L2042.02 -128.25L2077.15 -107.96L2112.28 -87.6799L2147.42 -67.4L2286.78 13.0601Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_8596">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

/* tuin? */
export const step29Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M505.66 329.54C548.18 354.09 548.4 393.88 506.17 418.42C463.94 442.96 395.24 442.97 352.72 418.42C310.21 393.88 309.99 354.08 352.23 329.54C394.46 305 463.15 305 505.66 329.54Z"
      fill="#00A3DE"
    />
    <path
      d="M536.61 640.39C536.56 656.37 526.03 672.32 504.99 684.52C462.64 709.06 393.75 709.07 351.11 684.52C333.4 674.32 323.02 661.49 319.96 648.22L319.3 880.77C319.25 896.96 329.94 913.17 351.33 925.52C393.85 950.07 462.54 950.06 504.78 925.52C525.76 913.33 536.26 897.37 536.31 881.39L537.7 392.29C537.7 394.03 537.56 395.77 537.3 397.51L536.61 640.39Z"
      fill="#00545E"
    />
    <path
      d="M536.61 622.39C536.56 638.37 526.03 654.32 504.99 666.52C462.64 691.06 393.75 691.07 351.11 666.52C333.4 656.32 323.02 643.49 319.96 630.22L319.3 862.77C319.25 878.96 329.94 895.17 351.33 907.52C393.85 932.07 462.54 932.06 504.78 907.52C525.76 895.33 536.26 879.37 536.31 863.39L537.7 374.29C537.7 376.03 537.56 377.77 537.3 379.51L536.61 622.39Z"
      fill="#71CFEC"
    />
    <path
      d="M351.12 666.52C393.75 691.07 462.64 691.06 505 666.52C526.04 654.33 536.57 638.37 536.62 622.39L537.31 379.51C535.24 393.7 524.87 407.55 506.18 418.42C463.95 442.96 395.25 442.97 352.73 418.42C331.34 406.07 320.66 389.86 320.7 373.67L319.97 630.21C323.03 643.49 333.41 656.32 351.12 666.52Z"
      fill="#00545E"
    />
    <path
      d="M1028.09 463.89L619.77 704.85L51.98 377.03L460.29 136.08L1028.09 463.89Z"
      fill="#00AB84"
    />
    <path
      d="M1028.09 463.89L1028.02 485.79L619.71 726.75L619.77 704.85L1028.09 463.89Z"
      fill="#007B5F"
    />
    <path
      d="M619.77 704.85L619.71 726.75L51.91 398.93L51.98 377.03L619.77 704.85Z"
      fill="#00664F"
    />
    <path
      d="M517.86 322.45C567.16 350.91 567.42 397.05 518.45 425.5C469.48 453.96 389.83 453.96 340.53 425.5C291.24 397.04 290.99 350.9 339.96 322.45C388.92 293.99 468.57 293.99 517.86 322.45Z"
      fill="#00545E"
    />
    <path
      d="M352.23 361.54C394.46 337 463.15 337 505.66 361.54C519.9 369.76 529.39 379.69 534.13 390.19C543.63 369.24 534.12 345.98 505.66 329.54C463.15 305 394.46 305 352.23 329.54C324.14 345.87 314.84 368.94 324.26 389.78C328.95 379.43 338.27 369.65 352.23 361.54Z"
      fill="#00A3DE"
    />
    <path
      d="M352.72 418.42C395.24 442.97 463.93 442.96 506.17 418.42C520.13 410.31 529.45 400.53 534.13 390.19C529.38 379.7 519.9 369.77 505.66 361.54C463.15 337 394.46 337 352.23 361.54C338.27 369.65 328.95 379.44 324.26 389.78C329 400.27 338.49 410.2 352.72 418.42Z"
      fill="#71CFEC"
    />
  </svg>
)

/* verhardetuin? */
export const step30Improvement = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 1080 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_8455)">
      <path
        d="M1028.09 710.48L619.77 951.44L51.98 623.62L460.29 382.67L1028.09 710.48Z"
        fill="#D7D3CC"
      />
      <path
        d="M1028.09 710.48L1028.02 732.38L619.71 973.33L619.77 951.44L1028.09 710.48Z"
        fill="#E8E6E0"
      />
      <path
        d="M619.77 951.44L619.71 973.33L51.91 645.52L51.98 623.62L619.77 951.44Z"
        fill="#B3B4B3"
      />
      <path
        d="M409.18 447.81C420.96 438.69 434.38 429.06 449.19 430.64"
        stroke="#B3B4B3"
        stroke-miterlimit="10"
      />
      <path
        d="M708.25 850.62C702.82 849.18 699.01 852.41 695.27 855.48C685.8 862.84 674.85 866.85 666.32 875.39C664.87 865.16 673.22 856.52 681.31 851.67C684.25 849.88 687.23 848.44 690.35 847.13C694.04 845.44 698.21 844.49 702.24 845.57C704.81 846.24 707.47 848.02 708.25 850.62Z"
        fill="#B3B4B3"
      />
      <path
        d="M419.28 784.33C423.16 779.23 431.07 780.2 435.95 783.31C440.61 786.13 444.14 790.6 447.88 794.35C452.46 798.96 457.31 803.18 462.44 807.17C470.5 813.42 479.07 818.49 487.92 823.8C476.95 824.87 465.95 821.39 456.58 815.8C448.74 811.04 441.88 804.45 436.87 796.78C432.87 790.57 428.18 782.11 419.28 784.33Z"
        fill="#B3B4B3"
      />
      <path
        d="M285.06 694.34C292.12 685.42 306.94 688.57 312.7 697.34C314.65 700.28 315.85 703.85 315.09 707.56C311.71 705.96 309.25 704.77 306.84 703.72C299.56 700.5 292.79 697.41 285.06 694.34Z"
        fill="#B3B4B3"
      />
      <path
        d="M189.22 642.58C190.73 638.55 194.35 635.78 198.19 634.21C209.72 629.77 223.01 634.82 229.96 644.77C222.44 645.99 215.81 644.59 208.89 643.72C202.01 642.81 196.75 642.12 189.22 642.58Z"
        fill="#B3B4B3"
      />
      <path
        d="M215.78 594.31C219.87 585.27 228.72 578.58 237.86 575.1C241.71 573.67 241.63 571.02 243.66 567.53C249.16 556.94 261.37 548.83 273.35 550.2C267.86 553.92 263.47 557.76 259.88 562.09C258.1 564.25 256.54 566.5 255.21 568.83C251.28 575.94 250.51 582.47 241.33 584.93C232.14 587.14 224.76 590.07 215.78 594.31Z"
        fill="#B3B4B3"
      />
      <path
        d="M581.21 876.44C588.11 866.93 603.77 866.6 611 875.91C601.61 881.47 590.79 881.61 581.21 876.44Z"
        fill="#B3B4B3"
      />
      <path
        d="M799.21 687.44C806.11 677.93 821.77 677.6 829 686.91C819.61 692.47 808.79 692.61 799.21 687.44Z"
        fill="#B3B4B3"
      />
      <path
        d="M241.21 613.44C248.11 603.93 263.77 603.6 271 612.91C261.61 618.47 250.79 618.61 241.21 613.44Z"
        fill="#B3B4B3"
      />
      <path
        d="M615.55 808.91C615.28 806.77 616.39 804.47 618.05 802.83C625.15 796.28 637.36 797.63 645.99 799.78C649.52 800.81 653.03 802.12 656.49 804.6C652.82 806.75 649.2 807.73 645.59 808.43C640.34 809.39 634.81 809.36 629.66 808.6C624.88 808.14 620.22 806.37 615.55 808.91Z"
        fill="#B3B4B3"
      />
      <path
        d="M776.29 757.9C780.33 754.11 781.05 749.87 785.4 746.08C793.39 738.8 805.9 739.52 815.48 741.11C820.08 741.83 824.26 742.56 829.55 742.84C822.88 750.44 810.63 750.52 801.71 751.13C797.62 751.4 793.94 751.79 790.2 753.23C786.26 754.67 781.17 758.92 776.29 757.9Z"
        fill="#B3B4B3"
      />
      <path
        d="M870.03 723.79C873.53 710.42 893.44 705.66 902.56 716.09C896.81 718.95 892.38 720.35 886.72 721.74C881.04 723.01 876.45 723.76 870.03 723.79Z"
        fill="#B3B4B3"
      />
      <path
        d="M845.71 776.95C848.95 766.47 862.55 762.29 871.08 769.22C864.58 777.15 855.52 779.86 845.71 776.95Z"
        fill="#B3B4B3"
      />
      <path
        d="M121.75 618.35C124.09 607.69 137.99 603.11 146.16 610.39C139.78 618.08 131.45 620.75 121.75 618.35Z"
        fill="#B3B4B3"
      />
      <path
        d="M336.31 750.71C343.12 739.4 363.34 744.27 364.18 757.48C353.39 756.95 346.15 755.14 336.31 750.71Z"
        fill="#B3B4B3"
      />
      <path
        d="M531.58 471.23L530.96 798.01L769 659.97L767.17 358.11L531.58 471.23Z"
        fill="#B3B4B3"
      />
      <path
        d="M313.27 672.32L530.96 798.01L531.58 471.23L311.43 345.54L313.27 672.32Z"
        fill="#E8E6E0"
      />
      <path
        d="M311.43 345.54L531.58 471.23L658.46 320.41L440.77 194.72L311.43 345.54Z"
        fill="#BBE5F0"
      />
      <path
        d="M658.46 320.41L767.17 358.11L531.58 471.23L658.46 320.41Z"
        fill="#B3B4B3"
      />
      <path
        d="M440.77 194.72L658.46 320.41L767.17 358.11L505.94 207.29L440.77 194.72Z"
        fill="#71CFEC"
      />
      <path
        d="M596.11 760.36L659.32 723.18L659.6 546.73L596.27 584.35L596.11 760.36Z"
        fill="#E8E6E0"
      />
      <mask
        id="mask0_68_8455"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="690"
        y="505"
        width="45"
        height="121"
      >
        <path
          d="M690.67 625.26L690.4 530.8L733.92 505.68L734.19 600.14L690.67 625.26Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_68_8455)">
        <path
          d="M733.92 505.68L690.4 530.8L690.67 625.26L734.19 600.14L733.92 505.68Z"
          fill="#E8E6E0"
        />
      </g>
      <path
        d="M439.7 549.05L483.22 574.18L482.96 668.64L439.43 643.52L439.7 549.05Z"
        fill="#B3B4B3"
      />
      <path
        d="M353.57 498.86L397.09 523.99L396.82 618.45L353.3 593.32L353.57 498.86Z"
        fill="#B3B4B3"
      />
      <path
        d="M311.43 345.54L531.58 471.23L658.46 320.41"
        stroke="#B3B4B3"
        stroke-miterlimit="10"
      />
      <path
        d="M530.96 798.01L531.58 471.23"
        stroke="#B3B4B3"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.3"
        d="M3.81995 208.41L-135.55 127.95L-170.68 107.67L-205.81 87.3799L-240.94 67.0999L-276.06 46.8199L-311.2 26.5399L-346.33 6.25995L-381.46 -14.0301L-416.59 -34.3101L-451.71 -54.5901L-486.84 -74.8701L-521.98 -95.1501L-557.11 -115.44L-592.24 -135.72L-627.37 -156.01L-662.49 -176.28L-697.62 -196.56L-732.75 -216.85L-767.89 -237.13L-803.02 -257.42L-838.15 -277.7L-873.27 -297.98L-908.4 -318.26L-943.53 -338.54L-978.67 -358.83L-1013.8 -379.11L-1048.93 -399.39L-1084.05 -419.67L-1119.18 -439.95L-1154.31 -460.24L-1189.45 -480.52L-1224.58 -500.8L-1259.71 -521.09L-1294.83 -541.36L-1329.96 -561.65L-1365.09 -581.93L-1400.23 -602.21L-1435.36 -622.5L-1470.48 -642.77L-1505.61 -663.06L-1540.74 -683.34L-1575.87 -703.62L-1715.24 -784.08L-1575.87 -703.62L-1540.74 -683.34L-1505.61 -663.06L-1470.48 -642.77L-1435.36 -622.5L-1400.23 -602.21L-1365.09 -581.93L-1329.96 -561.65L-1294.83 -541.36L-1259.71 -521.09L-1224.58 -500.8L-1189.45 -480.52L-1154.31 -460.24L-1119.18 -439.95L-1084.05 -419.67L-1048.93 -399.39L-1013.8 -379.11L-978.67 -358.83L-943.53 -338.54L-908.4 -318.26L-873.27 -297.98L-838.15 -277.7L-803.02 -257.42L-767.89 -237.13L-732.75 -216.85L-697.62 -196.56L-662.49 -176.28L-627.37 -156.01L-592.24 -135.72L-557.11 -115.44L-521.98 -95.1501L-486.84 -74.8701L-451.71 -54.5901L-416.59 -34.3101L-381.46 -14.0301L-346.33 6.25995L-311.2 26.5399L-276.06 46.8199L-240.94 67.0999L-205.81 87.3799L-170.68 107.67L-135.55 127.95L3.81995 208.41Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M38.71 188.13L-100.66 107.67L-135.79 87.39L-170.92 67.11L-206.05 46.82L-241.17 26.55L-276.3 6.26001L-311.44 -14.02L-346.57 -34.3L-381.7 -54.59L-416.82 -74.86L-451.95 -95.15L-487.08 -115.43L-522.22 -135.72L-557.35 -156L-592.48 -176.28L-627.6 -196.56L-662.73 -216.84L-697.86 -237.13L-733 -257.41L-768.13 -277.69L-803.26 -297.98L-838.38 -318.25L-873.51 -338.54L-908.64 -358.82L-943.77 -379.1L-978.91 -399.39L-1014.04 -419.67L-1049.16 -439.95L-1084.29 -460.23L-1119.42 -480.51L-1154.55 -500.8L-1189.69 -521.08L-1224.82 -541.36L-1259.94 -561.64L-1295.07 -581.92L-1330.2 -602.21L-1365.33 -622.49L-1400.47 -642.77L-1435.59 -663.05L-1470.72 -683.33L-1505.85 -703.62L-1540.98 -723.9L-1680.34 -804.36L-1540.98 -723.9L-1505.85 -703.62L-1470.72 -683.33L-1435.59 -663.05L-1400.47 -642.77L-1365.33 -622.49L-1330.2 -602.21L-1295.07 -581.92L-1259.94 -561.64L-1224.82 -541.36L-1189.69 -521.08L-1154.55 -500.8L-1119.42 -480.51L-1084.29 -460.23L-1049.16 -439.95L-1014.04 -419.67L-978.91 -399.39L-943.77 -379.1L-908.64 -358.82L-873.51 -338.54L-838.38 -318.25L-803.26 -297.98L-768.13 -277.69L-733 -257.41L-697.86 -237.13L-662.73 -216.84L-627.6 -196.56L-592.48 -176.28L-557.35 -156L-522.22 -135.72L-487.08 -115.43L-451.95 -95.15L-416.82 -74.86L-381.7 -54.59L-346.57 -34.3L-311.44 -14.02L-276.3 6.26001L-241.17 26.55L-206.05 46.82L-170.92 67.11L-135.79 87.39L-100.66 107.67L38.71 188.13Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M73.61 167.85L-65.75 87.39L-100.88 67.11L-136.02 46.82L-171.15 26.54L-206.27 6.26001L-241.4 -14.02L-276.53 -34.31L-311.66 -54.59L-346.8 -74.87L-381.92 -95.15L-417.05 -115.43L-452.18 -135.72L-487.31 -156L-522.44 -176.28L-557.58 -196.57L-592.7 -216.84L-627.83 -237.13L-662.96 -257.41L-698.09 -277.69L-733.22 -297.98L-768.36 -318.26L-803.48 -338.54L-838.61 -358.82L-873.74 -379.1L-908.87 -399.39L-944 -419.67L-979.13 -439.95L-1014.25 -460.23L-1049.39 -480.51L-1084.52 -500.8L-1119.65 -521.08L-1154.78 -541.36L-1189.91 -561.65L-1225.03 -581.92L-1260.17 -602.21L-1295.3 -622.49L-1330.43 -642.77L-1365.56 -663.06L-1400.68 -683.33L-1435.81 -703.62L-1470.95 -723.9L-1506.08 -744.18L-1645.44 -824.65L-1506.08 -744.18L-1470.95 -723.9L-1435.81 -703.62L-1400.68 -683.33L-1365.56 -663.06L-1330.43 -642.77L-1295.3 -622.49L-1260.17 -602.21L-1225.03 -581.92L-1189.91 -561.65L-1154.78 -541.36L-1119.65 -521.08L-1084.52 -500.8L-1049.39 -480.51L-1014.25 -460.23L-979.13 -439.95L-944 -419.67L-908.87 -399.39L-873.74 -379.1L-838.61 -358.82L-803.48 -338.54L-768.36 -318.26L-733.22 -297.98L-698.09 -277.69L-662.96 -257.41L-627.83 -237.13L-592.7 -216.84L-557.58 -196.57L-522.44 -176.28L-487.31 -156L-452.18 -135.72L-417.05 -115.43L-381.92 -95.15L-346.8 -74.87L-311.66 -54.59L-276.53 -34.31L-241.4 -14.02L-206.27 6.26001L-171.15 26.54L-136.02 46.82L-100.88 67.11L-65.75 87.39L73.61 167.85Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M108.51 147.57L-30.85 67.1L-65.98 46.8201L-101.11 26.54L-136.25 6.25006L-171.36 -14.02L-206.5 -34.3099L-241.63 -54.59L-276.76 -74.8699L-311.89 -95.1599L-347.01 -115.43L-382.14 -135.72L-417.28 -156L-452.41 -176.28L-487.54 -196.57L-522.67 -216.85L-557.79 -237.13L-592.92 -257.41L-628.06 -277.69L-663.19 -297.98L-698.32 -318.26L-733.45 -338.54L-768.57 -358.82L-803.7 -379.1L-838.84 -399.39L-873.97 -419.67L-909.1 -439.95L-944.23 -460.24L-979.35 -480.51L-1014.48 -500.8L-1049.61 -521.08L-1084.75 -541.36L-1119.88 -561.65L-1155.01 -581.93L-1190.13 -602.21L-1225.26 -622.49L-1260.39 -642.77L-1295.53 -663.06L-1330.66 -683.34L-1365.78 -703.62L-1400.91 -723.9L-1436.04 -744.18L-1471.17 -764.47L-1610.54 -844.93L-1471.17 -764.47L-1436.04 -744.18L-1400.91 -723.9L-1365.78 -703.62L-1330.66 -683.34L-1295.53 -663.06L-1260.39 -642.77L-1225.26 -622.49L-1190.13 -602.21L-1155.01 -581.93L-1119.88 -561.65L-1084.75 -541.36L-1049.61 -521.08L-1014.48 -500.8L-979.35 -480.51L-944.23 -460.24L-909.1 -439.95L-873.97 -419.67L-838.84 -399.39L-803.7 -379.1L-768.57 -358.82L-733.45 -338.54L-698.32 -318.26L-663.19 -297.98L-628.06 -277.69L-592.92 -257.41L-557.79 -237.13L-522.67 -216.85L-487.54 -196.57L-452.41 -176.28L-417.28 -156L-382.14 -135.72L-347.01 -115.43L-311.89 -95.1599L-276.76 -74.8699L-241.63 -54.59L-206.5 -34.3099L-171.36 -14.02L-136.25 6.25006L-101.11 26.54L-65.98 46.8201L-30.85 67.1L108.51 147.57Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M143.42 127.28L4.05005 46.8201L-31.08 26.54L-66.21 6.25006L-101.34 -14.03L-136.46 -34.3099L-171.59 -54.59L-206.73 -74.8699L-241.86 -95.1599L-276.99 -115.44L-312.11 -135.72L-347.24 -156L-382.37 -176.28L-417.5 -196.57L-452.64 -216.85L-487.77 -237.13L-522.89 -257.41L-558.02 -277.69L-593.15 -297.98L-628.28 -318.26L-663.42 -338.54L-698.55 -358.83L-733.67 -379.1L-768.8 -399.39L-803.93 -419.67L-839.06 -439.95L-874.2 -460.24L-909.33 -480.52L-944.45 -500.8L-979.58 -521.08L-1014.71 -541.36L-1049.84 -561.65L-1084.98 -581.93L-1120.11 -602.21L-1155.23 -622.49L-1190.36 -642.77L-1225.49 -663.06L-1260.62 -683.34L-1295.75 -703.62L-1330.87 -723.9L-1366.01 -744.18L-1401.14 -764.47L-1436.27 -784.75L-1575.63 -865.21L-1436.27 -784.75L-1401.14 -764.47L-1366.01 -744.18L-1330.87 -723.9L-1295.75 -703.62L-1260.62 -683.34L-1225.49 -663.06L-1190.36 -642.77L-1155.23 -622.49L-1120.11 -602.21L-1084.98 -581.93L-1049.84 -561.65L-1014.71 -541.36L-979.58 -521.08L-944.45 -500.8L-909.33 -480.52L-874.2 -460.24L-839.06 -439.95L-803.93 -419.67L-768.8 -399.39L-733.67 -379.1L-698.55 -358.83L-663.42 -338.54L-628.28 -318.26L-593.15 -297.98L-558.02 -277.69L-522.89 -257.41L-487.77 -237.13L-452.64 -216.85L-417.5 -196.57L-382.37 -176.28L-347.24 -156L-312.11 -135.72L-276.99 -115.44L-241.86 -95.1599L-206.73 -74.8699L-171.59 -54.59L-136.46 -34.3099L-101.34 -14.03L-66.21 6.25006L-31.08 26.54L4.05005 46.8201L143.42 127.28Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M178.32 107L38.96 26.54L3.82996 6.25L-31.3101 -14.03L-66.4399 -34.31L-101.56 -54.59L-136.69 -74.87L-171.82 -95.16L-206.95 -115.44L-242.09 -135.72L-277.21 -156L-312.34 -176.28L-347.47 -196.57L-382.6 -216.85L-417.73 -237.13L-452.86 -257.42L-487.98 -277.69L-523.12 -297.98L-558.25 -318.26L-593.38 -338.54L-628.51 -358.83L-663.64 -379.11L-698.76 -399.39L-733.9 -419.67L-769.03 -439.95L-804.16 -460.24L-839.29 -480.52L-874.42 -500.8L-909.54 -521.08L-944.68 -541.36L-979.81 -561.65L-1014.94 -581.93L-1050.07 -602.21L-1085.2 -622.5L-1120.32 -642.77L-1155.46 -663.06L-1190.59 -683.34L-1225.72 -703.62L-1260.85 -723.91L-1295.97 -744.18L-1331.1 -764.47L-1366.23 -784.75L-1401.37 -805.03L-1540.73 -885.5L-1401.37 -805.03L-1366.23 -784.75L-1331.1 -764.47L-1295.97 -744.18L-1260.85 -723.91L-1225.72 -703.62L-1190.59 -683.34L-1155.46 -663.06L-1120.32 -642.77L-1085.2 -622.5L-1050.07 -602.21L-1014.94 -581.93L-979.81 -561.65L-944.68 -541.36L-909.54 -521.08L-874.42 -500.8L-839.29 -480.52L-804.16 -460.24L-769.03 -439.95L-733.9 -419.67L-698.76 -399.39L-663.64 -379.11L-628.51 -358.83L-593.38 -338.54L-558.25 -318.26L-523.12 -297.98L-487.98 -277.69L-452.86 -257.42L-417.73 -237.13L-382.6 -216.85L-347.47 -196.57L-312.34 -176.28L-277.21 -156L-242.09 -135.72L-206.95 -115.44L-171.82 -95.16L-136.69 -74.87L-101.56 -54.59L-66.4399 -34.31L-31.3101 -14.03L3.82996 6.25L38.96 26.54L178.32 107Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M213.23 86.72L73.86 6.25L38.73 -14.03L3.59998 -34.31L-31.53 -54.6L-66.65 -74.87L-101.79 -95.16L-136.92 -115.44L-172.05 -135.72L-207.18 -156.01L-242.3 -176.28L-277.43 -196.57L-312.57 -216.85L-347.7 -237.13L-382.83 -257.42L-417.96 -277.7L-453.08 -297.98L-488.21 -318.26L-523.35 -338.54L-558.48 -358.83L-593.61 -379.11L-628.74 -399.39L-663.86 -419.67L-698.99 -439.95L-734.12 -460.24L-769.26 -480.52L-804.39 -500.8L-839.52 -521.09L-874.64 -541.36L-909.77 -561.65L-944.9 -581.93L-980.04 -602.21L-1015.17 -622.5L-1050.3 -642.78L-1085.42 -663.06L-1120.55 -683.34L-1155.68 -703.62L-1190.82 -723.91L-1225.95 -744.19L-1261.07 -764.47L-1296.2 -784.75L-1331.33 -805.04L-1366.46 -825.32L-1505.83 -905.78L-1366.46 -825.32L-1331.33 -805.04L-1296.2 -784.75L-1261.07 -764.47L-1225.95 -744.19L-1190.82 -723.91L-1155.68 -703.62L-1120.55 -683.34L-1085.42 -663.06L-1050.3 -642.78L-1015.17 -622.5L-980.04 -602.21L-944.9 -581.93L-909.77 -561.65L-874.64 -541.36L-839.52 -521.09L-804.39 -500.8L-769.26 -480.52L-734.12 -460.24L-698.99 -439.95L-663.86 -419.67L-628.74 -399.39L-593.61 -379.11L-558.48 -358.83L-523.35 -338.54L-488.21 -318.26L-453.08 -297.98L-417.96 -277.7L-382.83 -257.42L-347.7 -237.13L-312.57 -216.85L-277.43 -196.57L-242.3 -176.28L-207.18 -156.01L-172.05 -135.72L-136.92 -115.44L-101.79 -95.16L-66.65 -74.87L-31.53 -54.6L3.59998 -34.31L38.73 -14.03L73.86 6.25L213.23 86.72Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M248.12 66.4399L108.75 -14.0201L73.62 -34.3101L38.49 -54.5901L3.35999 -74.8701L-31.76 -95.1501L-66.89 -115.43L-102.03 -135.72L-137.16 -156L-172.29 -176.28L-207.41 -196.56L-242.54 -216.84L-277.67 -237.13L-312.81 -257.41L-347.94 -277.69L-383.07 -297.98L-418.19 -318.25L-453.32 -338.54L-488.45 -358.82L-523.59 -379.1L-558.72 -399.39L-593.85 -419.67L-628.97 -439.95L-664.1 -460.23L-699.23 -480.51L-734.36 -500.8L-769.5 -521.08L-804.63 -541.36L-839.75 -561.64L-874.88 -581.92L-910.01 -602.21L-945.14 -622.49L-980.28 -642.77L-1015.41 -663.06L-1050.53 -683.33L-1085.66 -703.62L-1120.79 -723.9L-1155.92 -744.19L-1191.06 -764.47L-1226.18 -784.75L-1261.31 -805.03L-1296.44 -825.31L-1331.57 -845.6L-1470.94 -926.06L-1331.57 -845.6L-1296.44 -825.31L-1261.31 -805.03L-1226.18 -784.75L-1191.06 -764.47L-1155.92 -744.19L-1120.79 -723.9L-1085.66 -703.62L-1050.53 -683.33L-1015.41 -663.06L-980.28 -642.77L-945.14 -622.49L-910.01 -602.21L-874.88 -581.92L-839.75 -561.64L-804.63 -541.36L-769.5 -521.08L-734.36 -500.8L-699.23 -480.51L-664.1 -460.23L-628.97 -439.95L-593.85 -419.67L-558.72 -399.39L-523.59 -379.1L-488.45 -358.82L-453.32 -338.54L-418.19 -318.25L-383.07 -297.98L-347.94 -277.69L-312.81 -257.41L-277.67 -237.13L-242.54 -216.84L-207.41 -196.56L-172.29 -176.28L-137.16 -156L-102.03 -135.72L-66.89 -115.43L-31.76 -95.1501L3.35999 -74.8701L38.49 -54.5901L73.62 -34.3101L108.75 -14.0201L248.12 66.4399Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M283.02 46.15L143.66 -34.3101L108.52 -54.5901L73.39 -74.8701L38.26 -95.16L3.14001 -115.43L-31.99 -135.72L-67.12 -156L-102.25 -176.28L-137.39 -196.57L-172.51 -216.84L-207.64 -237.13L-242.77 -257.41L-277.9 -277.69L-313.03 -297.98L-348.17 -318.26L-383.29 -338.54L-418.42 -358.82L-453.55 -379.1L-488.68 -399.39L-523.81 -419.67L-558.95 -439.95L-594.07 -460.23L-629.2 -480.51L-664.33 -500.8L-699.46 -521.08L-734.59 -541.37L-769.73 -561.65L-804.85 -581.92L-839.98 -602.21L-875.11 -622.49L-910.24 -642.78L-945.37 -663.06L-980.5 -683.34L-1015.62 -703.62L-1050.76 -723.9L-1085.89 -744.19L-1121.02 -764.47L-1156.15 -784.75L-1191.27 -805.03L-1226.4 -825.31L-1261.54 -845.6L-1296.67 -865.88L-1436.03 -946.34L-1296.67 -865.88L-1261.54 -845.6L-1226.4 -825.31L-1191.27 -805.03L-1156.15 -784.75L-1121.02 -764.47L-1085.89 -744.19L-1050.76 -723.9L-1015.62 -703.62L-980.5 -683.34L-945.37 -663.06L-910.24 -642.78L-875.11 -622.49L-839.98 -602.21L-804.85 -581.92L-769.73 -561.65L-734.59 -541.37L-699.46 -521.08L-664.33 -500.8L-629.2 -480.51L-594.07 -460.23L-558.95 -439.95L-523.81 -419.67L-488.68 -399.39L-453.55 -379.1L-418.42 -358.82L-383.29 -338.54L-348.17 -318.26L-313.03 -297.98L-277.9 -277.69L-242.77 -257.41L-207.64 -237.13L-172.51 -216.84L-137.39 -196.57L-102.25 -176.28L-67.12 -156L-31.99 -135.72L3.14001 -115.43L38.26 -95.16L73.39 -74.8701L108.52 -54.5901L143.66 -34.3101L283.02 46.15Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M317.92 25.87L178.56 -54.59L143.43 -74.87L108.3 -95.16L73.16 -115.44L38.04 -135.72L2.91003 -156L-32.22 -176.28L-67.35 -196.57L-102.48 -216.85L-137.6 -237.13L-172.73 -257.41L-207.87 -277.69L-243 -297.98L-278.13 -318.26L-313.26 -338.54L-348.38 -358.82L-383.51 -379.1L-418.65 -399.39L-453.78 -419.67L-488.91 -439.96L-524.04 -460.24L-559.16 -480.52L-594.29 -500.8L-629.43 -521.08L-664.56 -541.37L-699.69 -561.65L-734.82 -581.93L-769.94 -602.21L-805.07 -622.49L-840.21 -642.78L-875.34 -663.06L-910.47 -683.34L-945.6 -703.63L-980.72 -723.9L-1015.85 -744.19L-1050.98 -764.47L-1086.12 -784.75L-1121.25 -805.04L-1156.37 -825.31L-1191.5 -845.6L-1226.63 -865.88L-1261.76 -886.16L-1401.13 -966.62L-1261.76 -886.16L-1226.63 -865.88L-1191.5 -845.6L-1156.37 -825.31L-1121.25 -805.04L-1086.12 -784.75L-1050.98 -764.47L-1015.85 -744.19L-980.72 -723.9L-945.6 -703.63L-910.47 -683.34L-875.34 -663.06L-840.21 -642.78L-805.07 -622.49L-769.94 -602.21L-734.82 -581.93L-699.69 -561.65L-664.56 -541.37L-629.43 -521.08L-594.29 -500.8L-559.16 -480.52L-524.04 -460.24L-488.91 -439.96L-453.78 -419.67L-418.65 -399.39L-383.51 -379.1L-348.38 -358.82L-313.26 -338.54L-278.13 -318.26L-243 -297.98L-207.87 -277.69L-172.73 -257.41L-137.6 -237.13L-102.48 -216.85L-67.35 -196.57L-32.22 -176.28L2.91003 -156L38.04 -135.72L73.16 -115.44L108.3 -95.16L143.43 -74.87L178.56 -54.59L317.92 25.87Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M1974.96 929.46L1835.6 848.99L1800.46 828.71L1765.33 808.43L1730.2 788.14L1695.08 767.87L1659.95 747.58L1624.82 727.3L1589.69 707.02L1554.55 686.73L1519.43 666.46L1484.3 646.17L1449.17 625.89L1414.04 605.61L1378.91 585.32L1343.77 565.04L1308.65 544.76L1273.52 524.48L1238.39 504.2L1203.26 483.91L1168.13 463.63L1132.99 443.35L1097.87 423.07L1062.74 402.79L1027.61 382.5L992.48 362.22L957.35 341.94L922.21 321.65L887.09 301.38L851.96 281.09L816.83 260.81L781.7 240.53L746.57 220.24L711.44 199.96L676.32 179.68L641.18 159.4L606.05 139.12L570.92 118.83L535.79 98.55L500.67 78.27L465.54 57.99L430.4 37.71L395.27 17.42L255.91 -63.04L395.27 17.42L430.4 37.71L465.54 57.99L500.67 78.27L535.79 98.55L570.92 118.83L606.05 139.12L641.18 159.4L676.32 179.68L711.44 199.96L746.57 220.24L781.7 240.53L816.83 260.81L851.96 281.09L887.09 301.38L922.21 321.65L957.35 341.94L992.48 362.22L1027.61 382.5L1062.74 402.79L1097.87 423.07L1132.99 443.35L1168.13 463.63L1203.26 483.91L1238.39 504.2L1273.52 524.48L1308.65 544.76L1343.77 565.04L1378.91 585.32L1414.04 605.61L1449.17 625.89L1484.3 646.17L1519.43 666.46L1554.55 686.73L1589.69 707.02L1624.82 727.3L1659.95 747.58L1695.08 767.87L1730.2 788.14L1765.33 808.43L1800.46 828.71L1835.6 848.99L1974.96 929.46Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2009.86 909.17L1870.5 828.71L1835.37 808.43L1800.24 788.14L1765.1 767.86L1729.98 747.58L1694.85 727.3L1659.72 707.02L1624.59 686.73L1589.46 666.45L1554.34 646.17L1519.2 625.89L1484.07 605.61L1448.94 585.32L1413.81 565.04L1378.68 544.76L1343.56 524.48L1308.43 504.2L1273.29 483.91L1238.16 463.63L1203.03 443.35L1167.9 423.06L1132.78 402.79L1097.65 382.5L1062.51 362.22L1027.38 341.94L992.25 321.65L957.12 301.37L922 281.09L886.87 260.81L851.73 240.53L816.6 220.24L781.47 199.96L746.34 179.68L711.22 159.4L676.09 139.12L640.95 118.83L605.82 98.5499L570.69 78.2699L535.57 57.9899L500.44 37.7099L465.31 17.4199L430.18 -2.86007L290.81 -83.3201L430.18 -2.86007L465.31 17.4199L500.44 37.7099L535.57 57.9899L570.69 78.2699L605.82 98.5499L640.95 118.83L676.09 139.12L711.22 159.4L746.34 179.68L781.47 199.96L816.6 220.24L851.73 240.53L886.87 260.81L922 281.09L957.12 301.37L992.25 321.65L1027.38 341.94L1062.51 362.22L1097.65 382.5L1132.78 402.79L1167.9 423.06L1203.03 443.35L1238.16 463.63L1273.29 483.91L1308.43 504.2L1343.56 524.48L1378.68 544.76L1413.81 565.04L1448.94 585.32L1484.07 605.61L1519.2 625.89L1554.34 646.17L1589.46 666.45L1624.59 686.73L1659.72 707.02L1694.85 727.3L1729.98 747.58L1765.1 767.86L1800.24 788.14L1835.37 808.43L1870.5 828.71L2009.86 909.17Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2044.77 888.89L1905.4 808.43L1870.27 788.14L1835.14 767.86L1800.01 747.58L1764.89 727.3L1729.76 707.02L1694.62 686.73L1659.49 666.45L1624.36 646.17L1589.24 625.89L1554.11 605.61L1518.98 585.32L1483.84 565.04L1448.71 544.76L1413.58 524.47L1378.46 504.2L1343.33 483.91L1308.2 463.63L1273.07 443.35L1237.93 423.06L1202.8 402.78L1167.68 382.5L1132.55 362.22L1097.42 341.94L1062.29 321.65L1027.15 301.37L992.02 281.09L956.9 260.81L921.77 240.53L886.64 220.24L851.51 199.96L816.37 179.68L781.24 159.39L746.12 139.12L710.99 118.83L675.86 98.5499L640.73 78.2699L605.59 57.9799L570.47 37.7099L535.34 17.4199L500.21 -2.86011L465.08 -23.1501L325.72 -103.61L465.08 -23.1501L500.21 -2.86011L535.34 17.4199L570.47 37.7099L605.59 57.9799L640.73 78.2699L675.86 98.5499L710.99 118.83L746.12 139.12L781.24 159.39L816.37 179.68L851.51 199.96L886.64 220.24L921.77 240.53L956.9 260.81L992.02 281.09L1027.15 301.37L1062.29 321.65L1097.42 341.94L1132.55 362.22L1167.68 382.5L1202.8 402.78L1237.93 423.06L1273.07 443.35L1308.2 463.63L1343.33 483.91L1378.46 504.2L1413.58 524.47L1448.71 544.76L1483.84 565.04L1518.98 585.32L1554.11 605.61L1589.24 625.89L1624.36 646.17L1659.49 666.45L1694.62 686.73L1729.76 707.02L1764.89 727.3L1800.01 747.58L1835.14 767.86L1870.27 788.14L1905.4 808.43L2044.77 888.89Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2079.66 868.61L1940.3 788.15L1905.16 767.87L1870.03 747.58L1834.9 727.3L1799.78 707.02L1764.65 686.74L1729.52 666.46L1694.38 646.17L1659.25 625.89L1624.13 605.61L1589 585.33L1553.87 565.05L1518.74 544.76L1483.6 524.48L1448.47 504.2L1413.35 483.92L1378.22 463.64L1343.09 443.35L1307.96 423.07L1272.82 402.79L1237.69 382.5L1202.57 362.23L1167.44 341.94L1132.31 321.66L1097.18 301.38L1062.05 281.09L1026.91 260.81L991.79 240.53L956.66 220.25L921.53 199.97L886.4 179.68L851.27 159.4L816.13 139.12L781.01 118.84L745.88 98.5599L710.75 78.2699L675.62 57.9899L640.49 37.6999L605.37 17.4299L570.23 -2.86013L535.1 -23.1401L499.97 -43.4201L360.61 -123.88L499.97 -43.4201L535.1 -23.1401L570.23 -2.86013L605.37 17.4299L640.49 37.6999L675.62 57.9899L710.75 78.2699L745.88 98.5599L781.01 118.84L816.13 139.12L851.27 159.4L886.4 179.68L921.53 199.97L956.66 220.25L991.79 240.53L1026.91 260.81L1062.05 281.09L1097.18 301.38L1132.31 321.66L1167.44 341.94L1202.57 362.23L1237.69 382.5L1272.82 402.79L1307.96 423.07L1343.09 443.35L1378.22 463.64L1413.35 483.92L1448.47 504.2L1483.6 524.48L1518.74 544.76L1553.87 565.05L1589 585.33L1624.13 605.61L1659.25 625.89L1694.38 646.17L1729.52 666.46L1764.65 686.74L1799.78 707.02L1834.9 727.3L1870.03 747.58L1905.16 767.87L1940.3 788.15L2079.66 868.61Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2114.56 848.33L1975.2 767.87L1940.07 747.58L1904.94 727.3L1869.8 707.02L1834.68 686.74L1799.55 666.46L1764.42 646.17L1729.29 625.89L1694.16 605.61L1659.04 585.33L1623.9 565.05L1588.77 544.76L1553.64 524.48L1518.51 504.2L1483.38 483.91L1448.26 463.64L1413.12 443.35L1377.99 423.07L1342.86 402.79L1307.73 382.5L1272.6 362.22L1237.48 341.94L1202.34 321.66L1167.21 301.38L1132.08 281.09L1096.95 260.81L1061.82 240.52L1026.7 220.25L991.57 199.97L956.43 179.68L921.3 159.4L886.17 139.11L851.04 118.83L815.92 98.5501L780.79 78.2701L745.65 57.9901L710.52 37.7001L675.39 17.4201L640.27 -2.85992L605.14 -23.1399L570.01 -43.4199L534.87 -63.7099L395.51 -144.17L534.87 -63.7099L570.01 -43.4199L605.14 -23.1399L640.27 -2.85992L675.39 17.4201L710.52 37.7001L745.65 57.9901L780.79 78.2701L815.92 98.5501L851.04 118.83L886.17 139.11L921.3 159.4L956.43 179.68L991.57 199.97L1026.7 220.25L1061.82 240.52L1096.95 260.81L1132.08 281.09L1167.21 301.38L1202.34 321.66L1237.48 341.94L1272.6 362.22L1307.73 382.5L1342.86 402.79L1377.99 423.07L1413.12 443.35L1448.26 463.64L1483.38 483.91L1518.51 504.2L1553.64 524.48L1588.77 544.76L1623.9 565.05L1659.04 585.33L1694.16 605.61L1729.29 625.89L1764.42 646.17L1799.55 666.46L1834.68 686.74L1869.8 707.02L1904.94 727.3L1940.07 747.58L1975.2 767.87L2114.56 848.33Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2149.47 828.04L2010.1 747.58L1974.97 727.3L1939.84 707.02L1904.71 686.73L1869.59 666.46L1834.46 646.17L1799.32 625.89L1764.19 605.61L1729.06 585.32L1693.94 565.05L1658.81 544.76L1623.68 524.48L1588.54 504.2L1553.41 483.91L1518.28 463.63L1483.16 443.35L1448.03 423.07L1412.9 402.79L1377.76 382.5L1342.63 362.22L1307.5 341.93L1272.38 321.66L1237.25 301.37L1202.12 281.09L1166.98 260.81L1131.85 240.52L1096.72 220.24L1061.6 199.96L1026.47 179.68L991.34 159.4L956.21 139.11L921.07 118.83L885.94 98.5498L850.82 78.2698L815.69 57.9898L780.56 37.6998L745.43 17.4198L710.29 -2.8602L675.17 -23.1402L640.04 -43.4202L604.91 -63.7102L569.78 -83.9902L430.41 -164.45L569.78 -83.9902L604.91 -63.7102L640.04 -43.4202L675.17 -23.1402L710.29 -2.8602L745.43 17.4198L780.56 37.6998L815.69 57.9898L850.82 78.2698L885.94 98.5498L921.07 118.83L956.21 139.11L991.34 159.4L1026.47 179.68L1061.6 199.96L1096.72 220.24L1131.85 240.52L1166.98 260.81L1202.12 281.09L1237.25 301.37L1272.38 321.66L1307.5 341.93L1342.63 362.22L1377.76 382.5L1412.9 402.79L1448.03 423.07L1483.16 443.35L1518.28 463.63L1553.41 483.91L1588.54 504.2L1623.68 524.48L1658.81 544.76L1693.94 565.05L1729.06 585.32L1764.19 605.61L1799.32 625.89L1834.46 646.17L1869.59 666.46L1904.71 686.73L1939.84 707.02L1974.97 727.3L2010.1 747.58L2149.47 828.04Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2184.37 807.76L2045.01 727.3L2009.87 707.02L1974.74 686.73L1939.61 666.45L1904.49 646.17L1869.36 625.89L1834.23 605.61L1799.09 585.32L1763.96 565.04L1728.84 544.76L1693.71 524.48L1658.58 504.2L1623.45 483.91L1588.32 463.63L1553.18 443.34L1518.06 423.07L1482.93 402.78L1447.8 382.5L1412.67 362.22L1377.54 341.93L1342.4 321.65L1307.28 301.37L1272.15 281.09L1237.02 260.81L1201.89 240.52L1166.76 220.24L1131.62 199.96L1096.5 179.68L1061.37 159.4L1026.24 139.11L991.11 118.83L955.98 98.55L920.84 78.26L885.72 57.99L850.59 37.7L815.46 17.42L780.33 -2.85999L745.2 -23.15L710.08 -43.42L674.95 -63.71L639.81 -83.99L604.68 -104.27L465.32 -184.73L604.68 -104.27L639.81 -83.99L674.95 -63.71L710.08 -43.42L745.2 -23.15L780.33 -2.85999L815.46 17.42L850.59 37.7L885.72 57.99L920.84 78.26L955.98 98.55L991.11 118.83L1026.24 139.11L1061.37 159.4L1096.5 179.68L1131.62 199.96L1166.76 220.24L1201.89 240.52L1237.02 260.81L1272.15 281.09L1307.28 301.37L1342.4 321.65L1377.54 341.93L1412.67 362.22L1447.8 382.5L1482.93 402.78L1518.06 423.07L1553.18 443.34L1588.32 463.63L1623.45 483.91L1658.58 504.2L1693.71 524.48L1728.84 544.76L1763.96 565.04L1799.09 585.32L1834.23 605.61L1869.36 625.89L1904.49 646.17L1939.61 666.45L1974.74 686.73L2009.87 707.02L2045.01 727.3L2184.37 807.76Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2219.27 787.48L2079.91 707.02L2044.78 686.73L2009.65 666.45L1974.51 646.16L1939.39 625.89L1904.26 605.6L1869.13 585.32L1834 565.04L1798.87 544.75L1763.75 524.48L1728.61 504.19L1693.48 483.91L1658.35 463.63L1623.22 443.34L1588.09 423.06L1552.97 402.78L1517.84 382.5L1482.7 362.22L1447.57 341.93L1412.44 321.65L1377.31 301.37L1342.19 281.09L1307.06 260.81L1271.92 240.52L1236.79 220.24L1201.66 199.96L1166.53 179.67L1131.41 159.4L1096.28 139.11L1061.14 118.83L1026.01 98.55L990.88 78.26L955.75 57.98L920.63 37.7L885.5 17.42L850.36 -2.86002L815.23 -23.15L780.1 -43.43L744.98 -63.71L709.85 -83.99L674.72 -104.27L639.58 -124.56L500.22 -205.02L639.58 -124.56L674.72 -104.27L709.85 -83.99L744.98 -63.71L780.1 -43.43L815.23 -23.15L850.36 -2.86002L885.5 17.42L920.63 37.7L955.75 57.98L990.88 78.26L1026.01 98.55L1061.14 118.83L1096.28 139.11L1131.41 159.4L1166.53 179.67L1201.66 199.96L1236.79 220.24L1271.92 240.52L1307.06 260.81L1342.19 281.09L1377.31 301.37L1412.44 321.65L1447.57 341.93L1482.7 362.22L1517.84 382.5L1552.97 402.78L1588.09 423.06L1623.22 443.34L1658.35 463.63L1693.48 483.91L1728.61 504.19L1763.75 524.48L1798.87 544.75L1834 565.04L1869.13 585.32L1904.26 605.6L1939.39 625.89L1974.51 646.16L2009.65 666.45L2044.78 686.73L2079.91 707.02L2219.27 787.48Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2254.18 767.19L2114.81 686.73L2079.68 666.45L2044.55 646.16L2009.42 625.88L1974.3 605.6L1939.17 585.32L1904.03 565.04L1868.9 544.75L1833.77 524.47L1798.65 504.19L1763.52 483.91L1728.39 463.63L1693.25 443.34L1658.12 423.06L1622.99 402.78L1587.87 382.5L1552.74 362.22L1517.61 341.93L1482.47 321.65L1447.34 301.37L1412.21 281.08L1377.09 260.81L1341.96 240.52L1306.83 220.24L1271.7 199.96L1236.56 179.67L1201.43 159.39L1166.31 139.11L1131.18 118.83L1096.05 98.55L1060.92 78.2599L1025.78 57.9799L990.65 37.7L955.53 17.42L920.4 -2.86005L885.27 -23.15L850.14 -43.4301L815 -63.7101L779.88 -83.9901L744.75 -104.27L709.62 -124.56L674.49 -144.84L535.13 -225.3L674.49 -144.84L709.62 -124.56L744.75 -104.27L779.88 -83.9901L815 -63.7101L850.14 -43.4301L885.27 -23.15L920.4 -2.86005L955.53 17.42L990.65 37.7L1025.78 57.9799L1060.92 78.2599L1096.05 98.55L1131.18 118.83L1166.31 139.11L1201.43 159.39L1236.56 179.67L1271.7 199.96L1306.83 220.24L1341.96 240.52L1377.09 260.81L1412.21 281.08L1447.34 301.37L1482.47 321.65L1517.61 341.93L1552.74 362.22L1587.87 382.5L1622.99 402.78L1658.12 423.06L1693.25 443.34L1728.39 463.63L1763.52 483.91L1798.65 504.19L1833.77 524.47L1868.9 544.75L1904.03 565.04L1939.17 585.32L1974.3 605.6L2009.42 625.88L2044.55 646.16L2079.68 666.45L2114.81 686.73L2254.18 767.19Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2289.07 746.92L2149.71 666.45L2114.57 646.17L2079.44 625.89L2044.31 605.6L2009.19 585.33L1974.06 565.04L1938.93 544.76L1903.79 524.48L1868.66 504.19L1833.54 483.92L1798.41 463.63L1763.28 443.35L1728.15 423.07L1693.01 402.78L1657.88 382.5L1622.76 362.22L1587.63 341.94L1552.5 321.66L1517.37 301.37L1482.23 281.09L1447.1 260.81L1411.98 240.53L1376.85 220.25L1341.72 199.96L1306.59 179.68L1271.46 159.4L1236.32 139.11L1201.2 118.84L1166.07 98.5499L1130.94 78.2699L1095.81 57.9899L1060.68 37.6999L1025.54 17.4199L990.42 -2.86008L955.29 -23.1401L920.16 -43.4201L885.03 -63.7101L849.9 -83.9901L814.78 -104.27L779.64 -124.55L744.51 -144.83L709.38 -165.12L570.02 -245.58L709.38 -165.12L744.51 -144.83L779.64 -124.55L814.78 -104.27L849.9 -83.9901L885.03 -63.7101L920.16 -43.4201L955.29 -23.1401L990.42 -2.86008L1025.54 17.4199L1060.68 37.6999L1095.81 57.9899L1130.94 78.2699L1166.07 98.5499L1201.2 118.84L1236.32 139.11L1271.46 159.4L1306.59 179.68L1341.72 199.96L1376.85 220.25L1411.98 240.53L1447.1 260.81L1482.23 281.09L1517.37 301.37L1552.5 321.66L1587.63 341.94L1622.76 362.22L1657.88 382.5L1693.01 402.78L1728.15 423.07L1763.28 443.35L1798.41 463.63L1833.54 483.92L1868.66 504.19L1903.79 524.48L1938.93 544.76L1974.06 565.04L2009.19 585.33L2044.31 605.6L2079.44 625.89L2114.57 646.17L2149.71 666.45L2289.07 746.92Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2323.97 726.63L2184.61 646.17L2149.48 625.89L2114.34 605.6L2079.21 585.32L2044.09 565.04L2008.96 544.76L1973.83 524.48L1938.7 504.19L1903.57 483.91L1868.45 463.63L1833.31 443.35L1798.18 423.07L1763.05 402.78L1727.92 382.5L1692.79 362.22L1657.67 341.94L1622.53 321.66L1587.4 301.37L1552.27 281.09L1517.14 260.81L1482.01 240.52L1446.89 220.25L1411.75 199.96L1376.62 179.68L1341.49 159.4L1306.36 139.11L1271.23 118.83L1236.11 98.5499L1200.97 78.2699L1165.84 57.9899L1130.71 37.6999L1095.58 17.4199L1060.45 -2.86011L1025.33 -23.1401L990.2 -43.4201L955.06 -63.7101L919.93 -83.9901L884.8 -104.27L849.68 -124.55L814.55 -144.83L779.42 -165.12L744.28 -185.4L604.92 -265.86L744.28 -185.4L779.42 -165.12L814.55 -144.83L849.68 -124.55L884.8 -104.27L919.93 -83.9901L955.06 -63.7101L990.2 -43.4201L1025.33 -23.1401L1060.45 -2.86011L1095.58 17.4199L1130.71 37.6999L1165.84 57.9899L1200.97 78.2699L1236.11 98.5499L1271.23 118.83L1306.36 139.11L1341.49 159.4L1376.62 179.68L1411.75 199.96L1446.89 220.25L1482.01 240.52L1517.14 260.81L1552.27 281.09L1587.4 301.37L1622.53 321.66L1657.67 341.94L1692.79 362.22L1727.92 382.5L1763.05 402.78L1798.18 423.07L1833.31 443.35L1868.45 463.63L1903.57 483.91L1938.7 504.19L1973.83 524.48L2008.96 544.76L2044.09 565.04L2079.21 585.32L2114.34 605.6L2149.48 625.89L2184.61 646.17L2323.97 726.63Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2358.88 706.35L2219.51 625.89L2184.38 605.6L2149.25 585.32L2114.12 565.04L2079 544.76L2043.86 524.48L2008.73 504.19L1973.6 483.91L1938.47 463.63L1903.35 443.35L1868.22 423.07L1833.09 402.78L1797.95 382.5L1762.82 362.22L1727.69 341.93L1692.57 321.66L1657.44 301.37L1622.31 281.09L1587.17 260.81L1552.04 240.52L1516.91 220.24L1481.79 199.96L1446.66 179.68L1411.53 159.4L1376.39 139.11L1341.26 118.83L1306.13 98.5501L1271.01 78.2701L1235.88 57.9901L1200.75 37.7001L1165.61 17.4201L1130.48 -2.85989L1095.35 -23.1499L1060.23 -43.4199L1025.1 -63.7099L989.97 -83.9899L954.84 -104.27L919.7 -124.56L884.58 -144.83L849.45 -165.12L814.32 -185.4L779.19 -205.68L639.82 -286.15L779.19 -205.68L814.32 -185.4L849.45 -165.12L884.58 -144.83L919.7 -124.56L954.84 -104.27L989.97 -83.9899L1025.1 -63.7099L1060.23 -43.4199L1095.35 -23.1499L1130.48 -2.85989L1165.61 17.4201L1200.75 37.7001L1235.88 57.9901L1271.01 78.2701L1306.13 98.5501L1341.26 118.83L1376.39 139.11L1411.53 159.4L1446.66 179.68L1481.79 199.96L1516.91 220.24L1552.04 240.52L1587.17 260.81L1622.31 281.09L1657.44 301.37L1692.57 321.66L1727.69 341.93L1762.82 362.22L1797.95 382.5L1833.09 402.78L1868.22 423.07L1903.35 443.35L1938.47 463.63L1973.6 483.91L2008.73 504.19L2043.86 524.48L2079 544.76L2114.12 565.04L2149.25 585.32L2184.38 605.6L2219.51 625.89L2358.88 706.35Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2393.78 686.06L2254.42 605.6L2219.28 585.32L2184.15 565.04L2149.02 544.75L2113.9 524.48L2078.77 504.19L2043.64 483.91L2008.5 463.63L1973.37 443.34L1938.25 423.07L1903.12 402.78L1867.99 382.5L1832.86 362.22L1797.72 341.93L1762.59 321.65L1727.47 301.37L1692.34 281.09L1657.21 260.81L1622.08 240.52L1586.95 220.24L1551.81 199.96L1516.69 179.68L1481.56 159.4L1446.43 139.11L1411.3 118.83L1376.17 98.5498L1341.03 78.2598L1305.91 57.9898L1270.78 37.6998L1235.65 17.4198L1200.52 -2.86017L1165.39 -23.1502L1130.25 -43.4302L1095.13 -63.7102L1060 -83.9902L1024.87 -104.28L989.74 -124.56L954.61 -144.84L919.49 -165.12L884.35 -185.4L849.22 -205.69L814.09 -225.97L674.73 -306.43L814.09 -225.97L849.22 -205.69L884.35 -185.4L919.49 -165.12L954.61 -144.84L989.74 -124.56L1024.87 -104.28L1060 -83.9902L1095.13 -63.7102L1130.25 -43.4302L1165.39 -23.1502L1200.52 -2.86017L1235.65 17.4198L1270.78 37.6998L1305.91 57.9898L1341.03 78.2598L1376.17 98.5498L1411.3 118.83L1446.43 139.11L1481.56 159.4L1516.69 179.68L1551.81 199.96L1586.95 220.24L1622.08 240.52L1657.21 260.81L1692.34 281.09L1727.47 301.37L1762.59 321.65L1797.72 341.93L1832.86 362.22L1867.99 382.5L1903.12 402.78L1938.25 423.07L1973.37 443.34L2008.5 463.63L2043.64 483.91L2078.77 504.19L2113.9 524.48L2149.02 544.75L2184.15 565.04L2219.28 585.32L2254.42 605.6L2393.78 686.06Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2428.68 665.78L2289.32 585.32L2254.19 565.04L2219.06 544.75L2183.92 524.47L2148.8 504.19L2113.67 483.91L2078.54 463.63L2043.41 443.34L2008.28 423.06L1973.16 402.78L1938.02 382.5L1902.89 362.22L1867.76 341.93L1832.63 321.65L1797.5 301.37L1762.38 281.09L1727.24 260.81L1692.11 240.52L1656.98 220.24L1621.85 199.96L1586.72 179.67L1551.6 159.4L1516.47 139.11L1481.33 118.83L1446.2 98.54L1411.07 78.26L1375.94 57.98L1340.82 37.7L1305.69 17.42L1270.55 -2.86996L1235.42 -23.15L1200.29 -43.43L1165.16 -63.72L1130.04 -83.99L1094.91 -104.28L1059.77 -124.56L1024.64 -144.84L989.51 -165.13L954.39 -185.4L919.26 -205.69L884.13 -225.97L848.99 -246.25L709.63 -326.71L848.99 -246.25L884.13 -225.97L919.26 -205.69L954.39 -185.4L989.51 -165.13L1024.64 -144.84L1059.77 -124.56L1094.91 -104.28L1130.04 -83.99L1165.16 -63.72L1200.29 -43.43L1235.42 -23.15L1270.55 -2.86996L1305.69 17.42L1340.82 37.7L1375.94 57.98L1411.07 78.26L1446.2 98.54L1481.33 118.83L1516.47 139.11L1551.6 159.4L1586.72 179.67L1621.85 199.96L1656.98 220.24L1692.11 240.52L1727.24 260.81L1762.38 281.09L1797.5 301.37L1832.63 321.65L1867.76 341.93L1902.89 362.22L1938.02 382.5L1973.16 402.78L2008.28 423.06L2043.41 443.34L2078.54 463.63L2113.67 483.91L2148.8 504.19L2183.92 524.47L2219.06 544.75L2254.19 565.04L2289.32 585.32L2428.68 665.78Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2463.59 645.5L2324.22 565.04L2289.09 544.75L2253.96 524.47L2218.83 504.19L2183.71 483.91L2148.58 463.63L2113.44 443.34L2078.31 423.06L2043.18 402.78L2008.06 382.5L1972.93 362.22L1937.8 341.93L1902.66 321.65L1867.53 301.37L1832.4 281.08L1797.28 260.81L1762.15 240.52L1727.02 220.24L1691.88 199.95L1656.75 179.67L1621.62 159.39L1586.5 139.11L1551.37 118.83L1516.24 98.54L1481.1 78.26L1445.97 57.98L1410.84 37.69L1375.72 17.42L1340.59 -2.87L1305.46 -23.15L1270.33 -43.43L1235.19 -63.72L1200.06 -84L1164.94 -104.28L1129.81 -124.56L1094.68 -144.84L1059.55 -165.13L1024.41 -185.41L989.29 -205.69L954.16 -225.97L919.03 -246.25L883.9 -266.54L744.53 -347L883.9 -266.54L919.03 -246.25L954.16 -225.97L989.29 -205.69L1024.41 -185.41L1059.55 -165.13L1094.68 -144.84L1129.81 -124.56L1164.94 -104.28L1200.06 -84L1235.19 -63.72L1270.33 -43.43L1305.46 -23.15L1340.59 -2.87L1375.72 17.42L1410.84 37.69L1445.97 57.98L1481.1 78.26L1516.24 98.54L1551.37 118.83L1586.5 139.11L1621.62 159.39L1656.75 179.67L1691.88 199.95L1727.02 220.24L1762.15 240.52L1797.28 260.81L1832.4 281.08L1867.53 301.37L1902.66 321.65L1937.8 341.93L1972.93 362.22L2008.06 382.5L2043.18 402.78L2078.31 423.06L2113.44 443.34L2148.58 463.63L2183.71 483.91L2218.83 504.19L2253.96 524.47L2289.09 544.75L2324.22 565.04L2463.59 645.5Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2498.48 625.22L2359.11 544.76L2323.98 524.48L2288.85 504.19L2253.72 483.91L2218.6 463.63L2183.47 443.35L2148.34 423.07L2113.2 402.78L2078.07 382.5L2042.95 362.22L2007.82 341.94L1972.69 321.66L1937.56 301.37L1902.42 281.09L1867.29 260.8L1832.17 240.53L1797.04 220.24L1761.91 199.96L1726.78 179.68L1691.64 159.39L1656.51 139.11L1621.39 118.83L1586.26 98.55L1551.13 78.27L1516 57.98L1480.86 37.7L1445.73 17.42L1410.61 -2.86002L1375.48 -23.14L1340.35 -43.43L1305.22 -63.71L1270.09 -83.99L1234.95 -104.28L1199.83 -124.55L1164.7 -144.84L1129.57 -165.12L1094.44 -185.4L1059.31 -205.69L1024.19 -225.96L989.05 -246.25L953.92 -266.53L918.79 -286.81L779.43 -367.27L918.79 -286.81L953.92 -266.53L989.05 -246.25L1024.19 -225.96L1059.31 -205.69L1094.44 -185.4L1129.57 -165.12L1164.7 -144.84L1199.83 -124.55L1234.95 -104.28L1270.09 -83.99L1305.22 -63.71L1340.35 -43.43L1375.48 -23.14L1410.61 -2.86002L1445.73 17.42L1480.86 37.7L1516 57.98L1551.13 78.27L1586.26 98.55L1621.39 118.83L1656.51 139.11L1691.64 159.39L1726.78 179.68L1761.91 199.96L1797.04 220.24L1832.17 240.53L1867.29 260.8L1902.42 281.09L1937.56 301.37L1972.69 321.66L2007.82 341.94L2042.95 362.22L2078.07 382.5L2113.2 402.78L2148.34 423.07L2183.47 443.35L2218.6 463.63L2253.72 483.91L2288.85 504.19L2323.98 524.48L2359.11 544.76L2498.48 625.22Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2533.38 604.94L2394.02 524.48L2358.89 504.19L2323.75 483.91L2288.62 463.62L2253.5 443.35L2218.37 423.07L2183.24 402.78L2148.11 382.5L2112.97 362.21L2077.85 341.94L2042.72 321.65L2007.59 301.37L1972.46 281.09L1937.33 260.8L1902.2 240.52L1867.08 220.24L1831.94 199.96L1796.81 179.68L1761.68 159.39L1726.55 139.11L1691.42 118.83L1656.3 98.5499L1621.16 78.2699L1586.03 57.9799L1550.9 37.7L1515.77 17.42L1480.64 -2.87006L1445.52 -23.1401L1410.38 -43.4301L1375.25 -63.7101L1340.12 -83.9901L1304.99 -104.28L1269.86 -124.56L1234.74 -144.84L1199.6 -165.12L1164.47 -185.4L1129.34 -205.69L1094.21 -225.97L1059.09 -246.25L1023.96 -266.53L988.83 -286.81L953.69 -307.1L814.33 -387.56L953.69 -307.1L988.83 -286.81L1023.96 -266.53L1059.09 -246.25L1094.21 -225.97L1129.34 -205.69L1164.47 -185.4L1199.6 -165.12L1234.74 -144.84L1269.86 -124.56L1304.99 -104.28L1340.12 -83.9901L1375.25 -63.7101L1410.38 -43.4301L1445.52 -23.1401L1480.64 -2.87006L1515.77 17.42L1550.9 37.7L1586.03 57.9799L1621.16 78.2699L1656.3 98.5499L1691.42 118.83L1726.55 139.11L1761.68 159.39L1796.81 179.68L1831.94 199.96L1867.08 220.24L1902.2 240.52L1937.33 260.8L1972.46 281.09L2007.59 301.37L2042.72 321.65L2077.85 341.94L2112.97 362.21L2148.11 382.5L2183.24 402.78L2218.37 423.07L2253.5 443.35L2288.62 463.62L2323.75 483.91L2358.89 504.19L2394.02 524.48L2533.38 604.94Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2568.29 584.65L2428.92 504.19L2393.79 483.91L2358.66 463.62L2323.53 443.34L2288.41 423.06L2253.27 402.78L2218.14 382.5L2183.01 362.21L2147.88 341.93L2112.76 321.65L2077.63 301.37L2042.49 281.09L2007.36 260.8L1972.23 240.52L1937.1 220.24L1901.98 199.96L1866.85 179.68L1831.72 159.39L1796.58 139.11L1761.45 118.83L1726.32 98.5399L1691.2 78.2699L1656.07 57.9799L1620.94 37.6999L1585.8 17.4199L1550.67 -2.87009L1515.54 -23.1501L1480.42 -43.4301L1445.29 -63.7101L1410.16 -83.9901L1375.02 -104.28L1339.89 -124.56L1304.76 -144.84L1269.64 -165.12L1234.51 -185.4L1199.38 -205.69L1164.24 -225.97L1129.11 -246.25L1093.99 -266.53L1058.86 -286.81L1023.73 -307.1L988.6 -327.38L849.23 -407.84L988.6 -327.38L1023.73 -307.1L1058.86 -286.81L1093.99 -266.53L1129.11 -246.25L1164.24 -225.97L1199.38 -205.69L1234.51 -185.4L1269.64 -165.12L1304.76 -144.84L1339.89 -124.56L1375.02 -104.28L1410.16 -83.9901L1445.29 -63.7101L1480.42 -43.4301L1515.54 -23.1501L1550.67 -2.87009L1585.8 17.4199L1620.94 37.6999L1656.07 57.9799L1691.2 78.2699L1726.32 98.5399L1761.45 118.83L1796.58 139.11L1831.72 159.39L1866.85 179.68L1901.98 199.96L1937.1 220.24L1972.23 240.52L2007.36 260.8L2042.49 281.09L2077.63 301.37L2112.76 321.65L2147.88 341.93L2183.01 362.21L2218.14 382.5L2253.27 402.78L2288.41 423.06L2323.53 443.34L2358.66 463.62L2393.79 483.91L2428.92 504.19L2568.29 584.65Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2603.19 564.37L2463.83 483.91L2428.69 463.62L2393.56 443.34L2358.43 423.06L2323.31 402.78L2288.18 382.5L2253.05 362.21L2217.91 341.93L2182.78 321.65L2147.66 301.37L2112.53 281.09L2077.4 260.8L2042.27 240.52L2007.13 220.24L1972 199.95L1936.88 179.68L1901.75 159.39L1866.62 139.11L1831.49 118.83L1796.35 98.5399L1761.22 78.2599L1726.1 57.9799L1690.97 37.6999L1655.84 17.4199L1620.71 -2.87012L1585.58 -23.1501L1550.44 -43.4301L1515.32 -63.7101L1480.19 -83.9901L1445.06 -104.28L1409.93 -124.56L1374.8 -144.84L1339.66 -165.13L1304.54 -185.4L1269.41 -205.69L1234.28 -225.97L1199.15 -246.25L1164.02 -266.54L1128.9 -286.81L1093.76 -307.1L1058.63 -327.38L1023.5 -347.66L884.14 -428.13L1023.5 -347.66L1058.63 -327.38L1093.76 -307.1L1128.9 -286.81L1164.02 -266.54L1199.15 -246.25L1234.28 -225.97L1269.41 -205.69L1304.54 -185.4L1339.66 -165.13L1374.8 -144.84L1409.93 -124.56L1445.06 -104.28L1480.19 -83.9901L1515.32 -63.7101L1550.44 -43.4301L1585.58 -23.1501L1620.71 -2.87012L1655.84 17.4199L1690.97 37.6999L1726.1 57.9799L1761.22 78.2599L1796.35 98.5399L1831.49 118.83L1866.62 139.11L1901.75 159.39L1936.88 179.68L1972 199.95L2007.13 220.24L2042.27 240.52L2077.4 260.8L2112.53 281.09L2147.66 301.37L2182.78 321.65L2217.91 341.93L2253.05 362.21L2288.18 382.5L2323.31 402.78L2358.43 423.06L2393.56 443.34L2428.69 463.62L2463.83 483.91L2603.19 564.37Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2638.09 544.09L2498.73 463.62L2463.6 443.34L2428.47 423.06L2393.33 402.77L2358.21 382.5L2323.08 362.21L2287.95 341.93L2252.82 321.65L2217.69 301.36L2182.57 281.09L2147.43 260.8L2112.3 240.52L2077.17 220.24L2042.04 199.95L2006.91 179.67L1971.79 159.39L1936.65 139.11L1901.52 118.83L1866.39 98.5399L1831.26 78.2598L1796.13 57.9799L1761.01 37.6999L1725.87 17.4199L1690.74 -2.87015L1655.61 -23.1501L1620.48 -43.4301L1585.35 -63.7202L1550.23 -83.9901L1515.1 -104.28L1479.96 -124.56L1444.83 -144.84L1409.7 -165.13L1374.57 -185.41L1339.45 -205.69L1304.32 -225.97L1269.18 -246.25L1234.05 -266.54L1198.92 -286.82L1163.8 -307.1L1128.67 -327.38L1093.54 -347.66L1058.4 -367.95L919.04 -448.41L1058.4 -367.95L1093.54 -347.66L1128.67 -327.38L1163.8 -307.1L1198.92 -286.82L1234.05 -266.54L1269.18 -246.25L1304.32 -225.97L1339.45 -205.69L1374.57 -185.41L1409.7 -165.13L1444.83 -144.84L1479.96 -124.56L1515.1 -104.28L1550.23 -83.9901L1585.35 -63.7202L1620.48 -43.4301L1655.61 -23.1501L1690.74 -2.87015L1725.87 17.4199L1761.01 37.6999L1796.13 57.9799L1831.26 78.2598L1866.39 98.5399L1901.52 118.83L1936.65 139.11L1971.79 159.39L2006.91 179.67L2042.04 199.95L2077.17 220.24L2112.3 240.52L2147.43 260.8L2182.57 281.09L2217.69 301.36L2252.82 321.65L2287.95 341.93L2323.08 362.21L2358.21 382.5L2393.33 402.77L2428.47 423.06L2463.6 443.34L2498.73 463.62L2638.09 544.09Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2673 523.8L2533.63 443.34L2498.5 423.06L2463.37 402.77L2428.24 382.49L2393.12 362.21L2357.99 341.93L2322.85 321.65L2287.72 301.36L2252.59 281.08L2217.47 260.8L2182.34 240.52L2147.21 220.24L2112.07 199.95L2076.94 179.67L2041.81 159.39L2006.69 139.11L1971.56 118.83L1936.43 98.54L1901.29 78.2601L1866.16 57.98L1831.03 37.6901L1795.91 17.4201L1760.78 -2.86993L1725.65 -23.1499L1690.51 -43.4299L1655.38 -63.7199L1620.25 -83.9999L1585.13 -104.28L1550 -124.56L1514.87 -144.84L1479.73 -165.13L1444.6 -185.41L1409.47 -205.69L1374.35 -225.97L1339.22 -246.25L1304.09 -266.54L1268.96 -286.82L1233.82 -307.11L1198.7 -327.38L1163.57 -347.66L1128.44 -367.95L1093.31 -388.23L953.94 -468.69L1093.31 -388.23L1128.44 -367.95L1163.57 -347.66L1198.7 -327.38L1233.82 -307.11L1268.96 -286.82L1304.09 -266.54L1339.22 -246.25L1374.35 -225.97L1409.47 -205.69L1444.6 -185.41L1479.73 -165.13L1514.87 -144.84L1550 -124.56L1585.13 -104.28L1620.25 -83.9999L1655.38 -63.7199L1690.51 -43.4299L1725.65 -23.1499L1760.78 -2.86993L1795.91 17.4201L1831.03 37.6901L1866.16 57.98L1901.29 78.2601L1936.43 98.54L1971.56 118.83L2006.69 139.11L2041.81 159.39L2076.94 179.67L2112.07 199.95L2147.21 220.24L2182.34 240.52L2217.47 260.8L2252.59 281.08L2287.72 301.36L2322.85 321.65L2357.99 341.93L2393.12 362.21L2428.24 382.49L2463.37 402.77L2498.5 423.06L2533.63 443.34L2673 523.8Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2707.89 503.52L2568.52 423.06L2533.39 402.78L2498.26 382.5L2463.13 362.21L2428.01 341.94L2392.88 321.65L2357.74 301.37L2322.61 281.09L2287.48 260.8L2252.36 240.53L2217.23 220.24L2182.1 199.96L2146.97 179.68L2111.83 159.39L2076.7 139.11L2041.58 118.83L2006.45 98.55L1971.32 78.27L1936.19 57.98L1901.05 37.7L1865.92 17.42L1830.8 -2.85995L1795.67 -23.14L1760.54 -43.43L1725.41 -63.71L1690.27 -83.99L1655.14 -104.28L1620.02 -124.55L1584.89 -144.84L1549.76 -165.12L1514.63 -185.4L1479.49 -205.69L1444.36 -225.97L1409.24 -246.25L1374.11 -266.53L1338.98 -286.81L1303.85 -307.1L1268.72 -327.38L1233.6 -347.66L1198.46 -367.94L1163.33 -388.23L1128.2 -408.51L988.84 -488.97L1128.2 -408.51L1163.33 -388.23L1198.46 -367.94L1233.6 -347.66L1268.72 -327.38L1303.85 -307.1L1338.98 -286.81L1374.11 -266.53L1409.24 -246.25L1444.36 -225.97L1479.49 -205.69L1514.63 -185.4L1549.76 -165.12L1584.89 -144.84L1620.02 -124.55L1655.14 -104.28L1690.27 -83.99L1725.41 -63.71L1760.54 -43.43L1795.67 -23.14L1830.8 -2.85995L1865.92 17.42L1901.05 37.7L1936.19 57.98L1971.32 78.27L2006.45 98.55L2041.58 118.83L2076.7 139.11L2111.83 159.39L2146.97 179.68L2182.1 199.96L2217.23 220.24L2252.36 240.53L2287.48 260.8L2322.61 281.09L2357.74 301.37L2392.88 321.65L2428.01 341.94L2463.13 362.21L2498.26 382.5L2533.39 402.78L2568.52 423.06L2707.89 503.52Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2742.79 483.24L2603.43 402.78L2568.3 382.5L2533.16 362.21L2498.03 341.93L2462.91 321.65L2427.78 301.37L2392.65 281.09L2357.52 260.8L2322.38 240.52L2287.26 220.24L2252.13 199.96L2217 179.68L2181.87 159.39L2146.74 139.11L2111.6 118.83L2076.49 98.55L2041.35 78.27L2006.22 57.98L1971.09 37.7L1935.96 17.42L1900.83 -2.87L1865.71 -23.14L1830.57 -43.43L1795.44 -63.71L1760.31 -83.99L1725.18 -104.28L1690.05 -124.56L1654.93 -144.84L1619.79 -165.12L1584.66 -185.41L1549.53 -205.69L1514.4 -225.97L1479.27 -246.26L1444.15 -266.53L1409.01 -286.82L1373.88 -307.1L1338.75 -327.38L1303.62 -347.67L1268.5 -367.94L1233.37 -388.23L1198.23 -408.51L1163.1 -428.79L1023.74 -509.25L1163.1 -428.79L1198.23 -408.51L1233.37 -388.23L1268.5 -367.94L1303.62 -347.67L1338.75 -327.38L1373.88 -307.1L1409.01 -286.82L1444.15 -266.53L1479.27 -246.26L1514.4 -225.97L1549.53 -205.69L1584.66 -185.41L1619.79 -165.12L1654.93 -144.84L1690.05 -124.56L1725.18 -104.28L1760.31 -83.99L1795.44 -63.71L1830.57 -43.43L1865.71 -23.14L1900.83 -2.87L1935.96 17.42L1971.09 37.7L2006.22 57.98L2041.35 78.27L2076.49 98.55L2111.6 118.83L2146.74 139.11L2181.87 159.39L2217 179.68L2252.13 199.96L2287.26 220.24L2322.38 240.52L2357.52 260.8L2392.65 281.09L2427.78 301.37L2462.91 321.65L2498.03 341.93L2533.16 362.21L2568.3 382.5L2603.43 402.78L2742.79 483.24Z"
        fill="#FF7D7D"
      />
      <path
        opacity="0.3"
        d="M2777.7 462.96L2638.33 382.5L2603.2 362.21L2568.07 341.93L2532.94 321.65L2497.82 301.37L2462.68 281.09L2427.55 260.8L2392.42 240.52L2357.29 220.24L2322.17 199.96L2287.04 179.68L2251.9 159.39L2216.77 139.11L2181.64 118.83L2146.51 98.5399L2111.39 78.27L2076.26 57.9799L2041.12 37.7L2005.99 17.4099L1970.86 -2.87006L1935.73 -23.1501L1900.61 -43.4301L1865.48 -63.7101L1830.35 -84.0001L1795.21 -104.28L1760.08 -124.56L1724.95 -144.85L1689.83 -165.12L1654.7 -185.41L1619.57 -205.69L1584.43 -225.97L1549.3 -246.26L1514.17 -266.54L1479.05 -286.82L1443.92 -307.1L1408.79 -327.38L1373.65 -347.67L1338.52 -367.95L1303.4 -388.23L1268.27 -408.51L1233.14 -428.79L1198.01 -449.08L1058.64 -529.54L1198.01 -449.08L1233.14 -428.79L1268.27 -408.51L1303.4 -388.23L1338.52 -367.95L1373.65 -347.67L1408.79 -327.38L1443.92 -307.1L1479.05 -286.82L1514.17 -266.54L1549.3 -246.26L1584.43 -225.97L1619.57 -205.69L1654.7 -185.41L1689.83 -165.12L1724.95 -144.85L1760.08 -124.56L1795.21 -104.28L1830.35 -84.0001L1865.48 -63.7101L1900.61 -43.4301L1935.73 -23.1501L1970.86 -2.87006L2005.99 17.4099L2041.12 37.7L2076.26 57.9799L2111.39 78.27L2146.51 98.5399L2181.64 118.83L2216.77 139.11L2251.9 159.39L2287.04 179.68L2322.17 199.96L2357.29 220.24L2392.42 240.52L2427.55 260.8L2462.68 281.09L2497.82 301.37L2532.94 321.65L2568.07 341.93L2603.2 362.21L2638.33 382.5L2777.7 462.96Z"
        fill="#FF7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_8455">
        <rect width="1080" height="1080" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
