import React, { useRef, useEffect, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import FocusTrap from 'focus-trap-react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOppervlakteKey,
  getOppervlakteId,
  getSelectedModalTitle,
  getOptionFun,
  getSelectedOptFun,
  getPremImprovment,
  getModalOptions,
  getModalSelectedOption,
  getEnergyTitle,
} from '../../utils/helpers'

import './modal.scss'
import {
  getDimensions,
  getGroups,
  getImprovements,
  getResultCalculations,
  getAdviceText,
} from '../../utils/apiActions'
import {
  getImprovedPages,
  getSelectedDropdownOption,
} from '../../utils/resultHelpers'
import { setArea } from '../../store/actions'
import mapicon from '../../images/map_marker_icon.svg'
import Cookies from 'js-cookie'

const ModalImprovementDetail = (props) => {
  const {
    type,
    show,
    handleHideModal,
    modalTitle,
    status,
    increaseDecreaseLabel,
    handleShowModalMap,
    handleAchterModalMap,
    // handleVerwarmingModalMap,
    showModalMap,
    handleSubmitModal,
    handleRemoveChanges,
    setUpdated,
    pageIndex,
    itemKey,
    improvedSelection,
    modal,
    pages,
    buildingData,
    resultSetState,
    isAreaUpdated,
    vergronenArea,
    setVArea,
  } = props

  const newarea = 0
  const [vloerisolatieOrignalArea, setVloerisolatieOrignalArea] = useState(0)
  const [defaultArea, setDefaultArea] = useState(false)
  const [splittedValues, setSplit] = useState(false)
  const page13 = JSON.parse(pages)[13] && JSON.parse(pages)[13].answerNo
  const page4 = JSON.parse(pages)[4] && JSON.parse(pages)[4].answerNo
  const page6 = JSON.parse(pages)[6] && JSON.parse(pages)[6].answerNo
  const page7 = JSON.parse(pages)[7] && JSON.parse(pages)[7].answerNo
  const page25 = JSON.parse(pages)[25] && JSON.parse(pages)[25].answerNo
  const { handleSubmit, register, getValues } = useForm({
    mode: 'onChange',
  })

  const {
    minderEnergie,
    bespaar,
    area: allArea,
    electricity,
    gas,
    verkoopElectricity,
  } = useSelector((state) => state.result)
  // improvement modal dropdown options
  const dropDownOptions = useMemo(
    () =>
      getModalOptions(
        modalTitle,
        JSON.parse(pages),
        pageIndex,
        Number(Cookies.get('userId')),
      ),
    [],
  )
  // improvement modal dropdown selected options
  const selectedOption = useMemo(
    () =>
      getModalSelectedOption(
        modalTitle,
        JSON.parse(pages),
        pageIndex,
        Number(Cookies.get('userId')),
        0,
      ),
    [],
  )
  const count = 0
  const area = allArea[itemKey] || 0

  const minderEnergieValue = minderEnergie[itemKey] || 0
  const bespaarValue = bespaar[itemKey] || 0
  const electricityValue = electricity[itemKey] || 0
  const gasValue = gas[itemKey] || 0
  const verkoopElectricityValue = verkoopElectricity[itemKey] || 0
  const dispatch = useDispatch()
  const [state, setState] = useState({
    investCost: 0,
    selectVal: setUpdated
      ? JSON.parse(Cookies.get('localImprovements'))[pageIndex]?.answerNo
      : selectedOption,
    premAmount: 0,
    calculations: {
      energyDifference: 0,
      energyRate: 0,
      pagesEnergyGas: 0,
      pagesEnergyElec: 0,
      verkoopElec: 0,
    },
    increaseDecreaseTrigger: false,
    adviceContent: '',
    adviceContentXL: '',
    loading: true,
    areaApiValue: 0,
  })

  const modalRef = useRef(null)
  const answerKey = useMemo(
    () => modalTitle.toLowerCase().replace(/\s+/g, '_'),
    [modalTitle],
  )

  // improvement modal area field stata
  const areaSet = (value) => {
    if (JSON.parse(Cookies.get('localState'))[20]?.answerNo === 2) {
      if (modalTitle === 'Vloerisolatie') {
        dispatch(setArea({ [itemKey]: Math.round(value / 2) }))
      } else {
        dispatch(setArea({ [itemKey]: Math.round(value) }))
      }
    } else {
      dispatch(setArea({ [itemKey]: Math.round(value) }))
    }
  }
  useEffect(() => {
    if (!isAreaUpdated[modalTitle]) {
      areaSet(state.areaApiValue)
    }
  }, [isAreaUpdated[modalTitle]])

  // API calls for fetch data from backoffice
  const getCall = (value) => {
    const selectedOptText = getSelectedOptFun(
      dropDownOptions,
      !value ? selectedOption : value,
    )
    const selectedModalTitle = getSelectedModalTitle(
      modalTitle,
      JSON.parse(Cookies.get('localImprovements')),
    )
    const opperKey = getOppervlakteKey(
      JSON.parse(Cookies.get('localImprovements')),
      buildingData && buildingData[0],
    )
    const oppervlakteId = getOppervlakteId(modalTitle, pageIndex)
    const premImprovment = getPremImprovment(
      modalTitle,
      pageIndex,
      selectedOptText,
      JSON.parse(Cookies.get('localImprovements')),
    )

    // Backoffice dimentions call
    getDimensions(
      area ? () => {} : areaSet,
      opperKey,
      oppervlakteId,
      modalTitle,
      page13,
      setState,
      resultSetState,
      pageIndex,
    )

    // Backoffice improvement call
    getImprovements(
      setState,
      selectedModalTitle,
      selectedOptText,
      area,
      pageIndex,
      selectedOption,
      vergronenArea,
      JSON.parse(Cookies.get('localImprovements')),
    )

    // Backoffice premie call
    getGroups(
      setState,
      premImprovment,
      area,
      state.investCost,
      page4,
      pageIndex,
      vergronenArea,
    )

    // Backoffice improvement modal text call
    getAdviceText(
      setState,
      status,
      modalTitle,
      pageIndex,
      JSON.parse(Cookies.get('localImprovements'))[pageIndex]?.answerNo,
      improvedSelection,
    )
  }
  useEffect(() => {
    const vloerisolatie_orignalArea = localStorage.getItem(
      'vloerisolatie_orignalArea',
    )
    if (vloerisolatie_orignalArea) {
      setVloerisolatieOrignalArea(vloerisolatie_orignalArea)
    } else if (area > 0) {
      localStorage.setItem('vloerisolatie_orignalArea', JSON.stringify(area))
      setVloerisolatieOrignalArea(JSON.stringify(area))
    }
  }, [area])

  useEffect(() => {
    if (status !== 'green') {
      getCall()
    }
  }, [])

  useEffect(() => {
    if (show) {
      modalRef.current.focus()
      document.getElementById('root')
        ? document.getElementById('root').setAttribute('aria-hidden', false)
        : null
      document.getElementById('root')
        ? (document.getElementsByTagName('body')[0].style.overflow = 'hidden')
        : null
    }
    return () => {
      modalRef.current.focus()
      document.getElementById('root')
        ? document.getElementById('root').setAttribute('aria-hidden', false)
        : null
      document.getElementById('root')
        ? (document.getElementsByTagName('body')[0].style.overflow = 'initial')
        : null
    }
  }, [show])

  useEffect(() => {
    const selectedText = getOptionFun(getValues(), dropDownOptions, answerKey)
    const improvedPages = getImprovedPages(
      getValues(),
      answerKey,
      selectedText,
      {
        localImprovements: JSON.parse(Cookies.get('localState')),
        //         localImprovements: cookies?.localImprovements,
        // updated for having only the cards improvments details
        pageIndex,
        page6Answer: JSON.parse(Cookies.get('localState'))[6].answerNo,
      },
    )

    getResultCalculations(
      JSON.parse(Cookies.get('localState')),
      JSON.parse(Cookies.get('localImprovements')),
      improvedPages,
      setState,
      type,
      itemKey,
      area,
      Number(Cookies.get('userId')),
    )
    // }
  }, [
    type,
    answerKey,
    state.selectVal,
    dropDownOptions,
    getValues,
    pageIndex,
    Cookies.get('localImprovements'),
    Cookies.get('localState'),
    itemKey,
    area,
    Number(Cookies.get('userId')),
  ])

  useEffect(() => {
    getCall(state.selectVal)
  }, [area, state.investCost])

  useEffect(() => {
    getCall()
  }, [vergronenArea])

  // Increment button handler in improvement modal
  const handleIncrease = () => {
    setDefaultArea(true)
    setState((st) => ({
      ...st,
      // oppervlakte: st.oppervlakte + 1,
      increaseDecreaseTrigger: true,
    }))
    if (area >= 0 && area < 1000) {
      dispatch(setArea({ [itemKey]: Number(area) + 1 }))
    }
  }
  const handleIncreaseVergroenen = (id) => {
    if (id === 1) {
      setVArea([vergronenArea[0] + 1, vergronenArea[1], vergronenArea[2]])
      Cookies.set('vergronenArea1', vergronenArea[0] + 1)
    }
    if (id === 2) {
      setVArea([vergronenArea[0], vergronenArea[1] + 1, vergronenArea[2]])
      Cookies.set('vergronenArea2', vergronenArea[1] + 1)
    }
    if (id === 3) {
      setVArea([vergronenArea[0], vergronenArea[1], vergronenArea[2] + 1])
      Cookies.set('vergronenArea3', vergronenArea[2] + 1)
    }
  }
  // Increment button handler in improvement modal
  const handleDecrease = () => {
    setDefaultArea(true)
    if (area > 0) {
      setState((st) => ({
        ...st,
        // oppervlakte: st.oppervlakte - 1,
        increaseDecreaseTrigger: true,
      }))
      if (area <= 1000) {
        dispatch(setArea({ [itemKey]: Number(area) - 1 }))
      }
    }
  }
  const handleDecreaseVergroenen = (id) => {
    if (id === 1) {
      setVArea([
        vergronenArea[0] - 1 >= 0 ? vergronenArea[0] - 1 : 0,
        vergronenArea[1],
        vergronenArea[2],
      ])
      Cookies.set(
        'vergronenArea1',
        vergronenArea[0] - 1 >= 0 ? vergronenArea[0] - 1 : 0,
      )
    }
    if (id === 2) {
      setVArea([
        vergronenArea[0],
        vergronenArea[1] - 1 >= 0 ? vergronenArea[1] - 1 : 0,
        vergronenArea[2],
      ])
      Cookies.set(
        'vergronenArea2',
        vergronenArea[1] - 1 >= 0 ? vergronenArea[1] - 1 : 0,
      )
    }
    if (id === 3) {
      setVArea([
        vergronenArea[0],
        vergronenArea[1],
        vergronenArea[2] - 1 >= 0 ? vergronenArea[2] - 1 : 0,
      ])
      Cookies.set(
        'vergronenArea3',
        vergronenArea[2] - 1 >= 0 ? vergronenArea[2] - 1 : 0,
      )
    }
  }
  // area change handler in improvement modal
  const handleChange = (value) => {
    setDefaultArea(true)
    setState((st) => ({
      ...st,
      // oppervlakte: value,
      increaseDecreaseTrigger: true,
      // stateArea: value,
    }))
    if (value === '') {
      dispatch(setArea({ [itemKey]: '0' }))
    }
    if (value !== '' && value >= 0 && value <= 1000) {
      dispatch(setArea({ [itemKey]: value }))
    }
  }
  const handleChangeVergroenen = (id, value) => {
    console.log('id,value', id, value)
    if (!value) {
      value = 0
    }
    if (id === 1) {
      setVArea([
        value >= 0 ? Number(value) : 0,
        vergronenArea[1],
        vergronenArea[2],
      ])
      Cookies.set('vergronenArea1', value >= 0 ? Number(value) : 0)
    }
    if (id === 2) {
      setVArea([
        vergronenArea[0],
        value >= 0 ? Number(value) : 0,
        vergronenArea[2],
      ])
      Cookies.set('vergronenArea2', value >= 0 ? Number(value) : 0)
    }
    if (id === 3) {
      setVArea([
        vergronenArea[0],
        vergronenArea[1],
        value >= 0 ? Number(value) : 0,
      ])
      Cookies.set('vergronenArea3', value >= 0 ? Number(value) : 0)
    }
  }

  const handleDropdownChange = (value) => {
    setState((st) => ({ ...st, selectVal: value }))
    getCall(value)
  }
  const selectedDropdownValue = getSelectedDropdownOption(state.selectVal)

  const handleRemoveImprovement = () => {
    handleRemoveChanges()
    if (modalTitle === 'Verwarming' && page6 !== 1 && selectedDropdownValue) {
      setSplit(false)
      localStorage.setItem('split', false)
      localStorage.setItem('pagesEnergyElec', 0)
      localStorage.setItem('pagesEnergyGas', 0)
    }
  }

  const onSubmit = (values, event) => {
    const selectedText = getOptionFun(values, dropDownOptions, answerKey)
    handleSubmitModal(
      values,
      answerKey,
      selectedText,
      {
        investeer: state.investCost,
        bespaar: state.calculations.energyRate,
        premies: Math.round(state.premAmount),
        minderEnergie: state.calculations.energyDifference,
        pagesEnergyGas: state.calculations.pagesEnergyGas,
        verkoopElec: state.calculations.verkoopElec,
        pagesEnergyElec: state.calculations.pagesEnergyElec,
        pages: pageIndex,
        // investeer: pageIndex == 30 ? 0 : state.investCost,
        // bespaar:pageIndex == 30  ? 0 : state.calculations.energyRate,
        // premies: pageIndex == 30  ? 0 : Math.round(state.premAmount),
        // minderEnergie: pageIndex == 30  ? 0 : state.calculations.energyDifference,
        // pagesEnergyGas: pageIndex == 30  ? 0 : state.calculations.pagesEnergyGas,
        // verkoopElec: pageIndex == 30  ? 0 : state.calculations.verkoopElec,
        // pagesEnergyElec: pageIndex == 30  ? 0 : state.calculations.pagesEnergyElec,
      },
      area,
      selectedOption,
    )
    if (modalTitle === 'Verwarming') {
      if (page6 !== 1 && selectedDropdownValue) {
        setSplit(true)
        localStorage.setItem('split', true)
        localStorage.setItem('optionNo', state.selectVal)
        localStorage.setItem(
          'pagesEnergyElec',
          state.calculations.pagesEnergyElec,
        )
        localStorage.setItem(
          'pagesEnergyGas',
          state.calculations.pagesEnergyGas,
        )
      } else {
        setSplit(false)
        localStorage.setItem('split', false)
        localStorage.setItem('pagesEnergyElec', 0)
        localStorage.setItem('pagesEnergyGas', 0)
        // localStorage.setItem('optionNo', state.selectVal)
      }
    }
    // areaSet(state.stateArea)
  }

  const children = (
    <div
      ref={modalRef}
      id="modal-improvement"
      className={show ? 'modal visible' : 'modal'}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-improvement-title"
    >
      <div
        className="modal-inner"
        style={{
          height: '700px',
          overflowY: 'auto',
          paddingBottom: '0',
          margin: '4vh auto 0',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <button
              type="button"
              className="button close icon-cross modal-close"
              data-target="modal-improvement"
              onClick={handleHideModal}
            >
              <span>Sluiten</span>
            </button>
          </div>
          <div className="modal-content">
            <h3 id="modal-improvement-title">
              {modalTitle === 'Zonnepanelen'
                ? `${modal.improvement_modal_title} ${modalTitle.toLowerCase()}`
                : `${
                    modal.improvement_modal_title
                  } ${modalTitle.toLowerCase()}`}
            </h3>
            <p
              className={
                modalTitle === 'Zonnepanelen'
                  ? 'u-margin-bottom-xs'
                  : 'u-margin-bottom'
              }
            >
              <span dangerouslySetInnerHTML={{ __html: state.adviceContent }} />
              &nbsp;&nbsp;
              {(setUpdated || status !== 'green') && (
                <a
                  className="modal-intro__link"
                  href="/advies#meer-info-bij-ons-advies"
                >
                  {modal.improvement_modal_more_info_text}
                </a>
              )}
            </p>
            {modalTitle === 'Zonnepanelen' && (
              <>
                <p
                  className="u-margin-bottom"
                  dangerouslySetInnerHTML={{
                    __html: modal.improvement_modal_zonnepenelen_text,
                  }}
                />

                <div className="form-item">
                  <button
                    onClick={() => handleShowModalMap()}
                    type="button"
                    className="button button-secondary a-button a-button--outlined"
                    aria-controls="map-modal"
                  >
                    <img src={mapicon} className="map-image" />
                    {'  '}
                    {modal.improvement_modal_text_zonnepenelen_button}
                  </button>
                </div>
              </>
            )}
            {modalTitle === 'Voorgevel' && (
              <>
                <p
                  className="u-margin-bottom-xs"
                  dangerouslySetInnerHTML={{
                    __html: modal.improvement_modal_voorgevel_text,
                  }}
                />
                <div className="form-item u-margin-bottom">
                  <button
                    onClick={() => handleAchterModalMap()}
                    type="button"
                    className="button button-secondary a-button a-button--outlined"
                    aria-controls="map-modal"
                  >
                    <img src={mapicon} className="map-image" />
                    {'  '}
                    {modal.improvement_modal_voorgevel_button}
                  </button>
                </div>
              </>
            )}
            {(setUpdated || status !== 'green') &&
              (dropDownOptions && Object.keys(dropDownOptions[0]).length > 1 ? (
                <div className="form-item a-input has-icon-right u-margin-bottom">
                  <label
                    className="a-input__label"
                    htmlFor={`${modalTitle.toLowerCase().replace(/\s+/g, '_')}`}
                  >
                    {modal.improvement_modal_multiple_improvement_text}
                  </label>
                  <div className="form-columns">
                    <div className="form-item-column a-input__wrapper">
                      <select
                        name={`${modalTitle
                          .toLowerCase()
                          .replace(/\s+/g, '_')}`}
                        value={state.selectVal}
                        ref={register({})}
                        onChange={(e) => handleDropdownChange(e.target.value)}
                      >
                        {Object.keys(dropDownOptions[0]).map((option) => (
                          <option
                            key={`${option.toLowerCase().replace(/\s+/g, '_')}`}
                            value={dropDownOptions[0][option]}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      <span className="ai">
                        <svg width="100px" height="100px">
                          <use href="/public/icons.svg#ai-arrow-down-1" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="form-item a-input has-icon-right u-margin-bottom">
                  <label
                    className="a-input__label"
                    htmlFor={`${modalTitle.toLowerCase().replace(/\s+/g, '_')}`}
                  >
                    {modal.improvement_modal_single_improvement_text}
                  </label>
                  <div className="form-columns">
                    <div className="form-item-column a-input__wrapper">
                      <select
                        name={`${modalTitle
                          .toLowerCase()
                          .replace(/\s+/g, '_')}`}
                        value={state.selectVal}
                        style={{ border: '1px solid #808080' }}
                        ref={register({})}
                        onChange={(e) => handleDropdownChange(e.target.value)}
                        disabled
                      >
                        {Object.keys(dropDownOptions[0]).map((option) => (
                          <option
                            key={`${option.toLowerCase().replace(/\s+/g, '_')}`}
                            value={dropDownOptions[0][option]}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      <span className="ai">
                        <svg width="100px" height="100px">
                          <use href="/public/icons.svg#ai-arrow-down-1" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            {(setUpdated || status !== 'green') && increaseDecreaseLabel && (
              <div className="form-item a-input u-margin-bottom">
                <label className="a-input__label" htmlFor="oppervlakte">
                  {increaseDecreaseLabel}
                </label>
                <div className="form-columns increase-decrease">
                  <div className="form-item-column">
                    <button
                      type="button"
                      className="button button-secondary decrease-button a-button a-button--outlined"
                      onClick={handleDecrease}
                    >
                      <i className="icon-minus" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="form-item-column a-input__wrapper">
                    <input
                      value={
                        defaultArea === false
                          ? modalTitle == 'Vloerisolatie'
                            ? JSON.parse(Cookies.get('localState'))[20]
                                ?.answerNo === 2
                              ? area == vloerisolatieOrignalArea
                                ? area
                                : area
                              : area
                            : area
                          : area
                      }
                      ref={register({})}
                      type="number"
                      name="oppervlakte"
                      id="oppervlakte-id"
                      className="text"
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  </div>
                  <div className="form-item-column">
                    <button
                      type="button"
                      className="button button-secondary increase-button a-button a-button--outlined"
                      onClick={handleIncrease}
                    >
                      <i className="icon-plus" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            )}
            {pageIndex == 30 && status != 'green' && (
              <>
                <div className="form-item">
                  <label htmlFor="oppervlakte">
                    Aantal m² te ontharden in voortuin
                  </label>
                  <div className="form-columns increase-decrease">
                    <div className="form-item-column">
                      <button
                        type="button"
                        className="button button-secondary decrease-button a-button a-button--outlined"
                        onClick={() => handleDecreaseVergroenen(1)}
                      >
                        <i className="icon-minus" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="form-item-column">
                      <input
                        value={vergronenArea[0]}
                        // ref={register({})}
                        type="number"
                        name="opper301"
                        id="opper301-id"
                        className="text"
                        onChange={(e) =>
                          handleChangeVergroenen(1, e.target.value)
                        }
                      />
                    </div>
                    <div className="form-item-column">
                      <button
                        type="button"
                        className="button button-secondary increase-button a-button a-button--outlined"
                        onClick={() => handleIncreaseVergroenen(1)}
                      >
                        <i className="icon-plus" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-item">
                  <label htmlFor="oppervlakte">
                    Aantal m² te ontharden in achtertuin
                  </label>
                  <div className="form-columns increase-decrease">
                    <div className="form-item-column">
                      <button
                        type="button"
                        className="button button-secondary decrease-button a-button a-button--outlined"
                        onClick={() => handleDecreaseVergroenen(2)}
                      >
                        <i className="icon-minus" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="form-item-column">
                      <input
                        value={vergronenArea[1]}
                        // ref={register({})}
                        type="number"
                        name="opper302"
                        id="opper302-id"
                        className="text"
                        onChange={(e) =>
                          handleChangeVergroenen(2, e.target.value)
                        }
                      />
                    </div>
                    <div className="form-item-column">
                      <button
                        type="button"
                        className="button button-secondary increase-button a-button a-button--outlined"
                        onClick={() => handleIncreaseVergroenen(2)}
                      >
                        <i className="icon-plus" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-item">
                  <label htmlFor="oppervlakte">
                    Aantal m² te ontharden op oprit
                  </label>
                  <div className="form-columns increase-decrease">
                    <div className="form-item-column">
                      <button
                        type="button"
                        className="button button-secondary decrease-button a-button a-button--outlined"
                        onClick={() => handleDecreaseVergroenen(3)}
                      >
                        <i className="icon-minus" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="form-item-column">
                      <input
                        value={vergronenArea[2]}
                        // ref={register({})}
                        type="number"
                        name="opper303"
                        id="opper303-id"
                        className="text"
                        onChange={(e) =>
                          handleChangeVergroenen(3, e.target.value)
                        }
                      />
                    </div>
                    <div className="form-item-column">
                      <button
                        type="button"
                        className="button button-secondary increase-button a-button a-button--outlined"
                        onClick={() => handleIncreaseVergroenen(3)}
                      >
                        <i className="icon-plus" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {state.loading && (
              <div className="spinner a-spinner">
                <span className="a-spinner__circle" />
                <div className="a-spinner__text">&hellip;loading</div>
              </div>
            )}
            {(setUpdated || status !== 'green') && (
              <div className="form-item u-margin-bottom">
                <div className="form-columns">
                  <div className="form-item-column">
                    <section className="highlight highlight--box">
                      <div className="highlight__inner m-alert">
                        <ul className="highlight-list">
                          <li>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  modal.improvement_modal_calculation_investeer,
                              }}
                            />
                            <p>
                              {modal.improvement_modal_calculation_currency}{' '}
                              {Math.round(state.investCost)}
                            </p>
                          </li>
                          <li>
                            {modalTitle === 'Zonnepanelen' ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    modal.improvement_modal_calculation_zonnepenelen_ontvang_premies,
                                }}
                              />
                            ) : (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    modal.improvement_modal_calculation_ontvang_premies,
                                }}
                              />
                            )}
                            <p>
                              {modal.improvement_modal_calculation_currency}{' '}
                              {Math.round(state.premAmount)}
                            </p>
                          </li>
                          {modalTitle === 'Verwarming' &&
                          page6 !== 1 &&
                          selectedDropdownValue ? (
                            <>
                              <li>
                                <p>Ik verbruik minder elektriciteit</p>
                                <p>
                                  {state.calculations.pagesEnergyElec
                                    ? Math.round(
                                        state.calculations.pagesEnergyElec,
                                      )
                                    : Math.round(electricityValue)}{' '}
                                  {
                                    modal.improvement_modal_calculation_verbruik_minder_energie_unit
                                  }
                                </p>
                              </li>
                              <li>
                                <p>
                                  Ik verbruik minder{' '}
                                  {getEnergyTitle(page6, page7)}
                                </p>
                                <p>
                                  {state.calculations.pagesEnergyGas
                                    ? Math.round(
                                        state.calculations.pagesEnergyGas,
                                      )
                                    : Math.round(gasValue)}{' '}
                                  {
                                    modal.improvement_modal_calculation_verbruik_minder_energie_unit
                                  }
                                </p>
                              </li>
                            </>
                          ) : // ) : (
                          modalTitle === 'Zonnepanelen' ? (
                            <>
                              <li>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      modal.improvement_modal_calculation_verbruik_minder_energie,
                                  }}
                                />
                                <p>
                                  {
                                    // state.calculations.energyDifference > 0
                                    //   ?
                                    Math.round(
                                      state.calculations.energyDifference < 0
                                        ? 0
                                        : state.calculations.energyDifference,
                                    )
                                    // : Math.round(minderEnergieValue)
                                  }{' '}
                                  {
                                    modal.improvement_modal_calculation_verbruik_minder_energie_unit
                                  }
                                </p>
                              </li>
                              <li>
                                <p>Ik verkoop elektriciteit</p>
                                <p>
                                  {state.calculations.verkoopElec
                                    ? Math.round(
                                        state.calculations.verkoopElec,
                                      ) < 0
                                      ? 0
                                      : Math.round(
                                          state.calculations.verkoopElec,
                                        )
                                    : Math.round(verkoopElectricityValue) < 0
                                    ? 0
                                    : Math.round(verkoopElectricityValue)}{' '}
                                  {
                                    modal.improvement_modal_calculation_verbruik_minder_energie_unit
                                  }
                                </p>
                              </li>
                            </>
                          ) : (
                            <li>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    modal.improvement_modal_calculation_verbruik_minder_energie,
                                }}
                              />
                              <p>
                                {Math.round(
                                  state.calculations.energyDifference,
                                ) < 0
                                  ? 0
                                  : Math.round(
                                      state.calculations.energyDifference,
                                    )}{' '}
                                {
                                  modal.improvement_modal_calculation_verbruik_minder_energie_unit
                                }
                              </p>
                            </li>
                          )}
                          <li>
                            <p>
                              <strong
                                dangerouslySetInnerHTML={{
                                  __html:
                                    modal.improvement_modal_calculation_bespaar,
                                }}
                              />
                            </p>
                            <p>
                              {modalTitle === 'Verwarming' &&
                              page6 !== 1 &&
                              selectedDropdownValue ? (
                                <strong>
                                  {modal.improvement_modal_calculation_currency}{' '}
                                  {state.calculations.energyRate
                                    ? Math.round(state.calculations.energyRate)
                                    : Math.round(bespaarValue) < 0
                                    ? 0
                                    : Math.round(bespaarValue)}{' '}
                                  /{' '}
                                  {
                                    modal.improvement_modal_calculation_bespaar_unit
                                  }
                                </strong>
                              ) : modalTitle === 'Zonnepanelen' ? (
                                <strong>
                                  {modal.improvement_modal_calculation_currency}{' '}
                                  {Math.round(state.calculations.energyRate) < 0
                                    ? 0
                                    : Math.round(
                                        state.calculations.energyRate,
                                      )}{' '}
                                  /{' '}
                                  {
                                    modal.improvement_modal_calculation_bespaar_unit
                                  }
                                </strong>
                              ) : (
                                <strong>
                                  {modal.improvement_modal_calculation_currency}{' '}
                                  {state.calculations.energyRate > 0
                                    ? Math.round(
                                        state.calculations.energyRate,
                                      ) < 0
                                      ? 0
                                      : Math.round(
                                          state.calculations.energyRate,
                                        )
                                    : Math.round(bespaarValue) < 0
                                    ? 0
                                    : Math.round(bespaarValue)}{' '}
                                  /{' '}
                                  {
                                    modal.improvement_modal_calculation_bespaar_unit
                                  }
                                </strong>
                              )}
                            </p>
                          </li>
                          {modalTitle === 'Zonnepanelen' &&
                            modal.improvement_modal_zonnepenelen_note_button
                              .length > 1 && (
                              <>
                                <li>
                                  <p
                                    className="note_text"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        modal.improvement_modal_zonnepenelen_note,
                                    }}
                                  />
                                </li>
                                <li>
                                  {!modal.improvement_modal_zonnepenelen_note_button.startsWith(
                                    '<br>',
                                  ) && (
                                    <a
                                      className="note_text"
                                      href="https://stad.gent/nl/energiecentrale/energielening"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          modal.improvement_modal_zonnepenelen_note_button,
                                      }}
                                    />
                                  )}
                                </li>
                              </>
                            )}
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            )}

            <h5
              id="meer-info-bij-ons-advies"
              dangerouslySetInnerHTML={{
                __html: modal.improvement_modal_title_xl_text,
              }}
            />
            {(setUpdated || status !== 'green') && (
              <div
                id="more-content"
                className="more-content paragraph u-margin-bottom-xs"
                style={{ height: 'auto' }}
                dangerouslySetInnerHTML={{ __html: state.adviceContentXL }}
              />
            )}
            {!setUpdated && status === 'green' && (
              <div
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: state.adviceContentXL }}
              />
            )}
            <br />
            <p
              style={{ fontSize: '14px', height: '406px' }}
              id="meer-info-bij-ons-advies-small"
              dangerouslySetInnerHTML={{
                __html: modal.improvement_modal_small_text,
              }}
            />
          </div>
          <div className="modal-actions">
            {setUpdated && (
              <div className="form-item">
                <div className="form-columns">
                  <div className="form-item-column">
                    <button
                      type="submit"
                      className="a-button a-button--s modal-close"
                      data-target="modal-improvement"
                      dangerouslySetInnerHTML={{
                        __html: modal.improvement_modal_button_added_to_plan,
                      }}
                    />
                  </div>
                  <div className="form-item-column">
                    <button
                      type="button"
                      className="a-button a-button--s a-button--outlined modal-close remove-improvement-button"
                      data-target="modal-improvement"
                      onClick={handleRemoveImprovement}
                      dangerouslySetInnerHTML={{
                        __html:
                          modal.improvement_modal_button_added_to_plan_remove,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {!setUpdated && status !== 'green' && (
              <button
                type="submit"
                className="a-button a-button--s modal-close submit-improvement a-button"
                data-target="modal-improvement"
                dangerouslySetInnerHTML={{
                  __html: modal.improvement_modal_button_not_added_to_plan,
                }}
              />
            )}
          </div>
        </form>
      </div>
      <div
        className="modal-overlay modal-close"
        data-target="modal-improvement"
        onMouseDown={handleHideModal}
        role="button"
        aria-label="modal"
      >
        &nbsp;
      </div>
    </div>
  )

  return createPortal(
    show || showModalMap ? <FocusTrap>{children}</FocusTrap> : children,
    document.getElementsByTagName('body')[0],
  )
}
export default ModalImprovementDetail
